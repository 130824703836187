import { createSlice } from "@reduxjs/toolkit";

const chartSlice = createSlice({
  name: "chart",
  initialState: {
    monthlyChart: {},
    dailyChart: {},
    hourChart: {},
    tradingGrowth: {},
    tradingGrowthBottom: {},
  },
  reducers: {
    setMonthlyChart: (state, action) => {
      state.monthlyChart = action.payload;
    },
    setDailyChart: (state, action) => {
      state.dailyChart = action.payload;
    },
    setHourChart: (state, action) => {
      state.hourChart = action.payload;
    },
    setTradingGrowth: (state, action) => {
      state.tradingGrowth = action.payload;
    },
    setTradingGrowthBottom: (state, action) => {
      state.tradingGrowthBottom = action.payload;
    },
  },
});

export const { setMonthlyChart, setDailyChart, setHourChart, setTradingGrowth, setTradingGrowthBottom} = chartSlice.actions;
export default chartSlice.reducer;
