import { Box, Button, CircularProgress, FormControl, InputLabel, TextField, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { CloudUpload } from "@mui/icons-material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { upload_image } from "server/chat";
import { updateChannelContent } from "server/channel";
import { setToggleChatOrChannelDrawer } from "redux/features/toggle/toggleSlice";
import ReactFileReader from "react-file-reader";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};
const formats = ["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", "video"];

const EditPostFormChannelDrawer = memo(() => {
  const dispatch = useDispatch();
  const activeChannelContentPost = useSelector((state) => state.channel.activeChannelContentPost);
  const activeChannel = useSelector((state) => state.channel.activeChannel);
  const [description, setDescription] = useState(activeChannelContentPost?.content || "");
  const [title, setTitle] = useState(activeChannelContentPost?.title || "");
  const { enqueueSnackbar } = useSnackbar();
  const [url, setUrl] = useState(activeChannelContentPost?.attachment_content || null);
  const [image, setImage] = useState(activeChannelContentPost?.attachment_content || null);
  const [loading, setLoading] = useState(false);

  const handleFiles = (files) => {
    const maxFileSizeMB = 2;
    if (!files?.fileList[0]) {
      return;
    }
    const fileSizeMB = files.fileList[0].size / (1024 * 1024);
    if (fileSizeMB > maxFileSizeMB) {
      enqueueSnackbar(`File size exceeds the maximum limit of ${maxFileSizeMB} MB`, {
        variant: "error",
      });
      return;
    }

    if (files?.fileList[0]?.type !== "image/jpeg" && files?.fileList[0]?.type !== "image/png") {
      enqueueSnackbar("Only jpg, jpeg, png is allowed", {
        variant: "error",
      });
      return;
    }
    setImage(files?.fileList[0]);
    setUrl(files.base64);
  };

  const handleServerUpload = async (formData) => {
    const upload = await upload_image(formData);

    if (!upload) {
      return await handleServerUpload(formData);
    }
    return upload;
  };

  const handleUpdateChannelPostContent = async () => {
    setLoading(true);
    try {
      if (title === "") {
        enqueueSnackbar("Title is required", {
          variant: "error",
        });
        return;
      }
      if (description === "") {
        enqueueSnackbar("Description is required", {
          variant: "error",
        });
        return;
      }
      if (image === null) {
        enqueueSnackbar("Image is required", {
          variant: "error",
        });
        return;
      }

      if (image) {
        const data = {
          title,
          content: description,
        };
        const result = await updateChannelContent(activeChannelContentPost?.id, data);
        if (result?.data?.status) {
          enqueueSnackbar(result?.data?.message, {
            variant: "success",
          });
          dispatch(setToggleChatOrChannelDrawer("channel"));
        } else {
          enqueueSnackbar(result?.data?.message, {
            variant: "error",
          });
          dispatch(setToggleChatOrChannelDrawer("channel"));
        }
      } else {
        const formData = new FormData();
        formData.append("file", image);
        formData.append("attachment_for", "channel");
        formData.append("for_id", activeChannel?.id);

        const upload = await handleServerUpload(formData);

        if (upload?.data?.status) {
          let imageLink = process.env.REACT_APP_CHAT_API_BASEURL + "/file/upload/" + upload.data.data.id;
          const data = {
            title,
            content: description,
            attachment_id: upload.data.data.id,
            attachment_content: imageLink,
          };
          const result = await updateChannelContent(activeChannelContentPost?.id, data);
          if (result?.data?.status) {
            enqueueSnackbar(result?.data?.message, {
              variant: "success",
            });
            dispatch(setToggleChatOrChannelDrawer("channel"));
          } else {
            enqueueSnackbar(result?.data?.message, {
              variant: "error",
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
      });
    } finally {
      setLoading(false);
      setDescription("");
      setTitle("");
      setImage(null);
      setUrl(null);
    }
  };

  return (
    <form
      className="chat-list-scrollbar"
      style={{
        padding: "15px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "calc(100vh - 180px)",
        overflowY: "auto",
        width: "100%",
      }}
    >
      <Box>
        <InputLabel className="formLabel">Post Title</InputLabel>
        <FormControl fullWidth>
          <TextField
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            id="title"
            name="title"
            sx={{
              "& .MuiOutlinedInput-root": {
                width: "100%",
                color: "white",
                "& fieldset": {
                  border: "1px solid #2F4E43",
                },
                "&:hover fieldset": {
                  border: "1px solid var(--color-green-1)",
                },
                "&.Mui-focused fieldset": {
                  border: `1px solid var(--color-green-2)`,
                },
              },
              "& .MuiInputLabel-root": {
                color: "white",
                "&.Mui-focused": {
                  color: "var(--color-green-2)",
                },
              },
            }}
            variant="outlined"
            size="small"
            placeholder="Post title"
          />
        </FormControl>
        <InputLabel className="formLabel mt-4">Post Description</InputLabel>
        <ReactQuill
          theme="snow"
          value={description}
          onChange={setDescription}
          placeholder="Post description"
          style={{ marginBottom: "16px" }}
          modules={modules}
          formats={formats}
        />
        <InputLabel className="formLabel mt-4">Upload Image</InputLabel>
        <FormControl fullWidth>
          <ReactFileReader fileTypes={["jpg", "jpeg", "png"]} base64={true} handleFiles={handleFiles}>
            <Box sx={{ border: "2px dotted #2F4E43", height: "210px", borderRadius: "5px" }} className="mb-4 text-center !cursor-pointer">
              <Box className="mt-4">
                {url ? (
                  <img src={url} style={{ margin: "0 auto", width: "200px", height: "140px", marginTop: "10px", marginBottom: "4px" }} alt="" />
                ) : (
                  <CloudUpload sx={{ margin: "0 auto", width: "80px", height: "80px", marginTop: "50px", marginBottom: "4px" }} />
                )}
                <Typography variant="body2" className="text-[var(--color-gray-3)]">
                  Click here to upload image
                </Typography>
              </Box>
            </Box>
          </ReactFileReader>
        </FormControl>
      </Box>
      <Box className="flex justify-center">
        <Button
          disabled={loading ? true : false}
          onClick={handleUpdateChannelPostContent}
          fullWidth
          variant="contained"
          sx={{
            background: "var(--color-green-1)",
            textTransform: "capitalize",
            boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
            "&:hover": {
              color: "var(--color-white-1)",
              background: "var(--color-green-2)",
            },
          }}
        >
          {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Create Post"}
        </Button>
      </Box>
    </form>
  );
});

export default EditPostFormChannelDrawer;


// import { Box, Button, CircularProgress, FormControl, InputLabel, TextField, Typography } from "@mui/material";
// import React, { memo, useState } from "react";
// import { useSnackbar } from "notistack";
// import { useDispatch, useSelector } from "react-redux";
// import { CloudUpload } from "@mui/icons-material";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import { upload_image } from "server/chat";
// import { updateChannelContent } from "server/channel";
// import { setToggleChatOrChannelDrawer } from "redux/features/toggle/toggleSlice";
// import ReactFileReader from "react-file-reader";

// const modules = {
//   toolbar: [
//     [{ header: "1" }, { header: "2" }, { font: [] }],
//     [{ size: [] }],
//     ["bold", "italic", "underline", "strike", "blockquote"],
//     [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
//     ["link", "image", "video"],
//     ["clean"],
//   ],
//   clipboard: {
//     matchVisual: false,
//   },
// };
// const formats = ["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", "video"];

// const EditPostFormChannelDrawer = memo(() => {
//   const dispatch = useDispatch();
//   const activeChannelContentPost = useSelector((state) => state.channel.activeChannelContentPost);
//   const activeChannel = useSelector((state) => state.channel.activeChannel);
//   const [description, setDescription] = useState(activeChannelContentPost?.content || "");
//   const [title, setTitle] = useState(activeChannelContentPost?.title || "");
//   const { enqueueSnackbar } = useSnackbar();
//   const [url, setUrl] = useState(activeChannelContentPost?.attachment_content || null);
//   const [image, setImage] = useState(activeChannelContentPost?.attachment_content || null);
//   const [loading, setLoading] = useState(false);
//   const [uploadedImageLink, setUploadedImageLink] = useState("");
//   const [uploadedImageId, setUploadedImageId] = useState("");

//   const handleFiles = (files) => {
//     const maxFileSizeMB = 2;
//     if (!files?.fileList[0]) {
//       return;
//     }
//     const fileSizeMB = files.fileList[0].size / (1024 * 1024);
//     if (fileSizeMB > maxFileSizeMB) {
//       enqueueSnackbar(`File size exceeds the maximum limit of ${maxFileSizeMB} MB`, {
//         variant: "error",
//       });
//       return;
//     }

//     if (files?.fileList[0]?.type !== "image/jpeg" && files?.fileList[0]?.type !== "image/png") {
//       enqueueSnackbar("Only jpg, jpeg, png is allowed", {
//         variant: "error",
//       });
//       return;
//     }
//     setImage(files?.fileList[0]);
//     setUrl(files.base64);
//   };

//   const handleServerUpload = async (formData) => {
//     const upload = await upload_image(formData);
//     if (!upload) {
//       return await handleServerUpload(formData);
//     }
//     return upload;
//   };

  
//   const handleUpdateChannelPostContent = async () => {
//     setLoading(true);
//     try {
//       if (title === "") {
//         enqueueSnackbar("Title is required", {
//           variant: "error",
//         });
//         return;
//       }
//       if (description === "") {
//         enqueueSnackbar("Description is required", {
//           variant: "error",
//         });
//         return;
//       }
//       if (image === null) {
//         enqueueSnackbar("Image is required", {
//           variant: "error",
//         });
//         return;
//       }

//       const formData = new FormData();
//       formData.append("file", image);
//       formData.append("attachment_for", "channel");
//       formData.append("for_id", activeChannel?.id);

//       const upload = await handleServerUpload(formData);

//       if (upload?.data?.status) {
//         let imageLink = process.env.REACT_APP_CHAT_API_BASEURL + "/file/upload/" + upload.data.data.id;
//         setUploadedImageLink(imageLink);
//         setUploadedImageId(upload.data.data.id);
//       }

//       console.log("🚀 ~ handleUpdateChannelPostContent ~ uploadedImageLink && uploadedImageId:", uploadedImageLink , uploadedImageId)
//       if (uploadedImageLink && uploadedImageId) {
//         const data = {
//           title,
//           content: description,
//           attachment_id: uploadedImageId,
//           attachment_content: uploadedImageLink,
//         };
//         const result = await updateChannelContent(activeChannelContentPost?.id, data);
//         console.log("🚀 ~ handleUpdateChannelPostContent ~ result:", result);
//       }else{
//         const data = {
//           title,
//           content: description,
//         };
//         const result = await updateChannelContent(activeChannelContentPost?.id, data);
//         console.log("🚀 ~ handleUpdateChannelPostContent ~ result:", result);
//       }





//     } catch (err) {
//       console.log(err);
//       enqueueSnackbar("Something went wrong!", {
//         variant: "error",
//       });
//     } finally {
//       setLoading(false);
//       setDescription("");
//       setTitle("");
//       setImage(null);
//       setUrl(null);
//     }
//   };

//   return (
//     <form
//       className="chat-list-scrollbar"
//       style={{
//         padding: "15px",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "space-between",
//         height: "calc(100vh - 180px)",
//         overflowY: "auto",
//         width: "100%",
//       }}
//     >
//       <Box>
//         <InputLabel className="formLabel">Post Title</InputLabel>
//         <FormControl fullWidth>
//           <TextField
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             id="title"
//             name="title"
//             sx={{
//               "& .MuiOutlinedInput-root": {
//                 width: "100%",
//                 color: "white",
//                 "& fieldset": {
//                   border: "1px solid #2F4E43",
//                 },
//                 "&:hover fieldset": {
//                   border: "1px solid var(--color-green-1)",
//                 },
//                 "&.Mui-focused fieldset": {
//                   border: `1px solid var(--color-green-2)`,
//                 },
//               },
//               "& .MuiInputLabel-root": {
//                 color: "white",
//                 "&.Mui-focused": {
//                   color: "var(--color-green-2)",
//                 },
//               },
//             }}
//             variant="outlined"
//             size="small"
//             placeholder="Post title"
//           />
//         </FormControl>
//         <InputLabel className="formLabel mt-4">Post Description</InputLabel>
//         <ReactQuill
//           theme="snow"
//           value={description}
//           onChange={setDescription}
//           placeholder="Post description"
//           style={{ marginBottom: "16px" }}
//           modules={modules}
//           formats={formats}
//         />
//         <InputLabel className="formLabel mt-4">Upload Image</InputLabel>
//         <FormControl fullWidth>
//           <ReactFileReader fileTypes={["jpg", "jpeg", "png"]} base64={true} handleFiles={handleFiles}>
//             <Box sx={{ border: "2px dotted #2F4E43", height: "210px", borderRadius: "5px" }} className="mb-4 text-center !cursor-pointer">
//               <Box className="mt-4">
//                 {url ? (
//                   <img src={url} style={{ margin: "0 auto", width: "200px", height: "140px", marginTop: "10px", marginBottom: "4px" }} alt="" />
//                 ) : (
//                   <CloudUpload sx={{ margin: "0 auto", width: "80px", height: "80px", marginTop: "50px", marginBottom: "4px" }} />
//                 )}
//                 <Typography variant="body2" className="text-[var(--color-gray-3)]">
//                   Click here to upload image
//                 </Typography>
//               </Box>
//             </Box>
//           </ReactFileReader>
//         </FormControl>
//       </Box>
//       <Box className="flex justify-center">
//         <Button
//           disabled={loading ? true : false}
//           onClick={handleUpdateChannelPostContent}
//           fullWidth
//           variant="contained"
//           sx={{
//             background: "var(--color-green-1)",
//             textTransform: "capitalize",
//             boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
//             "&:hover": {
//               color: "var(--color-white-1)",
//               background: "var(--color-green-2)",
//             },
//           }}
//         >
//           {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Create Post"}
//         </Button>
//       </Box>
//     </form>
//   );
// });

// export default EditPostFormChannelDrawer;
