import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setChatList, setFriendList, setGroupChatList, setGroupList, setIncomingFriendList, setOutGoingFriendList } from "redux/features/chatSlice/chatSlice";
import { setChatListLoading } from "redux/features/loader/loaderSlice";
import { chat_list, friend_list, get_group_chat_list, get_group_list, incoming_friend_list, outgoing_friend_list } from "server/chat";

const InitialChatRequest = () => {
  const dispatch = useDispatch();
  const chat_user = useSelector((state) => state?.chat?.user?.id);

  const chatList = async () => {
    dispatch(setChatListLoading(true));
    if (chat_user) {
      const result = await chat_list();
      if (result.data?.status) {
        dispatch(setChatList(result.data.data));
        dispatch(setChatListLoading(false));
      } else {
        dispatch(setChatListLoading(false));
      }
    } else {
      dispatch(setChatListLoading(false));
    }
  };

  const friendListIncoming = async () => {
    if (chat_user) {
      const result = await incoming_friend_list();
      if (result.data?.status) {
        dispatch(setIncomingFriendList(result.data.data));
      }
    }
  };
  const friendList = async () => {
    if (chat_user) {
      const result = await friend_list();
      if (result.data?.status) {
        dispatch(setFriendList(result.data.data));
      }
    }
  };
  const outgoingFriendList = async () => {
    if (chat_user) {
      const result = await outgoing_friend_list();
      if (result.data?.status) {
        dispatch(setOutGoingFriendList(result.data.data));
      }
    }
  };

  // get all group List
  const getGroupList = async () => {
    if (chat_user) {
      const result = await get_group_list();
      if (result.data?.status) {
        dispatch(setGroupList(result.data.data));
      }
    }
  };
  // get all group chat list
  const getGroupChatList = async () => {
    if (chat_user) {
      const result = await get_group_chat_list();
      if (result.data?.status) {
        dispatch(setGroupChatList(result.data.data));
      }
    }
  };

  React.useEffect(() => {
    chatList();
    friendListIncoming();
    friendList();
    outgoingFriendList();
    getGroupList();
    getGroupChatList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default InitialChatRequest;
