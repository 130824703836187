import { Chart } from "chart.js/auto";
import React, { memo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

const LineChartAccountSection = () => {
  const chartRef = useRef(null);
  const performanceChart = useSelector((state) => state.pam.performanceChart);
  useEffect(() => {
    if (chartRef.current && performanceChart) {
      const ctx = chartRef.current.getContext("2d");
      const chartInstance = new Chart(ctx, {
        type: "line",
        data: {
          labels: performanceChart?.label,
          datasets: [
            {
              label: "",
              fill: false,
              tension: 0.4,
              backgroundColor: "#1A8D66",
              borderColor: "#27D195",
              data: performanceChart?.data,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 2,
              pointBackgroundColor: "#00d094",
            },
          ],
        },
        options: {
          scales: {
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              display: true,
              title: {
                display: false,
                text: "Time",
              },
            },
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              display: true,
              title: {
                display: false,
                text: "Value",
              },
            },
          },
          elements: {
            line: {
              borderWidth: 3,
            },
            point: {
              radius: 0,
              backgroundColor: "rgba(75,192,192,1)",
              hoverRadius: 5,
            },
          },
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 10,
              bottom: 10,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          chartArea: {
            backgroundColor: "transparent",
          },
          responsive: true,
          maintainAspectRatio: false,
          maxHeight: "100vh",
          hover: {
            intersect: false,
          },
        },
      });

      return () => {
        chartInstance.destroy();
      };
    }
  }, [performanceChart]);

  return <canvas ref={chartRef} style={{ height: "350px", width: "100%" }} />;
};

export default memo(LineChartAccountSection);
