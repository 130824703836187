import { createSlice } from "@reduxjs/toolkit";

const applicationSlice = createSlice({
  name: "application",
  initialState: {
    theme: "dark",
    loading: false,
    globalLoading: true,
    copyTradeModalOpen: false,
    updateProfileModalOpen: false,
    openUnCopyDialog: false,
    openAddAccountModal: false,
    openChangePasswordModal: false,
    openBrokerModalJoin: false,
    purchaseSubscriptionModal: false,
  },

  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    toggleTheme: (state) => {
      state.theme = state.theme === "dark" ? "light" : "dark";
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCopyTradeModalOpen: (state, action) => {
      state.copyTradeModalOpen = action.payload;
    },
    setUpdateProfileModalOpen: (state, action) => {
      state.updateProfileModalOpen = action.payload;
    },
    setOpenUnCopyDialog: (state, action) => {
      state.openUnCopyDialog = action.payload;
    },
    setOpenAddAccount: (state, action) => {
      state.openAddAccountModal = action.payload;
    },
    setOpenChangePasswordModal: (state, action) => {
      state.openChangePasswordModal = action.payload;
    },
    setOpenBrokerModalJoin: (state, action) => {
      state.openBrokerModalJoin = action.payload;
    },
    setPurchaseSubscriptionModal: (state, action) => {
      state.purchaseSubscriptionModal = action.payload;
    },
    setGlobalLoading: (state, action) => {
      state.globalLoading = action.payload;
    },
  },
});

export const { setTheme, toggleTheme, setLoading, setCopyTradeModalOpen, setUpdateProfileModalOpen, setOpenUnCopyDialog, setOpenAddAccount, setOpenChangePasswordModal, setOpenBrokerModalJoin, setPurchaseSubscriptionModal, setGlobalLoading } = applicationSlice.actions;
export default applicationSlice.reducer;
