import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "redux/features/auth/authSlice";
import {
  addChatHistory,
  addCurrentChatHistory,
  addFriendList,
  addGroupChatHistory,
  addIncomingFriendList,
  addOutGoingFriendList,
  removeFriendList,
  removeIncomingFriendList,
  removeOutGoingFriendList,
  setTyping,
  signOutChat,
} from "redux/features/chatSlice/chatSlice";
import { logOut } from "server/auth";
import { refreshToken } from "server/chat";
import { socketServiceDisconnect, socketServicePingPong } from "services/socketService";
import { Howl } from "howler";
import notificationSoundSrc from "../assets/notification/notification2.mp3";
import { addUnseenNotification } from "redux/features/notification/notificationSlice";
import { store } from "redux/store";
let chatTyping = true;
const InitSocket = ({ openChat }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.chat.user);
  const socket = useSelector((state) => state.chat.socket);
  // const activeChat = useSelector((state) => state.chat.activeChat);

  let notificationSound = new Howl({
    src: [notificationSoundSrc],
  });
  const socketStreamService = async () => {
    socket.on("TokenInvalid", async () => {
      const res = await refreshToken();
      if (!res) {
        await logOut();
        dispatch(signOut());
        dispatch(signOutChat());
        window.location.reload(true);
      }
    });
    socket.on("chat", (res) => {
      if (store.getState().chat.activeChat?.id !== res?.chat_id) {
        notificationSound.play();
        const data = {
          user_id: user?.id,
          for_id: res?.chat_id,
          for_type: "CHAT",
        }
        dispatch(addUnseenNotification(data));
      } else {
        if(res?.sender_id !== user?.id){
          dispatch(addChatHistory(res));
        }else{
          dispatch(addCurrentChatHistory(res));
        }
      }
    });
    socket.on("chatNotFound", (res) => {
      console.log(res);
    });
    socket.on("friendRequest", (res) => {
      if (res.user_one_id === user.id) {
        dispatch(
          addOutGoingFriendList({
            id: res.id,
            user: res.user_two,
          })
        );
      } else {
        dispatch(
          addIncomingFriendList({
            id: res.id,
            user: res.user_one,
          })
        );
      }
    });
    socket.on("friendRequestAccept", (res) => {
      if (res.status === "WITHDRAW") {
        if (res.user_one_id === user.id) {
          dispatch(
            removeOutGoingFriendList({
              id: res.id,
            })
          );
        } else {
          dispatch(
            removeIncomingFriendList({
              id: res.id,
            })
          );
        }
      } else if (res.status === "ACCEPTED") {
        if (res.user_one_id === user.id) {
          dispatch(
            removeOutGoingFriendList({
              id: res.id,
            })
          );
          dispatch(
            addFriendList({
              id: res.id,
              user: res.user_two,
            })
          );
        } else {
          dispatch(
            removeIncomingFriendList({
              id: res.id,
            })
          );
          dispatch(
            addFriendList({
              id: res.id,
              user: res.user_one,
            })
          );
        }
      } else if (res.status === "REJECTED") {
        if (res.user_one_id === user.id) {
          dispatch(
            removeOutGoingFriendList({
              id: res.id,
            })
          );
        } else {
          dispatch(
            removeIncomingFriendList({
              id: res.id,
            })
          );
        }
      } else if (res.status === "UNFRIEND") {
        dispatch(
          removeFriendList({
            id: res.id,
          })
        );
      }
    });
    socket.on("chatTyping", (res) => {
      if (res === user.id && !chatTyping) {
        dispatch(setTyping(true));
        setTimeout(() => {
          dispatch(setTyping(false));
        }, 5000);
      }
      chatTyping = false;
    });

    // group
    socket.on("groupChat", (res) => {
      if (store.getState().chat.activeChatGroup.id !== res.group_id ) {
        notificationSound.play();
        const data = {
          user_id: user.id,
          for_id: res.group_id,
          for_type: "GROUP_CHAT",
        }
        dispatch(addUnseenNotification(data));
      }
      dispatch(addGroupChatHistory(res));
    });
  };
  useEffect(() => {
    if (!socket || !user?.id) return;

    socketStreamService();

    const intId = setInterval(() => {
      socketServicePingPong();
    }, 1000 * 30);
    return () => {
      clearInterval(intId);
      socketServiceDisconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, socket, openChat]);
  return null;
};

export default InitSocket;
