import { createSlice } from "@reduxjs/toolkit";

const tradeSlice = createSlice({
    name: "trade",
    initialState: {
        openTrade: {},
        closeTrade: {},
        openTradeHistory: {},
        closeTradeHistory: {}
    },
    reducers: {
        setOpenTrade: (state, action) => {
            state.openTrade = action.payload;
        },
        setCloseTrade: (state, action) => {
            state.closeTrade = action.payload;
        },
        setOpenTradeHistory: (state, action) => {
            state.openTradeHistory = action.payload;
        },
        setCloseTradeHistory: (state, action) => {
            state.closeTradeHistory = action.payload;
        }
    },
});

export const { setOpenTrade, setCloseTrade, setCloseTradeHistory, setOpenTradeHistory } = tradeSlice.actions;
export default tradeSlice.reducer;
