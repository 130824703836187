import { Box, Divider } from "@mui/material";
import React, { memo } from "react";
import ChannelHeader from "./ChannelHeader";
import ChannelContent from "./ChannelContent";
// import ChannelMute from "./ChannelMute";

const Channel = memo(() => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 64px)",
        background: "var(--color-green-9)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <ChannelHeader />
        <Divider />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <ChannelContent />
      </Box>
      {/* <ChannelMute /> */}
    </Box>
  );
});

export default Channel;
