import { Grid, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";

const PammUserSkeletonLoader = memo(() => {
  return (
    <Grid container sx={{ justifyContent: "center" }}>
      <Grid item xs={12}>
        <Box>
          <Typography component="div" variant="h6">
            <Skeleton />
          </Typography>
          <Typography component="div" variant="h6">
            <Skeleton />
          </Typography>
          <Typography component="div" variant="h6">
            <Skeleton />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
});

export default PammUserSkeletonLoader;
