import mirrorApi from '../api/mirror_trade_api';
import { getAuthToken } from './auth';

export const pamRequest = async () => {
    try {
        return await mirrorApi.post(`/pamm-request`, {}, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
    console.log("🚀 ~ file: pam_request.js:12 ~ pamRequest ~ error:", error)
    }
}