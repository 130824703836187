import { createSlice } from "@reduxjs/toolkit";

const drawerSlice = createSlice({
  name: "drawer",
  initialState: {
    open: true,
    drawerWidth: 240,
  },
  reducers: {
    setDrawer: (state) => {
      state.open = !state.open;
    },
    setDrawerWidth: (state, action) => {
      state.open = action.payload;
    },
  },
});

export const { setDrawer, setDrawerWidth } = drawerSlice.actions;
export default drawerSlice.reducer;
