import { East, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import message from "enum/message";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setLoading } from "redux/features/application/applicationSlice";
import { setNavigateAuth } from "redux/features/navigateSlice/navigateSlice";
import { signUp } from "server/auth";
import { registration } from "server/chat";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup.string("Enter a valid name").required(message.EMAIL_REQUIRED),
  email: yup.string("Enter your email").email("Enter a valid email").required(message.EMAIL_REQUIRED),
  password: yup.string("Enter your password").min(6, message.MIN_PASSWORD).required(message.PASSWORD_REQUIRED),
});

const EmailRegistration = () => {
  const location = useLocation();
  const ref = new URLSearchParams(location.search).get("ref");
  const { enqueueSnackbar } = useSnackbar();
  const loading = useSelector((state) => state.application.loading);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      dispatch(setLoading(true));
      const mirrorResult = await signUp({
        auth_type: "email",
        name: values.name,
        email: values.email,
        password: values.password,
        confirm_password: values.password,
        ...(ref && { ref }),
      });

      if (mirrorResult?.data.status) {
        dispatch(setLoading(false));
        dispatch(setNavigateAuth("verifyEmailOtp"));
        enqueueSnackbar("User created successfully, Verify your email.", { variant: "success" });
      } else {
        enqueueSnackbar(mirrorResult?.data.errors.email[0], { variant: "warning" });
        dispatch(setLoading(false));
      }

      const result = await registration({
        name: values.name,
        email: values.email,
        password: values.password,
      });

      if (result?.data?.status) {
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
      }
    },
  });

  return (
    <form autoComplete="off" className="w-[90%] md:w-[335px] space-y-6" onSubmit={formik.handleSubmit}>
      <Box>
        <TextField
          label="Name"
          variant="standard"
          type="text"
          fullWidth
          size="medium"
          id="name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          sx={{
            "& .MuiInput-root": {
              color: "var(--color-black)",
              fontSize: "16px",
              fontWeight: 500,
            },
            "& label": {
              fontSize: "16px",
              color: "var(--color-white-1)",
              fontWeight: 500,
            },
            "& label.Mui-focused": {
              color: "var(--color-green-2)",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "var(--color-green-2)",
            },
          }}
        />
      </Box>
      <Box>
        <TextField
          label="Email"
          variant="standard"
          type="email"
          fullWidth
          size="medium"
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          sx={{
            "& .MuiInput-root": {
              color: "var(--color-black)",
              fontSize: "16px",
              fontWeight: 500,
            },
            "& label": {
              fontSize: "16px",
              color: "var(--color-white-1)",
              fontWeight: 500,
            },
            "& label.Mui-focused": {
              color: "var(--color-green-2)",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "var(--color-green-2)",
            },
          }}
        />
      </Box>
      <Box>
        <TextField
          label="Password"
          variant="standard"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          size="medium"
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          sx={{
            "& .MuiInput-root": {
              color: "var(--color-black)",
              fontSize: "16px",
              fontWeight: 500,
            },
            "& label": {
              fontSize: "16px",
              color: "var(--color-white-1)",
              fontWeight: 500,
            },
            "& label.Mui-focused": {
              color: "var(--color-green-2)",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "var(--color-green-2)",
            },
          }}
        />
      </Box>

      <Button
        type="submit"
        sx={{
          background: "var(--color-green-1)",
          textTransform: "none",
          "&:hover": {
            background: "var(--color-green-11)",
          },
        }}
        disableRipple
        variant="contained"
        fullWidth
      >
        {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Registration"}
      </Button>
      <Box className="flex justify-center items-center gap-2 pb-3">
        <Typography>Already have an account?</Typography>
        <Typography
          component={"p"}
          className="hover:!text-[var(--color-green-1)] !cursor-pointer !flex !justify-center !items-center"
          onClick={() => dispatch(setNavigateAuth("login"))}
        >
          Login <East className="!ml-1" />
        </Typography>
      </Box>
    </form>
  );
};

export default memo(EmailRegistration);
