import { Box, Grid, useTheme } from "@mui/material";
import Layout from "layouts/Layout";
import React, { memo, useState } from "react";
import All from "./All";
import OpenTicket from "./OpenTicket";
import CloseTicket from "./CloseTicket";
import AnswerTicket from "./AnswerTicket";
import InProgressTicket from "./InProgressTicket";
import OnHoldTicket from "./OnHoldTicket";

const SupportTicket = memo(() => {
  const theme = useTheme();

  const [activeTab, setActiveTab] = useState("All");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabDetails = [
    { label: "All", color: "bg-[#5ECFCC]" },
    { label: "Open", color: "bg-green-500" },
    { label: "Closed", color: "bg-indigo-500" },
    { label: "Answer", color: "bg-yellow-400" },
    { label: "In-Progress", color: "bg-violet-500" },
    { label: "On-Hold", color: "bg-red-400" },
  ];

  return (
    <Layout>
      <Grid container className="p-5">
        <Grid
          sx={{
            background: theme.palette.mode === "dark" ? "#27D195" : "var(--color-black-4)",
          }}
          item
          xs={12}
          className="h-24 rounded"
        >
          <Box className="px-5">
            <Grid container sx={{ minHeight: "60px" }} alignItems={"center"} gap={2} className="bg-[#0F3529] text-[var(--color-white-1)] mt-12 rounded p-2">
              {tabDetails.map(({ label, color }) => (
                <Grid
                  item
                  key={label}
                  className={`cursor-pointer px-4 py-2 rounded-md ${activeTab === label ? `bg-[var(--color-green-1)] text-black` : "bg-transparent"} flex items-center gap-1`}
                  onClick={() => handleTabClick(label)}
                >
                  {label !== "All" && <Box className={`h-3 w-3 rounded-full mr-2 ${color}`}></Box>} {label}
                </Grid>
              ))}
            </Grid>
          </Box>
          {activeTab === "All" && <All />}
          {activeTab === "Open" && <OpenTicket />}
          {activeTab === "Closed" && <CloseTicket />}
          {activeTab === "Answer" && <AnswerTicket />}
          {activeTab === "In-Progress" && <InProgressTicket />}
          {activeTab === "On-Hold" && <OnHoldTicket />}
        </Grid>
      </Grid>
    </Layout>
  );
});

export default SupportTicket;
