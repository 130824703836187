import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import bitcoin from "assets/package/bitcoin.png";
import { Formik } from "formik";
import Layout from "layouts/Layout";
import { useSnackbar } from "notistack";
import React, { memo, useEffect, useState } from "react";
import { cryptoWithdraw, getCryptoWithdrawDetails } from "server/subscription";
import * as yup from "yup";

const steps = ["Crypto Address", "Amount", "Status"];
const formValidationSchema = yup.object({
  usd_amount: yup.string("Enter your usd amount").required("USD amount is required"),
  crypto_amount: yup.string("Enter your crypto amount").required("Crypto amount is required"),
  account_password: yup.string("Enter your account password").required("Account password is required"),
});

const CryptoWithdraw = memo(() => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [lastTransaction, setLastTransaction] = useState({});
  const [loading, setLoading] = useState(false);
  const [cryptoDetails, setCryptoDetails] = useState({});
  const [blockChain, setBlockChain] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [selectCrypto, setSelectedCrypto] = useState("");
  const [selectCryptoError, setSelectCryptoError] = useState("");
  const [selectedBlockChain, setSelectedBlockChain] = useState("");
  const [selectedBlockChainError, setSelectedBlockChainError] = useState("");
  const [receiverAmount, setReceiverAmount] = useState("");
  const [receiverAmountError, setReceiverAmountError] = useState("");
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = (step) => {
    if (!selectCrypto) {
      setSelectCryptoError("Crypto currency is required");
    } else {
      setSelectCryptoError("");
    }

    if (!selectedBlockChain) {
      setSelectedBlockChainError("Blockchain is required");
    } else {
      setSelectedBlockChainError("");
    }
    if (!receiverAmount) {
      setReceiverAmountError("Receiver amount is required");
    } else {
      setReceiverAmountError("");
    }

    if (selectCrypto && selectedBlockChain && receiverAmount) {
      setActiveStep(step);
    }
  };

  useEffect(() => {
    if (selectCrypto === "BTC") {
      setBlockChain(cryptoDetails?.block_chain?.filter((block) => block.block_chain === "BITCOIN"));
    } else if (selectCrypto === "USDT") {
      setBlockChain(cryptoDetails?.block_chain.filter((block) => block.block_chain === "TRC20" || block.block_chain === "ERC20"));
    }
  }, [selectCrypto, cryptoDetails]);

  const fetchCryptoWithdrawDetails = async () => {
    const result = await getCryptoWithdrawDetails();
    if (result?.data?.status) {
      setCryptoDetails(result?.data);
    }
  };

  useEffect(() => {
    fetchCryptoWithdrawDetails();
  }, []);

  return (
    <Layout>
      <Grid container justifyContent={"center"} className="p-5">
        <Grid item>
          <Typography variant="h4" className="!capitalize !font-bold !text-center">
            Crypto Withdraw
          </Typography>
          <Typography className="!capitalize !text-center">We need your information because you make a transaction.</Typography>
        </Grid>
      </Grid>
      {/* Step  */}
      <Grid container sx={{ width: isSmallScreen ? "90%" : "50%", mx: "auto", mb: "30px", mt: "10px" }}>
        <Grid item xs={12}>
          <Stepper activeStep={activeStep}>
            {steps?.map((label) => {
              return (
                <Step
                  key={label}
                  sx={{
                    "& .MuiSvgIcon-root.Mui-completed": { color: theme.palette.mode === "dark" ? "white" : "#0c1f19 !important" },
                    "& .MuiSvgIcon-root.Mui-active": { color: "var(--color-green-1)" },
                  }}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
      </Grid>

      <Box
        sx={{
          background: theme.palette.mode === "dark" ? "var(--color-green-7)" : "var(--color-green-10)",
          width: isMediumScreen ? "90%" : "70%",
          mx: "auto",
          padding: "25px",
          borderRadius: "10px",
        }}
      >
        <Box>
          <Typography variant="h6" className="!capitalize !font-bold !text-center">
            {activeStep === 0 ? "Lets start with the basic information" : activeStep === 1 ? "Check your transaction carefully" : ""}
          </Typography>
          <Typography className="!capitalize !text-center">
            {activeStep === 0 ? "Please read carefully first and submit" : activeStep === 1 ? "Please provide your transaction information after click next" : ""}{" "}
          </Typography>
        </Box>
        <Grid container sx={{ mt: "20px" }}>
          {!isMediumScreen && (
            <Grid item xs={4}>
              <Box sx={{ width: "150px" }}>
                <img className="w-full" src={bitcoin} alt="bitcoin logo" />
              </Box>
            </Grid>
          )}
          <Grid item xs={isMediumScreen ? 12 : 8}>
            {activeStep === 0 && (
              <Grid item xs={12}>
                <Box className="space-y-7">
                  <FormControl
                    sx={{
                      marginTop: "10px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid var(--color-green-11)",
                        },
                        "&:hover fieldset": {
                          border: "1px solid var(--color-green-1)",
                        },
                        "&.Mui-focused fieldset": {
                          border: `1px solid var(--color-green-2)`,
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                        "&.Mui-focused": {
                          color: "var(--color-green-2)",
                        },
                      },
                    }}
                    size="small"
                  >
                    <InputLabel id="demo-select-small-label">Cryptocurrency</InputLabel>
                    <Select
                      MenuProps={{
                        PaperProps: {
                          style: {
                            background: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
                            height: isSmallScreen && 150,
                          },
                        },
                      }}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      label="Cryptocurrency"
                      value={selectCrypto}
                      onChange={(e) => {
                        setSelectedCrypto(e.target.value);
                        setSelectCryptoError("");
                      }}
                    >
                      <MenuItem value="">
                        <em>Select a crypto</em>
                      </MenuItem>
                      {cryptoDetails?.crypto_currency?.map((item, i) => (
                        <MenuItem key={i} value={item.crypto_currency}>
                          {item.crypto_currency}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography component={"p"} className="!text-[var(--color-red-1)] !text-sm !mt-1 !font-semibold">
                      {selectCryptoError}
                    </Typography>
                  </FormControl>
                  <FormControl
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid var(--color-green-11)",
                        },
                        "&:hover fieldset": {
                          border: "1px solid var(--color-green-1)",
                        },
                        "&.Mui-focused fieldset": {
                          border: `1px solid var(--color-green-2)`,
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                        "&.Mui-focused": {
                          color: "var(--color-green-2)",
                        },
                      },
                    }}
                    size="small"
                  >
                    <InputLabel id="demo-select-small-label">Blockchain</InputLabel>
                    <Select
                      MenuProps={{
                        PaperProps: {
                          style: {
                            background: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
                            height: isSmallScreen && 150,
                          },
                        },
                      }}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      label="Blockchain"
                      value={selectedBlockChain}
                      onChange={(e) => {
                        setSelectedBlockChain(e.target.value);
                        setSelectedBlockChainError("");
                      }}
                    >
                      <MenuItem value="">
                        <em>Select a crypto instrument</em>
                      </MenuItem>
                      {blockChain?.map((item, i) => (
                        <MenuItem key={i} value={item.block_chain}>
                          {item.block_chain}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography component={"p"} className="!text-[var(--color-red-1)] !text-sm !font-semibold !mt-1">
                      {selectedBlockChainError}
                    </Typography>
                  </FormControl>

                  <FormControl
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        height: "100%",
                        "& fieldset": {
                          border: "1px solid var(--color-green-11)",
                        },
                        "&:hover fieldset": {
                          border: "1px solid var(--color-green-1)",
                        },
                        "&.Mui-focused fieldset": {
                          border: `1px solid var(--color-green-2)`,
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                        "&.Mui-focused": {
                          color: "var(--color-green-2)",
                        },
                      },
                    }}
                    size="small"
                  >
                    <TextField
                      type="text"
                      onChange={(e) => {
                        setReceiverAmount(e.target.value);
                        setReceiverAmountError("");
                      }}
                      value={receiverAmount}
                      label="Receiver Crypto Address"
                      size="small"
                      variant="outlined"
                    />
                    <Typography component={"p"} className="!text-[var(--color-red-1)] !text-sm !mt-1 !font-semibold">
                      {receiverAmountError}
                    </Typography>
                  </FormControl>

                  <Box className="flex justify-end">
                    <Button
                      onClick={() => handleNext(1)}
                      sx={{
                        background: "var(--color-green-1)",
                        textTransform: "capitalize",
                        color: "var(--color-white-1)",
                        "&:hover": {
                          color: "var(--color-white-1)",
                          background: "var(--color-green-2)",
                        },
                      }}
                      type="submit"
                      variant="contained"
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </Grid>
            )}
            {activeStep === 1 && (
              <Grid item xs={12}>
                <Formik
                  initialValues={{
                    usd_amount: "",
                    crypto_amount: "",
                    account_password: "",
                  }}
                  validationSchema={formValidationSchema}
                  onSubmit={async (values) => {
                    setLoading(true);
                    const data = {
                      crypto_currency: selectCrypto,
                      block_chain: selectedBlockChain,
                      crypto_address: receiverAmount,
                      usd_amount: values.usd_amount,
                      crypto_amount: values.crypto_amount,
                      password: values.account_password,
                    };
                    const result = await cryptoWithdraw(data);
                    if (result?.data?.status) {
                      enqueueSnackbar(result?.data?.message, { variant: "success" });
                      setLastTransaction(result?.data?.last_transaction);
                      setActiveStep(2);
                      setSelectedBlockChain("");
                      setSelectedCrypto("");
                      setReceiverAmount("");
                      setLoading(false);
                    } else {
                      enqueueSnackbar(result?.data?.message, { variant: "warning" });
                      setLoading(false);
                    }
                  }}
                >
                  {({ handleChange, values, handleSubmit, errors, touched }) => (
                    <Box className="space-y-7">
                      <FormControl
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            height: "100%",
                            "& fieldset": {
                              border: "1px solid var(--color-green-11)",
                            },
                            "&:hover fieldset": {
                              border: "1px solid var(--color-green-1)",
                            },
                            "&.Mui-focused fieldset": {
                              border: `1px solid var(--color-green-2)`,
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                            "&.Mui-focused": {
                              color: "var(--color-green-2)",
                            },
                          },
                        }}
                        size="small"
                      >
                        <TextField
                          type="text"
                          name="usd_amount"
                          value={values.usd_amount}
                          onChange={handleChange}
                          error={touched.usd_amount && Boolean(errors.usd_amount)}
                          helperText={touched.usd_amount && errors.usd_amount}
                          label="USD Amount"
                          size="small"
                          variant="outlined"
                        />
                      </FormControl>
                      <FormControl
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            height: "100%",
                            "& fieldset": {
                              border: "1px solid var(--color-green-11)",
                            },
                            "&:hover fieldset": {
                              border: "1px solid var(--color-green-1)",
                            },
                            "&.Mui-focused fieldset": {
                              border: `1px solid var(--color-green-2)`,
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                            "&.Mui-focused": {
                              color: "var(--color-green-2)",
                            },
                          },
                        }}
                        size="small"
                      >
                        <TextField
                          type="text"
                          name="crypto_amount"
                          value={values.crypto_amount}
                          onChange={handleChange}
                          error={touched.crypto_amount && Boolean(errors.crypto_amount)}
                          helperText={touched.crypto_amount && errors.crypto_amount}
                          label="Crypto Amount"
                          size="small"
                          variant="outlined"
                        />
                      </FormControl>
                      <FormControl
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            height: "100%",
                            "& fieldset": {
                              border: "1px solid var(--color-green-11)",
                            },
                            "&:hover fieldset": {
                              border: "1px solid var(--color-green-1)",
                            },
                            "&.Mui-focused fieldset": {
                              border: `1px solid var(--color-green-2)`,
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                            "&.Mui-focused": {
                              color: "var(--color-green-2)",
                            },
                          },
                        }}
                        size="small"
                      >
                        <TextField
                          type="password"
                          name="account_password"
                          value={values.account_password}
                          onChange={handleChange}
                          error={touched.account_password && Boolean(errors.account_password)}
                          helperText={touched.account_password && errors.account_password}
                          label="Account Password"
                          size="small"
                          variant="outlined"
                        />
                      </FormControl>

                      <Box className="flex justify-between mt-3">
                        <Button
                          onClick={() => setActiveStep(0)}
                          sx={{
                            background: "var(--color-green-1)",
                            textTransform: "capitalize",
                            color: "var(--color-white-1)",
                            "&:hover": {
                              color: "var(--color-white-1)",
                              background: "var(--color-green-2)",
                            },
                          }}
                          type="submit"
                          variant="contained"
                        >
                          Previous
                        </Button>
                        <Button
                          onClick={handleSubmit}
                          sx={{
                            width: "140px",
                            background: "var(--color-green-1)",
                            textTransform: "capitalize",
                            color: "var(--color-white-1)",
                            "&:hover": {
                              color: "var(--color-white-1)",
                              background: "var(--color-green-2)",
                            },
                          }}
                          type="submit"
                          variant="contained"
                        >
                          {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Submit request"}
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Formik>
              </Grid>
            )}
            {activeStep === 2 && (
              <Grid item xs={12}>
                <Box className="p-5 dark:bg-[#182d27] bg-[var(--color-green-5)] rounded-md text-[var(--color-white-1)] text-center space-y-1">
                  <Grid container>
                    <Grid item xs={4} className="text-right text-base">
                      Amount
                    </Grid>
                    <Grid item xs={4}>
                      :
                    </Grid>
                    <Grid item xs={4} className="font-bold text-green-600 capitalize text-base text-left">
                      ${lastTransaction?.amount}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} className="text-right text-base">
                      Transaction Id
                    </Grid>
                    <Grid item xs={4}>
                      :
                    </Grid>
                    <Grid item xs={4} className="font-bold text-green-600 capitalize text-base text-left">
                      <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} title={isMediumScreen ? lastTransaction?.transaction_id : ""}>
                        {lastTransaction?.transaction_id}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} className="text-right text-base">
                      Status
                    </Grid>
                    <Grid item xs={4}>
                      :
                    </Grid>
                    <Grid item xs={4} className="font-bold text-yellow-400 capitalize text-base text-left">
                      Pending
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
});

export default CryptoWithdraw;
