import { Box, Typography } from "@mui/material";
import React, { memo } from "react";

const KYCStepsAddressVerify = memo(() => {
  return (
    <Box>
      <Typography component={"h2"} variant="h5" className="!uppercase text-center" sx={{ borderBottom: "1px solid var(--color-gray-1)", fontSize: { sm: "18px" } }}>
        KYC Steps
        <Typography variant="body1" className="dark:!text-[var(--color-gray-1)]" sx={{ fontSize: "14px", marginY: "2px", textTransform: "capitalize" }}>
          Address verification
        </Typography>
      </Typography>

      <Box sx={{ marginTop: "20px", paddingLeft: "20px" }}>
        <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
          1. Document Information
        </Typography>
        <Typography variant="body1" className="dark:!text-[var(--color-gray-1)]" sx={{ marginBottom: "10px", fontSize: "14px" }}>
          Obtain basic identification information from the customer, such as bank statement, bank certificate, credit card statement, issue date, expire date.
        </Typography>

        <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
          2. Upload Document
        </Typography>
        <Typography variant="body1" className="dark:!text-[var(--color-gray-1)]" sx={{ marginBottom: "10px", fontSize: "14px" }}>
          Collect and verify official documents that support the information provided by the customer the document(document is required). This may include bank statement, bank certificate, credit card statement,.
          Then submit this form.
        </Typography>
      </Box>
    </Box>
  );
});

export default KYCStepsAddressVerify;
