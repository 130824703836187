import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {},
    account: null,
    loggedIn: false,
    changePasswordEmail: "",
    servers: [],
    accounts: []
  },
  reducers: {
    loggedIn: (state, action) => {
      state.loggedIn = true;
      state.user = action.payload;
    },
    logIn: (state, action) => {
      state.loggedIn = true;
      state.user = action.payload;
      state.account = action.payload?.login;
      const json_user = JSON.stringify(action.payload);
      localStorage.setItem("user", json_user);
    },
    signOut: (state) => {
      localStorage.removeItem("user");
      localStorage.removeItem("auth-token");
      localStorage.removeItem("refresh-token");
      state.loggedIn = false;
      state.user = null;
    },
    setChangePasswordEmail: (state, action) => {
      state.changePasswordEmail = action.payload;
    },
    setServers: (state, action) => {
      state.servers = action.payload;
    },
    setAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    removeAccount: (state, action) => {
      state.accounts = state.accounts.filter((account) => account.id !== action.payload);
    }
  },
});
export const { logIn, signOut, loggedIn, setChangePasswordEmail, setServers, setAccounts, removeAccount } = authSlice.actions;
export default authSlice.reducer;
