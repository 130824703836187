import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Pagination, Select, TextField, useTheme } from "@mui/material";
import { useFormik } from "formik";
import Layout from "layouts/Layout";
import { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCommissionReport } from "redux/features/ibDashboard/ibDashboardSlice";
import { setCommissionReportLoading } from "redux/features/loader/loaderSlice";
import { commissionReports } from "server/ib_dashboard";
import CardCommission from "./CardCommission";
import CommissionTableContainer from "./CommissionTableContainer";

const CommissionReport = memo(() => {
  const commissionReportData = useSelector((state) => state.ib.commissionReport);
  const [commissionCardData, setCommissionCardData] = useState({});
  const [page, setPage] = useState(1);
  const packages = useSelector((state) => state.subscription.package);
  const dispatch = useDispatch();
  const theme = useTheme();
  const tableRef = useRef(null);

  const handleChangePage = (_, value) => {
    setPage(value);
  };

  const getCommissionReport = async (values) => {
    dispatch(setCommissionReportLoading(true));
    const result = await commissionReports({ page, ...values });
    if (result?.data?.status) {
      dispatch(setCommissionReport(result?.data?.data));
      setCommissionCardData(result?.data);
      dispatch(setCommissionReportLoading(false));
    } else {
      dispatch(setCommissionReportLoading(false));
    }
  };

  const formik = useFormik({
    initialValues: {
      package_name: "",
      package_type: "",
      commission_label: "",
      package_price_min: "",
      package_price_max: "",
      package_amount_min: "",
      package_amount_max: "",
    },

    onSubmit: async (values) => {
      getCommissionReport(values)
    },
    onReset: () => {
      getCommissionReport();
      setPage(1);
    },
  });

  useEffect(() => {
    getCommissionReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Layout>
      <Box className="p-5 space-y-10">
        <Box
          className="rounded-md p-5 !pb-0"
          sx={{
            backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
          }}
        >
          <Box className="flex justify-between items-center mb-12">
            <Box>Filter Reports</Box>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Grid container columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                  }}
                  size="small"
                >
                  <InputLabel id="package_name">Package Name</InputLabel>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        style: {
                          background: theme.palette.mode === "dark" ? "var(--color-green-8)" : "var(--color-green-10)",
                        },
                      },
                    }}
                    labelId="package_name"
                    id="demo-select-small"
                    name="package_name"
                    label="Package Name"
                    value={formik.values.package_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {packages?.map((item, i) => (
                      <MenuItem key={i} value={item?.name}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                  }}
                  size="small"
                >
                  <InputLabel id="commission_label">Commission Label</InputLabel>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        style: {
                          background: theme.palette.mode === "dark" ? "var(--color-green-8)" : "var(--color-green-10)",
                        },
                      },
                    }}
                    labelId="commission_label"
                    id="demo-select-small"
                    name="commission_label"
                    label="Commission Label"
                    value={formik.values.commission_label}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                  }}
                  size="small"
                >
                  <InputLabel id="package_type">Package Type</InputLabel>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        style: {
                          background: theme.palette.mode === "dark" ? "var(--color-green-8)" : "var(--color-green-10)",
                        },
                      },
                    }}
                    labelId="package_type"
                    id="demo-select-small"
                    name="package_type"
                    label="Package Type"
                    value={formik.values.package_type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value="standard">Standard</MenuItem>
                    <MenuItem value="pro">Pro</MenuItem>
                    <MenuItem value="vip">VIP</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      height: "100%",
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                  }}
                  size="small"
                >
                  <TextField
                    type="text"
                    label="Package Price Min"
                    placeholder=""
                    size="small"
                    variant="outlined"
                    name="package_price_min"
                    value={formik.values.package_price_min}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      height: "100%",
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                  }}
                  size="small"
                >
                  <TextField
                    type="text"
                    label="Package Price Max"
                    placeholder=""
                    size="small"
                    variant="outlined"
                    name="package_price_max"
                    value={formik.values.package_price_max}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      height: "100%",
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                  }}
                  size="small"
                >
                  <TextField
                    type="text"
                    label="Package Amount Min"
                    placeholder=""
                    size="small"
                    variant="outlined"
                    name="package_amount_min"
                    value={formik.values.package_amount_min}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      height: "100%",
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                  }}
                  size="small"
                >
                  <TextField
                    type="text"
                    label="Package Amount Max"
                    placeholder=""
                    size="small"
                    variant="outlined"
                    name="package_amount_max"
                    value={formik.values.package_amount_max}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Button
                  type="reset"
                  onClick={() => formik.handleReset()}
                  variant="contained"
                  disableRipple
                  fullWidth
                  sx={{
                    marginTop: "2px",
                    backgroundColor: "var(--color-green-5)",
                    ":hover": {
                      backgroundColor: "var(--color-green-4)",
                    },
                  }}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Button
                  type="submit"
                  variant="contained"
                  disableRipple
                  fullWidth
                  sx={{
                    marginTop: "2px",
                    backgroundColor: "var(--color-green-1)",
                    ":hover": {
                      backgroundColor: "var(--color-green-7)",
                    },
                  }}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </form>
        </Box>

        <CardCommission commissionCardData={commissionCardData} />

        <Box
          className="rounded-md p-5"
          sx={{
            backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <CommissionTableContainer {...{ tableRef }} />
            </Grid>
          </Grid>

          {commissionReportData?.data?.length > 0 && (
            <Box className="flex justify-center items-center mt-1">
              <Box>
                <Pagination count={commissionReportData?.last_page} variant="outlined" shape="rounded" page={page} onChange={handleChangePage} size={"medium"} />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Layout>
  );
});

export default CommissionReport;
