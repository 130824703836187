import { Button, CircularProgress, FormHelperText, Paper, TextField, Typography, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setCommentLoading } from "redux/features/loader/loaderSlice";
import { addPostComment, updatePostComment } from "redux/features/posts/postsSlice";
import { addComment, updateComment } from "server/posts";

const AddComment = memo(({ editMode, commentId, postId, editComment, setEditComment, setEditMode, setCommentId, setPostId }) => {
  const commentLoading = useSelector((state) => state.loader.commentLoading);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const theme = useTheme();
  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState("");
  const dispatch = useDispatch();

  const handlePostComment = async () => {
    dispatch(setCommentLoading(true));
    if (editMode) {
      const result = await updateComment(postId, commentId, editComment);
      if (result.data.status) {
        enqueueSnackbar(result.data.message, { variant: "success" });
        dispatch(updatePostComment(result.data.data));
        dispatch(setCommentLoading(false));
        setEditMode(false);
        setCommentId("");
        setEditComment("");
        setPostId("");
      } else {
        setContentError(result?.data?.errors?.content[0]);
        dispatch(setCommentLoading(false));
      }
    } else {
      // if (content.trim() === "") return setContentError("Please enter a comment.");
      dispatch(setCommentLoading(true));
      const result = await addComment(id, content);
      if (result.data.status) {
        enqueueSnackbar(result.data.message, { variant: "success" });
        dispatch(addPostComment(result.data.data));
        dispatch(setCommentLoading(false));
        setContent("");
        setContentError("");
      } else {
        setContentError(result?.data?.errors?.content[0]);
        dispatch(setCommentLoading(false));
      }
    }
  };

  const handleTextFieldKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handlePostComment();
    }
  };

  return (
    <Paper
      id="go-comment-section"
      className="space-y-4"
      elevation={3}
      sx={{
        background:
          theme.palette.mode === "dark" ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)" : "var(--color-green-10)",
        padding: 2,
        marginBottom: 2,
      }}
    >
      <Typography variant="h5">{editMode ? "Edit your comment" : "Place your comment here"}</Typography>
      <TextField
        onKeyDown={handleTextFieldKeyDown}
        value={editMode ? editComment : content}
        onChange={(e) => (editMode ? setEditComment(e.target.value) : setContent(e.target.value))}
        sx={{
          "& .MuiOutlinedInput-root": {
            width: "100%",
            color: "var(--color-white-1)",
            backgroundColor:
              theme.palette.mode === "dark" ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)" : "var(--color-green-10)",
            "& fieldset": {
              border: "1px solid var(--color-gray-1)",
            },
            "&:hover fieldset": {
              border: "1px solid var(--color-green-1)",
            },
            "&.Mui-focused fieldset": {
              border: `1px solid var(--color-green-2)`,
            },
          },
          "& .MuiInputLabel-root": {
            color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
            "&.Mui-focused": {
              color: "var(--color-green-2)",
            },
          },
          "& .MuiInputBase-root": {
            "& .MuiOutlinedInput-input": {
              overflowY: "auto",
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "5px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "none",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "8px",
                backgroundColor: "var(--color-green-7)",
              },
            },
          },
        }}
        label="Comment"
        multiline
        rows={4}
        fullWidth
        variant="outlined"
      />

      {contentError && <FormHelperText className="!text-sm !text-[--color-red-1]">{contentError}</FormHelperText>}
      <Button
        sx={{
          width: "130px",
          height: "35px",
          textTransform: "capitalize",
          color: theme.palette.mode === "dark" ? "var(--color-text-2)" : "var(--color-white-1)",
          textAlign: "center",
          fontFamily: "Open Sans",
          fontSize: "13px",
          fontWeight: 400,
          lineHeight: "14px",
          borderRadius: "4px",
          background: theme.palette.mode === "dark" ? "var(--color-green-1)" : "var(--color-black-5)",
          boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
          "&:hover": {
            background: theme.palette.mode === "dark" ? "var(--color-green-1)" : "var(--color-black-5)",
          },
        }}
        variant="contained"
        onClick={handlePostComment}
      >
        {commentLoading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Post Comment"}
      </Button>
    </Paper>
  );
});

export default AddComment;
