import { createSlice } from "@reduxjs/toolkit";

const supportTicketSlice = createSlice({
    name: "support",
    initialState: {
        composeTicket: false,
        chatTicket: false,
        ticket: [],
        singleTicket: {},
        ticketLoading: false,
        singleTicketLoading: false,
    },
    reducers: {
        setComposeTicket: (state, action) => {
            state.composeTicket = action.payload;
        },
        setChatTicket: (state, action) => {
            state.chatTicket = action.payload;
        },
        setTicket: (state, action) => {
            state.ticket = action.payload;
        },
        addNewTicket: (state, action) => {
            state.ticket.push(action.payload)
        },
        setSingleTicket: (state, action) => {
            state.singleTicket = action.payload;
        },
        setTicketLoading: (state, action) => {
            state.ticketLoading = action.payload;
        },
        setSingleTicketLoading: (state, action) => {
            state.singleTicketLoading = action.payload;
        },
    },
});

export const { setComposeTicket, setTicket, setChatTicket, setSingleTicket, setTicketLoading, setSingleTicketLoading, addNewTicket } = supportTicketSlice.actions;
export default supportTicketSlice.reducer;