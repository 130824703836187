import React, { memo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Grid, Pagination, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import StatsSkeletonLoader from "components/SkeletonLoader/StatsSkeletonLoader";
import { CloudOutlined } from "@mui/icons-material";

const CustomAccordion = ({ handleChangePage, page }) => {
  const calendar = useSelector((state) => state.calender.calendar);
  const calenderLoading = useSelector((state) => state.loader.calenderLoading);
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Typography variant="h6" component="h6" className="!my-5">
        {moment().format("DD MMM, YYYY")}
      </Typography>

      {calenderLoading ? (
        <StatsSkeletonLoader />
      ) : calendar?.data?.length > 0 ? (
        calendar?.data?.map((item, i) => (
          <Accordion
            key={i}
            expanded={expanded === item.id}
            onChange={handleChange(item.id)}
            className="customTableBG"
            sx={{
              background:
                theme.palette.mode === "dark"
                  ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)"
                  : "var(--color-green-10)",
            }}
          >
            <AccordionSummary sx={{ "& .MuiAccordionSummary-content": { margin: 0 } }} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <TableContainer>
                <Table sx={{ minWidth: "100%" }} aria-label="simple table" className="customCalTable">
                  <TableHead className="customCalThead">
                    <TableRow>
                      <TableCell className="!border-transparent !py-0" sx={{ paddingX: { xs: 0, sm: 0 } }}>
                        {item.time}
                      </TableCell>
                      <TableCell align="left" className="custom_flag !border-transparent !py-0" sx={{ paddingX: { xs: 0, sm: 0 } }}>
                        <Box className="customFlagSpace">
                          <Box component={"img"} src={item.flag} className="!h-4" alt=""></Box>
                          <Box component={"span"} alt="" className="!mx-2">
                            {item.country}
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell
                        align="center"
                        className="!border-transparent !py-0 customAccordionTitle"
                        sx={{
                          paddingX: { xs: 1, sm: 1 },
                          transition: "0.3s",
                        }}
                      >
                        <Box
                          sx={{
                            display: { xs: "block", sm: "flex", md: "flex" },
                            justifyContent: { xs: "normal", sm: "space-between", md: "space-between" },
                            alignItems: { xs: "normal", sm: "center", md: "center" },
                          }}
                        >
                          <Typography
                            component="span"
                            sx={{ fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.9rem" } }}
                            className={`uppercase px-2 py-1 text-xs font-medium text-[var(--color-white-1)] ring-1 ring-inset ring-gray-500/10 w-[70px] ${
                              item.priority === "low" ? "bg-[var(--calender-low)]" : item.priority === "medium" ? "bg-[var(--calender-medium)]" : "bg-[var(--calender-high)]"
                            }`}
                          >
                            {item.priority}
                          </Typography>
                          <Typography component="p" sx={{ fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.9rem" }, letterSpacing: "1px", fontWeight: "bold" }}>
                            {item.title}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="right" className="!border-transparent !py-0" sx={{ paddingX: { xs: 0, sm: 0 } }}>
                        <Box className="flex justify-evenly" sx={{ flexDirection: { xs: "column", sm: "column", md: "row" } }}>
                          <Box className="flex flex-col" sx={{ marginRight: { xs: 0, sm: 0 }, textAlign: { xs: "center", sm: "center", md: "left" } }}>
                            <Typography component={"span"} sx={{ fontSize: { xs: "12px", sm: "13px" }, fontWeight: "bold" }}>
                              Actual
                            </Typography>
                            <Typography component={"span"} sx={{ fontSize: { xs: "12px", sm: "13px" } }}>
                              {item.actual}
                            </Typography>
                          </Box>
                          <Box
                            className="flex flex-col"
                            sx={{ marginLeft: { xs: 0, sm: 0 }, textAlign: { xs: "center", sm: "center", md: "left" }, fontSize: { xs: "12px", sm: "13px" } }}
                          >
                            <Typography component={"span"} sx={{ fontSize: { xs: "12px", sm: "13px" }, fontWeight: "bold" }}>
                              Previous
                            </Typography>
                            <Typography component={"span"} sx={{ fontSize: { xs: "12px", sm: "13px" } }}>
                              {item.previous}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </AccordionSummary>
            <AccordionDetails className="!pt-0">
              <Box>
                <Box
                  sx={{
                    width: { xs: "100%", sm: "100%", md: "80%", xl: "50%" },
                    display: "flex",
                    float: { xs: "none", sm: "none", md: "right" },
                    flexDirection: { xs: "column", sm: "column", md: "row" },
                    gap: { xs: 2, sm: 2, md: 4 },
                  }}
                >
                  {/* Historical Data */}
                  <Box sx={{ flex: "1", textAlign: { xs: "center", sm: "center", md: "left" }, marginBottom: { xs: 2, sm: 2, md: 0 } }}>
                    <Box
                      className="!text-center !mb-2 uppercase text-[14px] font-bold tracking-wider"
                      sx={{ color: theme.palette.mode === "dark" ? "text-[var(--color-gray-2)]" : "--color-green-3" }}
                    >
                      Historical Data
                    </Box>
                    <Box sx={{ margin: "auto", width: { xs: "300px;", sm: "350px", md: "400px" }, "@media (max-width:320px)": { width: "240px" } }}>
                      <img src={item.image} alt={`Historical Data ${i}`} className="!w-full !h-80" />
                    </Box>
                  </Box>
                  {/* summary Data  */}
                  <Box sx={{ flex: "1", textAlign: { xs: "center", sm: "center", md: "left" }, marginBottom: { xs: 2, sm: 2, md: 0 } }}>
                    <Box
                      className="!text-center !mb-2 uppercase text-[14px] font-bold tracking-wider"
                      sx={{ color: theme.palette.mode === "dark" ? "text-[var(--color-gray-2)]" : "--color-green-3" }}
                    >
                      Summary
                    </Box>
                    <Box dangerouslySetInnerHTML={{ __html: item?.post }} />
                  </Box>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Box sx={{ height: "calc(100vh - 500px)" }} className="flex justify-center items-center flex-col !w-full">
          <CloudOutlined sx={{ fontSize: "40px" }} />
          <Typography
            className="text-center !mt-1"
            sx={{
              color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-green-3)",
            }}
          >
            There are no events scheduled.
          </Typography>
        </Box>
      )}
      {calendar?.data?.length > 0 && (
        <Grid item xs={12} className="flex justify-center items-center !mt-5">
          <Pagination count={calendar?.last_page} variant="outlined" shape="rounded" page={page} onChange={handleChangePage} size={isSmallScreen ? "small" : "medium"} />
        </Grid>
      )}
    </div>
  );
};

export default memo(CustomAccordion);
