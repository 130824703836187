import { ArrowBack, Chat, Edit, Login, PersonAdd, PersonRemove } from "@mui/icons-material";
import { Avatar, Box, Divider, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addGroupChatList, setActiveChatGroup, setEditGroup, setGroupChatList, setGroupList, setGroupNavigate } from "redux/features/chatSlice/chatSlice";
import { get_group_chat_list, get_group_list, get_group_members, leaveGroup, remove_group_member } from "server/chat";
import { socketServiceJoinGroupChat } from "services/socketService";
import AddMember from "./AddMember";

const GroupDetails = () => {
  const permission = useSelector((state) => state.permission.permission);
  const dispatch = useDispatch();
  const group = useSelector((state) => state.chat.detailsShowingGroup);
  const [groupMembers, setGroupMembers] = React.useState([]);
  const user = useSelector((state) => state.chat.user);
  const isGroupAdmin = group.super_admin_id === user?.id;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [groupId, setGroupId] = React.useState(null);
  const [getGroupMemberRender, setGetGroupMemberRender] = React.useState(false);

  // back previous
  const handleBack = () => {
    dispatch(setGroupNavigate("groups"));
  };

  // get group members
  const getGroupMembers = async () => {
    const result = await get_group_members(group?.id);
    if (result.data?.status) {
      setGroupMembers(result.data.data);
    }
  };

  // add member
  const maxMembers = permission?.filter(i => typeof i === "object")?.find(i => i.hasOwnProperty("max_group_member"))?.max_group_member ?? 0;

  const addMember = (e, id) => {
    if (typeof maxMembers === "number" && groupMembers?.length >= maxMembers) {
      enqueueSnackbar("You have no permission to add more members.", { variant: "warning" });
      return;
    } else if (maxMembers === "unlimited" || typeof maxMembers === "number") {
      setAnchorEl(e.currentTarget);
      setGroupId(id);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  // remove member
  const removeMember = async (member_id, group_id, user_id) => {
    const result = await remove_group_member(member_id);
    if (result.data?.status) {
      setGroupMembers(groupMembers.filter((item) => item.id !== member_id));
      // await removeMemberFromGroup();
      // dispatch(setGroupNavigate("group_chat_list"));
    }
  };

  // leave group
  const leftGroup = async (group_id) => {
    const res = await leaveGroup(group_id);
    if (res.data?.status) {
      await removeMemberFromGroup();
      dispatch(setGroupNavigate("group_chat_list"));
    }
  };

  // remove member from group
  const removeMemberFromGroup = async () => {
    const updateGroupList = await get_group_list();
    if (updateGroupList.data?.status) {
      dispatch(setGroupList(updateGroupList.data.data));
    }
    const updateChatList = await get_group_chat_list();
    if (updateChatList.data?.status) {
      dispatch(setGroupChatList(updateChatList.data.data));
    }
  };

  // group chat action
  const handleGroupChat = async (item) => {
    dispatch(setGroupNavigate("group_chat"));
    dispatch(setActiveChatGroup(item));
    socketServiceJoinGroupChat(item.id);
    dispatch(addGroupChatList(item));
  };

  // edit group
  const editGroup = (item) => {
    dispatch(setGroupNavigate("edit_group"));
    dispatch(setEditGroup(item));
  };

  useEffect(() => {
    getGroupMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getGroupMemberRender]);

  return (
    <Box
      sx={{
        width: "360px",
        height: "400px",
        background: "var(--color-green-8)",
        padding: "15px",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <Box sx={{ marginTop: "0", display: "flex", justifyContent: "space-between" }}>
        <Box>
          <IconButton aria-label="back" onClick={() => handleBack()} title="Back">
            <ArrowBack sx={{ fontSize: 16 }} />
          </IconButton>
        </Box>

        <Box className="flex">
          {isGroupAdmin && (
            <Box>
              <IconButton aria-label="message" onClick={() => editGroup(group)} title="Message Group">
                <Edit sx={{ color: "var(--color-white-1)", fontSize: 16 }} />
              </IconButton>
            </Box>
          )}
          <Box>
            <IconButton aria-label="message" onClick={() => handleGroupChat(group)} title="Message Group">
              <Chat sx={{ color: "var(--color-white-1)", fontSize: 16 }} />
            </IconButton>
          </Box>
          <Box>
            {isGroupAdmin ? (
              <IconButton aria-label="add" onClick={(e) => addMember(e, group?.id)} title="Add member">
                <PersonAdd sx={{ fontSize: 16 }} />
              </IconButton>
            ) : (
              <IconButton aria-label="remove" onClick={() => leftGroup(group?.id)} title="Left Group">
                <Login sx={{ color: "var(--color-red-1)", fontSize: 16 }} />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: "-30px" }}>
        <Avatar alt={group?.name} src={group?.icon} sx={{ width: 80, height: 80 }} />
      </Box>

      <Box sx={{ marginTop: "10px" }}>
        <Typography variant="span" component="div" className="text-center">
          {group?.name}
        </Typography>
        <Typography variant="span" component="div" fontSize={12} fontFamily={"chilanka"} className="text-center text-xs text-[#ffffffa1]">
          {group?.description}
        </Typography>
      </Box>
      <Divider variant="middle" sx={{ marginLeft: 0, marginRight: 0, marginTop: "10px" }} />
      <Box className="chat-list-scrollbar" sx={{ marginTop: "10px", height: "223px", overflowY: "auto" }}>
        {groupMembers?.map((item) => (
          <ListItem
            key={item.id}
            secondaryAction={
              // ((isGroupAdmin && item.user?.id !== user?.id) || (!isGroupAdmin && item.user?.id === user?.id)) && (
              //     <IconButton
              //         aria-label="delete"
              //         onClick={() => removeMember(item.id)}
              //         title="Remove member"
              //     >
              //         <PersonRemove sx={{ fontSize: 16, color: 'var(--color-gray-1)' }} />
              //     </IconButton>
              // )
              // i want if the user is admin and theis member user id is not admin id then show remove button
              isGroupAdmin &&
              item.user?.id !== user?.id && (
                <IconButton aria-label="delete" onClick={() => removeMember(item.id)} title="Remove member">
                  <PersonRemove sx={{ fontSize: 16, color: "var(--color-gray-1)" }} />
                </IconButton>
              )
            }
            disablePadding
          >
            <ListItemButton role={undefined} dense>
              <ListItemIcon>
                <Avatar alt={`${item.user?.name}`} src={item.user?.profile?.profile_image} sx={{ width: 24, height: 24 }} />
              </ListItemIcon>
              <ListItemText
                sx={{
                  "& .MuiListItemText-primary": { color: "#ffffffa1", fontSize: 14 },
                  "& .MuiListItemText-secondary": { color: "#ffffffa1", fontSize: 12 },
                }}
                primary={`${item.user?.name}`}
                secondary={`${item.user?.email}`}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            background: "var(--color-green-8)",
            color: "var(--color-white-1)",
            width: "360px",
            height: "400px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <AddMember {...{ handleClose, groupId, setGetGroupMemberRender }} />
      </Popover>
    </Box>
  );
};

export default GroupDetails;
