import { Badge, Box, Typography } from "@mui/material";
import React from "react";
import whatMark from "../../assets/icons/what.png";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { addUnseenNotification } from "redux/features/notification/notificationSlice";
import notifyIcon from "../../assets/icons/notification-unscreen.gif";
import { setOnlineUser } from "redux/features/chatSlice/chatSlice";

const ChatButton = ({ handlePopoverOpenChat }) => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notification.unseenNotification).length;
  const user_id = useSelector((state) => state.chat.user?.id);
  React.useEffect(() => {
    if (!user_id) return;
    const socket = io(process.env.REACT_APP_API_ROOTURL, {
      extraHeaders: {
        authorization: `Bearer ${localStorage.getItem("chat-auth-token")}`,
        notification: true,
      },
    });

    socket.on("notification", (res) => {
      if (res.for_type === "CHAT" || res.for_type === "GROUP_CHAT") {
        dispatch(addUnseenNotification(res));
      }
    });
    socket.on("online", (res) => {
      dispatch(setOnlineUser(res));
    });
    socket.emit("online");
    const intId = setInterval(() => {
      socket.emit("online");
    }, 1000 * 60);

    return () => {
      clearInterval(intId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  return (
    <Box
      onClick={handlePopoverOpenChat}
      sx={{
        width: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        textAlign: "center",
        padding: "8px 15px",
        background: "var(--color-green-2)",
        borderRadius: "30px",
        position: "fixed",
        right: {
          xs: "15px",
          md: "50px",
        },
        bottom: "30px",
        cursor: "pointer",
      }}
    >
      <Typography
        sx={{
          color: "var(--color-white-1)",
          fontSize: "18px",
          fontFamily: "Anek Telugu",
          marginTop: "6px",
        }}
      >
        Chat
      </Typography>
      {notifications > 0 ? (
        <Box sx={{ marginRight: "4px" }}>
          <Badge color="error" badgeContent={notifications} sx={{ color: "var(--color-red-1)" }} className="cursor-pointer">
            <img src={notifyIcon} alt="Notification" className="w-[35px]" />
          </Badge>
        </Box>
      ) : (
        <Typography component={"img"} src={whatMark} sx={{ width: "25px" }}></Typography>
      )}
    </Box>
  );
};

export default ChatButton;
