import { Box, useTheme } from "@mui/material";
import TradingGrowthChart from "components/Chart/TradingGrowthChart";
import GrowthSkeletonLoader from "components/SkeletonLoader/GrowthSkeletonLoader";
import React, { Fragment, memo } from "react";
import { useSelector } from "react-redux";

const TradingGrowth = () => {
  const theme = useTheme();
  const growthChartLoader = useSelector((state) => state.loader.growthChartLoader);
  return (
    <Fragment>
      {growthChartLoader ? (
        <GrowthSkeletonLoader />
      ) : (
        <Box
          className="dashChart"
          sx={{
            whiteSpace: "nowrap",
            borderStyle: "solid",
            border: theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "1px solid var(--color-green-1)",
            background: theme.palette.mode === "dark" ? "" : "var(--color-green-10)",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <TradingGrowthChart />
        </Box>
      )}
    </Fragment>
  );
};

export default memo(TradingGrowth);
