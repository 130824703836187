import { Box, Button, CircularProgress, TextField } from "@mui/material";
import message from "enum/message";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { setNavigateAuth } from "redux/features/navigateSlice/navigateSlice";
import { unverifiedToSendEmailVerify } from "server/auth";
import * as yup from "yup";

const validationSchema = yup.object({
  email: yup.string("Enter your email").email("Enter a valid email").required(message.EMAIL_REQUIRED),
});

const UnverifiedEmailLogin = memo(() => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const result = await unverifiedToSendEmailVerify(values.email);
      if (result?.data?.status) {
        enqueueSnackbar(result.data.message, { variant: "success" });
        dispatch(setNavigateAuth("verifyEmailOtp"));
        setLoading(false);
      } else {
        setLoading(false);
      }
    },
  });

  return (
    <form autoComplete="off" className="w-[90%] md:w-[335px] mx-auto space-y-6" onSubmit={formik.handleSubmit}>
      <Box>
        <TextField
          label="Email"
          variant="standard"
          type="email"
          fullWidth
          size="medium"
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          sx={{
            "& .MuiInput-root": {
              color: "var(--color-black)",
              fontSize: "16px",
              fontWeight: 500,
            },
            "& label": {
              fontSize: "16px",
              color: "var(--color-white-1)",
              fontWeight: 500,
            },
            "& label.Mui-focused": {
              color: "var(--color-green-2)",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "var(--color-green-2)",
            },
          }}
        />
      </Box>

      <Button
        type="submit"
        sx={{
          background: "var(--color-green-1)",
          textTransform: "none",
          "&:hover": {
            background: "var(--color-green-11)",
          },
        }}
        disableRipple
        variant="contained"
        fullWidth
      >
        {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Submit"}
      </Button>
      <Box className="pb-3">
        {/* <Typography component={"p"} className="hover:text-[var(--color-green-1)] cursor-pointer hover:underline text-center" onClick={() => dispatch(setNavigateAuth("login"))}>
          Go to login
        </Typography> */}
      </Box>
    </form>
  );
});

export default UnverifiedEmailLogin;
