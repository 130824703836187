import mirrorApi from '../api/mirror_trade_api';
// Mirror Trade Web

export const signUp = async ({ auth_type, name, email, password, confirm_password, login, server, subscription, ref }) => {
    try {
        return await mirrorApi.post("/register", { auth_type, name, email, password, confirm_password, login, server, subscription, ref });
    } catch (error) {
        console.log("🚀 ~ file: auth.js:8 ~ signUp ~ error:", error)
    }
}
export const signIn = async ({ username, password }) => {
    try {
        return await mirrorApi.post("/login", { username, password });
    } catch (error) {
        console.log("🚀 ~ file: auth.js:15 ~ signIn ~ error:", error)
    }
}

// Get Auth Token
export const getAuthToken = () => {
    try {
        return localStorage.getItem("auth-token") ? `Bearer ${localStorage.getItem("auth-token")}` : null;
    } catch (error) {
        console.log("🚀 ~ file: auth.js:24 ~ getAuthToken ~ error:", error)
    }
};
// Get Refresh Token
export const getRefreshToken = () => {
    try {
        return localStorage.getItem("refresh-token") ? `Bearer ${localStorage.getItem("refresh-token")}` : null;
    } catch (error) {
        console.log('Error in getting refresh token', error);
    }
};


export const refetchAuth = async () => {
    try {
        return await mirrorApi.post("/refresh-token", {}, {
            headers: {
                Authorization: getRefreshToken(),
            }
        });
    } catch (error) {
        console.log("🚀 ~ file: auth.js:32 ~ refetchAuth ~ error:", error)
    }
};

export const logOut = async () => {
    try {
        return await mirrorApi.post("/logout", {}, {
            headers: {
                Authorization: getAuthToken(),
            }
        });
    } catch (error) {
        console.log("🚀 ~ file: auth.js:27 ~ logOut ~ error:", error)
    }
}

export const forgot_sending_mail = async (email) => {
    try {
        return await mirrorApi.post("/password/email", { email });
    } catch (error) {
        console.log("🚀 ~ file: auth.js:56 ~ const forgot_sending_mail= ~ error:", error)
    }
}

export const otp_code = async (code) => {
    try {
        return await mirrorApi.post(`/password/code/check?code=${code}`, { code });
    } catch (error) {
        console.log("🚀 ~ file: auth.js:64 ~ const otp_code= ~ error:", error)
    }
}

export const forgot_password = async (data) => {
    try {
        return await mirrorApi.post(`/password/reset`, data);
    } catch (error) {
        console.log("🚀 ~ file: auth.js:72 ~ const forgot_password= ~ error:", error)
    }
}

//  Unverified email
export const unverifiedToSendEmailVerify = async (email) => {
    try {
        return await mirrorApi.post(`/resend/activation/code`, { email: email });
    } catch (error) {
        console.log("🚀 ~ unverifiedToSendEmailVerify ~ error:", error)
    }
}

export const EmailVerifyOTPCode = async (code) => {
    try {
        return await mirrorApi.post(`/account/activation`, { code: code });
    } catch (error) {
        console.log("🚀 ~ EmailVerifyOTPCode ~ error:", error)
    }
}

export const allAccount = async () => {
    try {
        return await mirrorApi.get("/user/all/accounts", {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ allAccount ~ error:", error)
    }
}
export const switchAccount = async (account) => {
    try {
        return await mirrorApi.post(`/user/accounts/switch`, { account }, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ switchAccount ~ error:", error)
    }
};

// delete account
export const delete_account = async (account) => {
    try {
        return await mirrorApi.post(`/user/account/delete/${account}`, {}, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ delete_account ~ error:", error)
    }
}

export const getFollows = async () => {
    try {
        return await mirrorApi.get(`/user/follows`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ delete_account ~ error:", error)
    }
}
