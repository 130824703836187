import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { FormControl, Grid, Pagination, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import LineChartAccountSection from "components/Chart/LineChartAccountSection";
import CopyTradeModal from "components/MirrorAllModal/CopyTradeModal";
import UnCopyTradeModal from "components/MirrorAllModal/UnCopyTradeModal";
import AccountSkeletonLoader from "components/SkeletonLoader/AccountSkeletonLoader";
import OpenAndCloseTradeLoader from "components/SkeletonLoader/OpenAndCloseTradeLoader";
import Layout from "layouts/Layout";
import React, { Fragment, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setCloseTradeLoading, setOpenTradeLoading, setPamUserDetailsLoader, setPerformanceChartLoader } from "redux/features/loader/loaderSlice";
import { setCloseTradePage, setOpenTradePage } from "redux/features/pagination/paginationSlice";
import { setPamUserDataDetails, setPerformanceChart } from "redux/features/pam/pamSlice";
import { setCloseTrade, setOpenTrade } from "redux/features/trade/tradeSlice";
import { tradeClose } from "server/close_trade";
import { getTradeOpen } from "server/open_trade";
import { pamUserDetails, performanceChart } from "server/pamm";
import Account from "./Account";
import AccountDetailsBottom from "./AccountDetailsBottom";
import AccountInfo from "./AccountInfo";
import CloseTrades from "./CloseTrades";
import DailyChart from "./DailyChart";
import FilterAccountGrowth from "./FilterAccountGrowth";
import FilterDailyHourlyMonthlyChart from "./FilterDailyHourlyMonthlyChart";
import FilterTrades from "./FilterTrades";
import HourChart from "./HourChart";
import MonthlyChart from "./MonthlyChart";
import OpenTrades from "./OpenTrades";
import TradingStats from "./TradingStats";
import BalanceInfo from "./BalanceInfo";

const AccountDetails = () => {
  const { id } = useParams();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const openTrade = useSelector((state) => state.trade.openTrade);
  const pamUserDetailsLoader = useSelector((state) => state.loader.pamUserDetailsLoader);
  const performanceChartLoader = useSelector((state) => state.loader.performanceChartLoader);
  const openTradeLoading = useSelector((state) => state.loader.openTradeLoading);
  const closeTradeLoading = useSelector((state) => state.loader.closeTradeLoading);
  const openTradePage = useSelector((state) => state.pagination.openTradePage);
  const copyTradeModalOpen = useSelector((state) => state.application.copyTradeModalOpen);
  const openUnCopyDialog = useSelector((state) => state.application.openUnCopyDialog);
  const closeTradePage = useSelector((state) => state.pagination.closeTradePage);
  const closeTrade = useSelector((state) => state.trade.closeTrade);

  const theme = useTheme();
  const [tradingTab, setTradingTab] = React.useState("1");
  const [chartTab, setChartTab] = React.useState("1");
  const [TableTab, setTableTab] = React.useState("1");
  const [dailyHourlyMonthlyChartFilterData, setDailyHourlyMonthlyChartFilterData] = useState(2024);
  const [filterTrades, setFilterTrades] = useState(10);
  const [filterGrowth, setFilterGrowth] = useState("balance");
  const [searchText, setSearchText] = useState("");

  const handleChangeTradingTab = (_, newValue) => {
    setTradingTab(newValue);
  };
  const handleChangeChartTab = (_, newValue) => {
    setDailyHourlyMonthlyChartFilterData(2024);
    setChartTab(newValue);
  };
  const handleTableTab = (_, newValue) => {
    setTableTab(newValue);
    setFilterTrades(10);
    setSearchText("");
    dispatch(setOpenTradePage(1));
    dispatch(setCloseTradePage(1));
  };
  const handleChangeCloseTradePage = async (_, value) => {
    dispatch(setCloseTradeLoading(true));
    const result = await tradeClose(id, closeTradePage, filterTrades, searchText);
    if (result?.status === 200) {
      dispatch(setCloseTrade(result.data));
      dispatch(setCloseTradeLoading(false));
    } else {
      dispatch(setCloseTradeLoading(false));
    }
    dispatch(setCloseTradePage(value));
  };

  const handleChangeOpenTradePage = async (_, value) => {
    dispatch(setOpenTradeLoading(true));
    const result = await getTradeOpen(id, openTradePage, filterTrades, searchText);
    if (result?.status === 200) {
      dispatch(setOpenTrade(result.data));
      dispatch(setOpenTradeLoading(false));
    } else {
      dispatch(setOpenTradeLoading(false));
    }
    dispatch(setOpenTradePage(value));
  };

  const getPamUserDetails = async () => {
    dispatch(setPamUserDetailsLoader(true));
    const result = await pamUserDetails(id);
    if (result?.data?.status) {
      dispatch(setPamUserDataDetails(result.data));
      dispatch(setPamUserDetailsLoader(false));
    } else {
      dispatch(setPamUserDetailsLoader(false));
    }
  };

  const getOpenTrade = async () => {
    dispatch(setOpenTradeLoading(true));
    const result = await getTradeOpen(id, openTradePage, filterTrades, searchText);
    if (result?.status === 200) {
      dispatch(setOpenTrade(result.data));
      dispatch(setOpenTradeLoading(false));
    } else {
      dispatch(setOpenTradeLoading(false));
    }
  };

  const getCloseTrade = async () => {
    dispatch(setCloseTradeLoading(true));
    const result = await tradeClose(id, closeTradePage, filterTrades, searchText);
    if (result?.status === 200) {
      dispatch(setCloseTrade(result.data));
      dispatch(setCloseTradeLoading(false));
    } else {
      dispatch(setCloseTradeLoading(false));
    }
  };

  useEffect(() => {
    getPamUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterGrowth]);

  useEffect(() => {
    getOpenTrade();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, filterTrades, openTradePage]);

  useEffect(() => {
    getCloseTrade();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, filterTrades, closeTradePage]);

  const getPerformanceChartData = async () => {
    dispatch(setPerformanceChartLoader(true));
    const result = await performanceChart(id, filterGrowth);
    if (result && result?.status === 200) {
      dispatch(setPerformanceChart(result?.data));
      dispatch(setPerformanceChartLoader(false));
    } else {
      dispatch(setPerformanceChartLoader(false));
    }
  };

  useEffect(() => {
    getPerformanceChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterGrowth]);

  return (
    <Layout>
      <Fragment>
        <Grid
          container
          spacing={0}
          columnSpacing={2}
          sx={{
            paddingLeft: "22px",
            paddingTop: "16px",
            paddingRight: "20px",
            "@media (min-width: 600px) and (max-width: 800px)": {
              width: "calc(100% - 48px)",
            },
            "@media (min-width: 801px) and (max-width: 1024px)": {
              width: "calc(100% - 55px)",
            },
          }}
        >
          <Grid item xs={12} sm={12} lg={2} md={3}>
            {pamUserDetailsLoader ? <AccountSkeletonLoader /> : <Account />}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            lg={10}
            md={9}
            sx={{
              "@media (min-width:769px) and (max-width:899px)": {
                marginTop: "30px",
              },
            }}
          >
            {performanceChartLoader ? (
              <AccountSkeletonLoader />
            ) : (
              <Fragment>
                <Box
                  sx={{
                    paddingBottom: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  className="performance_chat"
                >
                  <Box>
                    <Typography className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)]  !font-[Poppins] !font-bold !text-[16px] !leading-normal">
                      Performance Chart
                    </Typography>
                  </Box>
                  <FilterAccountGrowth {...{ filterGrowth, setFilterGrowth }} />
                </Box>
                <Box
                  sx={{
                    height: "393px",
                    borderRadius: "10px",
                    padding: "10px",
                    boxShadow: "0px 3px 18.1px 0px rgba(0, 208, 148, 0.10)",
                    border: "1px solid rgba(0, 208, 148, 0.3)",
                    background:
                      theme.palette.mode === "dark"
                        ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)"
                        : "var(--color-green-10)",
                  }}
                >
                  <LineChartAccountSection />
                </Box>
                <AccountDetailsBottom />
              </Fragment>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              "@media (min-width:769px) and (max-width:899px)": {
                marginTop: "30px",
              },
            }}
          >
            <TabContext value={tradingTab}>
              <Box>
                <TabList
                  sx={{
                    border: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}`,
                    backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-3)" : "var(--color-green-10)",
                    borderRadius: "10px 10px 0px 0px",
                    "& .MuiTabs-indicator": {
                      backgroundColor: "transparent",
                    },
                    "& .MuiTab-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-white-2)",

                      "&.Mui-selected": {
                        backgroundColor: "var(--color-green-2)",
                      },
                    },
                  }}
                  className="w-fit !h-[30px] tradingState"
                  onChange={handleChangeTradingTab}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    sx={{
                      borderRight: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}`,
                    }}
                    className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-medium"
                    label="Trading stats"
                    value="1"
                  />
                  <Tab
                    sx={{
                      borderRight: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}`,
                    }}
                    className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-medium"
                    label="Account Info"
                    value="2"
                  />
                  <Tab
                    className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-medium"
                    label="Balance Info"
                    value="3"
                  />
                </TabList>
              </Box>

              <TabPanel
                sx={{
                  borderRadius: "0px 22px 22px 22px ",
                  paddingX: { xs: 0 },
                  backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
                }}
                className="!text-[var(--color-white-1)] shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] "
                value="1"
              >
                <Grid container spacing={2} sx={{ borderRadius: "10px", padding: "0px 20px", boxSizing: "border-box" }}>
                  <TradingStats />
                </Grid>
              </TabPanel>
              <TabPanel
                sx={{
                  borderRadius: "0px 22px 22px 22px ",
                  paddingX: { xs: 0 },
                  backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
                }}
                className="!text-[var(--color-white-1)] shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)]"
                value="2"
              >
                <Grid container spacing={2} sx={{ borderRadius: "10px", padding: "0px 20px", boxSizing: "border-box" }}>
                  <AccountInfo />
                </Grid>
              </TabPanel>
              <TabPanel
                sx={{
                  borderRadius: "0px 22px 22px 22px ",
                  paddingX: { xs: 0 },
                  backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
                }}
                className="!text-[var(--color-white-1)] shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)]"
                value="3"
              >
                <Grid container spacing={2} sx={{ borderRadius: "10px", padding: "0px 20px", boxSizing: "border-box" }}>
                  <BalanceInfo />
                </Grid>
              </TabPanel>
            </TabContext>
          </Grid>
          {/* Report section */}
          <Grid item xs={12} className="!mt-10 mr-20">
            <TabContext value={chartTab}>
              {/* <Box className="flex items-center justify-between"> */}
              <Box
                sx={{
                  display: { sm: "inline-flex", md: "flex" },
                  flexWrap: { sm: "wrap" },
                  justifyContent: { md: "space-between", alignItems: "center" },
                  gap: 2,
                }}
              >
                <TabList
                  sx={{
                    border: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}`,
                    backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-3)" : "var(--color-green-10)",
                    borderRadius: "10px 10px 0px 0px",
                    "& .MuiTabs-indicator": {
                      backgroundColor: "transparent",
                    },
                    "& .MuiTab-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var!(--color-white-2)",
                      "&.Mui-selected": {
                        backgroundColor: "var(--color-green-2)",
                      },
                    },
                  }}
                  className="w-fit !h-[30px]"
                  onChange={handleChangeChartTab}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    sx={{
                      borderRight: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}`,
                    }}
                    className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-medium"
                    label="Monthly"
                    value="1"
                  />
                  <Tab
                    sx={{
                      borderRight: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}`,
                    }}
                    className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-medium"
                    label="Daily"
                    value="2"
                  />
                  <Tab className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-medium" label="Hourly" value="3" />
                </TabList>
                {chartTab === "1" && (
                  <Box sx={{ paddingY: isSmallScreen ? "10px" : "0px" }}>
                    <FilterDailyHourlyMonthlyChart {...{ dailyHourlyMonthlyChartFilterData, setDailyHourlyMonthlyChartFilterData }} />
                  </Box>
                )}
              </Box>
              <TabPanel
                sx={{ borderRadius: "0px 22px 22px 22px", backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)", paddingX: { xs: 0 } }}
                className="shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] "
                value="1"
              >
                <Grid container spacing={2} sx={{ padding: "0px 20px", boxSizing: "border-box" }}>
                  <MonthlyChart {...{ id, dailyHourlyMonthlyChartFilterData }} />
                </Grid>
              </TabPanel>
              <TabPanel
                sx={{ borderRadius: "0px 22px 22px 22px", backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)", paddingX: { xs: 0 } }}
                className=" shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] "
                value="2"
              >
                <Grid container spacing={2} sx={{ padding: "0px 20px", boxSizing: "border-box" }}>
                  <DailyChart {...{ id, dailyHourlyMonthlyChartFilterData }} />
                </Grid>
              </TabPanel>
              <TabPanel
                sx={{ borderRadius: "0px 22px 22px 22px", backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)", paddingX: { xs: 0 } }}
                className="shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)]"
                value="3"
              >
                <Grid container spacing={2} sx={{ padding: "0px 20px", boxSizing: "border-box" }}>
                  <HourChart {...{ id, dailyHourlyMonthlyChartFilterData }} />
                </Grid>
              </TabPanel>
            </TabContext>
          </Grid>
          {/* Table section */}
          <Grid item xs={12} className="!mt-10">
            <TabContext value={TableTab}>
              <Box
                sx={{
                  display: { xs: "inline-flex", sm: "flex", md: "flex" },
                  flexWrap: { xs: "wrap" },
                  justifyContent: { sm: "space-between", md: "space-between" },
                  gap: 2,
                }}
              >
                <TabList
                  sx={{
                    border: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}`,
                    backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-3)" : "var(--color-green-10)",
                    borderRadius: "10px 10px 0px 0px",
                    "& .MuiTabs-indicator": {
                      backgroundColor: "transparent",
                    },
                    "& .MuiTab-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var!(--color-white-2)",
                      "&.Mui-selected": {
                        backgroundColor: "var(--color-green-2)",
                      },
                    },
                  }}
                  className="w-fit !h-[30px]"
                  onChange={handleTableTab}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    onClick={() => getOpenTrade()}
                    className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !font-medium !normal-case"
                    label="Open Trades"
                    value="1"
                  />
                  <Tab
                    onClick={() => getCloseTrade()}
                    className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !font-medium !normal-case"
                    label="Closed Trades"
                    value="2"
                  />
                </TabList>
                <Box className="flex items-center space-x-2" sx={{ paddingBottom: "10px", paddingTop: "10px" }}>
                  <Box>
                    <FormControl
                      id="street"
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          height: "30px",
                          width: "120px",
                          color: theme.palette.mode === "dark" ? "var(--color-gray-3)" : "var(--color-black-2)",
                          backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-3)" : "var(--color-green-10)",
                          padding: 1,
                          boxSizing: "border-box",
                          borderRadius: "6px",
                          "& fieldset": {
                            border: "1px solid var(--color-green-7)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--color-green-1)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--color-green-2)`,
                          },
                          "& .MuiInputBase-input": {
                            padding: "3px",
                            fontSize: "12px",
                          },
                        },
                      }}
                    >
                      <TextField variant="outlined" value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Search" />
                    </FormControl>
                  </Box>
                  <FilterTrades {...{ filterTrades, setFilterTrades }} />
                  <Box
                    className="dark:text-[var(--color-gray-3)] text-[var(--color-black-2)] font-[Open Sans] text-[12px] font-normal pr-1"
                    sx={{ display: { xs: "none", sm: "none", md: "block" } }}
                  >
                    records per page
                  </Box>
                </Box>
              </Box>
              <TabPanel sx={{ paddingLeft: "16px", paddingRight: "0px" }} value="1">
                <Grid
                  container
                  spacing={2}
                  sx={{
                    borderRadius: "10px",
                  }}
                >
                  {openTradeLoading ? <OpenAndCloseTradeLoader /> : <OpenTrades />}

                  {openTrade?.data?.length > 0 && (
                    <Grid item xs={12} className="flex justify-center items-center !pt-5">
                      <Pagination
                        count={openTrade?.pagination?.last_page}
                        variant="outlined"
                        shape="rounded"
                        page={openTradePage}
                        onChange={handleChangeOpenTradePage}
                        size={isSmallScreen ? "small" : "medium"}
                      />
                    </Grid>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel sx={{ paddingLeft: "16px", paddingRight: "0px" }} value="2">
                <Grid
                  container
                  spacing={2}
                  sx={{
                    borderRadius: "10px",
                  }}
                >
                  {closeTradeLoading ? <OpenAndCloseTradeLoader /> : <CloseTrades />}
                  {closeTrade?.data?.length > 0 && (
                    <Grid item xs={12} className="flex justify-center items-center">
                      <Pagination
                        count={closeTrade?.pagination?.last_page}
                        variant="outlined"
                        shape="rounded"
                        page={closeTradePage}
                        onChange={handleChangeCloseTradePage}
                        size={isSmallScreen ? "small" : "medium"}
                      />
                    </Grid>
                  )}
                </Grid>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
        <CopyTradeModal {...{ copyTradeModalOpen, master: id }} />
        <UnCopyTradeModal {...{ openUnCopyDialog, id }} />
      </Fragment>
    </Layout>
  );
};

export default memo(AccountDetails);
