import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { memo, useCallback, useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "redux/features/application/applicationSlice";
import { setNavigateAuth } from "redux/features/navigateSlice/navigateSlice";
import { forgot_sending_mail, otp_code } from "server/auth";

const VerifyOtp = ({ setOtpCode, email }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.application.loading);
  const [token, setToken] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const [timer, setTimer] = useState(120);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        clearInterval(timerInterval);
      }
    }, 1000);
    return () => clearInterval(timerInterval);
  }, [timer]);

  const handleResendForgotEmail = useCallback(async () => {
    setTimer(120);
    const result = await forgot_sending_mail(email);
    if (result.data.status) {
      enqueueSnackbar(result.data.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "warning" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const handleTokenValidate = async (e) => {
    dispatch(setLoading(true));
    e.preventDefault();
    const result = await otp_code(token);
    if (result.data.status) {
      dispatch(setNavigateAuth("forgot_password"));
      dispatch(setLoading(false));
      enqueueSnackbar(result.data.message, { variant: "success" });
      setOtpCode(result?.data?.code);
    } else {
      dispatch(setLoading(false));
      enqueueSnackbar(result.data.message, { variant: "warning" });
    }
  };

  return (
    <form autoComplete="off" className="w-[90%] md:w-[335px] mx-auto space-y-6">
      <Box className="flex justify-center">
        <OTPInput
          value={token}
          onChange={setToken}
          numInputs={6}
          inputStyle="!w-[35px] !h-[35px] bg-[--color-green-7] border-none focus:outline-none focus:ring focus:ring-[var(--color-primary)] focus:border-1 focus:border-[#2e3552] text-2xl rounded text-[var(--color-white-1)]"
          renderSeparator={<span style={{ margin: "0px 5px 0px 5px" }}></span>}
          renderInput={(props, index) => <input {...props} key={index} required />}
        />
      </Box>
      <Box>
        <Box className="ml-[252px]">
          {timer > 0 ? (
            <Typography component={"p"}>{formatTime(timer)}</Typography>
          ) : (
            <Button
              sx={{
                background: "var(--color-green-1)",
                textTransform: "none",
                "&:hover": {
                  background: "var(--color-green-11)",
                },
              }}
              disableRipple
              variant="contained"
              onClick={handleResendForgotEmail}
            >
              Resend
            </Button>
          )}
        </Box>
      </Box>
      <Button
        onClick={handleTokenValidate}
        sx={{
          background: "var(--color-green-1)",
          textTransform: "none",
          "&:hover": {
            background: "var(--color-green-11)",
          },
        }}
        disableRipple
        variant="contained"
        fullWidth
      >
        {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Verify Otp"}
      </Button>
      <Box className="pb-3">
        <Typography component={"p"} className="hover:text-[var(--color-green-1)] cursor-pointer hover:underline text-center" onClick={() => dispatch(setNavigateAuth("login"))}>
          Go to login
        </Typography>
      </Box>
    </form>
  );
};

export default memo(VerifyOtp);
