import { CalendarToday } from "@mui/icons-material";
import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import permissionEnum from "enum/permissionEnum";
import Layout from "layouts/Layout";
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { setCalendar } from "redux/features/calendar/calendarSlice";
import { setCalenderLoading } from "redux/features/loader/loaderSlice";
import { getCalendar } from "server/calendar";
import CustomAccordion from "./CustomAccordion";
import DateCalender from "./DateCalender";
import FilterSectionCalender from "./FilterSectionCalender";

const Calender = () => {
  // eslint-disable-next-line no-unused-vars
  const calendar = useSelector((state) => state.calender.calendar);
  const dispatch = useDispatch();
  const permission = useSelector((state) => state.permission.permission);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [tabIndex, setTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [page, setPage] = useState(1);
  const [period, setPeriod] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs().format("DD-MM-YYYY"));
  const [findTitle, setFindTitle] = useState("");
  const [importance, setImportance] = useState([]);

  const handleSelect = (date) => {
    setSelectedDate(dayjs(date.$d).format("DD-MM-YYYY"));
    setPeriod(dayjs(date.$d).format("DD-MM-YYYY"));
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPeriod(selectedDate);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  useEffect(() => {
    if (permission.length === 0) return;
    if (!permission?.includes(permissionEnum[`/calender`])) {
      window.location.href = "/";
    }
  }, [permission, id]);

  const getCalender = async () => {
    dispatch(setCalenderLoading(true));
    const result = await getCalendar(page, period, findTitle, importance);
    if (result?.data?.status) {
      dispatch(setCalendar(result.data.data));
      dispatch(setCalenderLoading(false));
    } else {
      dispatch(setCalenderLoading(false));
    }
  };

  useEffect(() => {
    getCalender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, period, selectedDate, findTitle, importance]);

  const handlePeriodSelect = (periodType) => {
    setPeriod(periodType);
  };

  const handleFindTile = (title) => {
    setFindTitle(title);
  };

  const handleChangePage = async (_, page) => {
    dispatch(setCalenderLoading(true));
    setPage(page);
    const result = await getCalendar(page, period, findTitle, importance);
    if (result?.data?.status) {
      dispatch(setCalendar(result.data.data));
      dispatch(setCalenderLoading(false));
    } else {
      dispatch(setCalenderLoading(false));
    }
  };

  // Ref for the component to be printed
  const componentRef = useRef();

  // Function to handle printing
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const handlePrintStyle = () => {
      const printStyle = `
        @media print {
          body {
            overflow: visible !important;
            background-color: #001811 !important;
          }
          .scrollbar-container::-webkit-scrollbar {
            display: none;
          }
          .MuiGrid-container {
            background-color: #001811 !important;
            color: #ffffff !important;
          }
        }
      `;
      const style = document.createElement("style");
      style.innerHTML = printStyle;
      document.head.appendChild(style);
    };

    handlePrintStyle();

    return () => {
      document.head.removeChild(document.head.lastChild);
    };
  }, []);

  return (
    <Layout>
      <Box ref={componentRef}>
        <Grid container sx={{ "& > .MuiGrid-item": { paddingTop: 1 } }} spacing={2} className="text-justify p-[20px]">
          <Grid item xs={12}>
            <Typography variant="h5" className="!font-[Poppins] !font-bold !text-[24px] !mb-1">
              Economic Calender
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="p" className="dark:!text-[var(--color-gray-1)] !text-[var(--color-white-2)] !font-[Poppins] !text-[18px]">
              Use our economic calendar to explore key global events on the horizon that could subtly shift or substantially shake up the financial markets.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          className="p-[20px]"
          sx={{
            "@media (min-width: 600px) and (max-width: 860px)": {
              paddingRight: "80px !important",
            },
          }}
        >
          <Grid item xs={12}>
            <Box>
              {isSmallScreen ? (
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  sx={{
                    "& .Mui-selected": {
                      borderBottom: "1px solid red",
                    },
                    "& .MuiTabs-indicator": {
                      backgroundColor: "transparent",
                    },
                    "& .MuiTabs-scrollButtons.Mui-disabled": {
                      opacity: "0.3",
                    },
                  }}
                >
                  <Tab
                    onClick={() => handlePeriodSelect("last_7")}
                    disableRipple
                    sx={{
                      maxWidth: "none",
                      minWidth: "none",
                      "&.Mui-selected": {
                        background: "var(--color-green-12)",
                        borderRadius: "3px",
                      },
                    }}
                    className="!text-[12px] !font-[Poppins] !normal-case !font-normal"
                    label={
                      <Box className="space-y-2">
                        <Box>Last Seven Days</Box>
                        <Box className="flex items-center justify-between">
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-high)] text-[var(--color-white-1)]">1</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-medium)] text-[var(--color-white-1)]">6</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-low)] text-[var(--color-white-1)]">13</Box>
                        </Box>
                      </Box>
                    }
                  />
                  <Tab
                    onClick={() => handlePeriodSelect("yesterday")}
                    disableRipple
                    sx={{
                      maxWidth: "none",
                      minWidth: "none",
                      "&.Mui-selected": {
                        background: "var(--color-green-12)",
                        borderRadius: "3px",
                      },
                    }}
                    className="!text-[12px] !font-[Poppins] !normal-case !font-normal"
                    label={
                      <Box className="space-y-2">
                        <Box>Yesterday</Box>
                        <Box className="flex items-center justify-center gap-2">
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-high)] text-[var(--color-white-1)]">1</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-medium)] text-[var(--color-white-1)]">6</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-low)] text-[var(--color-white-1)]">13</Box>
                        </Box>
                      </Box>
                    }
                  />
                  <Tab
                    onClick={() => handlePeriodSelect("today")}
                    disableRipple
                    sx={{
                      maxWidth: "none",
                      minWidth: "none",
                      "&.Mui-selected": {
                        background: "var(--color-green-12)",
                        borderRadius: "3px",
                      },
                    }}
                    className="!text-[12px] !font-[Poppins] !normal-case !font-normal"
                    label={
                      <Box className="space-y-2">
                        <Box>Today</Box>
                        <Box className="flex items-center justify-center gap-2">
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-high)] text-[var(--color-white-1)]">1</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-medium)] text-[var(--color-white-1)]">6</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-low)] text-[var(--color-white-1)]">13</Box>
                        </Box>
                      </Box>
                    }
                  />
                  <Tab
                    onClick={() => handlePeriodSelect("tomorrow")}
                    disableRipple
                    sx={{
                      maxWidth: "none",
                      minWidth: "none",
                      "&.Mui-selected": {
                        background: "var(--color-green-12)",
                        borderRadius: "3px",
                      },
                    }}
                    className="!text-[12px] !font-[Poppins] !normal-case !font-normal"
                    label={
                      <Box className="space-y-2">
                        <Box>Tomorrow</Box>
                        <Box className="flex items-center justify-center gap-2">
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-high)] text-[var(--color-white-1)]">1</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-medium)] text-[var(--color-white-1)]">6</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-low)] text-[var(--color-white-1)]">13</Box>
                        </Box>
                      </Box>
                    }
                  />
                  <Tab
                    onClick={() => handlePeriodSelect("next_7")}
                    disableRipple
                    sx={{
                      maxWidth: "none",
                      minWidth: "none",
                      "&.Mui-selected": {
                        background: "var(--color-green-12)",
                        borderRadius: "3px",
                      },
                    }}
                    className="!text-[12px] !font-[Poppins] !normal-case !font-normal"
                    label={
                      <Box className="space-y-2">
                        <Box>Next Seven Days</Box>
                        <Box className="flex items-center justify-center gap-2">
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-high)] text-[var(--color-white-1)]">1</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-medium)] text-[var(--color-white-1)]">6</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-low)] text-[var(--color-white-1)]">13</Box>
                        </Box>
                      </Box>
                    }
                  />
                  <Tab
                    onClick={handleClick}
                    disableRipple
                    sx={{
                      "&.Mui-selected": {
                        background: "var(--color-green-12)",
                      },
                    }}
                    className="!text-[12px] !font-[Poppins] !normal-case !font-normal"
                    label={
                      <Box className="space-y-2 flex items-center">
                        <Box>{dayjs(selectedDate, "DD-MM-YYYY").format("DD MMM, YYYY")}</Box>
                        <CalendarToday className="!mt-0" />
                      </Box>
                    }
                  />
                </Tabs>
              ) : (
                <Tabs
                  sx={{
                    "& .Mui-selected": {
                      borderBottom: "1px solid red",
                    },
                    "& .MuiTabs-indicator": {
                      backgroundColor: "transparent",
                    },
                  }}
                  value={tabIndex}
                  onChange={handleTabChange}
                >
                  <Tab
                    onClick={() => handlePeriodSelect("last_7")}
                    disableRipple
                    sx={{
                      width: "16.66%",
                      "&.Mui-selected": {
                        background: "var(--color-green-12)",
                      },
                    }}
                    className="!text-[12px] !font-[Poppins] !normal-case !font-normal"
                    label={
                      <Box className="space-y-2">
                        <Box className="text-base font-semibold">Last Seven Days</Box>
                        <Box className="flex items-center justify-center gap-2">
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-high)] text-[var(--color-white-1)]">1</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-medium)] text-[var(--color-white-1)]">6</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-low)] text-[var(--color-white-1)]">13</Box>
                        </Box>
                      </Box>
                    }
                  />
                  <Tab
                    onClick={() => handlePeriodSelect("yesterday")}
                    disableRipple
                    sx={{
                      width: "16.66%",
                      "&.Mui-selected": {
                        background: "var(--color-green-12)",
                      },
                    }}
                    className="!text-[12px] !font-[Poppins] !normal-case !font-normal"
                    label={
                      <Box className="space-y-2">
                        <Box className="text-base font-semibold">Yesterday</Box>
                        <Box className="flex items-center justify-center gap-2">
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-high)] text-[var(--color-white-1)]">1</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-medium)] text-[var(--color-white-1)]">6</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-low)] text-[var(--color-white-1)]">13</Box>
                        </Box>
                      </Box>
                    }
                  />
                  <Tab
                    onClick={() => handlePeriodSelect("today")}
                    disableRipple
                    sx={{
                      width: "16.66%",
                      "&.Mui-selected": {
                        background: "var(--color-green-12)",
                      },
                    }}
                    className="!text-[12px] !font-[Poppins] !normal-case !font-normal"
                    label={
                      <Box className="space-y-2">
                        <Box className="text-base font-semibold">Today</Box>
                        <Box className="flex items-center justify-center gap-2">
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-high)] text-[var(--color-white-1)]">1</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-medium)] text-[var(--color-white-1)]">6</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-low)] text-[var(--color-white-1)]">13</Box>
                        </Box>
                      </Box>
                    }
                  />
                  <Tab
                    onClick={() => handlePeriodSelect("tomorrow")}
                    disableRipple
                    sx={{
                      width: "16.66%",
                      "&.Mui-selected": {
                        background: "var(--color-green-12)",
                      },
                    }}
                    className="!text-[12px] !font-[Poppins] !normal-case !font-normal"
                    label={
                      <Box className="space-y-2">
                        <Box className="text-base font-semibold">Tomorrow</Box>
                        <Box className="flex items-center justify-center gap-2">
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-high)] text-[var(--color-white-1)]">1</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-medium)] text-[var(--color-white-1)]">6</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-low)] text-[var(--color-white-1)]">13</Box>
                        </Box>
                      </Box>
                    }
                  />
                  <Tab
                    onClick={() => handlePeriodSelect("next_7")}
                    disableRipple
                    sx={{
                      width: "16.66%",
                      "&.Mui-selected": {
                        background: "var(--color-green-12)",
                      },
                    }}
                    className="!text-[12px] !font-[Poppins] !normal-case !font-normal"
                    label={
                      <Box className="space-y-2">
                        <Box className="text-base font-semibold">Next Seven Days</Box>
                        <Box className="flex items-center justify-center gap-2">
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-high)] text-[var(--color-white-1)]">1</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-medium)] text-[var(--color-white-1)]">6</Box>
                          <Box className="w-6 h-6 flex justify-center items-center bg-[var(--calender-low)] text-[var(--color-white-1)]">13</Box>
                        </Box>
                      </Box>
                    }
                  />

                  <Tab
                    onClick={handleClick}
                    disableRipple
                    sx={{
                      "&.Mui-selected": {
                        background: "var(--color-green-12)",
                        width: "16.66%",
                      },
                    }}
                    className="!text-[12px] !font-[Poppins] !normal-case !font-normal"
                    label={
                      <Box className="space-y-2">
                        <Box className="text-base font-semibold">{dayjs(selectedDate, "DD-MM-YYYY").format("DD MMM, YYYY")}</Box>
                        <Box className="flex items-center">
                          <CalendarToday />
                        </Box>
                      </Box>
                    }
                  />
                </Tabs>
              )}
            </Box>
            <Divider />
            {/* Filter section start here*/}
            <FilterSectionCalender {...{ handleFindTile, handlePrint, importance, setImportance }} />
            {/* Filter section end here*/}
            <Grid container>
              {tabIndex === 0 && (
                <Grid item xs={12}>
                  <CustomAccordion {...{ handleChangePage, page }} />
                </Grid>
              )}
              {tabIndex === 1 && (
                <Grid item xs={12}>
                  <CustomAccordion {...{ handleChangePage, page }} />
                </Grid>
              )}
              {tabIndex === 2 && (
                <Grid item xs={12}>
                  <CustomAccordion {...{ handleChangePage, page }} />
                </Grid>
              )}
              {tabIndex === 3 && (
                <Grid item xs={12}>
                  <CustomAccordion {...{ handleChangePage, page }} />
                </Grid>
              )}
              {tabIndex === 4 && (
                <Grid item xs={12}>
                  <CustomAccordion {...{ handleChangePage, page }} />
                </Grid>
              )}
              {tabIndex === 5 && (
                <Grid item xs={12}>
                  <CustomAccordion {...{ handleChangePage, page }} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <DateCalender {...{ id, open, anchorEl, handleClose, selectedDate, handleSelect }} />
      </Box>
    </Layout>
  );
};

export default memo(Calender);
