import { Box, Grid, Typography, useTheme } from "@mui/material";
import { memo } from "react";

const CardCommission = memo(({commissionCardData}) => {
  const theme = useTheme();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={6}>
        <Box
          sx={{
            backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
          }}
          className="flex gap-5 rounded h-[100px] p-5"
        >
          <Box>
            <Typography className="!text-[13px] !font-semibold dark:!text-[var(--color-gray-1)] !text-[var(--color-black-7)]">Total Packages</Typography>
            <Typography className="!text-[28px] !font-bold dark:!text-[var(--color-white-1)] !text-[var(--color-black-1)]">{commissionCardData?.total_packages}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <Box
          sx={{
            backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
          }}
          className="flex gap-5 rounded h-[100px] p-5"
        >
          <Box>
            <Typography className="!text-[13px] !font-semibold dark:!text-[var(--color-gray-1)] !text-[var(--color-black-7)]">Total Price</Typography>
            <Typography className="!text-[28px] !font-bold dark:!text-[var(--color-white-1)] !text-[var(--color-black-1)]">{commissionCardData?.total_package_price}</Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
});

export default CardCommission;
