import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { memo } from "react";
import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";

const MySlaves = () => {
  const theme = useTheme();
  const slaves = useSelector((state) => state.pam.slaves);
  return (
    <TableContainer
      sx={{
        marginBottom: {
          xs: "-10px",
          sm: "10px",
          md: "5px",
        },
        overflowX: "auto",
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "var(--color-green-7)",
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "none",
        },
      }}
    >
      <Table aria-label="Open Trades">
        <TableHead>
          <TableRow className="dark:bg-[--color-green-6] bg-[--color-dark-green] shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]">
            <TableCell
              sx={{
                borderBottom: "none",
                borderRadius: "10px 0 0 10px",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              ID
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              NAME
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              EMAIL
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              PHONE
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              COMMISSION
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              PLATFORM
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              ADDRESS
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              CITY
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              STATE
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              ZIP CODE
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                borderRadius: "0 10px 10px 0",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              ACCOUNT NUMBER
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {slaves?.data?.length > 0 ? (
            slaves?.data?.map((item, i) => (
              <TableRow
                className={`bg-[${i % 2 === 0 ? "--color-dark-green-2" : "--color-green-5"}] shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]`}
                key={i}
                sx={{
                  background:
                    theme.palette.mode === "dark"
                      ? i % 2 === 0
                        ? "var(--color-dark-green-2)"
                        : "var(--color-green-5)"
                      : i % 2 === 0
                      ? "var(--color-green-4)"
                      : "var(--color-green-5)",
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  sx={{ borderBottom: "none", borderRadius: "10px 0 0 10px", color: "var(--color-white-1)", fontFamily: "Anek Telugu", fontWeight: 500, fontSize: "14px" }}
                  component="th"
                  scope="row"
                >
                  {item.id}
                </TableCell>
                <TableCell sx={{ borderBottom: "none", color: "var(--color-white-1)", fontFamily: "Anek Telugu", fontWeight: 500, fontSize: "14px", textTransform: "capitalize" }}>
                  {item.name}
                </TableCell>
                <TableCell sx={{ borderBottom: "none", color: "var(--color-white-1)", fontFamily: "Anek Telugu", fontWeight: 500, fontSize: "14px" }}>{item.email}</TableCell>
                <TableCell sx={{ borderBottom: "none", color: "var(--color-white-1)", fontFamily: "Anek Telugu", fontWeight: 500, fontSize: "14px" }}>
                  {item.phone ? item.phone : "- - - - -"}
                </TableCell>
                <TableCell sx={{ borderBottom: "none", color: "var(--color-white-1)", fontFamily: "Anek Telugu", fontWeight: 500, fontSize: "14px" }}>
                  {item.commission ? item.commission : "- - - - -"}
                </TableCell>
                <TableCell sx={{ borderBottom: "none", color: "var(--color-white-1)", fontFamily: "Anek Telugu", fontWeight: 500, fontSize: "14px" }}>
                  {item.platform ? item.platform : "- - - - -"}
                </TableCell>
                <TableCell sx={{ borderBottom: "none", color: "var(--color-white-1)", fontFamily: "Anek Telugu", fontWeight: 500, fontSize: "14px" }}>
                  {item.address ? item.address : "- - - - -"}
                </TableCell>
                <TableCell sx={{ borderBottom: "none", color: "var(--color-white-1)", fontFamily: "Anek Telugu", fontWeight: 500, fontSize: "14px" }}>
                  {item.city ? item.city : "- - - - -"}
                </TableCell>
                <TableCell sx={{ borderBottom: "none", color: "var(--color-white-1)", fontFamily: "Anek Telugu", fontWeight: 500, fontSize: "14px" }}>
                  {item.state ? item.state : "- - - - -"}
                </TableCell>
                <TableCell sx={{ borderBottom: "none", color: "var(--color-white-1)", fontFamily: "Anek Telugu", fontWeight: 500, fontSize: "14px" }}>
                  {item.zipcode ? item.zipcode : "- - - - -"}
                </TableCell>
                <TableCell
                  sx={{ borderBottom: "none", borderRadius: "0 10px 10px 0", color: "var(--color-white-1)", fontFamily: "Anek Telugu", fontWeight: 500, fontSize: "14px" }}
                >
                  {item.account_number}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow className="!h-5 flex justify-center items-center">
              <TableCell
              align="center"
              colSpan={11}
                sx={{
                  borderBottom: "none",
                  color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                  fontFamily: "Anek Telugu",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
              >
                No data found!
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(MySlaves);
