import { createTheme } from "@mui/material/styles";

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: "#DBFFEE",
        },

    },
});

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: "#001811",
        },
    },
});
