import mirrorApi from '../api/mirror_trade_api';
import { getAuthToken } from './auth';

export const addAccount = async (data) => {
    try {
        return await mirrorApi.post(`/add-account`, data, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
    console.log("🚀 ~ file: add_account.js:12 ~ addAccount ~ error:", error)
    }
}

// Update Account
export const updateAccount = async (data) => {
    try {
        return await mirrorApi.post(`/user/account/update/${data.account_id}`, data.account, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ file: add_account.js:12 ~ addAccount ~ error:", error)
    }
}