import Chart from "chart.js/auto";
import { memo, useEffect, useRef } from "react";


const PamUsrChart = ({ profit, performanceChartData }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const existingChart = Chart.getChart(ctx);

    if (existingChart) {
      existingChart.destroy();
    }

    const gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);
    gradientStroke1.addColorStop(1, "rgba(0, 208, 148, 0.2)");
    gradientStroke1.addColorStop(0.2, "rgba(72, 72, 176, 0.0)");
    gradientStroke1.addColorStop(0, "rgba(0, 208, 148, 0)");

    const gradientRed = ctx.createLinearGradient(0, 230, 0, 50);
    gradientRed.addColorStop(1, "rgba(255, 0, 0, 0.2)");
    gradientRed.addColorStop(0.2, "rgba(255, 0, 0, 0.0)");
    gradientRed.addColorStop(0, "rgba(255, 0, 0, 0)");

    new Chart(ctx, {
      type: "line",
      data: {
        labels: performanceChartData?.data?.map((_, index) => String(index + 1)),
        datasets: [
          {
            label: "",
            tension: 0.4,
            borderWidth: 3,
            backgroundColor: profit > 0 ? gradientStroke1 : gradientRed,
            borderColor: profit > 0 ? "#00d094" : "#ef4d53",
            fill: true,
            data: performanceChartData?.data,
            maxBarThickness: 6,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointBackgroundColor: profit > 0 ? "#00d094" : "#ef4d53",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: false,
              padding: 0,
              color: "#b2b9bf",
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: false,
              color: "#b2b9bf",
              padding: 0,
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    });
  }, [performanceChartData, profit]);

  return <canvas ref={chartRef} />;
};

export default memo(PamUsrChart);
