import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { memo } from "react";
import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";

const OpenTradeHistoryTable = () => {
  const theme = useTheme();
  const openTradeHistory = useSelector((state) => state.trade.openTradeHistory);

  return (
    <TableContainer
      sx={{
        marginBottom: {
          xs: "-10px",
          sm: "10px",
          md: "5px",
        },
        overflowX: "auto",
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "var(--color-green-7)",
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "none",
        },
      }}
    >
      <Table aria-label="Open Trades">
        <TableHead>
          <TableRow className="dark:bg-[--color-green-6] bg-[--color-dark-green] shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]">
            <TableCell
              sx={{
                borderBottom: "none",
                borderRadius: "10px 0 0 10px",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              ID
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              TICKET
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              MASTER
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              COPY OF
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              OPEN TIME
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              SYMBOL
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              TYPE
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              LOTS
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              OPEN PRICE
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              SL
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              TP
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              COM
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                borderRadius: "0 10px 10px 0",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              SWAP
            </TableCell>
            {/* <TableCell
              sx={{
                borderBottom: "none",
                borderRadius: "0 10px 10px 0",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              PROFIT
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {openTradeHistory?.data?.length > 0 ? (
            openTradeHistory?.data?.map((item, i) => (
              <TableRow
                className={`shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]`}
                key={i}
                sx={{
                  background:
                    theme.palette.mode === "dark"
                      ? i % 2 === 0
                        ? "var(--color-dark-green-2)"
                        : "var(--color-green-5)"
                      : i % 2 === 0
                      ? "var(--color-green-4)"
                      : "var(--color-green-5)",
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  sx={{
                    borderBottom: "none",
                    borderRadius: "10px 0 0 10px",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-white-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                  component="th"
                  scope="row"
                >
                  {item.id}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-white-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.ticket}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-white-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.master}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-white-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.copy_of}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-white-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.open_time}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-white-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.symbol}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-white-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.type}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-white-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.lots}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-white-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.open_price}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-white-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.sl}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-white-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.tp}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-white-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.com}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    borderRadius: "0 10px 10px 0",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-white-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.swap}
                </TableCell>
                {/* <TableCell
                  sx={{
                    borderBottom: "none",
                    borderRadius: "0 10px 10px 0",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-white-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.profit}
                </TableCell> */}
              </TableRow>
            ))
          ) : (
            <TableRow className="!h-5 flex justify-center items-center">
              <TableCell
                align="center"
                colSpan={12}
                sx={{
                  borderBottom: "none",
                  color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                  fontFamily: "Anek Telugu",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
              >
                No data found!
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(OpenTradeHistoryTable);
