import { FormControl, Grid, InputAdornment, TextField, useMediaQuery } from "@mui/material";
import Layout from "layouts/Layout";
import React, { memo, useState } from "react";
import { Search } from "@mui/icons-material";
import BrokerPortalTable from "./BrokerPortalTable";
const BrokerPortal = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [searchText, setSearchText] = useState("");

  const handleSearchBroker = async (e) => {
    setSearchText(e.target.value);
  };

  return (
    <Layout>
      <Grid container sx={{ padding: 2 }}>
        <Grid item xs={12} className="bg-[var(--color-green-1)] h-[70px] flex items-center justify-end rounded-t">
          <FormControl
            className="!mr-10"
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "40px",
                width: isSmallScreen ? "100%" : "350px",
                background: "var(--color-black-4)",
                color: "var(--color-white-1)",
                borderRadius: "5px",
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: `none`,
                },
              },
            }}
          >
            <TextField
              onChange={handleSearchBroker}
              placeholder="FIND A BROKER"
              type="text"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ color: "var(--color-green-1)", fontSize: "28px" }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& input::placeholder": {
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "var(--color-white-1)",
                  opacity: 1,
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <BrokerPortalTable {...{searchText}} />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default memo(BrokerPortal);
