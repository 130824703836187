import { Box, Button, FormControl, Grid, MenuItem, Pagination, Select, TextField, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import Layout from "layouts/Layout";
import { memo, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useDispatch, useSelector } from "react-redux";
import { setAffiliateClients } from "redux/features/ibDashboard/ibDashboardSlice";
import { affiliateClients, getAffiliateClientsByQuery } from "server/ib_dashboard";
import ClientsTableContainer from "./ClientsTableContainer";
import ExportTypeSelect from "./ExportTypeSelect";

const MyClients = memo(() => {
  const [loading, setLoading] = useState(false);
  const clients = useSelector((state) => state.ib.affiliateClients);
  const dispatch = useDispatch();
  const [exportType, setExportType] = useState("export");
  const theme = useTheme();
  const tableRef = useRef(null);
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(clients?.length / itemsPerPage);

  const formik = useFormik({
    initialValues: {
      client_type: "full",
      trader_info: "",
      account_number: "",
      status: "select",
      platform: "select",
    },

    onSubmit: async (values) => {
      try {
        setLoading(true);
        const queryParams = {
          trader_info: values.trader_info,
          account_number: values.account_number,
          client_type: values.client_type,
        };

        const result = await getAffiliateClientsByQuery(queryParams);
        if (result?.data?.status) {
          setLoading(false);
          dispatch(setAffiliateClients(result?.data?.data));
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    },
  });

  const getAffiliateClients = async () => {
    setLoading(true);
    const result = await affiliateClients();
    if (result?.data?.status) {
      setLoading(false);
      dispatch(setAffiliateClients(result?.data?.data));
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAffiliateClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle page change
  const handlePageChange = (_, value) => {
    setCurrentPage(value);
  };

  // Sliced data for the current page
  const currentClientsData = clients.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <Layout>
      <Box className="p-5 space-y-10">
        <Box
          className="rounded-md p-5"
          sx={{
            backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
          }}
        >
          <Box className="flex justify-between items-center mb-12">
            <Box>Filter Reports</Box>
            <Box>
              <ExportTypeSelect {...{ exportType, setExportType, clients, tableRef }} />
            </Box>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Grid container columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                  }}
                  size="small"
                >
                  <Select
                    name="client_type"
                    value={formik.values.client_type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          background: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
                        },
                      },
                    }}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                  >
                    <MenuItem value="full">Full Team</MenuItem>
                    <MenuItem value="my_direct">My Direct</MenuItem>
                    <MenuItem value="my_team">My Team</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                  }}
                  size="small"
                >
                  <TextField
                    type="text"
                    placeholder="Trader Name / Email / Phone"
                    size="small"
                    variant="outlined"
                    name="trader_info"
                    value={formik.values.trader_info}
                    onChange={formik.handleChange}
                    sx={{
                      "& input::placeholder": {
                        fontWeight: 500,
                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                        opacity: 1,
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                  }}
                  size="small"
                >
                  <TextField
                    type="text"
                    placeholder="Trading Account Number"
                    size="small"
                    variant="outlined"
                    name="account_number"
                    value={formik.values.account_number}
                    onChange={formik.handleChange}
                    sx={{
                      "& input::placeholder": {
                        fontWeight: 500,
                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                        opacity: 1,
                      },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                  }}
                  size="small"
                >
                  <TextField
                    type="text"
                    placeholder="IB Name / Email / Phone"
                    size="small"
                    variant="outlined"
                    sx={{
                      "& input::placeholder": {
                        fontWeight: 500,
                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                        opacity: 1,
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                  }}
                  size="small"
                >
                  <Select
                    name="status"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          background: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
                        },
                      },
                    }}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                  >
                    <MenuItem value="select">Select Status Type</MenuItem>
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="block">Block</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                  }}
                  size="small"
                >
                  <Select
                    name="platform"
                    value={formik.values.platform}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          background: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
                        },
                      },
                    }}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                  >
                    <MenuItem value="select">Select Platform</MenuItem>
                    <MenuItem value="mt4">MT4</MenuItem>
                    <MenuItem value="mt5">MT5</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Button
                  type="reset"
                  onClick={() => formik.handleReset()}
                  variant="contained"
                  disableRipple
                  fullWidth
                  sx={{
                    marginTop: "2px",
                    backgroundColor: "var(--color-green-5)",
                    ":hover": {
                      backgroundColor: "var(--color-green-4)",
                    },
                  }}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Button
                  type="submit"
                  variant="contained"
                  disableRipple
                  fullWidth
                  sx={{
                    marginTop: "2px",
                    backgroundColor: "var(--color-green-1)",
                    ":hover": {
                      backgroundColor: "var(--color-green-7)",
                    },
                  }}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </form>
        </Box>

        <Box
          className="rounded-md p-5"
          sx={{
            backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
          }}
        >
          <Box className="flex justify-between items-center mb-4">
            <Typography></Typography>
            <Box className="flex items-center gap-2">
              <CSVLink data={clients}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "var(--color-green-5)",
                    ":hover": {
                      backgroundColor: "var(--color-green-4)",
                    },
                  }}
                >
                  CSV
                </Button>
              </CSVLink>
              <DownloadTableExcel filename="clients" sheet="clients" currentTableRef={tableRef.current}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "var(--color-green-1)",
                    ":hover": {
                      backgroundColor: "var(--color-green-7)",
                    },
                  }}
                >
                  EXCEL
                </Button>
              </DownloadTableExcel>
            </Box>
          </Box>

          <Grid container>
            <Grid item xs={12}>
              <ClientsTableContainer {...{ tableRef, loading, currentClientsData }} />
            </Grid>
          </Grid>

          <Box className="flex justify-center items-center mt-1">
            <Box>
              {/* <Pagination count={2} variant="outlined" shape="rounded" page={2} size={"medium"} /> */}
              <Pagination count={totalPages} variant="outlined" shape="rounded" page={currentPage} onChange={handlePageChange} size={"medium"} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
});

export default MyClients;
