import { memo, useEffect, useState } from "react";
import { pammProfit } from "server/pamm";

const PammProfit = memo(({ id }) => {
  const [profit, setProfits] = useState(0);

  const fetchProfit = async () => {
    const result = await pammProfit(id);
    if (result?.data?.status) {
      setProfits(result?.data?.data);
      setProfits(result?.data?.data);
    }
  };

  useEffect(() => {
    fetchProfit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return profit;
});

export default PammProfit;
