// import { Chart } from "chart.js/auto";
// import React, { memo, useEffect, useRef } from "react";
// import { useSelector } from "react-redux";
// import bgTradeImg from "../../assets/img/gridBaseMain.png";

// const TradingGrowthChart = () => {
//   const chartRef = useRef(null);
//   const tradingGrowth = useSelector((state) => state.chart.tradingGrowth);

//   useEffect(() => {

//     const createChart = () => {
//       if (chartRef.current) {
//         const ctx = chartRef.current.getContext("2d");

//         const image = new Image();
//         image.src = bgTradeImg;
//         const plugin = {
//           id: 'customCanvasBackgroundImageTradingGrowth',
//           beforeDraw: (chart) => {
//             if (image.complete) {
//               const ctx = chart.ctx;
//               const {top, left, width, height} = chart.chartArea;
//               const x = left + width / 2 - image.width / 2;
//               const y = top + height / 2 - image.height / 2;
//               ctx.drawImage(image, x, y);
//             } else {
//               image.onload = () => chart.draw();
//             }
//           }
//         }
//         Chart.register(plugin);

//         const chartInstance = new Chart(ctx, {
//           type: "line",
//           data: {
//             labels: tradingGrowth?.label,
//             datasets: [
//               {
//                 label: "",
//                 fill: false,
//                 lineTension: 0.3,
//                 backgroundColor: "#1A8D66",
//                 borderColor: "#27D195",
//                 data: tradingGrowth?.data,
//               },
//             ],
//           },
//           plugins: [plugin],
//           options: {
//             scales: {
//               x: {
//                 grid: {
//                   drawBorder: false,
//                   display: false,
//                   drawOnChartArea: false,
//                   drawTicks: false,
//                 },
//                 display: true,
//                 title: {
//                   display: false,
//                   text: "Time",
//                 },
//                 ticks: {
//                   padding: 20,
//                 },
//                 border: {
//                   width: 0,
//                   color: "transparent",
//                 },
//               },
//               y: {
//                 grid: {
//                   drawBorder: false,
//                   display: false,
//                   drawOnChartArea: false,
//                   drawTicks: false,
//                 },
//                 display: true,
//                 title: {
//                   display: false,
//                   text: "Value",
//                 },
//                 border: {
//                   width: 0,
//                   color: "transparent",
//                 },
//               },
//             },
//             elements: {
//               line: {
//                 borderWidth: 2,
//               },
//               point: {
//                 radius: 5,
//                 backgroundColor: "rgba(75,192,192,1)",
//               },
//             },
//             layout: {
//               padding: {
//                 left: 10,
//                 right: 10,
//                 top: 10,
//                 bottom: 0,
//               },
//             },
//             plugins: {
//               legend: {
//                 display: false,
//               },
//               resize: true,
//             },
//             responsive: true,
//             maintainAspectRatio: false,
//             maxHeight: "100vh",
//             animation: {
//               duration: function (animation) {
//                 return animation ? 1000 : 0;
//               },
//             },
//           },
//         });

//         return () => {
//           chartInstance.destroy();
//         };
//       }
//     };

//     const interval = setInterval(() => {
//       if (chartRef.current) {
//         clearInterval(interval);
//         createChart();
//       }
//     }, 100);

//     return () => clearInterval(interval);
//   }, [chartRef, tradingGrowth?.data, tradingGrowth?.label]);

//   return <canvas ref={chartRef} style={{ height: "350px" }} />;
// };

// export default memo(TradingGrowthChart);

import { Chart } from "chart.js/auto";
import React, { memo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import bgTradeImg from "../../assets/img/gridBaseMain.png";

const TradingGrowthChart = () => {
  const chartRef = useRef(null);
  const tradingGrowth = useSelector((state) => state.chart.tradingGrowth);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    const createChart = () => {
      if (chartRef.current) {
        if (chartInstanceRef.current) {
          // If chart instance exists, destroy it before creating a new one
          chartInstanceRef.current.destroy();
        }

        const ctx = chartRef.current.getContext("2d");
        const image = new Image();
        image.src = bgTradeImg;

        const plugin = {
          id: "customCanvasBackgroundImageTradingGrowth",
          beforeDraw: (chart) => {
            if (image.complete) {
              const ctx = chart.ctx;
              const { top, left, width, height } = chart.chartArea;
              const x = left + width / 2 - image.width / 2;
              const y = top + height / 2 - image.height / 2;
              ctx.drawImage(image, x, y);
            } else {
              image.onload = () => chart.draw();
            }
          },
        };

        Chart.register(plugin);

        const newChartInstance = new Chart(ctx, {
          type: "line",
          data: {
            labels: tradingGrowth?.label,
            datasets: [
              {
                label: "",
                fill: false,
                tension: 0.4,
                backgroundColor: "#1A8D66",
                borderColor: "#27D195",
                data: tradingGrowth?.data,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 2,
                pointBackgroundColor: "#00d094",
              },
            ],
          },
          plugins: [plugin],
          options: {
            scales: {
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                display: true,
                title: {
                  display: false,
                  text: "Time",
                },
                ticks: {
                  padding: 20,
                },
                border: {
                  width: 0,
                  color: "transparent",
                },
              },
              y: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                display: true,
                title: {
                  display: false,
                  text: "Value",
                },
                border: {
                  width: 0,
                  color: "transparent",
                },
              },
            },
            elements: {
              line: {
                borderWidth: 3,
              },
              point: {
                radius: 0,
                backgroundColor: "rgba(75,192,192,1)",
                hoverRadius: 5,
              },
            },
            layout: {
              padding: {
                left: 10,
                right: 10,
                top: 10,
                bottom: 0,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              resize: true,
            },
            responsive: true,
            maintainAspectRatio: false,
            hover: {
              intersect: false,
            },
            maxHeight: "100vh",
            animation: {
              duration: function (animation) {
                return animation ? 1000 : 0;
              },
            },
          },
        });

        // Save the new chart instance to the ref
        chartInstanceRef.current = newChartInstance;

        return () => {
          if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
          }
        };
      }
    };

    const interval = setInterval(() => {
      if (chartRef.current) {
        clearInterval(interval);
        createChart();
      }
    }, 100);

    return () => clearInterval(interval);
  }, [chartRef, tradingGrowth?.data, tradingGrowth?.label]);

  return <canvas ref={chartRef} style={{ height: "350px" }} />;
};

export default memo(TradingGrowthChart);
