import React, { useState } from "react";
import TopBarChat from "./TopBarChat";
import { Grid, Tab, Tabs } from "@mui/material";
import FriendList from "./FriendList";
import IncomingFriendList from "./IncomingFriendList";
import FriendOutgoingList from "./FriendOutgoingList";

const Friends = ({ handlePopoverCloseChat, setFriendId }) => {
  const [friendTabIndex, setFriendTabIndex] = useState(0);

  const handleFriendTab = (_, newTabIndex) => {
    setFriendTabIndex(newTabIndex);
  };

  return (
    <Grid
      container
      sx={{
        width: "360px",
        height: "400px",
        background: "var(--color-green-8)",
        padding: "15px",
        borderRadius: "10px",
        overflow: "hidden",
      }}>
      <Grid
        item
        xs={12}>
        <TopBarChat {...{ handlePopoverCloseChat }} />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ width: "100%" }}>
        <Tabs
          orientation="horizontal"
          value={friendTabIndex}
          onChange={handleFriendTab}
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "transparent",
            },
            "& .MuiTab-root": {
              display: "flex",
              alignItems: "flex-start",
            },
          }}>
          <Tab
            disableRipple
            sx={{
              maxWidth: "none",
              minWidth: "none",
              minHeight: "10px",
              borderRadius: "2px",
              "&.Mui-selected": {
                background: "var(--color-green-12)",
              },
            }}
            className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-normal"
            label="Requests"
          />

          <Tab
            disableRipple
            sx={{
              maxWidth: "none",
              minWidth: "none",
              minHeight: "10px",
              borderRadius: "2px",
              "&.Mui-selected": {
                background: "var(--color-green-12)",
              },
            }}
            className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-normal"
            label="Friend list"
          />
          <Tab
            disableRipple
            sx={{
              maxWidth: "none",
              minWidth: "none",
              minHeight: "10px",
              borderRadius: "2px",
              "&.Mui-selected": {
                background: "var(--color-green-12)",
              },
            }}
            className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-normal"
            label="Outgoing Request"
          />
        </Tabs>
      </Grid>
      {friendTabIndex === 0 && <IncomingFriendList {...{ setFriendId }} />}
      {friendTabIndex === 1 && <FriendList {...{ setFriendId }} />}
      {friendTabIndex === 2 && <FriendOutgoingList {...{ setFriendId }} />}
    </Grid>
  );
};

export default Friends;
