export default Object.freeze({
  USER_OR_EMAIL: "Email or user is required.",
  EMAIL_REQUIRED: "Email is required.",
  CITY_REQUIRED: "City is required.",
  ZIP_REQUIRED: "Zip is required.",
  ADDRESS_REQUIRED: "Address is required.",
  MIN_PASSWORD: 'Password should be of minimum 6 characters length.',
  PASSWORD_REQUIRED: "Password is required.",
  CHECKBOX_REQUIRED: "Terms and conditions is required",
  LOGIN_REQUIRED: "Login is required",
  SERVER_REQUIRED: "Server is required",
  LOGIN_ACCOUNT : "Login ID is Required",
  PLATFORM_SELECT : "Platform is Required"
});
