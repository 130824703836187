import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch, useSelector } from "react-redux";
import { setNavigateChat } from "redux/features/chatSlice/chatSlice";
import { friend_request_sent, search_user } from "server/chat";
import TopBarChat from "./TopBarChat";
import { CloudOutlined } from "@mui/icons-material";

const SearchFriend = ({ handlePopoverCloseChat }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [searchList, setSearchList] = useState([]);
  const friendList = useSelector((state) => state.chat.friendList);
  const incomingFriendList = useSelector((state) => state.chat.incomingFriendList);
  const friendListOutgoing = useSelector((state) => state.chat.friendListOutgoing);
  const [sentReq, setSentReq] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleSearchUser = async (e) => {
    // i want when value is more than 3 then i will call the api
    if (e.target.value.length < 3) {
      return;
    }
    setLoading(true);
    setSearchText(e.target.value);
    const result = await search_user(e.target.value);
    if (result?.data?.status) {
      // i want to check if the user is already in the friend list then i will not show this user in the search list
      setLoading(false);
      if (result?.data?.data?.length > 0) {
        const filtered = result?.data?.data?.filter((item) => {
          return (
            !friendList.some((friend) => friend.user.id === item.id) &&
            !incomingFriendList.some((friend) => friend.user.id === item.id) &&
            !friendListOutgoing.some((friend) => friend.user.id === item.id)
          );
        });
        setSearchList(filtered);
      }
    } else {
      setLoading(false);
    }
  };

  const handleSentFriendRequest = async (_, user_id) => {
    const result = await friend_request_sent(user_id);
    if (result?.data?.status) {
      setSentReq([...sentReq, user_id]);
    }
  };

  return (
    <Grid
      container
      sx={{
        width: "360px",
        height: "400px",
        background: "var(--color-green-8)",
        padding: "15px",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <Grid item xs={12}>
        <TopBarChat {...{ handlePopoverCloseChat }} />
      </Grid>
      <Grid item xs={12}>
        <FormControl
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              height: "30px",
              width: "100%",
              borderRadius: "200px",
              fontSize: "14px",
              color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-2)",
              "& fieldset": {
                border: "1px solid var(--color-green-7)",
              },
              "&:hover fieldset": {
                border: "1px solid var(--color-green-1)",
              },
              "&.Mui-focused fieldset": {
                border: `1px solid var(--color-green-2)`,
              },
            },
          }}
        >
          <TextField
            onChange={handleSearchUser}
            autoComplete="off"
            type="text"
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <KeyboardBackspaceIcon onClick={() => dispatch(setNavigateChat("chat_list"))} className="cursor-pointer" />
                </InputAdornment>
              ),
              sx: {
                "&::placeholder": {
                  color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                  opacity: 1,
                  fontSize: "12px",
                },
              },
            }}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} className="chat-list-scrollbar" sx={{ height: "300px", width: "100%", overflowY: "auto" }}>
        {loading ? (
          <Grid container justifyContent="center" alignItems="center" height="100%">
            <CircularProgress sx={{ color: "var(--color-white-1)" }} />
          </Grid>
        ) : (
          <List sx={{ width: "100%" }}>
            {searchList?.length ? (
              searchList?.map((item) => (
                <Fragment key={item.id}>
                  <ListItem
                    className="cursor-pointer"
                    sx={{
                      "&:hover": {
                        backgroundColor: "var(--border-color)",
                      },
                    }}
                    secondaryAction={
                      sentReq.includes(item.id) ? <Chip size="small" label="Sent" /> : <Chip size="small" label="Add" onClick={(e) => handleSentFriendRequest(e, item.id)} />
                    }
                  >
                    <ListItemAvatar>
                      <Avatar alt="chat list avatar" src={item?.profile?.profile_image}></Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.name} secondary={item.email} />
                  </ListItem>
                  <Divider variant="middle" component="li" sx={{ marginLeft: 0, marginRight: 0 }} />
                </Fragment>
              ))
            ) : searchText ? (
              <Box className="flex justify-center items-center flex-col h-full">
                <CloudOutlined sx={{ fontSize: "40px" }} />
                <Typography
                  className="text-center !mt-1"
                  sx={{
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-green-3)",
                  }}
                >
                  No data found.
                </Typography>
              </Box>
            ) : (
              <Box className="flex justify-center items-center flex-col h-full">
                <Typography
                  className="text-center !mt-1"
                  sx={{
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-green-3)",
                  }}
                >
                  Please enter a minimum of three words before searching.
                </Typography>
              </Box>
            )}
          </List>
        )}
      </Grid>
    </Grid>
  );
};

export default SearchFriend;
