import { createSlice } from "@reduxjs/toolkit";

const navigateSlice = createSlice({
  name: "navigate",
  initialState: {
    type: "login",
    changePasswordNavigate: "call_api_password_change"
  },
  reducers: {
    setNavigateAuth: (state, action) => {
      state.type = action.payload;
    },
    setChangePasswordNavigate: (state, action) => {
      state.changePasswordNavigate = action.payload;
    },
  },
});

export const { setNavigateAuth, setChangePasswordNavigate} = navigateSlice.actions;
export default navigateSlice.reducer;
