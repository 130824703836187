import mirrorApi from '../api/mirror_trade_api';
const { getAuthToken } = require("./auth");

export const monthlyChart = async (id, dailyHourlyMonthlyChartFilterData = 2024) => {
    try {
        return await mirrorApi.get(`user/pamm/chart-monthly/${id}?year=${dailyHourlyMonthlyChartFilterData}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ file: chart_data.js:12 ~ monthlyChart ~ error:", error)
    }
};
export const dailyChart = async (id, dailyHourlyMonthlyChartFilterData = 2024) => {
    try {
        return await mirrorApi.get(`user/pamm/chart-daily/${id}?year=${dailyHourlyMonthlyChartFilterData}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ file: chart_data.js:12 ~ dailyChart ~ error:", error)
    }
};
export const hourChart = async (id, dailyHourlyMonthlyChartFilterData = 2024) => {
    try {
        return await mirrorApi.get(`user/pamm/chart-hourly/${id}?year=${dailyHourlyMonthlyChartFilterData}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ file: chart_data.js:12 ~ hourChart ~ error:", error)
    }
};
export const tradingGrowth = async () => {
    try {
        return await mirrorApi.get(`/trading-growth-curve`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ file: chart_data.js:45 ~ tradingGrowth ~ error:", error)
    }
};
