import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalLoading } from "redux/features/application/applicationSlice";
import { setAccounts, setServers } from "redux/features/auth/authSlice";
import { setTradingGrowth, setTradingGrowthBottom } from "redux/features/chart/chartSlice";
import { setIbDashboard } from "redux/features/ibDashboard/ibDashboardSlice";
import { setAccountListLoading, setGrowthChartLoader, setPamUserLoader } from "redux/features/loader/loaderSlice";
import { setUnseenNotifications } from "redux/features/notification/notificationSlice";
import { setPamUserData, setSymbols } from "redux/features/pam/pamSlice";
import { setPermission } from "redux/features/permission/permissionSlice";
import { setPostType } from "redux/features/posts/postsSlice";
import { setCryptoCurrency, setPackage, setProSubscription, setStandardSubscription, setVIPSubscription } from "redux/features/subscription/subscriptionSlice";
import { setAddressVerificationStatus, setIdVerificationStatus } from "redux/features/toggleKycDashboard/kycSlice";
import { allAccount } from "server/auth";
import { tradingGrowth } from "server/chart_data";
import { getIbDashboard } from "server/ib_dashboard";
import { kyc_address_verification_status, kyc_id_verification_status } from "server/kyc_upload";
import { get_unseen_notification } from "server/notification";
import { getUserPam } from "server/pamm";
import { permission } from "server/permission";
import { getPostCount } from "server/posts";
import { serverList } from "server/server";
import { getCryptoCurrency, subscription } from "server/subscription";
import { symbols } from "server/symbols";

const InitialRestRequest = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user.id);

  const getPamUser = async () => {
    dispatch(setPamUserLoader(true));
    const result = await getUserPam(1);
    if (result?.data?.status) {
      dispatch(setPamUserData(result.data.data));
      dispatch(setPamUserLoader(false));
    } else {
      dispatch(setPamUserLoader(false));
    }
    return result;
  };
  const getSymbols = async () => {
    if (user) {
      const result = await symbols();
      if (result?.data?.status) {
        dispatch(setSymbols(result.data.data));
      }
    }
  };
  // get unseen notification
  const getUnseenNotification = async () => {
    if (user) {
      const result = await get_unseen_notification();
      if (result?.data?.status) {
        dispatch(setUnseenNotifications(result.data.data));
      }
    }
  };
  const getTradingGrowth = async () => {
    dispatch(setGrowthChartLoader(true));
    const result = await tradingGrowth();
    if (result?.data?.chart) {
      dispatch(setTradingGrowth(result.data.chart));
      dispatch(setTradingGrowthBottom(result.data.buttons));
      dispatch(setGrowthChartLoader(false));
    } else {
      dispatch(setGrowthChartLoader(false));
    }
  };

  // Post api call here
  // const getAllPost = async () => {
  //   dispatch(setPostLoading(true));
  //   const result = await getPosts(1);
  //   if (result?.data?.status) {
  //     dispatch(setAllPost(result.data.data));
  //     dispatch(setPostLoading(false));
  //   } else {
  //     dispatch(setPostLoading(false));
  //   }
  // };

  // get permission
  const getPermission = async () => {
    const result = await permission();
    if (result?.data?.status) {
      dispatch(setPermission(result?.data?.permission));
    }
  };

  const getSubscription = async () => {
    const result = await subscription();
    if (result?.data?.status) {
      dispatch(setPackage(result?.data?.data));
      const standardSubscription = result.data.data.filter((item) => item.package_type === "standard");
      dispatch(setStandardSubscription(standardSubscription));
      const proSubscription = result.data.data.filter((item) => item.package_type === "pro");
      dispatch(setProSubscription(proSubscription));
      const vipSubscription = result.data.data.filter((item) => item.package_type === "vip");
      dispatch(setVIPSubscription(vipSubscription));
    }
  };

  const fetchCryptoCurrency = async () => {
    const result = await getCryptoCurrency();
    if (result?.data?.status) {
      dispatch(setCryptoCurrency(result?.data?.data));
    }
  };

  const getKYCIdVerificationStatus = async () => {
    const result = await kyc_id_verification_status();
    if (result?.status) {
      dispatch(setIdVerificationStatus(result.data.kyc_status));
    }
  };

  const getKYCAddressVerificationStatus = async () => {
    const result = await kyc_address_verification_status();
    if (result?.status) {
      dispatch(setAddressVerificationStatus(result.data.kyc_status));
    }
  };

  const getIbDashboardData = async () => {
    const result = await getIbDashboard();
    if (result?.data?.status) {
      dispatch(setIbDashboard(result?.data?.data));
    }
  };

  const getPammSlaves = async () => {};

  const getPostType = async () => {
    const res = await getPostCount();
    if (res?.data?.status) {
      dispatch(setPostType(res?.data?.data));
    }
  };

  const getAllServer = async () => {
    const res = await serverList();
    if (res?.data?.status) {
      dispatch(setServers(res?.data?.data));
    }
  };

  const getAllAccount = async () => {
    dispatch(setAccountListLoading(true));
    const res = await allAccount();
    if (res?.data?.status) {
      dispatch(setAccounts(res?.data?.data));
      dispatch(setAccountListLoading(false));
    } else {
      dispatch(setAccountListLoading(false));
    }
  };

  useEffect(() => {
    getPamUser()
      .then((res) => {
        if (res?.data?.status) {
          getPermission();
          getTradingGrowth();
          getPammSlaves().then(() => {
            getSymbols();
            getAllAccount();
            getUnseenNotification().then(() => {
              getAllServer();
              fetchCryptoCurrency();
              getSubscription().then(() => {
                getKYCIdVerificationStatus();
                getKYCAddressVerificationStatus();
                getPostType().then(() => {
                  getIbDashboardData();
                });
              });
            });
          });
        }
      })
      .finally(() => {
        dispatch(setGlobalLoading(false));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default InitialRestRequest;
