import { Box, Grid, useTheme } from "@mui/material";
import HourBarChart from "components/Chart/HourBarChart";
import React, { Fragment, memo, useEffect } from "react";
import HourDoughnutChart from "./HourDoughnutChart";
import { useDispatch, useSelector } from "react-redux";
import { hourChart } from "server/chart_data";
import { setHourChart } from "redux/features/chart/chartSlice";
import { setHourlyChartLoading } from "redux/features/loader/loaderSlice";
import AccountSkeletonLoader from "components/SkeletonLoader/AccountSkeletonLoader";

let doubleCall = false;
const HourChart = ({ id, dailyHourlyMonthlyChartFilterData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const hourlyChartLoading = useSelector((state) => state.loader.hourlyChartLoading);
  const getHourChart = async () => {
    dispatch(setHourlyChartLoading(true));
    const result = await hourChart(id, dailyHourlyMonthlyChartFilterData);
    if (result?.data?.status) {
      dispatch(setHourChart(result.data.data));
      dispatch(setHourlyChartLoading(false));
    } else {
      dispatch(setHourlyChartLoading(false));
    }
  };

  useEffect(() => {
    if(doubleCall) return;
    doubleCall = true;
    setTimeout(() => {
      doubleCall = false;
    }, 1000);
    getHourChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyHourlyMonthlyChartFilterData]);

  return (
    <Fragment>
      <Grid item xs={12} sm={12} md={6} lg={7}>
        <Box
          sx={{
            border: "1px solid rgba(0, 208, 148, 0.3)",
            background:
              theme.palette.mode === "dark"
                ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)"
                : "var(--color-green-10)",
            borderRadius: "10px",
          }}
          className="!p-[8px]"
        >
          {hourlyChartLoading ? <AccountSkeletonLoader /> : <HourBarChart />}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={5}>
        <Box
          sx={{
            border: "1px solid rgba(0, 208, 148, 0.3)",
            background:
              theme.palette.mode === "dark"
                ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)"
                : "var(--color-green-10)",
            borderRadius: "10px",
          }}
          className="!p-[8px] h-full flex justify-center items-center"
        >
          {hourlyChartLoading ? <AccountSkeletonLoader /> : <HourDoughnutChart />}
        </Box>
      </Grid>
    </Fragment>
  );
};

export default memo(HourChart);
