import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import React, { memo, useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { Formik } from "formik";
import * as yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSnackbar } from "notistack";
import { kycUpload, kyc_id_verification_status } from "server/kyc_upload";
import { refetchAuth } from "server/auth";
import { logIn } from "redux/features/auth/authSlice";
import { useDispatch } from "react-redux";
import { setIdVerificationStatus } from "redux/features/toggleKycDashboard/kycSlice";
import FrontPartFile from "./FrontPartFile";
import BackPartFile from "./BackPartFile";

const steps = ["Document Information", "Upload Document"];
const firstStepFormValidationSchema = yup.object({
  documentID: yup.string("Enter your ID Number").required("ID Number is required"),
  documentType: yup.string("Enter your Document Type").required("Document Type is required"),
  issueDate: yup.date().nullable().typeError("Invalid issue date").required("Issue date is required"),
  expireDate: yup
    .date()
    .nullable()
    .typeError("Invalid expire date")
    .min(yup.ref("issueDate"), "Expire date can't be before issue date")
    .when("documentType", {
      is: (value) => value === "adhar card",
      then: (schema) => schema.nullable().optional(),
      otherwise: (schema) => schema.required("Expire date is required"),
    }),
});
const secondStepFormValidationSchema = yup.object({
  front: yup.mixed("Please upload the document front part ").required("The document front part field is required"),
});
const IdVerify = memo(({ setStatus }) => {
  const [issueDateError, setIssueDateError] = useState("");
  const dispatch = useDispatch();
  const [kycLoading, setKycLoading] = useState(false);
  const theme = useTheme();
  const [firstStepValues, setFirstStepValues] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = React.useState(0);
  const [frontFile, setFrontFile] = useState(null);
  const [backFile, setBackFile] = useState(null);
  const [uploadedFileFrontFile, setUploadedFileFrontFile] = useState(null);
  const [uploadedFileBackFile, setUploadedFileBackFile] = useState(null);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const handleBack = () => {
    setActiveStep((step) => step - 1);
  };

  const getKYCIdVerificationStatus = async () => {
    const result = await kyc_id_verification_status();
    if (result?.status) {
      dispatch(setIdVerificationStatus(result.data.kyc_status));
    }
  };

  return (
    <Box sx={{ width: "80%", margin: "0 auto" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step
            key={label}
            sx={{
              "& .MuiSvgIcon-root.Mui-completed": { color: theme.palette.mode === "dark" ? "white" : "#0c1f19 !important" },
              "& .MuiSvgIcon-root.Mui-active": { border: theme.palette.mode === "dark" ? "1px solid white" : "1px solid black !important", borderRadius: "50%" },
            }}
          >
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>

      {activeStep === 0 && (
        <Formik
          initialValues={{
            documentID: "",
            documentType: "",
            issueDate: null,
            expireDate: null,
          }}
          validationSchema={firstStepFormValidationSchema}
          onSubmit={(values) => {
            const data = {
              id_number: values.documentID,
              document_type: values.documentType,
              issue_date: values.issueDate,
              expire_date: values.expireDate,
              perpose: "id proof",
            };
            setFirstStepValues(data);
            setActiveStep(1);
          }}
        >
          {({ handleChange, handleBlur, values, errors, setFieldValue, handleSubmit, touched }) => (
            <>
              <FormControl
                className="!pt-4 !pb-4"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    height: "100%",
                    "& fieldset": {
                      border: "1px solid var(--color-gray-2) !important",
                    },
                    "&:hover fieldset": {
                      border: "1px solid var(--color-green-1) !important",
                    },
                    "&.Mui-focused fieldset": {
                      border: `1px solid var(--color-green-2) !important`,
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: theme.palette.mode === "dark" ? "var(--color-white-1) !important" : "var(--color-text-2) !important",
                    "&.Mui-focused": {
                      color: "var(--color-green-2) !important",
                    },
                  },
                }}
              >
                <TextField
                  type="text"
                  label="ID Number"
                  variant="outlined"
                  name="documentID"
                  value={values.documentID}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.documentID && Boolean(errors.documentID)}
                />
                {touched.documentID && errors.documentID && <Typography className="!text-[#f44336] !text-[12px] !mt-1">{touched.documentID && errors.documentID}</Typography>}
              </FormControl>
              <FormControl
                className="!pb-4"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid var(--color-gray-2) !important",
                    },
                    "&:hover fieldset": {
                      border: "1px solid var(--color-green-1) !important",
                    },
                    "&.Mui-focused fieldset": {
                      border: `1px solid var(--color-green-2) !important`,
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: theme.palette.mode === "dark" ? "var(--color-white-1) !important" : "var(--color-text-2) !important",
                    "&.Mui-focused": {
                      color: "var(--color-green-2) !important",
                    },
                  },
                }}
              >
                <InputLabel id="demo-select-small-label">Document Type</InputLabel>
                <Select
                  MenuProps={{
                    PaperProps: {
                      style: {
                        color: theme.palette.mode === "dark" ? "var(--color-white)" : "var(--color-white-1)",
                        backgroundColor: "#06241A",
                        height: "150px",
                        overflowY: "auto",
                        scrollbarWidth: "none !important",
                      },
                    },
                  }}
                  labelId="demo-select-small-label"
                  id="documentType"
                  label="Document Type"
                  name="documentType"
                  value={values.documentType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.documentType && Boolean(errors.documentType)}
                >
                  <MenuItem value={"passport"}>Passport</MenuItem>
                  <MenuItem value={"adhar card"}>Adhar Card</MenuItem>
                  <MenuItem value={"driving license"}>Driving license</MenuItem>
                </Select>
                {touched.documentType && errors.documentType && (
                  <Typography className="!text-[#f44336] !text-[12px] !mt-1">{touched.documentType && errors.documentType}</Typography>
                )}
              </FormControl>
              <FormControl
                className="!pb-4"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid var(--color-gray-2) !important",
                    },
                    "&:hover fieldset": {
                      border: "1px solid var(--color-green-1) !important",
                    },
                    "&.Mui-focused fieldset": {
                      border: `1px solid var(--color-green-2) !important`,
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: theme.palette.mode === "dark" ? "var(--color-white-1) !important" : "var(--color-text-2) !important",
                    "&.Mui-focused": {
                      color: "var(--color-green-2) !important",
                    },
                    textAlign: "center",
                  },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disableFuture
                    onError={(newError) => {
                      let errorMessage;
                      if (newError === "disableFuture") {
                        errorMessage = "Issue date cannot be select future date";
                      } else if (newError === "invalidDate") {
                        errorMessage = "";
                      } else {
                        errorMessage = newError;
                      }
                      setIssueDateError(errorMessage);
                    }}
                    slotProps={{
                      textField: {
                        helperText: issueDateError,
                      },
                    }}
                    label="Issue Date"
                    name="issueDate"
                    onChange={(value) => setFieldValue("issueDate", value)}
                    value={values.issueDate}
                    onBlur={handleBlur}
                    error={touched.issueDate && Boolean(errors.issueDate)}
                  />
                </LocalizationProvider>
                {touched.issueDate && errors.issueDate && <Typography className="!text-[#f44336] !text-[12px] !mt-1">{touched.issueDate && errors.issueDate}</Typography>}
              </FormControl>
              {values.documentType !== "adhar card" && (
                <FormControl
                  className="!pb-4"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--color-gray-2) !important",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1) !important",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2) !important`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1) !important" : "var(--color-text-2) !important",
                      "&.Mui-focused": {
                        color: "var(--color-green-2) !important",
                      },
                    },
                  }}
                  // size="small"
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Expire Date"
                      name="expireDate"
                      onChange={(value) => setFieldValue("expireDate", value)}
                      value={values.expireDate}
                      onBlur={handleBlur}
                      error={touched.expireDate && Boolean(errors.expireDate)}
                    />
                  </LocalizationProvider>
                  {errors.expireDate && errors.expireDate === "Expire date can't be before issue date" && (
                    <Typography className="!text-[#f44336] !text-[12px] !mt-1">{errors.expireDate}</Typography>
                  )}
                  {errors.expireDate && errors.expireDate !== "Expire date can't be before issue date" && touched.expireDate && (
                    <Typography className="!text-[#f44336] !text-[12px] !mt-1">{errors.expireDate}</Typography>
                  )}
                </FormControl>
              )}

              <Box className="flex justify-end">
                <Button type="submit" onClick={handleSubmit} sx={{ color: "white" }} className="!bg-[var(--color-green-1)] text-end">
                  Next
                </Button>
              </Box>
            </>
          )}
        </Formik>
      )}

      {activeStep === 1 && (
        <Formik
          initialValues={{
            front: null,
            back: null,
          }}
          validationSchema={secondStepFormValidationSchema}
          onSubmit={async (values) => {
            setKycLoading(true);
            const data = {
              document_front_part: values.front || frontFile,
              document_back_part: values.back || backFile,
            };
            const formData = {
              ...data,
              ...firstStepValues,
            };
            const result = await kycUpload(formData);
            console.log("🚀 ~ onSubmit={ ~ result:", result);

            if (result?.data?.status) {
              const resultRefetch = await refetchAuth();
              if (resultRefetch?.data?.status) {
                localStorage.setItem("auth-token", resultRefetch?.data?.token);
                dispatch(logIn({ ...resultRefetch?.data?.data }));
              }
              setKycLoading(false);
              enqueueSnackbar(result?.data?.message, { variant: "success" });
              setStatus("pending");
              getKYCIdVerificationStatus();
            } else {
              setKycLoading(false);
              enqueueSnackbar(result?.data?.message, { variant: "warning" });
            }
          }}
        >
          {({ errors, handleSubmit, setFieldValue, touched }) => (
            <>
              <FormControl sx={{ width: "100%" }} className="!pt-4">
                <Grid container columnGap={1} className="!justify-center">
                  <Grid item xs={5}>
                    <FrontPartFile {...{ setUploadedFileFrontFile, setFrontFile, frontFile, setFieldValue, uploadedFileFrontFile, errors, touched }} />
                  </Grid>
                  <Grid item xs={5}>
                    <BackPartFile {...{ setUploadedFileBackFile, setBackFile, backFile, uploadedFileBackFile, setFieldValue }} />
                  </Grid>
                </Grid>
              </FormControl>

              <Box className="flex justify-between !pt-4">
                <Button onClick={handleBack} sx={{ color: "white" }} className="!bg-[var(--color-green-1)] text-end">
                  Back
                </Button>
                <Button type="submit" onClick={handleSubmit} sx={{ color: "white" }} className="!bg-[var(--color-green-1)] text-end">
                  {kycLoading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Submit"}
                </Button>
              </Box>
            </>
          )}
        </Formik>
      )}
    </Box>
  );
});

export default IdVerify;
