import React, { useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { CloudUpload, PictureAsPdf } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import { enqueueSnackbar } from "notistack";

const BackPartFile = ({ setUploadedFileBackFile, setBackFile, backFile, uploadedFileBackFile, setFieldValue }) => {
  let allowedFileTypes = ["image/jpeg", "image/png", "application/pdf"];
  const maxFileSizeMB = 2;

  const onDropBack = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) {
      return;
    }
    if (!allowedFileTypes.includes(file.type)) {
      enqueueSnackbar("Only jpg, jpeg, png, pdf files are allowed", {
        variant: "error",
      });
      return;
    }
    const fileSizeMB = file.size / (1024 * 1024);
    if (fileSizeMB > maxFileSizeMB) {
      enqueueSnackbar(`File size exceeds the maximum limit of ${maxFileSizeMB} MB`, {
        variant: "error",
      });
      return;
    }
    setUploadedFileBackFile(URL.createObjectURL(file));
    setBackFile(file);
    setFieldValue("back", file);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps: getRootPropsBack, getInputProps: getInputPropsBack } = useDropzone({ onDrop: onDropBack });

  return (
    <Box
      {...getRootPropsBack()}
      style={{
        border: "2px dashed #cccccc",
        borderRadius: "4px",
        padding: "10px",
        textAlign: "center",
        cursor: "pointer",
        width: "100%",
        height: "250px",
      }}
    >
      <input {...getInputPropsBack()} />
      <Box className="mt-4">
        {uploadedFileBackFile && backFile.type.startsWith("image/") ? (
          <img
            src={uploadedFileBackFile}
            style={{
              margin: "0 auto",
              width: "100%",
              height: "140px",
              marginTop: "10px",
              marginBottom: "4px",
              borderRadius: 3,
            }}
            alt=""
          />
        ) : uploadedFileBackFile && backFile.type === "application/pdf" ? (
          <>
            <PictureAsPdf
              sx={{
                margin: "0 auto",
                width: "100%",
                height: "80px",
                marginTop: "50px",
                marginBottom: "4px",
              }}
            />
            <Typography
              variant="body2"
              className="text-[var(--color-gray-3)]"
              sx={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
            >
              {backFile.name}
            </Typography>
          </>
        ) : (
          <CloudUpload
            sx={{
              margin: "0 auto",
              width: "100%",
              height: "80px",
              marginTop: "50px",
              marginBottom: "4px",
            }}
          />
        )}
        <Typography
          variant="body2"
          className="text-[var(--color-gray-3)]"
          sx={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
          }}
        >
          Drag 'n' drop file here, or click to select file front part.
        </Typography>
      </Box>
    </Box>
  );
};

export default BackPartFile;
