import { /* KeyboardBackspace, */ KeyboardBackspace, /* Minimize, */ Search } from "@mui/icons-material";
import { Box, FormControl, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { memo } from "react";

const SearchBar = memo(({handleSearchContact}) => {

  const handleCloseFullScreenChat = () => {
    window.history.back();
  };

  return (
    <Box className="flex justify-between items-center px-2">
      <Box>
        <IconButton
          sx={{
            backgroundColor: "var(--btn-bg-color)",
          }}
          onClick={handleCloseFullScreenChat}
          title="Minimize Full Screen Chat"
        >
          <KeyboardBackspace sx={{ fontSize: "30px" }} />
        </IconButton>
      </Box>
      <Box>
        <FormControl>
          <TextField
            onChange={handleSearchContact}
            autoComplete="off"
            fullWidth
            variant="outlined"
            placeholder="Search for contact..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search
                    sx={{
                      color: "var(--color-white-1)",
                    }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "38px",
                width: "95%",
                color: "var(--color-white-1)",
                padding: 1,
                boxSizing: "border-box",
                borderRadius: "20px",
                "& fieldset": {
                  border: "1px solid var(--color-gray-2)",
                  opacity: 1,
                },
                "&:hover fieldset": {
                  border: "1px solid var(--color-green-1)",
                },
                "&.Mui-focused fieldset": {
                  border: `1px solid var(--color-green-2)`,
                },
                "& .MuiInputBase-input": {
                  fontSize: "14px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                },
              },
            }}
          />
        </FormControl>
      </Box>
    </Box>
  );
});

export default SearchBar;
