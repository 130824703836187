import { Box, CircularProgress } from "@mui/material";
// import permissionEnum from "enum/permissionEnum";

import Layout from "layouts/Layout";
import { memo, useState } from "react";
// import { useSelector } from "react-redux";

const FinancialNews = () => {
  // const permission = useSelector((state) => state.permission.permission);
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  // useEffect(() => {
  //   if (permission.length === 0) return;
  //   if (!permission?.includes(permissionEnum["/financial-news"])) {
  //     window.location.href = "/";
  //   }
  // }, [permission]);

  return (
    <Layout>
      <Box className="h-full w-full">
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress sx={{ color: "#00c990" }} />
          </Box>
        )}

        <iframe
          title="FinancialNews"
          src="https://api.infotraderhub.com/Financial_News/Financial_News.html"
          style={{ width: "100%", height: "100vh", display: loading ? "none" : "block" }}
          onLoad={handleLoad}
        ></iframe>
      </Box>
    </Layout>
  );
};

export default memo(FinancialNews);
