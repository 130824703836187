import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { Box, Grid } from "@mui/material";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import GroupTopBar from "./GroupTopbar";
import { setActiveChatGroup, setGroupNavigate } from "redux/features/chatSlice/chatSlice";

const GroupChatList = ({ handlePopoverCloseChat }) => {
    const dispatch = useDispatch();
    const groupChatList = useSelector((state) => state.chat.groupChatList);
    const unseenNotification = useSelector((state) => state.notification.unseenNotification);
    const groupLastMessages = useSelector((state) => state.chat.lastGroupChatContent);

    const findLastMessage = (id) => {
        const message = groupLastMessages.filter((item) => item.group_chat_id === id);
        return message?.content;
    };

    const calCulateUnseenNotification = (id) => {
        const notification = unseenNotification.filter((item) => item.for_id === id);
        return notification.length;
    };
    const openChat = async (item) => {
        dispatch(setActiveChatGroup(item));
        dispatch(setGroupNavigate("group_chat"));
    };
    return (
        <Grid
            container
            sx={{
                width: "360px",
                height: "400px",
                background: "var(--color-green-8)",
                padding: "15px",
                borderRadius: "10px",
                overflow: "hidden",
            }}>
            <Grid
                item
                xs={12}>
                <GroupTopBar {...{ handlePopoverCloseChat }} />
            </Grid>
            <Grid
                item
                xs={12}
                className="chat-list-scrollbar"
                sx={{ height: "340px", width: "100%", overflowY: "auto" }}>
                {groupChatList && groupChatList.length > 0 ? (
                    <List sx={{ width: "100%" }}>
                        {groupChatList.map((item) => (
                            <Fragment key={item.id}>
                                <ListItem
                                    className="cursor-pointer"
                                    onClick={() => openChat(item)}
                                    sx={{
                                        backgroundColor: `${calCulateUnseenNotification(item.id) > 0 ? "var(--color-dark-green)" : ""}`,
                                        "&:hover": {
                                            backgroundColor: "var(--border-color)",
                                        },
                                    }}
                                    secondaryAction={
                                        <Box className="flex flex-col justify-end items-end">
                                            {calCulateUnseenNotification(item.id) > 0 && (
                                                <Box
                                                    sx={{
                                                        width: 20,
                                                        height: 20,
                                                        borderRadius: 100,
                                                        border: "",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        background: "var(--color-red-1)",
                                                        fontSize: 10,
                                                        marginBottom: 1,
                                                    }}
                                                >
                                                    {calCulateUnseenNotification(item.id)}
                                                </Box>
                                            )}
                                            <Box className="text-xs text-[#F0F0F0)]">{moment(item.createdAt).format("DD/MM/YYYY")}</Box>
                                        </Box>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            alt="chat list avatar"
                                            src={item.icon}></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{
                                            "& .MuiListItemText-secondary": {
                                                width: '175px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                            },
                                        }}
                                        primary={item.name}
                                        secondary={findLastMessage(item.id)}
                                    />
                                </ListItem>
                                <Divider
                                    variant="middle"
                                    component="li"
                                    sx={{ marginLeft: 0, marginRight: 0 }}
                                />
                            </Fragment>
                        ))}
                    </List>
                ) : (
                    <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <span>No Groups chats available</span>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

export default GroupChatList;
