import { Box } from "@mui/material";
import PamUsrChart from "components/Chart/PamUsrChart";
import PammUserSkeletonLoader from "components/SkeletonLoader/PammUserSkeletonLoader";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPamUserChartLoading } from "redux/features/loader/loaderSlice";
import { pamUserChart } from "server/pamm";

const PammUserChartCard = memo(({ profit, account }) => {
  const pamUserChartLoading = useSelector((state) => state.loader.pamUserChartLoading);
  const dispatch = useDispatch();
  const [performanceChartData, setPerformanceChartData] = useState({});
  const getPerformanceChartData = async () => {
    dispatch(setPamUserChartLoading(true));
    const result = await pamUserChart(account);
    if (result && result?.status === 200) {
      setPerformanceChartData(result?.data);
      dispatch(setPamUserChartLoading(false));
    } else {
      dispatch(setPamUserChartLoading(false));
    }
  };

  useEffect(() => {
    getPerformanceChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return pamUserChartLoading ? (
    <PammUserSkeletonLoader />
  ) : (
    <Box sx={{ height: "100px", width: "100%", marginY: "15px" }}>
      <PamUsrChart {...{ profit, performanceChartData }} />
    </Box>
  );
});

export default PammUserChartCard;
