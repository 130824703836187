import { Avatar, Box, Chip, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import React from 'react';
import GroupTopBar from './GroupTopbar';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addGroupChatList, setActiveChatGroup, setDetailsShowingGroup, setGroupNavigate } from 'redux/features/chatSlice/chatSlice';
import { socketServiceJoinGroupChat } from 'services/socketService';

const Groups = ({ handlePopoverCloseChat }) => {
    const groupList = useSelector((state) => state.chat.groupList);
    const dispatch = useDispatch();

    // group chat action
    const handleGroupChat = async (e, item) => {
        e.stopPropagation();
        dispatch(setGroupNavigate("group_chat"));
        dispatch(setActiveChatGroup(item));
        socketServiceJoinGroupChat(item.id);
        dispatch(addGroupChatList(item));
    }

    // show group details
    const groupDetails = async (item) => { 
        dispatch(setGroupNavigate("group_details"));
        dispatch(setDetailsShowingGroup(item));
    };

    return (
        <Grid
            container
            sx={{
                width: "360px",
                height: "400px",
                background: "var(--color-green-8)",
                padding: "15px",
                borderRadius: "10px",
                overflow: "hidden",
            }}>
            <Grid
                item
                xs={12}>
                <GroupTopBar {...{ handlePopoverCloseChat }} />
            </Grid>
            <Grid
                item
                xs={12}
                className="chat-list-scrollbar"
                sx={{ height: "340px", width: "100%", overflowY: "auto" }}>
                {groupList && groupList?.length > 0 ? (
                    <List sx={{ width: "100%" }}>
                        {groupList?.map((item) => (
                            <Fragment key={item.id}>
                                <ListItem
                                    className="cursor-pointer"
                                    onClick={() => groupDetails(item)}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "var(--border-color)",
                                        },
                                    }}
                                    secondaryAction={
                                        <Box>
                                            <Chip size="small" sx={{ fontSize: "11px", width: 65 }} color="success" label="Message" onClick={(e) => handleGroupChat(e, item)} />
                                        </Box>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            alt="chat list avatar"
                                            src={item.icon}></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{
                                            "& .MuiListItemText-secondary": {
                                                width: '175px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                            },
                                        }}
                                        primary={item.name}
                                        secondary={item.description || "No description"}
                                    />
                                </ListItem>
                                <Divider
                                    variant="middle"
                                    component="li"
                                    sx={{ marginLeft: 0, marginRight: 0 }}
                                />
                            </Fragment>
                        ))}
                    </List>
                ) : (
                    <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <span>No Groups available</span>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

export default Groups;