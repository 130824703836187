import { Add, Groups, Message, Search } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setGroupNavigate } from "redux/features/chatSlice/chatSlice";

const GroupTopBar = ({ handlePopoverCloseChat }) => {
  const permission = useSelector((state) => state.permission.permission);
  const dispatch = useDispatch();
  const groupNavigate = useSelector((state) => state.chat.groupNavigate);
  const activeGroup = useSelector((state) => state.chat.activeChatGroup);
  return (
    <Grid container alignItems={"center"}>
      <Grid item xs={4} className="overflow-hidden whitespace-nowrap overflow-ellipsis">
        {activeGroup?.name || "Group Chat"}
      </Grid>
      {permission?.includes("create group") && (
        <Grid className="text-end" item xs={2} onClick={() => dispatch(setGroupNavigate("add_group"))}>
          <IconButton
            title="Add New Group"
            sx={{
              backgroundColor: `${groupNavigate === "add_group" ? "var(--btn-bg-color)" : ""}`,
            }}
          >
            <Add />
          </IconButton>
        </Grid>
      )}
      <Grid className="text-end" item xs={2} onClick={() => dispatch(setGroupNavigate("group_chat_list"))}>
        <IconButton
          title="Group Chat List"
          sx={{
            backgroundColor: `${groupNavigate === "group_chat_list" ? "var(--btn-bg-color)" : ""}`,
          }}
        >
          <Message />
        </IconButton>
      </Grid>
      <Grid className="text-end" item xs={2} onClick={() => dispatch(setGroupNavigate("groups"))}>
        <IconButton
          title="Groups"
          sx={{
            backgroundColor: `${groupNavigate === "groups" ? "var(--btn-bg-color)" : ""}`,
          }}
        >
          <Groups />
        </IconButton>
      </Grid>
      <Grid className="text-end" item xs={2}>
        <IconButton
          onClick={() => dispatch(setGroupNavigate("groups_search"))}
          title="Search Group"
          sx={{
            backgroundColor: `${groupNavigate === "groups_search" ? "var(--btn-bg-color)" : ""}`,
          }}
        >
          <Search />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default GroupTopBar;
