import { Avatar, Grid, IconButton, Pagination, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { Fragment, memo, useEffect, useState } from "react";
import avatarNews from "../../assets/news/avatarnews.jpg";
import AddComment from "./AddComment";
import moment from "moment";
import { getComments } from "server/posts";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setComments } from "redux/features/posts/postsSlice";
import { Edit } from "@mui/icons-material";

const Comments = memo(() => {
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [page, setPage] = useState(1);
  const { id } = useParams();
  const theme = useTheme();
  const allComment = useSelector((state) => state.posts.comments);
  const [editMode, setEditMode] = useState(false);
  const [commentId, setCommentId] = useState("");
  const [postId, setPostId] = useState("");
  const [editComment, setEditComment] = useState("");

  const handleUpdateComment = (id, post_id, comment) => {
    setEditMode(true);
    setCommentId(id);
    setPostId(post_id);
    setEditComment(comment);

    const addCommentSection = document.getElementById("go-comment-section");
    if (addCommentSection) {
      addCommentSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleChangePage = async (e, value) => {
    setPage(value);
    const result = await getComments(id, value);
    if (result?.status === 200) {
      dispatch(setComments(result.data));
    } else {
    }
  };

  const getComment = async () => {
    const result = await getComments(id, page);
    if (result?.status === 200) {
      dispatch(setComments(result.data));
    } else {
    }
  };

  useEffect(() => {
    getComment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Typography variant="h6" component="div" className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !ml-3">
        Comments
      </Typography>

      {allComment?.data?.map((item) => (
        <Paper
          key={item?.id}
          style={{
            padding: "40px 20px",
            background:
              theme.palette.mode === "dark" ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)" : "var(--color-green-10)",
          }}
        >
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <Avatar alt="User" src={avatarNews} />
            </Grid>
            <Grid justifyContent="left" item xs zeroMinWidth>
              <Typography component={"h4"} variant="h6" sx={{ margin: 0, textAlign: "left" }}>
                Michel
              </Typography>
              <Typography component={"p"} sx={{ textAlign: "left" }}>
                {item?.comment}
              </Typography>
              <Typography component={"p"} sx={{ textAlign: "left", color: "gray" }}>
                {item?.created_at === item?.updated_at ? `posted ${moment(item?.created_at).fromNow()}` : `edited ${moment(item?.updated_at).fromNow()}`}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleUpdateComment(item?.id, item?.post_id, item?.comment)} title="Edit comment">
                <Edit />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      ))}
      {allComment?.data?.length > 0 && allComment?.last_page && (
        <Grid item xs={12} className="flex justify-center items-center">
          <Pagination count={allComment.last_page} variant="outlined" shape="rounded" page={page} onChange={handleChangePage} size={isSmallScreen ? "small" : "medium"} />
        </Grid>
      )}

      <AddComment {...{ editMode, commentId, postId, editComment, setEditComment, setEditMode, setCommentId, setPostId }} />
    </Fragment>
  );
});

export default Comments;
