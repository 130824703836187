import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import AccountDetails from "components/AccountDetails/AccountDetails";
import BrokerPortal from "components/BrokerPortal/BrokerPortal";
import Calender from "components/Calender/Calender";
import FullScreenChat from "components/Chat/FullScreenChat/FullScreenChat";
import CryptocurrencyCalendar from "components/CryptocurrencyCalendar/CryptocurrencyCalendar";
import EconomicCalendar from "components/EconomicCalendar/EconomicCalendar";
import AdvancedTechnicalAnalysis from "components/Education/AdvancedTechnicalAnalysis";
import CryptoTradingInvesting from "components/Education/CryptoTradingInvesting";
import CryptocurrencyFundamentals from "components/Education/CryptocurrencyFundamentals";
import Education from "components/Education/Education";
import IntroToFinancialMarkets from "components/Education/IntroToFinancialMarkets";
import TechnicalAnalysisArticles from "components/Education/TechnicalAnalysisArticles";
import TechnicalAnalysisEssentials from "components/Education/TechnicalAnalysisEssentials";
import TradingSignals from "components/Education/TradingSignals";
import FinancialNews from "components/FinancialNews/FinancialNews";
import Glossary from "components/Glossary/Glossary";
import IbDashboard from "components/IbDashboard/IbDashboard";
import Kyc from "components/Kyc/Kyc";
import MyClients from "components/MyClients/MyClients";
import Packages from "components/Packages/Packages";
import PostDetails from "components/Posts/PostDetails";
import Posts from "components/Posts/Posts";
import CommissionReport from "components/Reports/Commission/CommissionReport";
import FollowsReport from "components/Reports/Follow/FollowsReport";
import WithdrawReport from "components/Reports/Withdraw/WithdrawReport";
import AccountList from "components/Reports/account/AccountList";
import PackagePurchaseReport from "components/Reports/package/PackagePurchaseReport";
import SupportTicket from "components/SupportTicket/SupportTicket";
import AdvancedTechnicalAnalysisVideo from "components/VideoAcademy/AdvancedTechnicalAnalysisVideo";
import CryptoTradingInvestingVideo from "components/VideoAcademy/CryptoTradingInvestingVideo";
import CryptocurrencyFundamentalsVideo from "components/VideoAcademy/CryptocurrencyFundamentalsVideo";
import ExpertTechnicalAnalysisVideo from "components/VideoAcademy/ExpertTechnicalAnalysisVideo";
import IntroductionFinancialMarketsVideo from "components/VideoAcademy/IntroductionFinancialMarketsVideo";
import MarketHighlightsTVVideo from "components/VideoAcademy/MarketHighlightsTVVideo";
import MarketResearchVideos from "components/VideoAcademy/MarketResearchVideos";
import MetaTraderTutorialsVideo from "components/VideoAcademy/MetaTraderTutorialsVideo";
import TechnicalAnalysisEssentialsVideo from "components/VideoAcademy/TechnicalAnalysisEssentialsVideo";
import VideoAcademy from "components/VideoAcademy/VideoAcademy";
import CryptoWithdraw from "components/Withdraw/CryptoWithdraw/CryptoWithdraw";
import AuthLayout from "layouts/AuthLayout";
import ProtectedLayout from "layouts/ProtectedLayout";
import { SnackbarProvider } from "notistack";
import About from "pages/About";
import NotFound from "pages/NotFound";
import Auth from "pages/auth/Auth";
import Dashboard from "pages/protected/Dashboard";
import PammDashboard from "pages/protected/PammDashboard";
import { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { darkTheme, lightTheme } from "theme";

const App = () => {
  const mode = useSelector((state) => state.application.theme);
  const theme = mode === "dark" ? darkTheme : lightTheme;
  useEffect(() => {
    if (mode === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [mode]);
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <CssBaseline />
        <SnackbarProvider
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          maxSnack={1}
          autoHideDuration={3000}
        >
          <BrowserRouter>
            <Routes>
              <Route element={<AuthLayout />}>
                {/* Auth route start from here. */}
                <Route path="/auth" element={<Auth />} />
                {/* Auth route end here. */}
              </Route>
              <Route element={<ProtectedLayout />}>
                {/* Protected route start from here. */}
                <Route path="/" element={<Dashboard />} />
                <Route path="/pamm-dashboard" element={<PammDashboard />} />
                <Route path="/account-details/:id" element={<AccountDetails />} />
                <Route path="/posts" element={<Posts />} />
                <Route path="/posts/:id" element={<PostDetails />} />
                <Route path="/calender" element={<Calender />} />
                <Route path="/cryptocurrency-calender" element={<CryptocurrencyCalendar />} />
                <Route path="/economic-calendar" element={<EconomicCalendar />} />

                <Route path="/education" element={<Education />} />
                <Route path="/advanced-technical" element={<AdvancedTechnicalAnalysis />} />
                <Route path="/crypto-trading" element={<CryptoTradingInvesting />} />
                <Route path="/crypto-fundamental" element={<CryptocurrencyFundamentals />} />
                <Route path="/intro-financial-market" element={<IntroToFinancialMarkets />} />
                <Route path="/technical-analysis-essential" element={<TechnicalAnalysisEssentials />} />
                <Route path="/trading-signals" element={<TradingSignals />} />
                <Route path="/technical-analysis-articles" element={<TechnicalAnalysisArticles />} />
                {/* Video Academy Route */}
                <Route path="/video-academy" element={<VideoAcademy />} />
                <Route path="/advanced-technical-video" element={<AdvancedTechnicalAnalysisVideo />} />
                <Route path="/crypto-trading-video" element={<CryptoTradingInvestingVideo />} />
                <Route path="/crypto-fundamental-video" element={<CryptocurrencyFundamentalsVideo />} />
                <Route path="/intro-financial-market-video" element={<IntroductionFinancialMarketsVideo />} />
                <Route path="/expert-technical-analysis" element={<ExpertTechnicalAnalysisVideo />} />
                <Route path="/metaTrader-tutorials" element={<MetaTraderTutorialsVideo />} />
                <Route path="/technical-analysis-essentials" element={<TechnicalAnalysisEssentialsVideo />} />
                <Route path="/market-highlights-TV" element={<MarketHighlightsTVVideo />} />
                <Route path="/market-research-videos" element={<MarketResearchVideos />} />

                <Route path="/financial-news" element={<FinancialNews />} />
                <Route path="/glossary" element={<Glossary />} />
                <Route path="/support-ticket" element={<SupportTicket />} />
                <Route path="/broker-portal" element={<BrokerPortal />} />
                <Route path="/kyc" element={<Kyc />} />
                <Route path="/packages" element={<Packages />} />
                {/* IB DASHBOARD START */}
                <Route path="/ib-dashboard" element={<IbDashboard />} />
                <Route path="/my-clients" element={<MyClients />} />
                <Route path="/commission" element={<CommissionReport />} />
                <Route path="/account-list" element={<AccountList />} />
                <Route path="/package-purchase-report" element={<PackagePurchaseReport />} />
                <Route path="/follows" element={<FollowsReport />} />
                <Route path="/crypto-withdraw" element={<CryptoWithdraw />} />
                <Route path="/withdraw-report" element={<WithdrawReport />} />
                {/* IB DASHBOARD END */}
                <Route path="/chat" element={<FullScreenChat />} />
                {/* Protected route end here. */}
              </Route>
              {/* Other route start here. */}
              <Route path="/about" element={<About />} />
              {/* Other route end here. */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </SnackbarProvider>
      </Box>
    </ThemeProvider>
  );
};

export default memo(App);
