import mirrorApi from '../api/mirror_trade_api';
const { getAuthToken } = require("./auth");

export const getUserPam = async (page = 1) => {
    try {
        return await mirrorApi.get(`/user/pamm?page=${page}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ file: auth.js:46 ~ pamUser ~ error:", error)

    }
};

export const pamUserDetails = async (id) => {
    try {
        return await mirrorApi.get(`/user/pamm/${id}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ pamUserDetails ~ error:", error)
    }
};
export const pammProfit = async (id) => {
    try {
        return await mirrorApi.get(`/user/pamm/chart-growth-perchantage/${id}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
    console.log("🚀 ~ pammProfit ~ error:", error)
    }
};

export const performanceChart = async (id, filterGrowth) => {
    try {
        return await mirrorApi.get(`/user/pamm/chart-performance/${id}?chart=${filterGrowth}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ performanceChart ~ error:", error)
    }
};
export const pamUserChart = async (id) => {
    try {
        return await mirrorApi.get(`/user/pamm/chart-performance/${id}?chart=balance`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
    console.log("🚀 ~ pamUserChart ~ error:", error)
    }
};

export const getMySlaves = async (page = 1) => {
    try {
        return await mirrorApi.get(`/user/slaves?page=${page}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ getMySlaves ~ error:", error)
    }
};

export const getBalanceEquity = async (id) => {
    try {
        return await mirrorApi.get(`/user/pamm/balance-equity/${id}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        })
    } catch (error) {
        console.log("🚀 ~ balanceEquity ~ error:", error)
    }
}