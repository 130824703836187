import { ListItemIcon, Menu, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Delete, Edit } from "@mui/icons-material";
import { deleteChannelContent } from "server/channel";
import { useSnackbar } from "notistack";
import { setDrawerOpen, setToggleChannelOrCreateChannel, setToggleChatOrChannelDrawer } from "redux/features/toggle/toggleSlice";

const MoreChannelContentPopover = memo(({ moreChannelContentOpen, anchorElMoreChannelContent, handleCloseMoreChannelContent }) => {
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const activeChannelContentPost = useSelector((state) => state.channel.activeChannelContentPost);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const theme = useTheme();

  const ChannelPostDeleteHandler = async () => {
    handleCloseMoreChannelContent();
    const result = await deleteChannelContent(activeChannelContentPost?.id);
    if (result?.data?.status) {
      enqueueSnackbar(result?.data?.message, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(result?.data?.message, {
        variant: "error",
      });
    }
  };

  const ChannelPostEditHandler = () => {
    if (isMediumScreen) {
      dispatch(setToggleChatOrChannelDrawer("editPost"));
      handleCloseMoreChannelContent();
      dispatch(setDrawerOpen(true));
    } else {
      dispatch(setToggleChannelOrCreateChannel("editPost"));
      handleCloseMoreChannelContent();
    }
  };

  return (
    anchorElMoreChannelContent && (
      <Menu
        anchorEl={anchorElMoreChannelContent}
        open={moreChannelContentOpen}
        onClose={handleCloseMoreChannelContent}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            background: theme.palette.mode === "dark" ? "var(--color-green-7)" : "var(--color-green-10)",
          },
        }}
      >
        <MenuItem className="capitalize" onClick={ChannelPostEditHandler}>
          <ListItemIcon>
            <Edit fontSize="medium" />
          </ListItemIcon>
          Edit post
        </MenuItem>
        <MenuItem className="capitalize" onClick={ChannelPostDeleteHandler}>
          <ListItemIcon>
            <Delete fontSize="medium" />
          </ListItemIcon>
          Delete post
        </MenuItem>
      </Menu>
    )
  );
});

export default MoreChannelContentPopover;
