import { Box, Button, CircularProgress, FormControl, InputLabel, TextField, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { CloudUpload } from "@mui/icons-material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { upload_image } from "server/chat";
import { socketServiceSendChannelPost } from "services/socketServiceFullScreenChat";
import { v4 as uuid } from "uuid";
import { setToggleChannelOrCreateChannel } from "redux/features/toggle/toggleSlice";

// Define modules, formats, and propTypes for the text editor
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
const formats = ["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", "video"];

const CreatePostForm = memo(() => {
  const dispatch = useDispatch();
  const activeChannel = useSelector((state) => state.channel.activeChannel);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [url, setUrl] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleImageUpload = async (event) => {
    const maxFileSizeMB = 2;
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const fileSizeMB = file.size / (1024 * 1024);

    if (fileSizeMB > maxFileSizeMB) {
      enqueueSnackbar(`File size exceeds the maximum limit of ${maxFileSizeMB} MB`, {
        variant: "error",
      });
      return;
    }

    // Check file type
    if (!["image/jpeg", "image/png"].includes(file.type)) {
      enqueueSnackbar("Only jpg, jpeg, png is allowed", {
        variant: "error",
      });
      return;
    }
    // Display uploaded image
    setUrl(URL.createObjectURL(file));
    setImage(file);
  };

  const handleServerUpload = async (formData) => {
    const upload = await upload_image(formData);

    if (!upload) {
      return await handleServerUpload(formData);
    }
    return upload;
  };

  const createChannelContentHandler = async () => {
    const uniqueId = uuid();
    setLoading(true);
    try {
      if (title === "") {
        enqueueSnackbar("Title is required", {
          variant: "error",
        });
        return;
      }
      if (description === "") {
        enqueueSnackbar("Description is required", {
          variant: "error",
        });
        return;
      }
      if (image === null) {
        enqueueSnackbar("Image is required", {
          variant: "error",
        });
        return;
      }

      const formData = new FormData();
      formData.append("file", image);
      formData.append("attachment_for", "channel");
      formData.append("for_id", activeChannel?.id);

      const upload = await handleServerUpload(formData);

      if (upload?.data?.status) {
        let imageLink = process.env.REACT_APP_CHAT_API_BASEURL + "/file/upload/" + upload.data.data.id;
        const type = "NEWS";
        socketServiceSendChannelPost({
          content: description,
          channel_id: activeChannel?.id,
          attachment_id: upload.data.data.id,
          attachment_content: imageLink,
          u_id: uniqueId,
          title: title,
          type,
        });
        enqueueSnackbar("Channel post created successfully.", {
          variant: "success",
        });
        dispatch(setToggleChannelOrCreateChannel("channel"));
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
      });
    } finally {
      setLoading(false);
      setDescription("");
      setTitle("");
      setImage(null);
      setUrl(null);
    }
  };

  return (
    <form
      className="chat-list-scrollbar"
      style={{
        padding: "15px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "calc(100vh - 140px)",
        overflowY: "auto",
        width: "100%",
      }}
    >
      <Box>
        <InputLabel className="formLabel">Post Title</InputLabel>
        <FormControl fullWidth>
          <TextField
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            id="title"
            name="title"
            sx={{
              "& .MuiOutlinedInput-root": {
                width: "100%",
                color: "white",
                "& fieldset": {
                  border: "1px solid #2F4E43",
                },
                "&:hover fieldset": {
                  border: "1px solid var(--color-green-1)",
                },
                "&.Mui-focused fieldset": {
                  border: `1px solid var(--color-green-2)`,
                },
              },
              "& .MuiInputLabel-root": {
                color: "white",
                "&.Mui-focused": {
                  color: "var(--color-green-2)",
                },
              },
            }}
            variant="outlined"
            size="small"
            placeholder="Post title"
          />
        </FormControl>
        <InputLabel className="formLabel mt-4">Post Description</InputLabel>
        <ReactQuill
          theme="snow"
          value={description}
          onChange={setDescription}
          placeholder="Post description"
          style={{ marginBottom: "16px" }}
          modules={modules}
          formats={formats}
        />
        <InputLabel className="formLabel mt-4">Upload Image</InputLabel>
        <InputLabel htmlFor="avatar-upload" className="!cursor-pointer mt-4">
          <Box sx={{ border: "2px dotted #2F4E43", height: "200px" }} className="mb-4 text-center">
            {url ? (
              <img src={url} style={{ margin: "0 auto", width: "200px", height: "140px", marginTop: "10px", marginBottom: "4px" }} alt="" />
            ) : (
              <CloudUpload sx={{ margin: "0 auto", width: "80px", height: "80px", marginTop: "50px", marginBottom: "4px" }} />
            )}
            <input accept="image/*" id="avatar-upload" type="file" onChange={handleImageUpload} style={{ display: "none" }} />
            <Typography variant="body2" className="text-[var(--color-gray-3)]">
              Click here to upload image
            </Typography>
          </Box>
        </InputLabel>
      </Box>
      <Box className="flex justify-center">
        <Button
          disabled={loading ? true : false}
          onClick={createChannelContentHandler}
          fullWidth
          variant="contained"
          sx={{
            background: "var(--color-green-1)",
            textTransform: "capitalize",
            boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
            "&:hover": {
              color: "var(--color-white-1)",
              background: "var(--color-green-2)",
            },
          }}
        >
          {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Create Post"}
        </Button>
      </Box>
    </form>
  );
});

export default CreatePostForm;
