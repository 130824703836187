import { Box, Button, CircularProgress, FormControl, FormControlLabel, InputLabel, Switch, TextField, Typography, styled } from "@mui/material";
import React, { memo, useState } from "react";
import ReactFileReader from "react-file-reader";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { upload_image } from "server/chat";
import { createChannel } from "server/channel";
import { addChannelList } from "redux/features/channel/channelSlice";
import { setToggleChatOrChannelDrawer } from "redux/features/toggle/toggleSlice";
import { CloudUpload } from "@mui/icons-material";

const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "var(--color-green-1)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "var(--color-green-9)",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const CreateChannelFormDrawer = memo(() => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState(null);
  const [image, setImage] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFiles = (files) => {
    if (files?.fileList[0]?.type !== "image/jpeg" && files?.fileList[0]?.type !== "image/png") {
      enqueueSnackbar("Only jpg, jpeg, png is allowed", {
        variant: "error",
      });
      return;
    }
    setImage(files?.fileList[0]);
    setUrl(files.base64);
  };

  const handlePrivateToggle = () => {
    setIsPrivate((prevIsPrivate) => !prevIsPrivate);
  };

  const handleVisibleToggle = () => {
    setIsVisible((prevIsVisible) => !prevIsVisible);
  };

  const handleServerUpload = async (formData) => {
    const upload = await upload_image(formData);

    if (!upload) {
      return await handleServerUpload(formData);
    }
    return upload;
  };

  const createChannelHandler = async () => {
    setLoading(true);
    try {
      if (name === "") {
        enqueueSnackbar("Name is required", {
          variant: "error",
        });
        return;
      }
      if (description === "") {
        enqueueSnackbar("Description is required", {
          variant: "error",
        });
        return;
      }
      if (image === null) {
        enqueueSnackbar("Image is required", {
          variant: "error",
        });
        return;
      }

      const formData = new FormData();
      formData.append("file", image);
      formData.append("attachment_for", "channel");
      formData.append("for_id", "create_channel");

      const upload = await handleServerUpload(formData);

      if (upload?.data?.status) {
        let icon = process.env.REACT_APP_CHAT_API_BASEURL + "/file/upload/" + upload?.data?.data?.id;
        const data = {
          name: name,
          description: description,
          icon: icon,
          private: isPrivate,
          visible_on_search: !isPrivate,
          type: "NEWS",
        };

        const result = await createChannel(data);
        if (result?.data?.status) {
          dispatch(addChannelList(result.data.data));
          enqueueSnackbar("Channel Created Successfully", {
            variant: "success",
          });
          setName("");
          setDescription("");
          setImage(null);
          setUrl(null);
          setIsPrivate(false);
          dispatch(setToggleChatOrChannelDrawer("channel"));
          setLoading(false);
        } else {
          enqueueSnackbar("Channel name already exist", { variant: "warning" });
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      className="chat-list-scrollbar"
      style={{
        padding: "15px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "calc(100vh - 180px)",
        overflow: "auto",
        width: "100%",
      }}
    >
      <Box>
        <InputLabel className="formLabel">Channel Name</InputLabel>
        <FormControl className="w-full">
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="createChannelInput"
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "#ffffff",
                "& fieldset": {
                  border: 0,
                },
                "&:hover": {
                  border: 0,
                },
                "&.Mui-focused fieldset": {
                  border: 0,
                },
              },
              "& .MuiOutlinedInput-input": {
                paddingY: "5px",
                color: "#ffffff",
                outline: 0,
              },
              "& .MuiOutlinedInput-input::placeholder": {
                fontSize: "12px",
              },
            }}
            placeholder="Name"
          />
        </FormControl>
        <InputLabel className="formLabel !mt-5">channel description</InputLabel>
        <FormControl className="w-full">
          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="createChannelInput"
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "#ffffff",
                "& fieldset": {
                  border: 0,
                },
                "&:hover": {
                  border: 0,
                },
                "&.Mui-focused fieldset": {
                  border: 0,
                },
              },
              "& .MuiOutlinedInput-input": {
                paddingY: "5px",
                color: "#ffffff",
                outline: 0,
              },
              "& .MuiOutlinedInput-input::placeholder": {
                fontSize: "12px",
              },
            }}
            placeholder="Description"
          />
        </FormControl>
        <InputLabel className="formLabel mt-4">SET CHANNEL PICTURE</InputLabel>
        <FormControl fullWidth>
          <ReactFileReader fileTypes={["jpg", "jpeg", "png"]} base64={true} handleFiles={handleFiles}>
            <Box sx={{ border: "2px dotted #2F4E43", height: "210px", borderRadius: "5px" }} className="mb-4 text-center !cursor-pointer">
              <Box className="mt-4">
                {url ? (
                  <img src={url} style={{ margin: "0 auto", width: "200px", height: "140px", marginTop: "10px", marginBottom: "4px" }} alt="" />
                ) : (
                  <CloudUpload sx={{ margin: "0 auto", width: "80px", height: "80px", marginTop: "50px", marginBottom: "4px" }} />
                )}
                <Typography variant="body2" className="text-[var(--color-gray-3)]">
                  Click here to upload image
                </Typography>
              </Box>
            </Box>
          </ReactFileReader>
        </FormControl>

        <Box className="flex justify-between items-center !mt-5">
          <Typography className="formLabel">Make private?</Typography>
          <FormControlLabel control={<IOSSwitch checked={isPrivate} onChange={handlePrivateToggle} sx={{ m: 1 }} />} />
        </Box>
        <Typography className="!text-[12px] !mt-5 !mb-5 !text-[var(--color-gray-3)] !text-left !leading-[21px] !font-[500]">
          Users can follow Private Channels by joining requests. Only admins can add new members.
        </Typography>
        <Box className="flex justify-between items-center">
          <Typography className="formLabel">VISIBLE ON SEARCH?</Typography>
          <FormControlLabel control={<IOSSwitch checked={isVisible} onChange={handleVisibleToggle} sx={{ m: 1 }} />} />
        </Box>
      </Box>
      <Box className="flex justify-center">
        <Button
          onClick={createChannelHandler}
          fullWidth
          variant="contained"
          sx={{
            background: "var(--color-green-1)",
            textTransform: "capitalize",
            boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
            "&:hover": {
              color: "var(--color-white-1)",
              background: "var(--color-green-2)",
            },
          }}
        >
          {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Create Channel"}
        </Button>
      </Box>
    </form>
  );
});

export default CreateChannelFormDrawer;
