import React, { useState } from "react";
import { Box, Checkbox, FormControl, FormControlLabel, Grid, Tab, Tabs } from "@mui/material";
import { useTheme } from "@emotion/react";
import { americasCountries, asiaPacific, euroAreaCountries, middleEastAfrica, nonEuroZone } from "data/calenderFilterData";

const FilterCountryRegion = () => {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const handleTabChange = (_, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const americaChange = (val) => {
    if (val) {
      setSelectedCountry((prevSelected) => [...prevSelected, ...americasCountries]);
    } else {
      setSelectedCountry((prevSelected) => prevSelected.filter((country) => !americasCountries.includes(country)));
    }
  };

  const euroCountryChange = (val) => {
    if (val) {
      setSelectedCountry((prevSelected) => [...prevSelected, ...euroAreaCountries]);
    } else {
      setSelectedCountry((prevSelected) => prevSelected.filter((country) => !euroAreaCountries.includes(country)));
    }
  };

  const handleCountryChange = (country) => {
    // Check if the country is already selected
    const isSelected = selectedCountry.find((item) => item.country === country.country);

    // If the country is already selected, remove it from the selected countries
    if (isSelected) {
      setSelectedCountry((prevSelected) => prevSelected.filter((item) => item.country !== country.country));
    } else {
      // If the country is not selected, add it to the selected countries
      setSelectedCountry((prevSelected) => [...prevSelected, country]);
    }
  };

  return (
    <Grid
      spacing={2}
      container
      marginTop="1px"
      sx={{
        background:
          theme.palette.mode === "dark"
            ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)"
            : "var(--color-green-10)",
        overflow: "hidden",
      }}
      className="h-[450px] lg:h-80 p-[20px]"
    >
      <Grid item xs={12} md={5} lg={2}>
        <Tabs
          orientation="vertical"
          centered
          value={tabIndex}
          onChange={handleTabChange}
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "transparent",
            },
            "& .MuiTab-root": {
              display: "flex",
              alignItems: "flex-start",
            },
          }}
        >
          <Tab
            disableRipple
            sx={{
              maxWidth: "none",
              minWidth: "none",
              minHeight: "25px",
              "&.Mui-selected": {
                background: "var(--color-green-12)",
              },
            }}
            className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-normal !cursor-default"
            label={
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    marginLeft: "10px",
                  },
                }}
                control={
                  <Checkbox
                    onChange={(e) => americaChange(e.target.checked)}
                    sx={{
                      padding: 0,
                      "& .MuiCheckbox-root": {
                        padding: "0 !important",
                      },
                      "&.Mui-checked": {
                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-2)",
                      },
                    }}
                  />
                }
                label="Americas"
              />
            }
          />

          <Tab
            disableRipple
            sx={{
              maxWidth: "none",
              minWidth: "none",
              minHeight: "25px",
              "&.Mui-selected": {
                background: "var(--color-green-12)",
              },
            }}
            className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-normal !cursor-default"
            label={
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    marginLeft: "10px",
                  },
                }}
                control={
                  <Checkbox
                    onChange={(e) => euroCountryChange(e.target.checked)}
                    sx={{
                      padding: 0,
                      "& .MuiCheckbox-root": {
                        padding: "0 !important",
                      },
                      "&.Mui-checked": {
                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-2)",
                      },
                    }}
                  />
                }
                label="Euro Area"
              />
            }
          />

          <Tab
            disableRipple
            sx={{
              maxWidth: "none",
              minWidth: "none",
              minHeight: "25px",
              "&.Mui-selected": {
                background: "var(--color-green-12)",
              },
            }}
            className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-normal !cursor-default"
            label={
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    marginLeft: "10px",
                  },
                }}
                control={
                  <Checkbox
                    sx={{
                      padding: 0,
                      "& .MuiCheckbox-root": {
                        padding: "0 !important",
                      },
                      "&.Mui-checked": {
                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-2)",
                      },
                    }}
                  />
                }
                label="Non-Euro Zone Europe"
              />
            }
          />

          <Tab
            disableRipple
            sx={{
              maxWidth: "none",
              minWidth: "none",
              minHeight: "25px",
              "&.Mui-selected": {
                background: "var(--color-green-12)",
              },
            }}
            className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-normal !cursor-default"
            label={
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    marginLeft: "10px",
                  },
                }}
                control={
                  <Checkbox
                    sx={{
                      padding: 0,
                      "& .MuiCheckbox-root": {
                        padding: "0 !important",
                      },
                      "&.Mui-checked": {
                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-2)",
                      },
                    }}
                  />
                }
                label="Asia and Pacific"
              />
            }
          />
          <Tab
            disableRipple
            sx={{
              maxWidth: "none",
              minWidth: "none",
              minHeight: "25px",
              "&.Mui-selected": {
                background: "var(--color-green-12)",
              },
            }}
            className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-normal !cursor-default"
            label={
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    marginLeft: "10px",
                  },
                }}
                control={
                  <Checkbox
                    sx={{
                      padding: 0,
                      "& .MuiCheckbox-root": {
                        padding: "0 !important",
                      },
                      "&.Mui-checked": {
                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-2)",
                      },
                    }}
                  />
                }
                label="Middle East & Africa"
              />
            }
          />
        </Tabs>
      </Grid>
      <Grid item xs={12} md={5}>
        <Grid container spacing={2}>
          {tabIndex === 0 &&
            americasCountries?.map((item) => (
              <Grid xs={6} lg={4} item key={item.id}>
                <Box className="flex items-center p-1 bg-[var(--color-green-13)] space-x-2" onClick={(e) => e.stopPropagation()}>
                  <FormControl size="small">
                    <Checkbox
                      id={item?.country}
                      checked={selectedCountry.find((j) => j.country === item.country) ? true : false}
                      onChange={() => handleCountryChange(item)}
                      sx={{
                        padding: 0,
                        color: "var(--color-black-2)",
                        "& .MuiCheckbox-root": {
                          color: "red",
                          padding: "0 !important",
                        },
                        "&.Mui-checked": {
                          color: theme.palette.mode === "dark" ? "var(--color-black-2)" : "var(--color-black-2)",
                        },
                      }}
                    />
                  </FormControl>
                  <Box className="h-6" component={"img"} src={item.flag} alt="" />
                  <Box className="!text-[var(--color-black-2)] dark:!text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-medium">{item.country}</Box>
                </Box>
              </Grid>
            ))}

          {tabIndex === 1 &&
            euroAreaCountries?.map((item) => (
              <Grid xs={6} lg={4} item key={item.id}>
                <Box className="flex items-center p-1 bg-[var(--color-green-13)] space-x-2" onClick={(e) => e.stopPropagation()}>
                  <FormControl size="small">
                    <Checkbox
                      id={item?.country}
                      checked={selectedCountry.find(j => j.country === item.country) ? true : false}
                      onChange={() => handleCountryChange(item)}
                      sx={{
                        padding: 0,
                        color: "var(--color-black-2)",
                        "& .MuiCheckbox-root": {
                          color: "red",
                          padding: "0 !important",
                        },
                        "&.Mui-checked": {
                          color: theme.palette.mode === "dark" ? "var(--color-black-2)" : "var(--color-black-2)",
                        },
                      }}
                    />
                  </FormControl>
                  <Box className="h-6" component={"img"} src={item.flag} alt="" />
                  <Box className="!text-[var(--color-black-2)] dark:!text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-medium">{item.country}</Box>
                </Box>
              </Grid>
            ))}
          {tabIndex === 2 &&
            nonEuroZone?.map((item) => (
              <Grid xs={6} lg={4} item key={item.id}>
                <Box className="flex items-center p-1 bg-[var(--color-green-13)] space-x-2" onClick={(e) => e.stopPropagation()}>
                  <FormControl size="small">
                    <Checkbox
                      id={item?.country}
                      sx={{
                        padding: 0,
                        color: "var(--color-black-2)",
                        "& .MuiCheckbox-root": {
                          color: "red",
                          padding: "0 !important",
                        },
                        "&.Mui-checked": {
                          color: theme.palette.mode === "dark" ? "var(--color-black-2)" : "var(--color-black-2)",
                        },
                      }}
                    />
                  </FormControl>
                  <Box className="h-6" component={"img"} src={item.flag} alt="" />
                  <Box className="!text-[var(--color-black-2)] dark:!text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-medium">{item.country}</Box>
                </Box>
              </Grid>
            ))}
          {tabIndex === 3 &&
            asiaPacific?.map((item) => (
              <Grid xs={6} lg={4} item key={item.id}>
                <Box className="flex items-center p-1 bg-[var(--color-green-13)] space-x-2" onClick={(e) => e.stopPropagation()}>
                  <FormControl size="small">
                    <Checkbox
                      id={item?.country}
                      sx={{
                        padding: 0,
                        color: "var(--color-black-2)",
                        "& .MuiCheckbox-root": {
                          color: "red",
                          padding: "0 !important",
                        },
                        "&.Mui-checked": {
                          color: theme.palette.mode === "dark" ? "var(--color-black-2)" : "var(--color-black-2)",
                        },
                      }}
                    />
                  </FormControl>
                  <Box className="h-6" component={"img"} src={item.flag} alt="" />
                  <Box className="!text-[var(--color-black-2)] dark:!text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-medium">{item.country}</Box>
                </Box>
              </Grid>
            ))}
          {tabIndex === 4 &&
            middleEastAfrica?.map((item) => (
              <Grid xs={6} lg={4} item key={item.id}>
                <Box className="flex items-center p-1 bg-[var(--color-green-13)] space-x-2" onClick={(e) => e.stopPropagation()}>
                  <FormControl size="small">
                    <Checkbox
                      id={item?.country}
                      sx={{
                        padding: 0,
                        color: "var(--color-black-2)",
                        "& .MuiCheckbox-root": {
                          color: "red",
                          padding: "0 !important",
                        },
                        "&.Mui-checked": {
                          color: theme.palette.mode === "dark" ? "var(--color-black-2)" : "var(--color-black-2)",
                        },
                      }}
                    />
                  </FormControl>
                  <Box className="h-6" component={"img"} src={item.flag} alt="" />
                  <Box className="!text-[var(--color-black-2)] dark:!text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-medium">{item.country}</Box>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilterCountryRegion;
