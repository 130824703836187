import { createSlice } from "@reduxjs/toolkit";

const postsSlice = createSlice({
    name: "posts",
    initialState: {
        posts: {},
        postDetails: {},
        comments: {},
        postType: []
    },
    reducers: {
        setAllPost: (state, action) => {
            state.posts = action.payload;
        },
        setPostDetails: (state, action) => {
            state.postDetails = action.payload;
        },
        setComments: (state, action) => {
            state.comments = action.payload;
        },
        addPostComment: (state, action) => {
            state.comments.data.push(action.payload)
        },
        updatePostComment: (state, action) => {
            const { id, updated_at, comment } = action.payload;
            const commentIndex = state.comments.data.findIndex(item => item.id === id);
            if (commentIndex !== -1) {
                state.comments.data[commentIndex] = {
                    ...state.comments.data[commentIndex],
                    updated_at: updated_at,
                    comment: comment,
                };
            }
        },

        setPostType: (state, action) => {
            state.postType = action.payload;
        },


    },
});

export const { setAllPost, setPostDetails, addPostComment, setComments, updatePostComment, setPostType } = postsSlice.actions;
export default postsSlice.reducer;
