import { Avatar, Box, Typography } from "@mui/material";
import moment from "moment";
import React, { Fragment, memo } from "react";
import { useSelector } from "react-redux";

const UserReplay = memo(() => {
  const user = useSelector((state) => state.auth.user);
  const singleTicket = useSelector((state) => state.support.singleTicket);
  const userReply = singleTicket?.reply?.filter((item) => item.reply_by.email === user?.email);

  return (
    <Fragment>
      {userReply?.map((item) => (
        <Box key={item?.id} className="p-2">
          <Box className="flex flex-row-reverse">
            <Box className="flex items-end justify-start !ml-2">
              <Avatar src={item?.reply_by?.photo} alt="Avatar"></Avatar>
            </Box>
            <Box className="p-3 shadow-lg rounded-lg bg-[var(--color-green-7)]">
              <Typography component={"p"} variant="p" className="!text-xs !capitalize !mb-1">
                {item?.reply_by?.name}
              </Typography>
              {item?.attachment && <Typography component={"img"} src={item?.attachment} alt="" className="!h-28" />}
              <Typography component={"p"} variant="p">
                {item.reply_description}
              </Typography>
              <Typography component={"p"} variant="p" className="!text-xs">
                {moment(item?.created_at).format("DD MMM YYYY hh:mm:ss A")}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Fragment>
  );
});

export default UserReplay;
