import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Box, Grid } from "@mui/material";
import { Fragment } from "react";
import TopBarChat from "./TopBarChat";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { setActiveChat, setChatHistory, setNavigateChat } from "redux/features/chatSlice/chatSlice";
import { chat_history } from "server/chat";
import CustomAvatar from "components/Auth/CustomAvatar";

const ChatList = ({ handlePopoverCloseChat }) => {
  const dispatch = useDispatch();
  const chatList = useSelector((state) => state.chat.chatList);
  const unseenNotification = useSelector((state) => state.notification.unseenNotification);
  console.log("🚀 ~ ChatList ~ unseenNotification:", unseenNotification)

  const calCulateUnseenNotification = (id) => {
    const notification = unseenNotification.filter((item) => item.for_id === id);
    return notification.length;
  };

  const openChat = async (item) => {
    dispatch(setActiveChat(item));
    dispatch(setNavigateChat("chat"));
    const chats = await chat_history(item.id);
    if (chats.data?.status) {
      dispatch(setChatHistory(chats.data?.data[0]?.chat_content));
    }
  };

  return (
    <Grid
      container
      sx={{
        width: "360px",
        height: "400px",
        background: "var(--color-green-8)",
        padding: "15px",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <Grid item xs={12}>
        <TopBarChat {...{ handlePopoverCloseChat }} />
      </Grid>
      <Grid item xs={12} className="chat-list-scrollbar" sx={{ height: "340px", width: "100%", overflowY: "auto" }}>
        {chatList && chatList.length > 0 ? (
          <List sx={{ width: "100%" }}>
            {chatList.map((item) => (
              <Fragment key={item.id}>
                <ListItem
                  className="cursor-pointer"
                  onClick={() => openChat(item)}
                  sx={{
                    backgroundColor: `${calCulateUnseenNotification(item.id) > 0 ? "var(--color-dark-green)" : ""}`,
                    "&:hover": {
                      backgroundColor: "var(--border-color)",
                    },
                  }}
                  secondaryAction={
                    <Box className="flex flex-col justify-end items-end">
                      {calCulateUnseenNotification(item.id) > 0 && (
                        <Box
                          sx={{
                            width: 20,
                            height: 20,
                            borderRadius: 100,
                            border: "",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "var(--color-red-1)",
                            fontSize: 10,
                            marginBottom: 1,
                          }}
                        >
                          {calCulateUnseenNotification(item.id)}
                        </Box>
                      )}
                      <Box className="text-xs text-[#F0F0F0)]">{moment(item?.createdAt).format("DD/MM/YYYY")}</Box>
                      <Box className="text-xs text-[#F0F0F0)]">{moment(item?.createdAt).format("hh:mm A")}</Box>
                    </Box>
                  }
                >
                  <ListItemAvatar>
                    <CustomAvatar {...{ user_id: item?.user.id, image: item?.user?.profile?.profile_image }} />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{
                      textTransform: "capitalize",
                      "& .MuiListItemText-secondary": {
                        width: "175px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      },
                    }}
                    primary={item.user.name}
                    secondary={(item?.chat_content && item?.chat_content[0]?.content) || "No message"}
                  />
                </ListItem>
                <Divider variant="middle" component="li" sx={{ marginLeft: 0, marginRight: 0 }} />
              </Fragment>
            ))}
          </List>
        ) : (
          <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <span>No chats available</span>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default ChatList;
