import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, Switch, TextField, Typography } from "@mui/material";
import GroupTopBar from "./GroupTopbar";
import ReactFileReader from "react-file-reader";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { upload_image, create_group, get_group_list } from "server/chat";
import { useDispatch, useSelector } from "react-redux";
import { addGroupList, setGroupList } from "redux/features/chatSlice/chatSlice";

const profilePic = {
  width: "100px",
  aspectRatio: 1,
  clipPath: "circle(50% at 50% 50%)",
};
const AddNewGroup = ({ handlePopoverCloseChat }) => {
  const permission = useSelector((state) => state.permission.permission);
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState("/assets/avatar.jpg");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleFiles = (files) => {
    if (files?.fileList[0]?.type !== "image/jpeg" && files?.fileList[0]?.type !== "image/png") {
      enqueueSnackbar("Only jpg, jpeg, png is allowed", {
        variant: "error",
      });
      return;
    }
    setImage(files?.fileList[0]);
    setUrl(files.base64);
  };

  const createGroupHandler = async () => {
    try {
      setLoading(true);
      if (name === "") {
        enqueueSnackbar("Name is required", {
          variant: "error",
        });
        return;
      }
      if (description === "") {
        enqueueSnackbar("Description is required", {
          variant: "error",
        });
        return;
      }
      if (image === null) {
        enqueueSnackbar("Image is required", {
          variant: "error",
        });
        return;
      }

      const formData = new FormData();
      formData.append("file", image);
      formData.append("attachment_for", "other");
      formData.append("for_id", "create_group");

      const upload = await upload_image(formData);

      if (upload.data?.status) {
        let icon = process.env.REACT_APP_CHAT_API_BASEURL + "/file/upload/" + upload.data.data.id;
        const data = {
          name: name,
          description: description,
          icon: icon,
          private: isPrivate,
          visible_on_search: !isPrivate,
        };

        const result = await create_group(data);
        setLoading(false);
        if (result.data?.status) {
          dispatch(addGroupList(result.data.data));
          enqueueSnackbar("Group Created Successfully", {
            variant: "success",
          });
          const updateGroupList = await get_group_list();
          if (updateGroupList.data?.status) {
            dispatch(setGroupList(updateGroupList.data.data));
          }
          setName("");
          setDescription("");
          setImage(null);
          setUrl("/assets/avatar.jpg");
          setIsPrivate(false);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid
      container
      sx={{
        width: "360px",
        height: "400px",
        background: "var(--color-green-8)",
        padding: "15px",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <Grid item xs={12}>
        <GroupTopBar {...{ handlePopoverCloseChat }} />
      </Grid>
      <Grid item xs={12} className="chat-list-scrollbar" sx={{ height: "340px", width: "100%", overflowY: "auto" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className="flex justify-center"
          sx={{
            marginTop: 2,
          }}
        >
          <FormControl>
            <Box sx={profilePic}>
              <Box className="profilePicUpdate">
                <img src={url} alt="" className="w-full" />
                <ReactFileReader fileTypes={["jpg", "jpeg", "png"]} base64={true} handleFiles={handleFiles}>
                  <Typography component="input" className="profilePicUpdateBtn" type="button" value={"Add"} />
                </ReactFileReader>
              </Box>
            </Box>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} sx={{ marginTop: 2 }} className="flex items-center">
          <InputLabel className="capitalize w-[20%] mb-0">
            <Typography component={"span"} className="!text-xs" sx={{ color: "var(--color-white-1)" }}>
              Name:
            </Typography>
          </InputLabel>
          <FormControl sx={{ width: "80%" }}>
            <TextField
              inputProps={{ style: { fontSize: 12 } }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{
                backgroundColor: "var(--color-dark-green)",
                "& .MuiOutlinedInput-root": {
                  color: "var(--color-white-1)",
                  "& fieldset": {
                    border: 0,
                  },
                  "&:hover": {
                    border: 0,
                  },
                  "&.Mui-focused fieldset": {
                    border: 0,
                  },
                },
                "& .MuiOutlinedInput-input": {
                  paddingY: "8px",
                  color: "var(--color-white-1)",
                  outline: 0,
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} sx={{ marginTop: 2 }} className="flex items-center">
          <InputLabel className="capitalize w-[20%] mb-0">
            <Typography component={"span"} className="!text-xs" sx={{ color: "var(--color-white-1)" }}>
              Description:
            </Typography>
          </InputLabel>
          <FormControl sx={{ width: "80%" }}>
            <TextField
              inputProps={{ style: { fontSize: 12 } }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              sx={{
                backgroundColor: "var(--color-dark-green)",
                "& .MuiOutlinedInput-root": {
                  color: "var(--color-white-1)",
                  "& fieldset": {
                    border: 0,
                  },
                  "&:hover": {
                    border: 0,
                  },
                  "&.Mui-focused fieldset": {
                    border: 0,
                  },
                },
                "& .MuiOutlinedInput-input": {
                  paddingY: "8px",
                  color: "var(--color-white-1)",
                  outline: 0,
                },
              }}
            />
          </FormControl>
        </Grid>
        {permission?.includes("create private group") && (
          <Grid item xs={12} sm={12} md={12} sx={{ marginTop: 2 }} className="flex justify-between">
            <Box className="flex items-center">
              <Typography component={"span"} className="!text-xs" sx={{ color: "var(--color-white-1)" }}>
                Private:
              </Typography>
              <Switch color="primary" onChange={(e) => setIsPrivate(e.target.checked)} />
            </Box>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            marginTop: 2,
          }}
        >
          <Button
            onClick={createGroupHandler}
            size="small"
            disabled={loading}
            sx={{
              width: "100%",
              background: "var(--color-green-1)",
              textTransform: "capitalize",
              boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
              "&:hover": {
                color: "var(--color-white-1)",
                background: "var(--color-green-2)",
              },
            }}
          >
            {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Create"}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddNewGroup;
