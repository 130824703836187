import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { memo, useCallback, useState } from "react";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "redux/features/application/applicationSlice";
import { setNavigateAuth } from "redux/features/navigateSlice/navigateSlice";
import { EmailVerifyOTPCode } from "server/auth";

const VerifyEmailOtp = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.application.loading);
  const [token, setToken] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleResendForgotEmail = useCallback(async () => {
    dispatch(setNavigateAuth("unverifiedEmail"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTokenValidate = async (e) => {
    dispatch(setLoading(true));
    e.preventDefault();
    const result = await EmailVerifyOTPCode(token);
    if (result.data.status) {
      dispatch(setNavigateAuth("login"));
      dispatch(setLoading(false));
      enqueueSnackbar(result.data.message, { variant: "success" });
      setErrorMessage("");
    } else {
      dispatch(setLoading(false));
      enqueueSnackbar(result.data.message, { variant: "warning" });
      if (result.data.errors.code) {
        setErrorMessage(result.data.errors.code);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleTokenValidate(e);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <form autoComplete="off" className="w-[90%] md:w-[335px] mx-auto space-y-6">
      <Box className="flex justify-center">
        <OTPInput
          value={token}
          onChange={setToken}
          numInputs={6}
          inputStyle="!w-[35px] !h-[35px] bg-[--color-green-7] border-none focus:outline-none focus:ring focus:ring-[var(--color-primary)] focus:border-1 focus:border-[#2e3552] text-2xl rounded text-[var(--color-white-1)]"
          renderSeparator={<span style={{ margin: "0px 5px 0px 5px" }}></span>}
          renderInput={(props, index) => <input {...props} key={index} required onKeyDown={handleKeyPress} />}
        />
      </Box>
      {errorMessage && (
        <Box className="flex justify-evenly items-center">
          <Typography component={"p"} className="!text-[var(--color-red-1)] !text-sm text-center !font-semibold">
            {errorMessage}
          </Typography>
          <Typography className="hover:text-[var(--color-green-1)] cursor-pointer !text-sm hover:underline" component={"p"} onClick={handleResendForgotEmail}>
            Resend?
          </Typography>
        </Box>
      )}

      <Button
        onClick={handleSubmit}
        sx={{
          background: "var(--color-green-1)",
          textTransform: "none",
          "&:hover": {
            background: "var(--color-green-11)",
          },
        }}
        disableRipple
        variant="contained"
        fullWidth
      >
        {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Verify Now"}
      </Button>
      <Box className="pb-3">
        {/* <Typography component={"p"} className="hover:text-[var(--color-green-1)] cursor-pointer hover:underline text-center" onClick={() => dispatch(setNavigateAuth("login"))}>
          Go to login
        </Typography> */}
      </Box>
    </form>
  );
};

export default memo(VerifyEmailOtp);
