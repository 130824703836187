import { InfoOutlined, MonetizationOnOutlined, PersonOutline } from "@mui/icons-material";
import { Avatar, Box, Button, Divider, Grid, IconButton, Typography, useTheme } from "@mui/material";
import Layout from "layouts/Layout";
import moment from "moment";
import React, { memo, useRef, useState } from "react";
import googleImg from "../../assets/ib-dashboard/google.png";
import logo from "../../assets/img/image-2023-11-21t10-07-25-128z-1.png";
import InfoDialog from "./InfoDialog";
import InfoDialogCommission from "./InfoDialogCommission";
import LineChartIbDashboard from "components/Chart/LineChartIbDashboard";
import CommissionDailyChart from "components/Chart/CommissionDailyChart";
import IbDashboardDailyChart from "components/Chart/IbDashboardDailyChart";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProfileUpdateIBDashboard from "./ProfileUpdateIBDashboard";

const IbDashboard = memo(() => {
  const user = useSelector((state) => state.auth.user);
  const ib = useSelector((state) => state.ib.ib);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [openInfoDialogCommission, setOpenInfoDialogCommission] = useState(false);
  const navigate = useNavigate();
  const [updateIBProfileModalOpen, setIBUpdateProfileModalOpen] = useState(false);

  const handleInfoOpen = () => {
    setOpenInfoDialog(true);
  };
  const handleInfoClose = () => {
    setOpenInfoDialog(false);
  };
  // const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const textToCopyRef = useRef(null);

  const handleCopy = () => {
    // textToCopyRef.current.select();
    // textToCopyRef.current.setSelectionRange(0, 99999);
    // document.execCommand("copy");
    // // navigator?.clipboard?.writeText(textToCopyRef?.current?.value);
    // enqueueSnackbar("Copied To Clipboard", { variant: "success" });

    const copyText = document.getElementById("copyText");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator?.clipboard?.writeText(copyText.value);
  };

  return (
    <Layout>
      <Box className="w-full h-screen p-5">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={4}>
            <Box
              sx={{
                backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
              }}
              className="flex gap-5 rounded h-[138px] p-5"
            >
              <Box className="h-16 w-16 !bg-[var(--color-green-5)] rounded-md flex justify-center items-center">
                <MonetizationOnOutlined sx={{ fontSize: "30px", color: "var(--color-green-2)" }} />
              </Box>
              <Box>
                <Typography className="!text-[13px] !font-semibold dark:!text-[var(--color-gray-1)] !text-[var(--color-black-7)] !uppercase">TOTAL CLIENT DEPOSIT</Typography>
                <Typography className="!text-[28px] !font-bold dark:!text-[var(--color-white-1)] !text-[var(--color-black-1)]">$ {ib?.client_deposit_balance}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={12} lg={4}>
            <Box
              sx={{
                backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
              }}
              className="flex gap-5 rounded h-[138px] p-5"
            >
              <Box className="h-16 w-16 !bg-[var(--color-green-5)] rounded-md flex justify-center items-center">
                <PersonOutline sx={{ fontSize: "30px", color: "var(--color-green-2)" }} />
              </Box>
              <Box>
                <Typography className="!text-[13px] !font-semibold dark:!text-[var(--color-gray-1)] !text-[var(--color-black-7)] !uppercase">TOTAL TRADER</Typography>
                <Typography className="!text-[28px] !font-bold dark:!text-[var(--color-white-1)] !text-[var(--color-black-1)]">$ {ib?.total_trader}</Typography>
                <Typography className="!text-[13px] !font-semibold dark:!text-[var(--color-gray-1)] !text-[var(--color-black-7)] !uppercase">{ib?.total_sub_ib} SUB IB</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Box
              sx={{
                backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
              }}
              className="flex gap-5 rounded h-[138px] p-5"
            >
              <Box className="h-16 w-16 !bg-[var(--color-green-5)] rounded-md flex justify-center items-center">
                <MonetizationOnOutlined sx={{ fontSize: "30px", color: "var(--color-green-2)" }} />
              </Box>
              <Box>
                <Typography className="!text-[13px] !font-semibold dark:!text-[var(--color-gray-1)] !text-[var(--color-black-7)] !uppercase">TODAYS EARNING</Typography>
                <Typography className="!text-[28px] !font-bold dark:!text-[var(--color-white-1)] !text-[var(--color-black-1)]">$ {ib?.total_trader}</Typography>
                <Typography className="!text-[13px] !font-semibold dark:!text-[var(--color-gray-1)] !text-[var(--color-black-7)] !uppercase">
                  $ {ib?.total_commission} Total Commission
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Box className="rounded" sx={{ backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)" }}>
              <Box sx={{ p: 2 }}>
                <Box className="flex items-center justify-between">
                  <Typography className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-1)] !text-[15px] !font-semibold">IB PROFILE</Typography>
                  <Typography className="!text-[10px] !bg-[var(--color-green-5)] !font-medium !py-1 !px-2 !rounded !text-[var(--color-white-1)]">
                    {moment(user?.created_at).format("DD MMM YYYY")}
                  </Typography>
                </Box>
              </Box>
              <Divider light />
              <Box sx={{ p: 2, background: "#4abf6430" }} className="flex items-center gap-2">
                <Avatar src={user?.photo} alt="Avatar" /> <Typography>{user?.name}</Typography>
              </Box>
              <Divider light />
              <Box sx={{ p: 2, background: "#4abf6430" }} className="flex items-center gap-2">
                <Box className="w-10 h-10">
                  <img className="w-full h-full" src={googleImg} alt="google logo" />
                </Box>
                <Box>
                  <Typography className="!text-[15px] !font-medium">Email</Typography>
                  <Typography className="dark:!text-[var(--color-white-4)] !text-[var(--color-black-7)]">{user?.email}</Typography>
                </Box>
              </Box>
              <Divider light />
              <Box sx={{ p: 3 }} className="space-y-4">
                <Box>
                  <Typography className="!uppercase !text-[15px] !font-medium">Phone Number</Typography>
                  <Typography className="dark:!text-[var(--color-white-4)] !text-[var(--color-black-7)] !text-[14px]">{user?.phone ? user?.phone : "..."}</Typography>
                </Box>
                <Divider light />
                <Box>
                  <Typography className="!uppercase !text-[15px] !font-medium">Join Date</Typography>
                  <Typography className="dark:!text-[var(--color-white-4)] !text-[var(--color-black-7)] !text-[14px]">{moment(user?.created_at).format("DD MMM YYYY")}</Typography>
                </Box>
                <Divider light />
                <Box className="flex items-center justify-between">
                  <Button
                    onClick={() => setIBUpdateProfileModalOpen(true)}
                    variant="contained"
                    sx={{ background: "var(--color-green-5)", textTransform: "capitalize", color: "var(--color-white-1)", "&:hover": { background: "var(--color-green-5)" } }}
                  >
                    Edit Profile
                  </Button>
                  <Button
                    onClick={() => navigate("/kyc")}
                    variant="contained"
                    sx={{ background: "var(--color-green-1)", textTransform: "capitalize", "&:hover": { background: "var(--color-green-2)" } }}
                  >
                    Verify Account
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={8}>
            <Grid container sx={{ marginLeft: 0 }}>
              <Grid item xs={12} md={12} lg={12} className="!mb-[24px]">
                <Box className="rounded p-5" sx={{ backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)" }}>
                  <Box className="flex items-center justify-between">
                    <Typography>Referral Links</Typography>
                    <IconButton onClick={handleInfoOpen}>
                      <InfoOutlined />
                    </IconButton>
                  </Box>
                  <Box sx={{ border: "1px solid var(--color-green-2)", height: "40px", borderRadius: "5px" }} className="flex items-center mt-16">
                    <Box className="h-[40px]  w-[80%] md:w-[85%] lg:w-[90%] flex items-center pl-3">
                      <input
                        type="text"
                        value={ib?.trader_referral || ""}
                        readOnly
                        ref={textToCopyRef}
                        id="copyText"
                        className="w-full outline-none border-none dark:text-[var(--color-white-1)] bg-transparent"
                      />
                    </Box>
                    <Box className="h-[40px]  w-[20%] md:w-[15%] lg:w-[10%] flex justify-center items-center" sx={{ borderLeft: "1px solid var(--color-green-2)" }}>
                      <Button
                        onClick={handleCopy}
                        sx={{
                          width: "100%",
                          height: "100%",
                          fontSize: "16px",
                          color: "var(--color-white-1)",
                          borderRadius: "3px",
                          textTransform: "capitalize",
                          transition: "ease-in-out background 400ms",
                          "&:hover": {
                            backgroundColor: "var(--color-green-2)",
                          },
                        }}
                      >
                        Copy
                      </Button>
                    </Box>
                  </Box>
                  <Box className="mb-[30px]"></Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <Box
                  sx={{
                    marginRight: { xs: 0, md: 0, lg: "12px" },
                    marginBottom: { xs: "12px", md: "12px", lg: 0 },
                    backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
                  }}
                  className="rounded p-5"
                >
                  <Box>
                    <Typography className="!text-[13px] !font-semibold dark:!text-[var(--color-gray-1)] !text-[var(--color-black-7)] !uppercase">Pending Withdraw</Typography>
                    <Typography className="!text-[28px] !font-bold dark:!text-[var(--color-white-1)] !text-[var(--color-black-1)]">$ {ib?.ib_pending_withdraw}</Typography>
                  </Box>
                  <Box className="mb-[106px]"></Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <Box
                  sx={{
                    marginLeft: { xs: 0, md: 0, lg: "12px" },
                    marginTop: { xs: "12px", md: "12px", lg: 0 },
                    backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
                  }}
                  className="rounded p-5"
                >
                  <Box>
                    <Typography className="!text-[13px] !font-semibold  dark:!text-[var(--color-gray-1)] !text-[var(--color-black-7)] !uppercase">Balance</Typography>
                    <Typography className="!text-[28px] !font-bold dark:!text-[var(--color-white-1)] !text-[var(--color-black-1)]">$ {ib?.ib_balance}</Typography>
                  </Box>
                  <Box className="flex justify-end -mt-[6px]">
                    <Box className="w-28 h-28">
                      <img className="w-full object-contain h-full mt-6" src={logo} alt="logo" />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} lg={4}>
            <Box
              sx={{
                backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
              }}
              className="rounded p-5"
            >
              <Box className="flex items-center justify-between">
                <Typography>Commission By Instruments</Typography>
                <IconButton onClick={() => setOpenInfoDialogCommission(true)}>
                  <InfoOutlined />
                </IconButton>
              </Box>

              <Box className="mt-32 ml-8">
                <Typography className="!ml-20 !text-[21px] !font-semibold">{ib?.total_subscriptions}</Typography>
                <Typography className="!text-xl !font-bold dark:!text-[var(--color-gray-1)] !text-[var(--color-black-7)]">APX SUBSCRIPTION</Typography>
              </Box>
              <Box className="mb-14"></Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={12} lg={4}>
            <Box
              sx={{
                backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
              }}
              className="rounded p-5"
            >
              <Typography className="!mb-5">Commission Day Chart</Typography>
              <Box className="h-60">
                <CommissionDailyChart />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={12} lg={4}>
            <Box
              sx={{
                backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
              }}
              className="rounded p-5"
            >
              <Typography className="!mb-5">Commission By Reference</Typography>
              <Box className="h-60">
                <IbDashboardDailyChart />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Box
              sx={{
                backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
              }}
              className="rounded p-5"
            >
              <Typography className="!mb-5">Last 12 Month IB Commission</Typography>
              <Box className="h-[460px]">
                <LineChartIbDashboard />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={12} lg={12}></Grid>
        </Grid>
      </Box>
      <InfoDialog {...{ handleInfoClose, openInfoDialog }} />
      <InfoDialogCommission {...{ openInfoDialogCommission, setOpenInfoDialogCommission }} />
      <ProfileUpdateIBDashboard {...{ setIBUpdateProfileModalOpen, updateIBProfileModalOpen }} />
    </Layout>
  );
});

export default IbDashboard;
