import { Avatar, Box, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { Fragment, memo } from "react";
import { Call, KeyboardBackspace, Menu, MoreVert, VideoCall } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerOpen } from "redux/features/toggle/toggleSlice";

const ChattingHeader = memo(() => {
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const activeChat = useSelector((state) => state.chat.activeChat);
  const dispatch = useDispatch();
  const handleCloseFullScreenChat = () => {
    window.history.back();
  };

  return (
    <Fragment>
      <Box
        className="flex justify-between items-center"
        sx={{
          padding: "0px 10px",
          boxSizing: "border-box",
          height: "64px",
        }}
      >
        <Box className="w-full flex items-center pt-3 pb-2">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 2
            }}
          >
            {isMediumScreen && (
              <IconButton
                sx={{
                  backgroundColor: "var(--btn-bg-color)",
                }}
                onClick={handleCloseFullScreenChat}
                title="Minimize Full Screen Chat"
              >
                <KeyboardBackspace sx={{ fontSize: "30px" }} />
              </IconButton>
            )}
            <Box>
              <Avatar src={activeChat?.user?.profile?.profile_image} alt={"Avatar"} />
            </Box>
          </Box>
          <Box className="flex flex-col ps-2">
            <Typography variant="h6" className="!text-[14px] capitalize" sx={{ color: "var(--color-white)", fontWeight: 600 }}>
              {activeChat?.user?.name}
            </Typography>
            {activeChat?.user?.profile?.country && <Typography variant="caption">{activeChat?.user?.profile?.country}</Typography>}
          </Box>
        </Box>
        <Box>
          <Stack direction="row" spacing={1}>
            <IconButton>
              <VideoCall />
            </IconButton>
            <IconButton>
              <Call />
            </IconButton>
            <IconButton>
              <MoreVert />
            </IconButton>
            {isMediumScreen && (
              <IconButton title="Chat / Channel" onClick={() => dispatch(setDrawerOpen(true))}>
                <Menu />
              </IconButton>
            )}
          </Stack>
        </Box>
      </Box>
    </Fragment>
  );
});

export default ChattingHeader;
