import { useTheme } from "@emotion/react";
import { ContentCopy, HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Formik } from "formik";
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPurchaseSubscriptionModal } from "redux/features/application/applicationSlice";
import { useSnackbar } from "notistack";
import { getBlockChain, subscribeByCrypto } from "server/subscription";
import * as yup from "yup";

const steps = ["Select Crypto", "Make Payment"];

const formValidationSchema = yup.object({
  transaction_id: yup.string("Enter your transaction hash").required("Transaction hash is required"),
});

const PurchaseSubscriptionModal = memo(({ purchaseSubscriptionModal }) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const cryptoCurrency = useSelector((state) => state.subscription.cryptoCurrency);
  const selectedPackage = useSelector((state) => state.subscription.selectedPackage);
  const [selectCrypto, setSelectedCrypto] = useState("");
  const [selectCryptoError, setSelectCryptoError] = useState("");
  const [selectedBlockChain, setSelectedBlockChain] = useState("");
  const [selectedBlockChainError, setSelectedBlockChainError] = useState("");
  const [blockChainSelectItem, setBlockChainSelectItem] = useState({});
  const [blockChain, setBlockChain] = useState([]);
  const textToCopyRef = useRef(null);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const theme = useTheme();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = (step) => {
    if (!selectCrypto) {
      setSelectCryptoError("Crypto currency is required");
    } else {
      setSelectCryptoError("");
    }

    if (!selectedBlockChain) {
      setSelectedBlockChainError("Blockchain is required");
    } else {
      setSelectedBlockChainError("");
    }

    if (selectCrypto && selectedBlockChain) {
      setActiveStep(step);
    }
  };

  const handleGetBlockChainType = async () => {
    const result = await getBlockChain(selectCrypto);
    if (result?.data?.status) {
      setBlockChain(result.data.data.instrument);
    }
  };

  useEffect(() => {
    handleGetBlockChainType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCrypto]);

  return (
    <Modal
      open={purchaseSubscriptionModal}
      onClose={() => dispatch(setPurchaseSubscriptionModal(false))}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isSmallScreen ? "90%" : 500,
          background: theme.palette.mode === "dark" ? "var(--color-green-7)" : "var(--color-green-10)",
          borderRadius: "5px",
          padding: "25px",
          outline: "none",
        }}
      >
        <Grid container rowSpacing={3} columnSpacing={3} alignItems={"center"}>
          <Grid item xs={6}>
            <Typography className="!capitalize !font-bold">Purchase by Crypto</Typography>
          </Grid>
          <Grid item xs={6} className="text-end">
            <IconButton onClick={() => dispatch(setPurchaseSubscriptionModal(false))} title="Close">
              <HighlightOff />
            </IconButton>
          </Grid>

          <Grid item xs={12}>
            <Stepper activeStep={activeStep}>
              {steps.map((label) => {
                return (
                  <Step
                    key={label}
                    sx={{
                      "& .MuiSvgIcon-root.Mui-completed": { color: theme.palette.mode === "dark" ? "white" : "#0c1f19 !important" },
                      "& .MuiSvgIcon-root.Mui-active": { color: "var(--color-green-1)" },
                    }}
                  >
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>

          {activeStep === 0 && (
            <Grid item xs={12}>
              <Box className="space-y-7">
                <FormControl
                  sx={{
                    marginTop: "10px",
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--color-gray-2)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                  }}
                  size="small"
                >
                  <InputLabel id="demo-select-small-label">Cryptocurrency</InputLabel>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        style: {
                          background: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
                          height: isSmallScreen && 150,
                        },
                      },
                    }}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Cryptocurrency"
                    value={selectCrypto}
                    onChange={(e) => setSelectedCrypto(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>Select a crypto</em>
                    </MenuItem>
                    {cryptoCurrency?.map((item, i) => (
                      <MenuItem key={i} value={item.block_chain}>
                        {item.block_chain}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography component={"p"} className="!text-[var(--color-red-1)] !text-sm !mt-1 !font-semibold">
                    {selectCryptoError}
                  </Typography>
                </FormControl>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--color-gray-2)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                  }}
                  size="small"
                >
                  <InputLabel id="demo-select-small-label">Blockchain</InputLabel>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        style: {
                          background: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
                          height: isSmallScreen && 150,
                        },
                      },
                    }}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Blockchain"
                    value={selectedBlockChain}
                    onChange={(e) => setSelectedBlockChain(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>Select a blockchain</em>
                    </MenuItem>
                    {blockChain?.map((item, i) => (
                      <MenuItem key={i} value={item.name} onClick={() => setBlockChainSelectItem(item)}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography component={"p"} className="!text-[var(--color-red-1)] !text-sm !font-semibold !mt-1">
                    {selectedBlockChainError}
                  </Typography>
                </FormControl>

                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      height: "100%",
                      "& fieldset": {
                        border: "1px solid var(--color-gray-2)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                  }}
                  size="small"
                >
                  <TextField type="text" value={selectedPackage?.fee} label="USD" size="small" variant="outlined" />
                </FormControl>

                <Box className="flex justify-end">
                  <Button
                    onClick={() => handleNext(1)}
                    sx={{
                      background: "var(--color-green-1)",
                      textTransform: "capitalize",
                      color: "var(--color-white-1)",
                      "&:hover": {
                        color: "var(--color-white-1)",
                        background: "var(--color-green-2)",
                      },
                    }}
                    type="submit"
                    variant="contained"
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </Grid>
          )}

          {activeStep === 1 && (
            <Grid item xs={12}>
              <Formik
                initialValues={{
                  transaction_id: "",
                }}
                validationSchema={formValidationSchema}
                onSubmit={async (values) => {
                  setLoading(true);
                  const data = {
                    package_id: selectedPackage?.id,
                    block_chain: selectCrypto,
                    instrument: blockChainSelectItem?.name,
                    usd_amount: selectedPackage?.fee,
                    crypto_amount: selectedPackage?.fee,
                    crypto_address: blockChainSelectItem?.address,
                    transaction_id: values.transaction_id,
                  };

                  const result = await subscribeByCrypto(data);
                  if (result?.data?.status) {
                    enqueueSnackbar(result.data.message, { variant: "success" });
                    dispatch(setPurchaseSubscriptionModal(false));
                    setActiveStep(0);
                    setSelectedBlockChain("");
                    setSelectedCrypto("");
                    setLoading(false);
                  } else {
                    enqueueSnackbar(result.data.message, { variant: "warning" });
                    if (result.data.errors.transaction_id[0]) {
                      enqueueSnackbar(result.data.errors.transaction_id[0], { variant: "warning" });
                    }
                    setLoading(false);
                  }
                }}
              >
                {({ handleChange, values, handleSubmit, errors }) => (
                  <Box className="">
                    <Typography className="!text-center !font-bold !my-1">Crypto QR Code And Transaction Id</Typography>
                    <Typography className="!text-center !w-[70%] !text-sm !m-auto dark:!text-[var(--color-white-4)]">
                      Give Us More Details About You. What Do You Enjoy Doing In Your Spare Time?
                    </Typography>

                    <Box className="flex justify-center my-3">
                      <Box className="shadow-md w-60 h-60 rounded-md flex justify-center">
                        <img
                          className="rounded-md w-full h-full "
                          // src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=%27${blockChainSelectItem?.address}%27&choe=UTF-8`}
                          src={`https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${blockChainSelectItem?.address}`}
                          alt="QR Code"
                        />
                      </Box>
                    </Box>
                    <Box sx={{ border: "1px solid var(--color-gray-2)", height: "40px", borderRadius: "5px" }} className="flex items-center my-4">
                      <Box className="h-[40px] w-[90%] flex items-center pl-3">
                        <input
                          type="text"
                          value={blockChainSelectItem?.address}
                          readOnly
                          ref={textToCopyRef}
                          className="w-full outline-none border-none dark:text-[var(--color-white-1)] bg-transparent"
                        />
                      </Box>
                      <Box className="h-[40px] w-[10%] flex justify-center items-center" sx={{ borderLeft: "1px solid var(--color-gray-2)" }}>
                        <IconButton
                          title="Copy"
                          onClick={() => {
                            textToCopyRef.current.select();
                            window.document.execCommand("copy");
                            enqueueSnackbar("Copied To Clipboard", { variant: "success" });
                          }}
                        >
                          <ContentCopy />
                        </IconButton>
                      </Box>
                    </Box>

                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          height: "100%",
                          "& fieldset": {
                            border: "1px solid var(--color-gray-2)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--color-green-1)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--color-green-2)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                          "&.Mui-focused": {
                            color: "var(--color-green-2)",
                          },
                        },
                      }}
                      size="small"
                    >
                      <TextField
                        type="text"
                        label="Transaction Hash"
                        name="transaction_id"
                        value={values.transaction_id}
                        onChange={handleChange}
                        error={Boolean(errors.transaction_id)}
                        helpertext={errors.transaction_id}
                        size="small"
                        variant="outlined"
                      />
                      <Typography component={"p"} className="!text-[var(--color-red-1)] !text-sm !font-semibold !mt-1">
                        {errors.transaction_id}
                      </Typography>
                    </FormControl>
                    <Box className="flex justify-between mt-3">
                      <Button
                        onClick={() => setActiveStep(0)}
                        sx={{
                          background: "var(--color-green-1)",
                          textTransform: "capitalize",
                          color: "var(--color-white-1)",
                          "&:hover": {
                            color: "var(--color-white-1)",
                            background: "var(--color-green-2)",
                          },
                        }}
                        type="submit"
                        variant="contained"
                      >
                        Previous
                      </Button>
                      <Button
                        onClick={handleSubmit}
                        sx={{
                          width: "140px",
                          background: "var(--color-green-1)",
                          textTransform: "capitalize",
                          color: "var(--color-white-1)",
                          "&:hover": {
                            color: "var(--color-white-1)",
                            background: "var(--color-green-2)",
                          },
                        }}
                        type="submit"
                        variant="contained"
                      >
                        {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Submit request"}
                      </Button>
                    </Box>
                  </Box>
                )}
              </Formik>
            </Grid>
          )}
        </Grid>
      </Box>
    </Modal>
  );
});

export default PurchaseSubscriptionModal;
