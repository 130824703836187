import { Box } from "@mui/system";
import React, { memo } from "react";
import ChattingHeader from "./ChattingHeader";
import ChattingContent from "./ChattingContent";
import { Divider } from "@mui/material";
import ChattingInput from "./ChattingInput";

const Chatting = memo(() => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 64px)",
        background: "var(--color-green-9)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <ChattingHeader />
        <Divider />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <ChattingContent />
      </Box>
      <ChattingInput />
    </Box>
  );
});

export default Chatting;
