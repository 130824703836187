import { createSlice } from "@reduxjs/toolkit";

const brokerSlice = createSlice({
    name: "broker",
    initialState: {
        broker_list: {},
        searchedBrokerList: {},
        currentBroker: {},
    },
    reducers: {
        setAllBroker: (state, action) => {
            state.broker_list = action.payload;
        },
        setCurrentBroker: (state, action) => {
            state.currentBroker = action.payload;
        },
    },
});

export const { setAllBroker, setAffiliateId, setCurrentBroker } = brokerSlice.actions;
export default brokerSlice.reducer;
