import {
    Box,
    Card,
    CardContent,
    CardMedia,
    CircularProgress,
    Grid,
    Typography,
    useTheme,
} from "@mui/material";
import permissionEnum from "enum/permissionEnum";
import Layout from "layouts/Layout";
import moment from "moment";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setPostLoading } from "redux/features/loader/loaderSlice";
import { setPostDetails } from "redux/features/posts/postsSlice";
import { getPostsDetails } from "server/posts";
import avatarNews from "../../assets/news/avatarnews.jpg";
import Comments from "./Comments";

import PostAsideDetails from "./PostAsideDetails";
const PostDetails = () => {
  const permission = useSelector((state) => state.permission.permission);
  const post = useSelector((state) => state.posts.postDetails);
  const postLoading = useSelector((state) => state.loader.postLoading);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { id } = useParams();

  const postDetails = async () => {
    dispatch(setPostLoading(true));
    const result = await getPostsDetails(id);
    if (result?.data?.status) {
      dispatch(setPostDetails(result.data.data));
      dispatch(setPostLoading(false));
    } else {
      dispatch(setPostLoading(false));
    }
  };

  useEffect(() => {
    postDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (permission.length === 0) return;
    if (!permission?.includes(permissionEnum[`/posts`])) {
      window.location.href = "/";
    }
  }, [permission, id]);

  return (
    <Layout>
      <Grid container spacing={3} className="p-3">
        <Grid item xs={12} md={8.5} className="space-y-4">
          {postLoading ? (
            <Box className="w-full h-screen flex justify-center items-center">
              <CircularProgress color="success" size={50} />
            </Box>
          ) : (
            <>
              <Card
                sx={{
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor:
                    theme.palette.mode === "dark"
                      ? "rgba(65, 255, 200, 0.31)"
                      : "rgba(0, 208, 148, 0.30)",
                  background:
                    theme.palette.mode === "dark"
                      ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)"
                      : "var(--color-green-10)",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    component="div"
                    className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)]"
                  >
                    {post?.title}.
                    <Typography
                      className="!text-[--color-gray-1] !ml-2"
                      component={"span"}
                    >
                      {post?.published_date
                        ? moment(post.published_date).format("MMMM DD, YYYY")
                        : moment(post?.updated_at).format("MMMM DD, YYYY")}
                    </Typography>
                  </Typography>
                </CardContent>
                <CardMedia
                  className="!h-[450px] object-cover"
                  component="img"
                  alt="green iguana"
                  image={post?.image}
                />
                <CardContent>
                  <Typography
                    variant="body2"
                    className="!text-[var(--color-gray-1)] text-justify"
                    dangerouslySetInnerHTML={{ __html: post?.post }}
                  ></Typography>
                </CardContent>
              </Card>
              {/* Comments Section  Start*/}
              <Comments />
              {/* Ends comments section */}
              {/* Author section */}
              <Grid
                justifyContent={"center"}
                alignItems={"center"}
                container
                className="border-2 rounded-xl border-dashed border-[#323248] p-7"
              >
                <Grid item xs={12} md={4} lg={1.5}>
                  <Box>
                    <Box
                      className="h-[65px] w-[65px] rounded-full"
                      component={"img"}
                      src={avatarNews}
                      alt=""
                    ></Box>
                  </Box>
                  <Typography className="!text-sm !capitalize">
                    MirrorTrade
                  </Typography>
                  <Typography className="!text-sm !text-[--color-gray-1]">
                    Co-founder
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} lg={10.5}>
                  <Typography className="!text-[--color-gray-1]">
                    First, a disclaimer – the entire process of writing a blog
                    post often takes more than a couple of hours, even if you
                    can type eighty words per minute and your writing skills are
                    sharp writing a blog post often takes more than a couple.
                  </Typography>
                  <Typography className="!text-[--color-blue-1] hover:!text-blue-500 !cursor-pointer !mt-2">
                    Author’s Profile
                  </Typography>
                </Grid>
              </Grid>
              {/* Author section */}
            </>
          )}
        </Grid>
        <Grid item xs={12} md={3.5} className="p-5">
          <PostAsideDetails />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default memo(PostDetails);
