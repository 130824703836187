import mirrorApi from '../api/mirror_trade_api';
const { getAuthToken } = require("./auth");

export const tradeClose = async (user_id, page, filterTrades, searchText) => {
    try {
        return await mirrorApi.get(`/close-orders/${user_id}?page=${page}&per_page=${filterTrades}&search=${searchText}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ file: close_trade.js:12 ~ closeTrade ~ error:", error)
    }
};

// Trade history
export const closeTradeHistory = async (page, filterTrades, searchText) => {
    try {
        return await mirrorApi.get(`/user/close/trades?page=${page}&per_page=${filterTrades}&search=${searchText}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ closeTradeHistory ~ error:", error)
    }
};
