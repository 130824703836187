import { Box, Grid, Typography, useTheme } from "@mui/material";
import StatsSkeletonLoader from "components/SkeletonLoader/StatsSkeletonLoader";
import React, { Fragment, memo } from "react";
import { useSelector } from "react-redux";

const TradingStats = () => {
  const pamUser = useSelector((state) => state.pam.pamUserDetails);
  const pamUserDetailsLoader = useSelector((state) => state.loader.pamUserDetailsLoader);
  const theme = useTheme();
  return (
    <Fragment>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        {pamUserDetailsLoader ? (
          <StatsSkeletonLoader />
        ) : (
          <Box
            sx={{
              border: "1px solid rgba(0, 208, 148, 0.3)",
              background:
                theme.palette.mode === "dark"
                  ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)"
                  : "var(--color-green-10)",
              borderRadius: "10px",
            }}
            className="!p-[8px]"
          >
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">
                Total Trades
              </Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser?.total_trades}
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">
                Win %
              </Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser?.win_percent?.toFixed(2)}% <span className="!text-[12px]">{pamUser.trade_win} of {pamUser?.total_trades}</span>
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">
                Loss %
              </Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser?.loss_percent?.toFixed(2)}% <span className="!text-[12px]">{pamUser.trade_loss} of {pamUser?.total_trades}</span>
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">
                Lots
              </Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser?.lots?.toFixed(2)}
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">
                Commissions
              </Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser?.commission} USD
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">
                Swap
              </Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser?.swap}
              </Typography>
            </Box>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        {pamUserDetailsLoader ? (
          <StatsSkeletonLoader />
        ) : (
          <Box
            sx={{
              border: "1px solid rgba(0, 208, 148, 0.3)",
              background:
                theme.palette.mode === "dark"
                  ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)"
                  : "var(--color-green-10)",
              borderRadius: "10px",
            }}
            className="!p-[8px]"
          >
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">
                Longs Won
              </Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
              {pamUser?.logns_trade_perchant?.toFixed(2)}% <span className="!text-[12px]">{pamUser?.longs_won} of {pamUser?.longs_trade}</span>
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">
                Shorts Won
              </Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
              {pamUser?.shorts_won_percentage?.toFixed(2)}% <span className="!text-[12px]">{pamUser?.shorts_won} of {pamUser?.shorts_trade}</span>
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">
                Best Trade
              </Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser?.best_trade?.toFixed(2)} USD
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">
                Worst trade
              </Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
              {pamUser?.worst_trade?.toFixed(2)} USD
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">
                Average Win
              </Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser.average_win?.toFixed(2)} USD
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">
                Average Loss
              </Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser.average_loss?.toFixed(2)} USD
              </Typography>
            </Box>
          </Box>
        )}
      </Grid>
    </Fragment>
  );
};

export default memo(TradingStats);
