import { Button, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import React, { Fragment, memo } from "react";
import { useDispatch } from "react-redux";
import { setOpenBrokerModalJoin } from "redux/features/application/applicationSlice";
import BlurImage from "./BlurImage";
import { setCurrentBroker } from "redux/features/broker/brokerSlice";

const BrokerTableRow = memo(({ item }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleSubmitBroker = (item) => {
    dispatch(setOpenBrokerModalJoin(true));
    dispatch(setCurrentBroker(item))
  };

  return (
    <Fragment>
      <TableRow
        sx={{
          ".MuiTableCell-root": { borderBottom: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}` },
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell align="center" sx={{ color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)" }} className="!font-bold !text-base">
          <BlurImage
            src={item?.logo}
            style={{
              height: "fit-content",
              width: "100px",
            }}
            alt={item?.name}
          />
        </TableCell>
        <TableCell align="center" sx={{ color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)" }} className="!font-bold !text-base">
          {item?.name}
        </TableCell>
        <TableCell align="center" sx={{ color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)" }} className="!font-bold !text-base">
          {item?.type}
        </TableCell>
        <TableCell align="center" sx={{ color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)" }} className="!font-bold !text-base">
          {JSON.parse(item?.license)?.map((item, i) => (
            <Fragment key={item}>
              {i > 0 && ", "}
              {item}
            </Fragment>
          ))}
        </TableCell>
        <TableCell align="center" sx={{ color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)" }} className="!font-bold !text-base">
          {JSON.parse(item?.platform)?.map((item, i) => (
            <Fragment key={item}>
              {i > 0 && ", "}
              {item}
            </Fragment>
          ))}
        </TableCell>
        <TableCell align="center" sx={{ color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)" }} className="!font-bold !text-base !space-y-1">
          <Button
            href={item?.affiliate_link}
            target="_blank"
            variant="contained"
            sx={{
              backgroundColor: "var(--color-green-1)",
              height: "40px",
              width: "190px",
              fontWeight: 600,
              fontSize: "13px",
              "&:hover": {
                backgroundColor: "var(--color-green-2)",
              },
            }}
          >
            OPEN AN ACCOUNT
          </Button>
          <Typography component={"p"} sx={{ color: "var(--color-gray-4)", fontSize: "14px" }}>
            Your capital is at risk
          </Typography>
          <Button
            onClick={() => handleSubmitBroker(item)}
            variant="contained"
            sx={{
              backgroundColor: "var(--color-white-1)",
              height: "40px",
              width: "190px",
              fontWeight: 600,
              color: "var(--color-black-1)",
              fontSize: "13px",
              "&:hover": {
                backgroundColor: "var(--color-white-1)",
              },
            }}
          >
            Submit
          </Button>
        </TableCell>
      </TableRow>
    </Fragment>
  );
});

export default BrokerTableRow;
