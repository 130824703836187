import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setOpenUnCopyDialog } from "redux/features/application/applicationSlice";
import { logIn } from "redux/features/auth/authSlice";
import { refetchAuth } from "server/auth";
import { unCopy } from "server/copy_un_copy";

const UnCopyTradeModal = ({ openUnCopyDialog, id }) => {
  const loading = useSelector((state) => state.application.loading);
  const user = useSelector((state) => state.auth.user);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleUnCopy = async () => {
    dispatch(setLoading(true));
    const unCopyResult = await unCopy(id);
    if (unCopyResult.status) {
      enqueueSnackbar(unCopyResult.data.message, { variant: "success" });
      const result = await refetchAuth();
      if (result?.data?.status) {
        localStorage.setItem("auth-token", result?.data?.token);
        dispatch(logIn({ ...result?.data?.data }));
      }
      dispatch(setLoading(false));
      dispatch(setOpenUnCopyDialog(false));
    } else {
      enqueueSnackbar(unCopyResult.data.message, { variant: "warning" });
      dispatch(setOpenUnCopyDialog(false));
      dispatch(setLoading(false));
    }
  };
  
  const findMasterLogin = user.slave_of && user.slave_of.find((item) => parseInt(item.master_id) === parseInt(id));

  return (
    <Dialog
      PaperProps={{
        sx: {
          background: theme.palette.mode === "dark" ? "var(--color-green-7)" : "var(--color-green-10)",
          width: 400,
        },
      }}
      open={openUnCopyDialog}
      onClose={() => dispatch(setOpenUnCopyDialog(false))}
    >
      <DialogTitle id="alert-dialog-title">Uncopy</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description"> Are you sure to uncopy this account {findMasterLogin?.master_login}.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={() => dispatch(setOpenUnCopyDialog(false))}>
          Cancel
        </Button>
        <Button variant="contained" sx={{ background: "var(--color-green-1)", "&:hover": { background: "#119670" } }} onClick={handleUnCopy} autoFocus>
          {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(UnCopyTradeModal);
