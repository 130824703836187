import { Chart } from "chart.js/auto";
import React, { memo, useEffect, useRef } from "react";

const LineChartIbDashboard = () => {
  const chartRef = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const datal = ["January", "February", "March", "April", "May", "June", "July"];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = [10, 15, 8, 12, 18, 14, 20];

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      const chartInstance = new Chart(ctx, {
        type: "line",
        data: {
          labels: datal,
          datasets: [
            {
              label: "",
              fill: false,
              tension: 0.3,
              backgroundColor: "#1A8D66",
              borderColor: "#27D195",
              data: data,
            },
          ],
        },
        options: {
          scales: {
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              display: true,
              title: {
                display: false,
                text: "Time",
              },
            },
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              display: true,
              title: {
                display: false,
                text: "Value",
              },
            },
          },
          elements: {
            line: {
              borderWidth: 2,
            },
            point: {
              radius: 5,
              backgroundColor: "rgba(75,192,192,1)",
            },
          },
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 10,
              bottom: 10,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          chartArea: {
            backgroundColor: "transparent",
          },
          responsive: true,
          maintainAspectRatio: false,
          maxHeight: "100vh",
        },
      });

      return () => {
        chartInstance.destroy();
      };
    }
  }, [data, datal]);

  return <canvas ref={chartRef} style={{ height: "350px", width: "100%" }} />;
};

export default memo(LineChartIbDashboard);
