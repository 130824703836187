import { createSlice } from "@reduxjs/toolkit";

const toggleSlice = createSlice({
    name: "toggle",
    initialState: {
        toggleChatOrChannel: 'chat',
        toggleChatOrGroupList: true,
        toggleChannelOrCreateChannel: 'channel',
        drawerOpen: false,
        toggleChatOrChannelDrawer: "channel"
    },
    reducers: {
        setToggleChatOrChannel: (state, action) => {
            state.toggleChatOrChannel = action.payload;
        },
        setToggleChatOrGroupList: (state,) => {
            state.toggleChatOrGroupList = !state.toggleChatOrGroupList;
        },
        setToggleChannelOrCreateChannel: (state, action) => {
            state.toggleChannelOrCreateChannel = action.payload;
        },
        setDrawerOpen: (state, action) => {
            state.drawerOpen = action.payload
        },
        setToggleChatOrChannelDrawer: (state, action) => {
            state.toggleChatOrChannelDrawer = action.payload;
        },
    },
});

export const { setToggleChatOrChannel, setToggleChatOrGroupList, setToggleChannelOrCreateChannel, setDrawerOpen, setToggleChatOrChannelDrawer } = toggleSlice.actions;
export default toggleSlice.reducer;
