import React from "react";
import { Badge, Box, CircularProgress, IconButton, Popover, Tab } from "@mui/material";
import SearchFriend from "./SearchFriend";
import { useDispatch, useSelector } from "react-redux";
import ChatList from "./ChatList";
import Chat from "./Chat";
import Friends from "./Friends";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState } from "react";
import GroupChatList from "./Group/GroupChatList";
import Groups from "./Group/Groups";
import SearchGroup from "./Group/SearchGroup";
import GroupChat from "./Group/GroupChat";
import InitSocket from "initialLoad/InitSocket";
import { setSocket } from "redux/features/chatSlice/chatSlice";
import { io } from "socket.io-client";
import { Close, Fullscreen, NotificationsNoneOutlined } from "@mui/icons-material";
// import notifyIcon from "../../assets/icons/notification-unscreen.gif";
import AddNewGroup from "./Group/AddNewGroup";
import InitialChatRequest from "initialLoad/InitialChatRequest";
import GroupDetails from "./Group/GroupDetails";
import EditGroup from "./Group/EditGroup";
import { useNavigate } from "react-router-dom";
const ChatBot = ({ openChat, anchorElChat, handlePopoverCloseChat }) => {
  const navigate = useNavigate();
  const navigate_chat = useSelector((state) => state.chat.chatNavigate);
  const loading = useSelector((state) => state.loader.chatListLoading);
  const user_id = useSelector((state) => state.chat.user?.id);
  const group_navigate = useSelector((state) => state.chat.groupNavigate);
  const [value, setValue] = useState("friends");
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notification.unseenNotification).length;

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (!user_id) return;
    const socket = io(process.env.REACT_APP_API_ROOTURL, {
      extraHeaders: {
        authorization: `Bearer ${localStorage.getItem("chat-auth-token")}`,
      },
    });
    socket.on("connect", () => {});
    dispatch(setSocket(socket));
    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line
  }, [user_id, openChat]);

  return (
    <>
      <InitSocket openChat={openChat} />
      <InitialChatRequest />
      <Popover
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "transparent",
            boxShadow: "none",
            backgroundImage: "none",
            borderRadius: "0px",
          },
        }}
        open={openChat}
        anchorEl={anchorElChat}
        onClose={handlePopoverCloseChat}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ typography: "body1", width: "360px", height: "450px", background: "var(--color-green-8)" }}>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
              <CircularProgress sx={{ color: "var(--color-white-1)" }} />
            </Box>
          ) : (
            <TabContext value={value}>
              <Box sx={{ borderBottom: 0, borderColor: "divider", background: "var(--color-dark-green)" }} className="flex justify-between items-center">
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  TabIndicatorProps={{
                    style: { display: "none" },
                  }}
                  sx={{
                    // i want to change the color of the tab when it is active
                    "& .MuiTab-root": {
                      color: "var(--color-white-1)",
                      "&.Mui-selected": {
                        color: "var(--color-green-1)",
                        background: "var(--color-green-8)",
                      },
                    },
                  }}
                >
                  <Tab label="Friends" value="friends" />
                  <Tab label="Groups" value="groups" />
                </TabList>
                <Box>
                  <Badge
                    color="error"
                    size="small"
                    badgeContent={notifications}
                    sx={{
                      color: "var(--color-red-1)",
                    }}
                    className="cursor-pointer cus-noti-badge"
                  >
                    {/* <img src={notifyIcon} alt="Notification" className="w-[35px]" /> */}
                    <IconButton title="Notification" sx={{ height: 30, width: 30 }}>
                      <NotificationsNoneOutlined sx={{ color: "var(--color-white-1)" }} />
                    </IconButton>
                  </Badge>
                  <IconButton
                    title="Fullscreen"
                    sx={{ height: 30, width: 30 }}
                    onClick={() => {
                      navigate("/chat");
                      handlePopoverCloseChat();
                    }}
                  >
                    <Fullscreen />
                  </IconButton>
                  <IconButton title="Close" sx={{ height: 30, width: 30, marginRight: 1 }} onClick={handlePopoverCloseChat}>
                    <Close />
                  </IconButton>
                </Box>
              </Box>
              <TabPanel value="friends" sx={{ padding: 0 }}>
                {navigate_chat === "chat_list" && <ChatList {...{ handlePopoverCloseChat }} />}
                {navigate_chat === "search_list" && <SearchFriend />}
                {navigate_chat === "chat" && <Chat {...{ handlePopoverCloseChat }} />}
                {navigate_chat === "friends" && <Friends {...{ handlePopoverCloseChat }} />}
              </TabPanel>
              <TabPanel value="groups" sx={{ padding: 0 }}>
                {group_navigate === "add_group" && <AddNewGroup {...{ handlePopoverCloseChat }} />}
                {group_navigate === "group_chat_list" && <GroupChatList {...{ handlePopoverCloseChat }} />}
                {group_navigate === "groups" && <Groups {...{ handlePopoverCloseChat }} />}
                {group_navigate === "groups_search" && <SearchGroup />}
                {group_navigate === "group_chat" && <GroupChat {...{ handlePopoverCloseChat }} />}
                {group_navigate === "group_details" && <GroupDetails {...{ handlePopoverCloseChat }} />}
                {group_navigate === "edit_group" && <EditGroup {...{ handlePopoverCloseChat }} />}
              </TabPanel>
            </TabContext>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default ChatBot;
