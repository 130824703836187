import mirrorApi from '../api/mirror_trade_api';
const { getAuthToken } = require("./auth");

export const symbols = async () => {
    try {
        return await mirrorApi.get("/symbols", {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ file: auth.js:46 ~ pamUser ~ error:", error)
        
    }
};
