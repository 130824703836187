import { Badge, Box, CircularProgress, Divider, FormControl, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React, { Fragment, memo, useEffect, useState } from "react";
import { Add, Search } from "@mui/icons-material";
import MoreIcon from "@mui/icons-material/MoreVert";
import { setDrawerOpen, setToggleChatOrChannel, setToggleChatOrChannelDrawer } from "redux/features/toggle/toggleSlice";
import { useDispatch, useSelector } from "react-redux";
import { setActiveChannel, setChannelChatList, setChannelSearchList } from "redux/features/channel/channelSlice";
import { getChannelChatList, searchChannelList } from "server/channel";
import { socketServiceJoinChannel } from "services/socketServiceFullScreenChat";
import MoreChannelPopover from "./MoreChannelPopover";
import moment from "moment";

const profilePic = {
  width: "50px",
  height: "50px",
  aspectRatio: 1,
  clipPath: "polygon(93.30% 75.00%,50.00% 100.00%,6.70% 75.00%,6.70% 25.00%,50.00% 0.00%,93.30% 25.00%)",
  borderRadius: "10px",
  overflow: "hidden",
};

const ChannelFullScreenDrawer = memo(() => {
  const unseenNotification = useSelector((state) => state.notification.unseenNotification);
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorElMoreChannel, setAnchorElMoreChannel] = React.useState(null);
  const moreChannelOpen = Boolean(anchorElMoreChannel);
  const channelSearchList = useSelector((state) => state.channel.channelSearchList);
  const channelChatList = useSelector((state) => state.channel.channelChatList);
  const channelList = useSelector((state) => state.channel.channelList);
  const [selectedChannelItem, setSelectedChannelItem] = useState("");
  const [selectedPostItem, setSelectedPostItem] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const dispatch = useDispatch();

  const handleSearchChannel = async (e) => {
    setLoadingSearch(true);
    const searchQuery = e.target.value.toLowerCase();
    setSearchQuery(searchQuery);

    if (!searchQuery) {
      dispatch(setChannelSearchList([]));
      setLoadingSearch(false);
      return;
    }

    const result = await searchChannelList({ searchText: searchQuery });
    if (result?.data?.status) {
      dispatch(setChannelSearchList(result.data.data));
      setLoadingSearch(false);
    } else {
      setLoadingSearch(false);
    }
  };

  const channelChatListFetch = async () => {
    setLoading(true);
    const result = await getChannelChatList();
    if (result?.data?.status) {
      dispatch(setChannelChatList(result.data.data));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    channelChatListFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedChannelItem = (channel, section) => {
    if (section === "posts") {
      setSelectedPostItem(channel?.id);
      setSelectedChannelItem(null);
      dispatch(setToggleChatOrChannel("channel"));
      dispatch(setActiveChannel(channel));
      socketServiceJoinChannel(channel?.id);
      dispatch(setDrawerOpen(false));
    } else {
      setSelectedChannelItem(channel?.id);
      setSelectedPostItem(null);
      dispatch(setToggleChatOrChannel("channel"));
      dispatch(setActiveChannel(channel));
      socketServiceJoinChannel(channel?.id);
      dispatch(setDrawerOpen(false));
    }
  };
  const handleClickMoreChannel = (event) => {
    event.stopPropagation();
    setAnchorElMoreChannel(event.currentTarget);
  };
  const handleCloseMoreChannel = () => {
    setAnchorElMoreChannel(null);
  };

  const calculateUnseenNotification = (id) => {
    const notification = unseenNotification.filter((item) => item.for_id === id);
    return notification?.length;
  };

  return (
    <Fragment>
      <Box
        sx={{
          height: "calc(100vh - 64px)",
          background: "var(--color-green-8)",
        }}
      >
        <Box className="h-[64px] flex justify-between items-center gap-1 px-2">
          <Typography variant="body1" sx={{ color: "var(--color-green-1)", fontWeight: 400, fontSize: 14, paddingX: 2 }}>
            Channel
          </Typography>
          <FormControl>
            <TextField
              onChange={handleSearchChannel}
              autoComplete="off"
              fullWidth
              variant="outlined"
              placeholder="Search for channel..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      sx={{
                        color: "var(--color-white-1)",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "38px",
                  width: "100%",
                  color: "var(--color-white-1)",
                  padding: 1,
                  boxSizing: "border-box",
                  borderRadius: "20px",
                  "& fieldset": {
                    border: "1px solid var(--color-gray-2)",
                    opacity: 1,
                  },
                  "&:hover fieldset": {
                    border: "1px solid var(--color-green-1)",
                  },
                  "&.Mui-focused fieldset": {
                    border: `1px solid var(--color-green-2)`,
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "14px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  },
                },
              }}
            />
          </FormControl>
          <IconButton
            onClick={() => dispatch(setToggleChatOrChannelDrawer("createChannel"))}
            title="Create Channel"
            sx={{
              backgroundColor: "var(--btn-bg-color)",
            }}
          >
            <Add />
          </IconButton>
        </Box>
        <Divider />
        <Box className="chat-list-scrollbar" sx={{ height: "calc(100vh - 178px)", overflowY: "auto" }}>
          {searchQuery ? (
            <Fragment>
              <Box>
                <Typography sx={{ color: "var(--color-green-1)", fontWeight: 500, fontSize: 20 }} className="px-6 pt-4">
                  Search Channel List
                </Typography>
              </Box>
              <Box className="mt-4">
                {channelSearchList && channelSearchList?.length > 0 ? (
                  loadingSearch ? (
                    <Box sx={{ height: "calc(100vh - 250px)" }} className="flex items-center justify-center">
                      <CircularProgress color="success" />
                    </Box>
                  ) : (
                    channelSearchList?.map((item, i) => (
                      <Box
                        key={i}
                        onClick={() => handleSelectedChannelItem(item)}
                        className={`w-full flex justify-between mb-2 px-2`}
                        sx={{
                          cursor: "pointer",
                          backgroundImage: selectedChannelItem === item.id ? "linear-gradient(to right, var(--color-green-1), var(--color-green-14))" : "transparent",
                          // borderRadius: "10px",
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.08)",
                          },
                          "&:hover .moreIcon": {
                            visibility: "visible",
                          },
                        }}
                      >
                        <Box className="flex items-center pt-3 pb-3">
                          <MoreIcon onClick={handleClickMoreChannel} className="moreIcon" />
                          <Badge color="secondary" badgeContent={0}>
                            <Box
                              className="relative"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Box sx={profilePic}>
                                <img src={item.icon} alt={"channel"} className="!w-[50px] h-[50px] flex justify-center items-center bg-[#5F6165)]" />
                              </Box>
                            </Box>
                          </Badge>
                          <Box className="flex flex-col ps-3">
                            <Typography variant="h6" className="!text-[13px] !font-bold !capitalize">
                              {item.name}
                            </Typography>
                            <Typography
                              variant="caption"
                              dangerouslySetInnerHTML={{
                                __html:
                                  item && item.content && item.content[0] && item.content[0].content && item.content[0].content.length > 71
                                    ? item.content[0].content.slice(0, 71) + "..."
                                    : (item.content && item.content[0] && item.content[0].content) || "No new news",
                              }}
                            ></Typography>
                          </Box>
                        </Box>
                        <Box className="flex flex-col items-center p-3">
                          <Typography className=" !text-[12px] text-center text-[#5F6165)]">Today</Typography>
                          <Badge
                            sx={{
                              "& .MuiBadge-badge": {
                                color: "var(--color-white-1)",
                                backgroundColor: "var(--color-green-1)",
                              },
                            }}
                            badgeContent={100}
                            className="mt-4"
                          ></Badge>
                        </Box>
                      </Box>
                    ))
                  )
                ) : (
                  <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                    <Typography>No results for search channel.</Typography>
                  </Box>
                )}
              </Box>
            </Fragment>
          ) : (
            <Fragment>
              <Box>
                <Typography sx={{ color: "var(--color-green-1)", fontWeight: 500, fontSize: 20 }} className="px-6 pt-4">
                  Channel Posts
                </Typography>
              </Box>

              <Box className="mt-4">
                {channelChatList && channelChatList?.length > 0 ? (
                  channelChatList?.map((item, i) => (
                    <Box
                      key={i}
                      onClick={() => handleSelectedChannelItem(item, "posts")}
                      className={`w-full flex justify-between mb-2 px-2`}
                      sx={{
                        cursor: "pointer",
                        backgroundImage: selectedPostItem === item.id ? "linear-gradient(to right, var(--color-green-1), var(--color-green-14))" : "transparent",
                        // borderRadius: "10px",
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.08)",
                        },
                        "&:hover .moreIcon": {
                          visibility: "visible",
                        },
                      }}
                    >
                      <Box className="flex items-center pt-3 pb-3">
                        <MoreIcon onClick={handleClickMoreChannel} className="moreIcon" />
                        <Badge color="secondary" badgeContent={0}>
                          <Box
                            className="relative"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Box sx={profilePic}>
                              <img src={item.icon} alt={"channel"} className="!w-[50px] h-[50px] flex justify-center items-center bg-[#5F6165)]" />
                            </Box>
                          </Box>
                        </Badge>
                        <Box className="flex flex-col ps-3">
                          <Typography variant="h6" className="!text-[13px] !font-bold !capitalize">
                            {item.name}
                          </Typography>
                          <Typography
                            variant="caption"
                            dangerouslySetInnerHTML={{
                              __html:
                                item && item.content && item.content[0] && item.content[0].content && item.content[0].content.length > 71
                                  ? item.content[0].content.slice(0, 71) + "..."
                                  : (item.content && item.content[0] && item.content[0].content) || "No new news",
                            }}
                          ></Typography>
                        </Box>
                      </Box>
                      <Box className="flex flex-col items-center p-3">
                        {item?.content && item.content[0] && (
                          <Typography className=" !text-[12px] text-center text-[#5F6165)]">
                            {moment(item.content[0].updatedAt).isSame(moment(), "day") ? "Today" : moment(item.content[0].updatedAt).format("DD MMM")}
                          </Typography>
                        )}
                        {calculateUnseenNotification(item?.id) > 0 && (
                          <Badge
                            sx={{
                              "& .MuiBadge-badge": {
                                color: "var(--color-white-1)",
                                backgroundColor: "var(--color-green-1)",
                              },
                            }}
                            badgeContent={calculateUnseenNotification(item?.id)}
                            className="mt-4"
                          ></Badge>
                        )}
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                    <Typography>No channel available</Typography>
                  </Box>
                )}
              </Box>

              <Box>
                <Typography sx={{ color: "var(--color-green-1)", fontWeight: 500, fontSize: 20 }} className="px-6 pt-4">
                  Channel Lists
                </Typography>
              </Box>

              <Box className="mt-4">
                {channelList && channelList?.length > 0 ? (
                  channelList?.map((item, i) => (
                    <Box
                      key={i}
                      onClick={() => handleSelectedChannelItem(item, "list")}
                      className={`w-full flex justify-between mb-2 px-2`}
                      sx={{
                        cursor: "pointer",
                        backgroundImage: selectedChannelItem === item.id ? "linear-gradient(to right, var(--color-green-1), var(--color-green-14))" : "transparent",
                        // borderRadius: "10px",
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.08)",
                        },
                        "&:hover .moreIcon": {
                          visibility: "visible",
                        },
                      }}
                    >
                      <Box className="flex items-center pt-3 pb-3">
                        <MoreIcon onClick={handleClickMoreChannel} className="moreIcon" />
                        <Badge color="secondary" badgeContent={0}>
                          <Box
                            className="relative"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Box sx={profilePic}>
                              <img src={item.icon} alt={"channel"} className="!w-[50px] h-[50px] flex justify-center items-center bg-[#5F6165)]" />
                            </Box>
                          </Box>
                        </Badge>
                        <Box className="flex flex-col ps-3">
                          <Typography variant="h6" className="!text-[13px] !font-bold !capitalize">
                            {item.name}
                          </Typography>
                          <Typography
                            variant="caption"
                            dangerouslySetInnerHTML={{
                              __html:
                                item && item.content && item.content[0] && item.content[0].content && item.content[0].content.length > 71
                                  ? item.content[0].content.slice(0, 71) + "..."
                                  : (item.content && item.content[0] && item.content[0].content) || "No new news",
                            }}
                          ></Typography>
                        </Box>
                      </Box>
                      <Box className="flex flex-col items-center p-3">
                        {item?.content && item.content[0] && (
                          <Typography className=" !text-[12px] text-center text-[#5F6165)]">
                            {moment(item.content[0].updatedAt).isSame(moment(), "day") ? "Today" : moment(item.content[0].updatedAt).format("DD MMM")}
                          </Typography>
                        )}
                        {calculateUnseenNotification(item?.id) > 0 && (
                          <Badge
                            sx={{
                              "& .MuiBadge-badge": {
                                color: "var(--color-white-1)",
                                backgroundColor: "var(--color-green-1)",
                              },
                            }}
                            badgeContent={calculateUnseenNotification(item?.id)}
                            className="mt-4"
                          ></Badge>
                        )}
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                    <Typography>No channel available</Typography>
                  </Box>
                )}
              </Box>
            </Fragment>
          )}
        </Box>
      </Box>
      <MoreChannelPopover {...{ moreChannelOpen, anchorElMoreChannel, handleCloseMoreChannel }} />
    </Fragment>
  );
});

export default ChannelFullScreenDrawer;
