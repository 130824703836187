const { createSlice } = require("@reduxjs/toolkit");

const subscriptionSlice = createSlice({
    name: "subscription",
    initialState: {
        standard: [],
        pro: [],
        vip: [],
        cryptoCurrency: [],
        selectedPackage: {},
        package: [],
        subscriptionData: []
    },
    reducers: {
        setPackage: (state, action) => {
            state.package = action.payload
        },
        setStandardSubscription: (state, action) => {
            state.standard = action.payload;
        },
        setProSubscription: (state, action) => {
            state.pro = action.payload;
        },
        setVIPSubscription: (state, action) => {
            state.vip = action.payload;
        },
        setCryptoCurrency: (state, action) => {
            state.cryptoCurrency = action.payload;
        },
        setSelectedPackage: (state, action) => {
            state.selectedPackage = action.payload;
        },
        setSubscriptionData: (state, action) => {
            state.subscriptionData = action.payload;
        }
    }
})
export const { setStandardSubscription, setProSubscription, setVIPSubscription, setCryptoCurrency, setSelectedPackage, setPackage, setSubscriptionData } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;