import { Box, Button, FormControl, Grid, Pagination, TextField, useTheme } from "@mui/material";

import { useFormik } from "formik";
import Layout from "layouts/Layout";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AccountListTable from "./AccountListTable";

const AccountList = memo(() => {
  const all_accounts = useSelector((state) => state.auth.accounts);
  const [data, setData] = useState([]);
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(data?.length / itemsPerPage);

  const formik = useFormik({
    initialValues: {
      account: "",
    },
    onSubmit: async (values) => {
      try {
        const filteredData = all_accounts.filter((i) => i.account_number.toString().includes(values.account));
        setData(filteredData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    onReset: () => {
      setData(all_accounts);
    },
  });

  useEffect(() => {
    setData(all_accounts);
  }, [all_accounts]);

  // Handle page change
  const handlePageChange = (_, value) => {
    setCurrentPage(value);
  };

  // Sliced data for the current page
  const currentData = data?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <Layout>
      <Box className="p-5 space-y-10">
        <Box
          className="rounded-md p-5 !pb-0"
          sx={{
            backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
          }}
        >
          <Box className="flex justify-between items-center mb-12">
            <Box>Filter Reports</Box>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Grid container columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      height: "100%",
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                  }}
                  size="small"
                >
                  <TextField
                    type="text"
                    label="Account number"
                    placeholder=""
                    size="small"
                    variant="outlined"
                    name="account"
                    value={formik.values.account}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Button
                  type="reset"
                  onClick={() => formik.handleReset()}
                  variant="contained"
                  disableRipple
                  fullWidth
                  sx={{
                    marginTop: "2px",
                    backgroundColor: "var(--color-green-5)",
                    ":hover": {
                      backgroundColor: "var(--color-green-4)",
                    },
                  }}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Button
                  type="submit"
                  variant="contained"
                  disableRipple
                  fullWidth
                  sx={{
                    marginTop: "2px",
                    backgroundColor: "var(--color-green-1)",
                    ":hover": {
                      backgroundColor: "var(--color-green-7)",
                    },
                  }}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </form>
        </Box>

        <Box
          className="rounded-md p-5"
          sx={{
            backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <AccountListTable data={currentData} />
            </Grid>
          </Grid>

          <Box className="flex justify-center items-center mt-1">
            {data?.length > 0 && (
              <Box>
                <Pagination count={totalPages} variant="outlined" shape="rounded" page={currentPage} onChange={handlePageChange} size={"medium"} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
});

export default AccountList;
