import { Box, Grid, Typography, useTheme } from "@mui/material";
import AccountInfoLoader from "components/SkeletonLoader/AccountInfoLoader";
import moment from "moment";
import React, { Fragment, memo } from "react";
import { useSelector } from "react-redux";

const AccountInfo = () => {
  const pamUser = useSelector((state) => state.pam.pamUserDetails);
  const pamUserDetailsLoader = useSelector((state) => state.loader.pamUserDetailsLoader);
  const theme = useTheme();
  return (
    <Fragment>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        {pamUserDetailsLoader ? (
          <AccountInfoLoader />
        ) : (
          <Box
            sx={{
              border: "1px solid rgba(0, 208, 148, 0.3)",
              background:
                theme.palette.mode === "dark" ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)" : "var(--color-green-10)",
              borderRadius: "10px",
            }}
            className="!p-[8px]"
          >
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">Total Trades</Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser?.total_trades}
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">Total Copier</Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser?.slaves?.total ?? 0}
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">With Us</Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {moment().diff(pamUser?.created_at, "days")} Days
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">Leverage</Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser?.account?.leverage}
              </Typography>
            </Box>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        {pamUserDetailsLoader ? (
          <AccountInfoLoader />
        ) : (
          <Box
            sx={{
              border: "1px solid rgba(0, 208, 148, 0.3)",
              background:
                theme.palette.mode === "dark" ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)" : "var(--color-green-10)",
              borderRadius: "10px",
            }}
            className="!p-[8px]"
          >
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">Account Number</Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser?.account_number}
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">User Name</Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal !capitalize">
                {pamUser?.name}
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">Email</Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser?.email}
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">Minimum Deposit</Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser?.min_deposit} USD
              </Typography>
            </Box>
          </Box>
        )}
      </Grid>
    </Fragment>
  );
};

export default memo(AccountInfo);
