import Search from "@mui/icons-material/Search";
import { Box, FormControl, Grid, InputAdornment, Tab, Tabs, TextField, Typography, useTheme } from "@mui/material";
import { americasTab, asiaPacificTab, europeTab, middleEastTab, timeZoneTab } from "data/calenderFilterData";
import React, { memo, useEffect, useState } from "react";
import { BiLoaderCircle } from "react-icons/bi";
import { BsCheck2 } from "react-icons/bs";

const FilterTimeZone = ({ setOpenTime, setActiveFilter }) => {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState(0);
  const [tabSearch, setSearchTab] = useState("");
  const tabSearchHandler = (text) => {
    setSearchTab(text);
    setTabIndex(0);
  };
  const handleTabChange = (_, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const filteredTabs = timeZoneTab.filter((tab) => tab.title.toLowerCase().includes(tabSearch.toLowerCase()));

  useEffect(() => {
    if (tabIndex >= filteredTabs?.length) {
      setTabIndex(0);
    }
  }, [filteredTabs?.length, tabIndex]);

  return (
    <Grid
      spacing={2}
      container
      marginTop="1px"
      sx={{
        background:
          theme.palette.mode === "dark"
            ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)"
            : "var(--color-green-10)",
        overflow: "hidden",
      }}
      className="h-[450px] lg:h-80"
    >
      <Grid item xs={12} md={3}>
        <Box>
          <Box className="mb-2 bg-[var(--color-green-13)] cursor-pointer hover:bg-[#00C28B] transition">
            <Box className="flex items-center gap-1 h-[30px] box-border p-3">
              <Box className="mt-1">
                <BiLoaderCircle className="text-[var(--color-black-2)]" />
              </Box>
              <Box className="text-[14px] text-[var(--color-black-2)] font-medium">Auto detect</Box>
              <Box className="mt-2 ml-2">
                <BsCheck2 className="text-[var(--color-black-2)]" />
              </Box>
            </Box>
          </Box>
          <Box className="mb-2">
            <FormControl
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "0px",
                  height: "30px",
                  width: "100%",
                  color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-2)",
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)"
                      : "var(--color-green-10)",
                  "& fieldset": {
                    border: "1px solid var(--color-gray-1)",
                  },
                  "&:hover fieldset": {
                    border: "1px solid var(--color-green-1)",
                  },
                  "&.Mui-focused fieldset": {
                    border: `1px solid var(--color-green-2)`,
                  },
                },
              }}
            >
              <TextField
                onChange={(e) => tabSearchHandler(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search
                        sx={{
                          color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                        }}
                      />
                    </InputAdornment>
                  ),
                  sx: {
                    "& input::placeholder": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      opacity: 1,
                      fontSize: "12px",
                    },
                  },
                }}
                placeholder="Search"
                variant="outlined"
              />
            </FormControl>
          </Box>
          <Tabs
            orientation="vertical"
            centered
            value={tabIndex}
            onChange={handleTabChange}
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "transparent",
              },
              "& .MuiTab-root": {
                display: "flex",
                alignItems: "flex-start",
              },
            }}
          >
            {filteredTabs?.map((item) => (
              <Tab
                key={item.id}
                disableRipple
                sx={{
                  maxWidth: "none",
                  minWidth: "none",
                  minHeight: "25px",
                  "&.Mui-selected": {
                    background: "var(--color-green-12)",
                  },
                }}
                className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-normal"
                label={item.title}
              />
            ))}
          </Tabs>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={9}
        className="p-5 mirror-trade-global-scrollbar h-full overflow-y-auto"
        onClick={() => {
          setOpenTime(false);
          setActiveFilter("");
        }}
      >
        {tabIndex === 0 &&
          americasTab?.map((item) => (
            <Box sx={{ borderBottom: "1px solid var(--color-black-6)" }} className="space-y-[1px] my-1" key={item.id}>
              <Typography className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-normal" component={"h5"}>
                {item.timeZone}
              </Typography>
              <Typography className="dark:!text-[var(--color-white-3)] !text-[var(--color-black-2)] !font-bold !text-[12px]" component={"p"}>
                {item.title}
              </Typography>
              <Typography className="dark:!text-[var(--color-white-3)] !text-[var(--color-black-2)] !text-[12px]" component={"p"}>
                {item.des}
              </Typography>
            </Box>
          ))}
        {tabIndex === 1 &&
          europeTab?.map((item) => (
            <Box sx={{ borderBottom: "1px solid var(--color-black-6)" }} className="space-y-[1px] my-1" key={item.id}>
              <Typography className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-normal" component={"h5"}>
                {item.timeZone}
              </Typography>
              <Typography className="dark:!text-[var(--color-white-3)] !text-[var(--color-black-2)] !font-bold !text-[12px]" component={"p"}>
                {item.title}
              </Typography>
              <Typography className="dark:!text-[var(--color-white-3)] !text-[var(--color-black-2)] !text-[12px]" component={"p"}>
                {item.des}
              </Typography>
            </Box>
          ))}
        {tabIndex === 2 &&
          middleEastTab?.map((item) => (
            <Box sx={{ borderBottom: "1px solid var(--color-black-6)" }} className="space-y-[1px] my-1" key={item.id}>
              <Typography className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-normal" component={"h5"}>
                {item.timeZone}
              </Typography>
              <Typography className="dark:!text-[var(--color-white-3)] !text-[var(--color-black-2)] !font-bold !text-[12px]" component={"p"}>
                {item.title}
              </Typography>
              <Typography className="dark:!text-[var(--color-white-3)] !text-[var(--color-black-2)] !text-[12px]" component={"p"}>
                {item.des}
              </Typography>
            </Box>
          ))}
        {tabIndex === 3 &&
          asiaPacificTab?.map((item) => (
            <Box sx={{ borderBottom: "1px solid var(--color-black-6)" }} className="space-y-[1px] my-1" key={item.id}>
              <Typography className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-normal" component={"h5"}>
                {item.timeZone}
              </Typography>
              <Typography className="dark:!text-[var(--color-white-3)] !text-[var(--color-black-2)] !font-bold !text-[12px]" component={"p"}>
                {item.title}
              </Typography>
              <Typography className="dark:!text-[var(--color-white-3)] !text-[var(--color-black-2)] !text-[12px]" component={"p"}>
                {item.des}
              </Typography>
            </Box>
          ))}
      </Grid>
    </Grid>
  );
};

export default memo(FilterTimeZone);
