import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Pagination,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { CloudOutlined } from "@mui/icons-material";

const ForexAnalysis = ({ handleChangePage, page }) => {
  const theme = useTheme();
  const posts = useSelector((state) => state.posts.posts);
  const postLoading = useSelector((state) => state.loader.postLoading);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={4}>
      {postLoading ? (
        <Box className="w-full h-screen flex justify-center items-center">
          <CircularProgress color="success" size={50} />
        </Box>
      ) : (
        <>
          {posts?.data?.length > 0 ? (
            <>
              {posts?.data?.map((post, i) => (
                <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    sx={{
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor:
                        theme.palette.mode === "dark"
                          ? "rgba(65, 255, 200, 0.31)"
                          : "rgba(0, 208, 148, 0.30)",
                      background:
                        theme.palette.mode === "dark"
                          ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)"
                          : "var(--color-green-10)",
                      filter:
                        theme.palette.mode === "dark"
                          ? "drop-shadow(0px -13px 50.6px #051c14)"
                          : "drop-shadow(0px -13px 21.9px rgba(5, 28, 20, 0.07))",
                      backdropFilter: "blur(9px)",
                      borderRadius: "10px",
                    }}
                  >
                    <CardMedia
                      className="!h-64 object-cover"
                      component="img"
                      alt="green iguana"
                      image={post?.image}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        className="dark:!text-[var(--color-white-1)] !text-xl !text-[var(--color-black-2)]"
                        sx={{
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                        }}
                      >
                        {post?.title}...
                      </Typography>
                      <Typography
                        variant="body2"
                        className="!text-[var(--color-gray-1)]"
                        sx={{
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 3,
                        }}
                        dangerouslySetInnerHTML={{ __html: post?.post }}
                      ></Typography>
                    </CardContent>
                    <CardActions className="flex justify-between items-center">
                      <Link to={`/posts/${post?.id}`}>
                        <Button
                          sx={{
                            textTransform: "capitalize",
                            padding: "10px",
                            color:
                              theme.palette.mode === "dark"
                                ? "var(--color-text-2)"
                                : "var(--color-white-1)",
                            textAlign: "center",
                            fontFamily: "Open Sans",
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "14px",
                            borderRadius: "4px",
                            background:
                              theme.palette.mode === "dark"
                                ? "var(--color-green-1)"
                                : "var(--color-black-5)",
                            boxShadow:
                              "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
                            "&:hover": {
                              background:
                                theme.palette.mode === "dark"
                                  ? "var(--color-green-1)"
                                  : "var(--color-black-5)",
                            },
                          }}
                          size="small"
                        >
                          See More
                        </Button>
                      </Link>
                      <Typography className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[13px] font-[Poppins]">
                        {post?.published_date
                          ? moment(post?.published_date).format("MMMM DD, YYYY")
                          : moment(post?.updated_at).format("MMMM DD, YYYY")}
                      </Typography>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </>
          ) : (
            <Box className="flex justify-center items-center flex-col !h-screen !w-full">
              <CloudOutlined sx={{ fontSize: "40px" }} />
              <Typography
                className="text-center !mt-1"
                sx={{
                  color:
                    theme.palette.mode === "dark"
                      ? "var(--color-white-1)"
                      : "var(--color-green-3)",
                }}
              >
                No data found.
              </Typography>
            </Box>
          )}
        </>
      )}
      {posts?.data?.length > 0 && (
        <Grid item xs={12} className="flex justify-center items-center">
          <Pagination
            count={posts?.last_page}
            variant="outlined"
            shape="rounded"
            page={page}
            onChange={handleChangePage}
            size={isSmallScreen ? "small" : "medium"}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default memo(ForexAnalysis);
