import CloseIcon from "@mui/icons-material/Close";
import { List, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
// Menu Icons
import { Feed, KeyboardArrowDown, KeyboardArrowUp, PeopleAltOutlined } from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListIcon from "@mui/icons-material/List";
import { navigationMenu } from "data/navigationMenu";
import { enqueueSnackbar } from "notistack";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logIn } from "redux/features/auth/authSlice";
import { setDrawer } from "redux/features/drawer/drawerSlice";
import { refetchAuth } from "server/auth";
import { ibRequest } from "server/ib_dashboard";
import activeIcon from "../../assets/icons/activeIcon.svg";
import affiliateIcon from "../../assets/icons/affiliate.svg";
import applyIcon from "../../assets/icons/apply.svg";
import brokerIcon from "../../assets/icons/broker.svg";
import calenderIcon from "../../assets/icons/calender.svg";
import dividerIcon from "../../assets/icons/divider.svg";
import educationIcon from "../../assets/icons/education.svg";
import pammDashIcon from "../../assets/icons/pamm.svg";

const MobileSidebar = ({ open, handleDrawerClose }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const permission = useSelector((state) => state.permission.permission);
  const theme = useTheme();
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = React.useState("Dashboard");

  const menuHandler = (active) => {
    setActiveMenu(active);
  };

  React.useEffect(() => {
    const matchingRoute = navigationMenu.find((item) => location.pathname === item.routeUrl);
    if (matchingRoute) {
      setActiveMenu(matchingRoute.navigationName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const [isDropdownOpen, setDropdownOpen] = React.useState(false);
  const [isMenuSelected, setIsMenuSelected] = React.useState("");
  const handleAffiliateButtonClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const [isDropdownOpenReport, setDropdownOpenReport] = React.useState(false);
  const [isMenuSelectedReport, setIsMenuSelectedReport] = React.useState("");
  const handleReportButtonClick = () => {
    setDropdownOpenReport(!isDropdownOpenReport);
  };

  const [isDropdownOpenEducation, setDropdownOpenEducation] = React.useState(false);
  const [isMenuSelectedEducation, setIsMenuSelectedEducation] = React.useState("");
  const handleEducationOpenOrClose = () => {
    setDropdownOpenEducation(!isDropdownOpenEducation);
  };

  const [isDropdownOpenCalender, setDropdownOpenCalender] = React.useState(false);
  const [isMenuSelectedCalender, setIsMenuSelectedCalender] = React.useState("");
  const handleCalenderOpenOrClose = () => {
    setDropdownOpenCalender(!isDropdownOpenCalender);
  };

  const [isDropdownOpenVideoAcademy, setDropdownOpenVideoAcademy] = React.useState(false);
  const [isMenuSelectedVideoAcademy, setIsMenuSelectedVideoAcademy] = React.useState("");
  const handleVideoAcademyOpenOrClose = () => {
    setDropdownOpenVideoAcademy(!isDropdownOpenVideoAcademy);
  };

  React.useEffect(() => {
    if (location.pathname === "/advanced-technical") {
      setDropdownOpenEducation(true);
      setIsMenuSelectedEducation("Advanced Technical Analysis");
    } else if (location.pathname === "/crypto-trading") {
      setDropdownOpenEducation(true);
      setIsMenuSelectedEducation("Crypto Trading");
    } else if (location.pathname === "/crypto-fundamental") {
      setDropdownOpenEducation(true);
      setIsMenuSelectedEducation("Cryptocurrency Fundamentals");
    } else if (location.pathname === "/intro-financial-market") {
      setDropdownOpenEducation(true);
      setIsMenuSelectedEducation("Introduction to Financial Markets");
    } else if (location.pathname === "/technical-analysis-essential") {
      setDropdownOpenEducation(true);
      setIsMenuSelectedEducation("Technical Analysis Essentials");
    } else if (location.pathname === "/trading-signals") {
      setDropdownOpenEducation(true);
      setIsMenuSelectedEducation("Trading Signals");
    } else if (location.pathname === "/technical-analysis-articles") {
      setDropdownOpenEducation(true);
      setIsMenuSelectedEducation("Technical Analysis Articles");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  React.useEffect(() => {
    if (location.pathname === "/advanced-technical-video") {
      setDropdownOpenVideoAcademy(true);
      setIsMenuSelectedVideoAcademy("Advanced Technical Analysis");
    } else if (location.pathname === "/crypto-trading-video") {
      setDropdownOpenVideoAcademy(true);
      setIsMenuSelectedVideoAcademy("Crypto Trading");
    } else if (location.pathname === "/crypto-fundamental-video") {
      setDropdownOpenVideoAcademy(true);
      setIsMenuSelectedVideoAcademy("Cryptocurrency Fundamentals");
    } else if (location.pathname === "/intro-financial-market-video") {
      setDropdownOpenVideoAcademy(true);
      setIsMenuSelectedVideoAcademy("Introduction to Financial Markets");
    } else if (location.pathname === "/expert-technical-analysis") {
      setDropdownOpenVideoAcademy(true);
      setIsMenuSelectedVideoAcademy("Expert Technical Analysis");
    } else if (location.pathname === "/metaTrader-tutorials") {
      setDropdownOpenVideoAcademy(true);
      setIsMenuSelectedVideoAcademy("MetaTrader Tutorials");
    } else if (location.pathname === "/technical-analysis-essentials") {
      setDropdownOpenVideoAcademy(true);
      setIsMenuSelectedVideoAcademy("Technical Analysis Essentials");
    } else if (location.pathname === "/market-highlights-TV") {
      setDropdownOpenVideoAcademy(true);
      setIsMenuSelectedVideoAcademy("Market Highlights TV");
    } else if (location.pathname === "/market-research-videos") {
      setDropdownOpenVideoAcademy(true);
      setIsMenuSelectedVideoAcademy("Market Research Videos");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  React.useEffect(() => {
    if (location.pathname === "/economic-calendar") {
      setDropdownOpenCalender(true);
      setIsMenuSelectedCalender("Economic Calendar");
    } else if (location.pathname === "/cryptocurrency-calender") {
      setDropdownOpenCalender(true);
      setIsMenuSelectedCalender("Crypto Calendar");
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (location.pathname === "/my-clients") {
      setDropdownOpen(true);
      setIsMenuSelected("my-clients");
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (location.pathname === "/commission") {
      setDropdownOpenReport(true);
      setIsMenuSelectedReport("commission");
    }
  }, [location.pathname]);

  const handleIbRequest = async () => {
    dispatch(setDrawer(false));
    if (user?.ib_access === 0) {
      const result = await ibRequest();
      if (result?.data?.status) {
        enqueueSnackbar(result.data.message, { variant: "success" });
        const resultRefetch = await refetchAuth();
        if (resultRefetch?.data?.status) {
          localStorage.setItem("auth-token", resultRefetch?.data?.token);
          dispatch(logIn({ ...resultRefetch?.data?.data }));
        }
      }
    } else if (user?.ib_access === 1) {
      navigate("/ib-dashboard");
    } else if (user?.ib_access === 2) {
      enqueueSnackbar("IB dashboard request already submitted.Your request is pending.", { variant: "warning" });
    }
  };

  return (
    <Box container="true" display={{ xs: "block", sm: "none" }} sx={{ width: "100%", height: "100vh", bgcolor: "#061F16" }}>
      <IconButton disableRipple sx={{ color: "var(--color-green-1)" }} onClick={handleDrawerClose}>
        {theme.direction === "ltr" ? <CloseIcon /> : <CloseIcon />}
        <Typography sx={{ color: "var(--color-white-1)" }}>Compact</Typography>
      </IconButton>
      <List>
        <Typography
          onClick={() => {
            menuHandler("Dashboard");
            navigate("/");
            dispatch(setDrawer(false));
          }}
          sx={{
            display: "flex",
            alignItems: "normal",
            alignContent: "center",
            paddingTop: "8px",
            cursor: "pointer",
            fontFamily: "Anek Telugu",
            fontSize: "15px",
          }}
        >
          <Typography
            component={"span"}
            sx={{
              marginTop: "20px",
              visibility: activeMenu === "Dashboard" ? "visible" : "hidden",
            }}
          >
            <img src={activeIcon} alt="icon"></img>
          </Typography>
          <Typography
            component={"span"}
            sx={{
              width: "100%",
              marginTop: "10px",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              color: activeMenu === "Dashboard" ? "var(--color-black-8)" : "var(--color-white-1)",
              backgroundColor: activeMenu === "Dashboard" ? "var(--color-green-2)" : "transparent",
            }}
          >
            <Typography
              component={"span"}
              sx={{
                height: "45px",
                alignItems: "center",
                display: "flex",
                paddingLeft: "10px",
                marginLeft: "5px",
              }}
            >
              <Typography
                container="true"
                component={"span"}
                sx={{
                  paddingRight: "10px",
                  paddingLeft: "5px",
                  paddingTop: "3px",
                }}
              >
                <DashboardIcon />
              </Typography>
              <Typography component={"span"} sx={{ paddingLeft: "10px" }}>
                Dashboard
              </Typography>
              <Divider variant="inset" component="li" />
            </Typography>
          </Typography>
        </Typography>

        {/* Master Dashboard */}
        {user?.role === "pamm" && (
          <Typography
            onClick={() => {
              menuHandler("PAMM Dashboard");
              navigate("/pamm-dashboard");
              dispatch(setDrawer(false));
            }}
            sx={{
              display: "flex",
              alignItems: "normal",
              alignContent: "center",
              paddingTop: "8px",
              cursor: "pointer",
              fontFamily: "Anek Telugu",
              fontSize: "15px",
            }}
          >
            <Typography
              component={"span"}
              sx={{
                marginTop: "20px",
                visibility: activeMenu === "PAMM Dashboard" ? "visible" : "hidden",
              }}
            >
              <img src={activeIcon} alt="icon"></img>
            </Typography>
            <Typography
              component={"span"}
              sx={{
                width: "100%",
                marginTop: "10px",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
                color: activeMenu === "PAMM Dashboard" ? "var(--color-black-8)" : "var(--color-white-1)",
                backgroundColor: activeMenu === "PAMM Dashboard" ? "var(--color-green-2)" : "transparent",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  height: "45px",
                  alignItems: "center",
                  display: "flex",
                  paddingLeft: "10px",
                  marginLeft: "5px",
                }}
              >
                <Typography
                  container="true"
                  component={"span"}
                  sx={{
                    paddingRight: "10px",
                    paddingLeft: "0px",
                    paddingTop: "3px",
                  }}
                >
                  <img src={pammDashIcon} alt="pammDashIcon" />
                </Typography>
                <Typography component={"span"} sx={{ paddingLeft: "10px" }}>
                  Master Dashboard
                </Typography>
                <Divider variant="inset" component="li" />
              </Typography>
            </Typography>
            <img
              src={dividerIcon}
              alt="bottom icon"
              style={{
                width: "90%",
                position: "absolute",
                marginLeft: "15px",
              }}
            />
          </Typography>
        )}

        {/* Education Route */}
        {permission?.includes("education") && (
          <React.Fragment>
            <Typography onClick={handleEducationOpenOrClose} component={"span"} variant="span">
              <Typography
                component={"div"}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "none",
                  padding: "10px 20px",
                  background: "transparent",
                  marginLeft: "9px",
                  borderRadius: "7px",
                  cursor: "pointer",
                  marginTop: "10px",
                  paddingTop: user?.role === "pamm" ? "22px" : "22px",
                  paddingBottom: "18px",
                  "&:active": {
                    background: "transparent",
                  },
                }}
              >
                <Typography component={"div"} sx={{ display: "flex", alignItems: "center" }}>
                  <img src={educationIcon} alt="education Icon" sx={{ paddingRight: "10px" }} />
                  <Typography
                    sx={{
                      marginLeft: "18px",
                      color: "var(--color-white-1)",
                      textTransform: "capitalize",
                    }}
                  >
                    Education
                  </Typography>
                </Typography>
                {isDropdownOpenEducation ? (
                  <KeyboardArrowUp className="!mr-4 !text-[var(--color-white-1)]" />
                ) : (
                  <KeyboardArrowDown className="!mr-4 !text-[var(--color-white-1)]" />
                )}
              </Typography>
              <img
                src={dividerIcon}
                alt="bottom icon"
                style={{
                  width: "90%",
                  position: "absolute",
                  marginLeft: "15px",
                }}
              />
            </Typography>

            {isDropdownOpenEducation && (
              <>
                <Typography
                  component={"span"}
                  title="Advanced Technical Analysis"
                  variant="span"
                  onClick={() => {
                    navigate("/advanced-technical");
                    setIsMenuSelectedEducation("Advanced Technical Analysis");
                    dispatch(setDrawer(false));
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedEducation === "Advanced Technical Analysis" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedEducation === "Advanced Technical Analysis" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <img src={educationIcon} alt="education Icon" sx={{ paddingRight: "10px" }} />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Advanced Technical...
                    </Typography>
                  </Typography>
                  <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "90%",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  />
                </Typography>
                <Typography
                  component={"span"}
                  title="Crypto Trading and Investing"
                  variant="span"
                  onClick={() => {
                    navigate("/crypto-trading");
                    setIsMenuSelectedEducation("Crypto Trading");
                    dispatch(setDrawer(false));
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedEducation === "Crypto Trading" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedEducation === "Crypto Trading" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <img src={educationIcon} alt="education Icon" sx={{ paddingRight: "10px" }} />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Crypto Trading And...
                    </Typography>
                  </Typography>
                  <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "90%",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  />
                </Typography>
                <Typography
                  title="Cryptocurrency Fundamentals"
                  component={"span"}
                  variant="span"
                  onClick={() => {
                    navigate("/crypto-fundamental");
                    setIsMenuSelectedEducation("Cryptocurrency Fundamentals");
                    dispatch(setDrawer(false));
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedEducation === "Cryptocurrency Fundamentals" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedEducation === "Cryptocurrency Fundamentals" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <img src={educationIcon} alt="education Icon" sx={{ paddingRight: "10px" }} />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Cryptocurrency Fun...
                    </Typography>
                  </Typography>
                  <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "90%",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  />
                </Typography>
                <Typography
                  title="Introduction to Financial Markets"
                  component={"span"}
                  variant="span"
                  onClick={() => {
                    navigate("/intro-financial-market");
                    setIsMenuSelectedEducation("Introduction to Financial Markets");
                    dispatch(setDrawer(false));
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedEducation === "Introduction to Financial Markets" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedEducation === "Introduction to Financial Markets" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <img src={educationIcon} alt="education Icon" sx={{ paddingRight: "10px" }} />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Introduction to Fina...
                    </Typography>
                  </Typography>
                  <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "90%",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  />
                </Typography>
                <Typography
                  title="Technical Analysis Essentials"
                  component={"span"}
                  variant="span"
                  onClick={() => {
                    navigate("/technical-analysis-essential");
                    setIsMenuSelectedEducation("Technical Analysis Essentials");
                    dispatch(setDrawer(false));
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedEducation === "Technical Analysis Essentials" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedEducation === "Technical Analysis Essentials" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <img src={educationIcon} alt="education Icon" sx={{ paddingRight: "10px" }} />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Technical Analysis E...
                    </Typography>
                  </Typography>
                  <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "90%",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  />
                </Typography>
                <Typography
                  component={"span"}
                  variant="span"
                  onClick={() => {
                    navigate("/trading-signals");
                    setIsMenuSelectedEducation("Trading Signals");
                    dispatch(setDrawer(false));
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedEducation === "Trading Signals" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedEducation === "Trading Signals" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <img src={educationIcon} alt="education Icon" sx={{ paddingRight: "10px" }} />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Trading Signals
                    </Typography>
                  </Typography>
                  <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "90%",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  />
                </Typography>
                <Typography
                  component={"span"}
                  title="Technical Analysis Articles"
                  variant="span"
                  onClick={() => {
                    navigate("/technical-analysis-articles");
                    setIsMenuSelectedEducation("Technical Analysis Articles");
                    dispatch(setDrawer(false));
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedEducation === "Technical Analysis Articles" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedEducation === "Technical Analysis Articles" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <img src={educationIcon} alt="education Icon" sx={{ paddingRight: "10px" }} />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Technical Analysis A...
                    </Typography>
                  </Typography>
                  <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "90%",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  />
                </Typography>
              </>
            )}
          </React.Fragment>
        )}

        {/* Video academy Route */}
        {permission?.includes("video academy") && (
          <React.Fragment>
            <Typography onClick={handleVideoAcademyOpenOrClose} component={"span"} variant="span">
              <Typography
                component={"div"}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "none",
                  padding: "10px 20px",
                  background: "transparent",
                  marginLeft: "9px",
                  borderRadius: "7px",
                  cursor: "pointer",
                  marginTop: "10px",
                  paddingTop: "16px",
                  paddingBottom: "18px",
                  "&:active": {
                    background: "transparent",
                  },
                }}
              >
                <Typography component={"div"} sx={{ display: "flex", alignItems: "center" }}>
                  <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                  <Typography
                    sx={{
                      marginLeft: "22px",
                      color: "var(--color-white-1)",
                      textTransform: "capitalize",
                    }}
                  >
                    Video Academy
                  </Typography>
                </Typography>
                {isDropdownOpenVideoAcademy ? (
                  <KeyboardArrowUp className="!mr-4 !text-[var(--color-white-1)]" />
                ) : (
                  <KeyboardArrowDown className="!mr-4 !text-[var(--color-white-1)]" />
                )}
              </Typography>
              <img
                src={dividerIcon}
                alt="bottom icon"
                style={{
                  width: "90%",
                  position: "absolute",
                  marginLeft: "15px",
                }}
              />
            </Typography>

            {isDropdownOpenVideoAcademy && (
              <>
                <Typography
                  component={"span"}
                  title="Advanced Technical Analysis"
                  variant="span"
                  onClick={() => {
                    navigate("/advanced-technical-video");
                    setIsMenuSelectedVideoAcademy("Advanced Technical Analysis");
                    dispatch(setDrawer(false));
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedVideoAcademy === "Advanced Technical Analysis" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedVideoAcademy === "Advanced Technical Analysis" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Advanced Technical...
                    </Typography>
                  </Typography>
                  <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "90%",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  />
                </Typography>
                <Typography
                  component={"span"}
                  title="Crypto Trading and Investing"
                  variant="span"
                  onClick={() => {
                    navigate("/crypto-trading-video");
                    setIsMenuSelectedVideoAcademy("Crypto Trading");
                    dispatch(setDrawer(false));
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedVideoAcademy === "Crypto Trading" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedVideoAcademy === "Crypto Trading" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Crypto Trading And...
                    </Typography>
                  </Typography>
                  {/* <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "90%",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  /> */}
                </Typography>
                <Typography
                  title="Cryptocurrency Fundamentals"
                  component={"span"}
                  variant="span"
                  onClick={() => {
                    navigate("/crypto-fundamental-video");
                    setIsMenuSelectedVideoAcademy("Cryptocurrency Fundamentals");
                    dispatch(setDrawer(false));
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedVideoAcademy === "Cryptocurrency Fundamentals" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedVideoAcademy === "Cryptocurrency Fundamentals" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Cryptocurrency Fun...
                    </Typography>
                  </Typography>
                  <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "90%",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  />
                </Typography>
                <Typography
                  title="Introduction to Financial Markets"
                  component={"span"}
                  variant="span"
                  onClick={() => {
                    navigate("/intro-financial-market-video");
                    setIsMenuSelectedVideoAcademy("Introduction to Financial Markets");
                    dispatch(setDrawer(false));
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedVideoAcademy === "Introduction to Financial Markets" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedVideoAcademy === "Introduction to Financial Markets" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Introduction to Fina...
                    </Typography>
                  </Typography>
                  <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "90%",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  />
                </Typography>
                <Typography
                  title="Expert Technical Analysis"
                  component={"span"}
                  variant="span"
                  onClick={() => {
                    navigate("/expert-technical-analysis");
                    setIsMenuSelectedVideoAcademy("Expert Technical Analysis");
                    dispatch(setDrawer(false));
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedVideoAcademy === "Expert Technical Analysis" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedVideoAcademy === "Expert Technical Analysis" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Expert Technical Ana...
                    </Typography>
                  </Typography>
                  <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "90%",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  />
                </Typography>
                <Typography
                  component={"span"}
                  variant="span"
                  onClick={() => {
                    navigate("/metaTrader-tutorials");
                    setIsMenuSelectedVideoAcademy("MetaTrader Tutorials");
                    dispatch(setDrawer(false));
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedVideoAcademy === "MetaTrader Tutorials" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedVideoAcademy === "MetaTrader Tutorials" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      MetaTrader Tutorials
                    </Typography>
                  </Typography>
                  <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "90%",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  />
                </Typography>
                <Typography
                  component={"span"}
                  title="Technical Analysis Essentials"
                  variant="span"
                  onClick={() => {
                    navigate("/technical-analysis-essentials");
                    setIsMenuSelectedVideoAcademy("Technical Analysis Essentials");
                    dispatch(setDrawer(false));
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedVideoAcademy === "Technical Analysis Essentials" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedVideoAcademy === "Technical Analysis Essentials" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Technical Analysis E...
                    </Typography>
                  </Typography>
                  <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "90%",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  />
                </Typography>
                <Typography
                  component={"span"}
                  variant="span"
                  onClick={() => {
                    navigate("/market-highlights-TV");
                    setIsMenuSelectedVideoAcademy("Market Highlights TV");
                    dispatch(setDrawer(false));
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedVideoAcademy === "Market Highlights TV" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedVideoAcademy === "Market Highlights TV" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Market Highlights TV
                    </Typography>
                  </Typography>
                  <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "90%",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  />
                </Typography>
                <Typography
                  component={"span"}
                  title="Market Research Videos"
                  variant="span"
                  onClick={() => {
                    navigate("/market-research-videos");
                    setIsMenuSelectedVideoAcademy("Market Research Videos");
                    dispatch(setDrawer(false));
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedVideoAcademy === "Market Research Videos" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedVideoAcademy === "Market Research Videos" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Market Research Vi...
                    </Typography>
                  </Typography>
                  {/* <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "90%",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  /> */}
                </Typography>
              </>
            )}
          </React.Fragment>
        )}

        {/* Calender */}
        {permission?.includes("calendar") && (
          <React.Fragment>
            <Typography onClick={handleCalenderOpenOrClose} component={"span"} variant="span">
              <Typography
                component={"div"}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "none",
                  padding: "10px 20px",
                  background: "transparent",
                  marginLeft: "9px",
                  borderRadius: "7px",
                  cursor: "pointer",
                  marginTop: "10px",
                  "&:active": {
                    background: "transparent",
                  },
                }}
              >
                <Typography component={"div"} sx={{ display: "flex", alignItems: "center" }}>
                  <img src={calenderIcon} alt="calenderIcon" sx={{ paddingRight: "10px" }} />
                  <Typography
                    sx={{
                      marginLeft: "18px",
                      color: "var(--color-white-1)",
                      textTransform: "capitalize",
                    }}
                  >
                    Calender
                  </Typography>
                </Typography>

                {isDropdownOpenCalender ? <KeyboardArrowUp className="!mr-4 !text-[var(--color-white-1)]" /> : <KeyboardArrowDown className="!mr-4 !text-[var(--color-white-1)]" />}
              </Typography>
              {/* <img
                src={dividerIcon}
                alt="bottom icon"
                style={{
                  width: "90%",
                  position: "absolute",
                  marginLeft: "15px",
                }}
              /> */}
            </Typography>
            {isDropdownOpenCalender && (
              <>
                {permission?.includes("economic calendar") && (
                  <Typography
                    component={"span"}
                    variant="span"
                    onClick={() => {
                      navigate("/economic-calendar");
                      setIsMenuSelectedCalender("Economic Calendar");
                      dispatch(setDrawer(false));
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedCalender === "Economic Calendar" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedCalender === "Economic Calendar" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <img src={calenderIcon} alt="calenderIcon" sx={{ paddingRight: "10px" }} />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Economic Calendar
                      </Typography>
                    </Typography>
                    <img
                      src={dividerIcon}
                      alt="bottom icon"
                      style={{
                        width: "90%",
                        position: "absolute",
                        marginLeft: "15px",
                      }}
                    />
                  </Typography>
                )}
                {permission?.includes("crypto calendar") && (
                  <Typography
                    component={"span"}
                    variant="span"
                    onClick={() => {
                      navigate("/cryptocurrency-calender");
                      setIsMenuSelectedCalender("Crypto Calendar");
                      dispatch(setDrawer(false));
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedCalender === "Crypto Calendar" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedCalender === "Crypto Calendar" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <img src={calenderIcon} alt="calenderIcon" sx={{ paddingRight: "10px" }} />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Crypto Calendar
                      </Typography>
                    </Typography>
                    {/* <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "90%",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  /> */}
                  </Typography>
                )}
              </>
            )}
          </React.Fragment>
        )}
        {permission?.includes("finance news") && (
          <Typography
            onClick={() => {
              menuHandler("Financial News");
              navigate("/financial-news");
              dispatch(setDrawer(false));
            }}
            sx={{
              display: "flex",
              alignItems: "normal",
              alignContent: "center",
              paddingTop: "8px",
              cursor: "pointer",
              fontFamily: "Anek Telugu",
              fontSize: "15px",
            }}
          >
            <Typography
              component={"span"}
              sx={{
                marginTop: "20px",
                visibility: activeMenu === "Financial News" ? "visible" : "hidden",
              }}
            >
              <img src={activeIcon} alt="icon"></img>
            </Typography>
            <Typography
              component={"span"}
              sx={{
                width: "100%",
                marginTop: "10px",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
                color: activeMenu === "Financial News" ? "var(--color-black-8)" : "var(--color-white-1)",
                backgroundColor: activeMenu === "Financial News" ? "var(--color-green-2)" : "transparent",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  height: "45px",
                  alignItems: "center",
                  display: "flex",
                  paddingLeft: "10px",
                  marginLeft: "5px",
                }}
              >
                <Typography
                  container="true"
                  // display={{ sm: "block", xs: "none" }}
                  component={"span"}
                  sx={{
                    paddingRight: "10px",
                    paddingLeft: "0px",
                    paddingTop: "3px",
                  }}
                >
                  <Feed sx={{ color: "#e1e4e3" }} />
                </Typography>
                <Typography component={"span"} sx={{ paddingLeft: "10px" }}>
                  Financial News
                </Typography>
                <Divider variant="inset" component="li" />
              </Typography>
            </Typography>
            <img
              src={dividerIcon}
              alt="bottom icon"
              style={{
                width: "90%",
                position: "absolute",
                marginLeft: "15px",
              }}
            />
          </Typography>
        )}
        {permission?.includes("glosary") && (
          <Typography
            onClick={() => {
              menuHandler("Glossary");
              navigate("/glossary");
              dispatch(setDrawer(false));
            }}
            sx={{
              display: "flex",
              alignItems: "normal",
              alignContent: "center",
              paddingTop: "8px",
              cursor: "pointer",
              fontFamily: "Anek Telugu",
              fontSize: "15px",
            }}
          >
            <Typography
              component={"span"}
              sx={{
                marginTop: "20px",
                visibility: activeMenu === "Glossary" ? "visible" : "hidden",
              }}
            >
              <img src={activeIcon} alt="icon"></img>
            </Typography>
            <Typography
              component={"span"}
              sx={{
                width: "100%",
                marginTop: "10px",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
                color: activeMenu === "Glossary" ? "var(--color-black-8)" : "var(--color-white-1)",
                backgroundColor: activeMenu === "Glossary" ? "var(--color-green-2)" : "transparent",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  height: "45px",
                  alignItems: "center",
                  display: "flex",
                  paddingLeft: "10px",
                  marginLeft: "5px",
                }}
              >
                <Typography
                  container="true"
                  // display={{ sm: "block", xs: "none" }}
                  component={"span"}
                  sx={{
                    paddingRight: "10px",
                    paddingLeft: "0px",
                    paddingTop: "3px",
                  }}
                >
                  <ListIcon sx={{ color: "#e1e4e3" }} />
                </Typography>
                <Typography component={"span"} sx={{ paddingLeft: "10px" }}>
                  Glossary
                </Typography>
                <Divider variant="inset" component="li" />
              </Typography>
            </Typography>
            <img
              src={dividerIcon}
              alt="bottom icon"
              style={{
                width: "90%",
                position: "absolute",
                marginLeft: "15px",
              }}
            />
          </Typography>
        )}

        {permission?.includes("broker portal") && (
          <Typography
            onClick={() => {
              menuHandler("Broker Portal");
              navigate("/broker-portal");
              dispatch(setDrawer(false));
            }}
            sx={{
              display: "flex",
              alignItems: "normal",
              alignContent: "center",
              paddingTop: "8px",
              cursor: "pointer",
              fontFamily: "Anek Telugu",
              fontSize: "15px",
            }}
          >
            <Typography
              component={"span"}
              sx={{
                marginTop: "20px",
                visibility: activeMenu === "Broker Portal" ? "visible" : "hidden",
              }}
            >
              <img src={activeIcon} alt="icon"></img>
            </Typography>
            <Typography
              component={"span"}
              sx={{
                width: "100%",
                marginTop: "10px",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
                color: activeMenu === "Broker Portal" ? "var(--color-black-8)" : "var(--color-white-1)",
                backgroundColor: activeMenu === "Broker Portal" ? "var(--color-green-2)" : "transparent",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  height: "45px",
                  alignItems: "center",
                  display: "flex",
                  paddingLeft: "10px",
                  marginLeft: "5px",
                }}
              >
                <Typography
                  container="true"
                  // display={{ sm: "block", xs: "none" }}
                  component={"span"}
                  sx={{
                    paddingRight: "10px",
                    paddingLeft: "5px",
                    paddingTop: "3px",
                  }}
                >
                  <img src={brokerIcon} alt="broker Icon" />
                </Typography>
                <Typography component={"span"} sx={{ paddingLeft: "10px" }}>
                  Broker Portal
                </Typography>
                <Divider variant="inset" component="li" />
              </Typography>
            </Typography>
            <img
              src={dividerIcon}
              alt="bottom icon"
              style={{
                width: "90%",
                position: "absolute",
                marginLeft: "15px",
              }}
            />
          </Typography>
        )}
        
      {permission?.includes("become an affiliate") && (
        <Typography
          onClick={handleIbRequest}
          component={"button"}
          sx={{
            width: "100%",
            display: "flex",
            border: "none",
            padding: "10px 20px",
            background: "var(--color-green-1)",
            marginLeft: "9px",
            borderRadius: "7px",
            cursor: "pointer",
            transition: "background 0.2s",
            "&:active": {
              background: "var(--color-green-14)",
            },
          }}
        >
          <img src={affiliateIcon} alt="affiliate Icon" sx={{ paddingRight: "10px" }} />
          <Typography sx={{ marginLeft: "15px", color: "var(--color-white-1)", textTransform: "capitalize" }}>
            {user?.ib_access === 0 ? "Become an Affiliate" : user?.ib_access === 1 ? "IB Dashboard" : "IB Requested"}
          </Typography>
        </Typography>
      )}
      {user?.ib_access === 1 && (
        <React.Fragment>
          <Typography onClick={handleAffiliateButtonClick} component={"span"} variant="span">
            <Typography
              component={"div"}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "none",
                padding: "10px 20px",
                background: "transparent",
                marginLeft: "9px",
                borderRadius: "7px",
                cursor: "pointer",
                marginTop: "10px",
                "&:active": {
                  background: "transparent",
                },
              }}
            >
              <Typography component={"div"} sx={{ display: "flex", alignItems: "center" }}>
                <img src={affiliateIcon} alt="affiliate Icon" sx={{ paddingRight: "10px" }} />
                <Typography sx={{ marginLeft: "18px", color: "var(--color-white-1)", textTransform: "capitalize" }}>Affiliate</Typography>
              </Typography>
              {isDropdownOpen ? <KeyboardArrowUp className="!mr-4 !text-[var(--color-white-1)]" /> : <KeyboardArrowDown className="!mr-4 !text-[var(--color-white-1)]" />}
            </Typography>
            <img
              src={dividerIcon}
              alt="bottom icon"
              style={{
                width: "90%",
                position: "absolute",
                marginLeft: "15px",
              }}
            />
          </Typography>

          {isDropdownOpen && (
            <Typography
              component={"span"}
              variant="span"
              onClick={() => {
                navigate("/my-clients");
                setIsMenuSelected("my-clients");
                dispatch(setDrawer(false));
              }}
            >
              <Typography
                component={"button"}
                sx={{
                  width: "100%",
                  display: "flex",
                  border: "none",
                  padding: "10px 20px",
                  background: isMenuSelected === "my-clients" ? "var(--color-green-1)" : "transparent",
                  marginLeft: "9px",
                  borderRadius: "7px",
                  cursor: "pointer",
                  marginTop: "10px",
                  "&:active": {
                    background: isMenuSelected === "my-clients" ? "var(--color-green-1)" : "transparent",
                  },
                }}
              >
                <PeopleAltOutlined className="!text-[var(--color-white-1)]" />
                <Typography sx={{ marginLeft: "18px", color: "var(--color-white-1)", textTransform: "capitalize" }}>My Clients</Typography>
              </Typography>
              <img
                src={dividerIcon}
                alt="bottom icon"
                style={{
                  width: "90%",
                  position: "absolute",
                  marginLeft: "15px",
                }}
              />
            </Typography>
          )}
        </React.Fragment>
      )}
      {user?.ib_access === 1 && (
        <React.Fragment>
          <Typography onClick={handleReportButtonClick} component={"span"} variant="span">
            <Typography
              component={"div"}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "none",
                padding: "10px 20px",
                background: "transparent",
                marginLeft: "9px",
                borderRadius: "7px",
                cursor: "pointer",
                marginTop: "10px",
                "&:active": {
                  background: "transparent",
                },
              }}
            >
              <Typography component={"div"} sx={{ display: "flex", alignItems: "center" }}>
                <img src={affiliateIcon} alt="affiliate Icon" sx={{ paddingRight: "10px" }} />
                <Typography sx={{ marginLeft: "18px", color: "var(--color-white-1)", textTransform: "capitalize" }}>Reports</Typography>
              </Typography>
              {isDropdownOpenReport ? <KeyboardArrowUp className="!mr-4 !text-[var(--color-white-1)]" /> : <KeyboardArrowDown className="!mr-4 !text-[var(--color-white-1)]" />}
            </Typography>
            <img
              src={dividerIcon}
              alt="bottom icon"
              style={{
                width: "90%",
                position: "absolute",
                marginLeft: "15px",
              }}
            />
          </Typography>

          {isDropdownOpenReport && (
            <Typography
              component={"span"}
              variant="span"
              onClick={() => {
                navigate("/commission");
                setIsMenuSelectedReport("commission");
                dispatch(setDrawer(false));
              }}
            >
              <Typography
                component={"button"}
                sx={{
                  width: "100%",
                  display: "flex",
                  border: "none",
                  padding: "10px 20px",
                  background: isMenuSelectedReport === "commission" ? "var(--color-green-1)" : "transparent",
                  marginLeft: "9px",
                  borderRadius: "7px",
                  cursor: "pointer",
                  marginTop: "10px",
                  "&:active": {
                    background: isMenuSelectedReport === "commission" ? "var(--color-green-1)" : "transparent",
                  },
                }}
              >
                <PeopleAltOutlined className="!text-[var(--color-white-1)]" />
                <Typography sx={{ marginLeft: "18px", color: "var(--color-white-1)", textTransform: "capitalize" }}>Commission</Typography>
              </Typography>
              <img
                src={dividerIcon}
                alt="bottom icon"
                style={{
                  width: "90%",
                  position: "absolute",
                  marginLeft: "15px",
                }}
              />
            </Typography>
          )}
        </React.Fragment>
      )}
      </List>

    </Box>
  );
};

export default memo(MobileSidebar);
