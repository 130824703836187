import { Box, Divider, Tab, Tabs } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import EmailRegistration from "./EmailRegistration";
import MT5Registration from "./MT5Registration";
import MT4Registration from "./MT4Registration";
import { serverList } from "server/server";

const Registration = () => {
  const [authType, setAuthType] = useState(0);
  const [servers, setServers] = useState([]);
  const handleAuthTypeTab = (_, newTabIndex) => {
    setAuthType(newTabIndex);
  };

  useEffect(() => {
    serverList().then((res) => {
      if (res?.data?.status) {
        setServers(res.data?.data ?? []);
      }
      console.log();
    });
  }, []);

  return (
    <Box className="w-[90%] md:w-[335px] mx-auto">
      <Tabs
        orientation="horizontal"
        centered
        value={authType}
        onChange={handleAuthTypeTab}
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "transparent",
          },
          "& .MuiTab-root": {},
        }}
      >
        <Tab
          disableRipple
          sx={{
            maxWidth: "none",
            minWidth: "none",
            minHeight: "25px",
            borderRadius: "2px",
            "&.Mui-selected": {
              background: "var(--color-green-12)",
            },
          }}
          className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-normal"
          label="Email"
        />

        <Tab
          disableRipple
          sx={{
            maxWidth: "none",
            minWidth: "none",
            minHeight: "25px",
            borderRadius: "2px",
            "&.Mui-selected": {
              background: "var(--color-green-12)",
            },
          }}
          className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-normal"
          label="MT4"
        />

        <Tab
          disableRipple
          sx={{
            maxWidth: "none",
            minWidth: "none",
            minHeight: "25px",
            borderRadius: "2px",
            "&.Mui-selected": {
              background: "var(--color-green-12)",
            },
          }}
          className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-normal"
          label="MT5"
        />
      </Tabs>
      <Divider sx={{ mb: 2 }} orientation="horizontal" variant="fullWidth" />
      {authType === 0 && <EmailRegistration />}
      {authType === 1 && <MT4Registration servers={servers.filter((i) => i.platform === "mt4")} />}
      {authType === 2 && <MT5Registration servers={servers.filter((i) => i.platform === "mt5")} />}
    </Box>
  );
};

export default memo(Registration);
