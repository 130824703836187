import { createSlice } from "@reduxjs/toolkit";

const pamSlice = createSlice({
    name: "pam",
    initialState: {
        currentPamUserId: "",
        pamUser: {},
        pamUserDetails: {},
        symbols: [],
        slaves: {},
        performanceChart: {},
        profit: 0,
        balanceEquity: {}
    },
    reducers: {
        setCurrentPamUserId: (state, action) => {
            state.currentPamUserId = action.payload;
        },
        setPamUserData: (state, action) => {
            state.pamUser = action.payload;
        },
        setPamUserDataDetails: (state, action) => {
            state.pamUserDetails = action.payload;
        },
        setSymbols: (state, action) => {
            state.symbols = action.payload;
        },
        setMySlaves: (state, action) => {
            state.slaves = action.payload;
        },
        setPerformanceChart: (state, action) => {
            state.performanceChart = action.payload;
        },
        setProfit: (state, action) => {
            state.profit = action.payload;
        },
        setBalanceEquity: (state, action) => {
            state.balanceEquity = action.payload;
        }
    },
});
export const { setPamUserData, setSymbols, setPamUserDataDetails, setCurrentPamUserId, setMySlaves, setPerformanceChart, setProfit, setBalanceEquity } = pamSlice.actions;
export default pamSlice.reducer;
