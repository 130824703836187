import mirrorApi from '../api/mirror_trade_api';
const { getAuthToken } = require("./auth");

export const subscription = async () => {
    try {
        return await mirrorApi.get(`/user/subscriptions`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ subscription ~ error:", error)
    }
};

export const buy_subscription = async (package_id) => {
    try {
        return await mirrorApi.post(`/user/buy-subscription?package_id=${package_id}`, {}, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ constbuy_subscription= ~ error:", error)
    }
};

export const getCryptoCurrency = async () => {
    try {
        return await mirrorApi.get(`/user/get-cryptocurrency`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ getCryptoCurrency ~ error:", error)
    }
};

export const getBlockChain = async (currency = "btc") => {
    try {
        return await mirrorApi.post(`/user/get-brockchain-info?op=instrument&request_data=${currency}`, {}, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
    }
};

export const subscribeByCrypto = async (subscribe) => {
    try {
        return await mirrorApi.post(`/user/subscribe-by-crypto`, subscribe, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
    }
};

// get all subscription
export const getAllSubscription = async (page) => {
    try {
        return await mirrorApi.get(`/reports/user-subscription-report?page=${page}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
    }
};

export const getCryptoWithdrawDetails = async () => {
    try {
        return await mirrorApi.get(`/ib/withdraw/crypto-withdraw-details`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
    }
};

export const cryptoWithdraw = async (withdraw) => {
    try {
        return await mirrorApi.post(`/ib/withdraw/crypto-withdraw`, withdraw, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
    }
};

export const getWithdrawReport = async ({ page = 1, crypto_currency = "", block_chain = "", min_amount = "", max_amount = "", crypto_address = "", transaction_id = "" }) => {
    try {
        return await mirrorApi.get(`/reports/ib-withdraw-report?page=${page}&crypto_currency=${crypto_currency}&block_chain=${block_chain}&min_amount=${min_amount}&max_amount=${max_amount}&crypto_address=${crypto_address}&transaction_id=${transaction_id}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
    }
};