import { Box, Chip, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import CustomAvatar from "components/Auth/CustomAvatar";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveChat, setNavigateChat } from "redux/features/chatSlice/chatSlice";
import { chat_create, friend_request_accept } from "server/chat";
import { socketServiceJoinChat } from "services/socketService";

const FriendList = () => {
  const friendList = useSelector((state) => state.chat.friendList);
  const dispatch = useDispatch();
  const handleUnFriend = async (e, friend_id, unFriend) => {
    e.stopPropagation();
    await friend_request_accept(friend_id, unFriend);
  };

  // handle chat
  const handleChat = async (_, item) => {
    _?.stopPropagation();
    const createChat = await chat_create(item.user.id);
    const ac_chat = {
      id: createChat.data?.data?.id,
      sender_id: createChat.data?.data?.sender_id,
      receiver_id: createChat.data?.data?.receiver_id,
      createdAt: createChat.data?.data?.createdAt,
      user: createChat.data?.data?.receiver,
    }
    if (createChat.data?.status) {
      socketServiceJoinChat(createChat.data.data.id);
      dispatch(setNavigateChat("chat"));
      dispatch(setActiveChat(ac_chat));
    }
  };

  return (
    <Grid
      item
      xs={12}
      className="chat-list-scrollbar"
      sx={{ height: "300px", width: "100%", overflowY: "auto" }}>
      {friendList && friendList.length > 0 ? (
        <List sx={{ width: "100%" }}>
          {friendList.map((item) => (
            <Fragment key={item.id}>
              <ListItem
                // onClick={() => showUserProfile(item)}
                className="cursor-pointer"
                sx={{
                  "&:hover": {
                    backgroundColor: "var(--border-color)",
                  },
                }}
                secondaryAction={
                  <Box className="flex flex-col items-center gap-y-[2px]">
                    <Box>
                      <Chip
                        size="small"
                        sx={{ fontSize: "11px" }}
                        color="success"
                        label="Message"
                        onClick={(e) => handleChat(e, item)}
                      />
                    </Box>
                    <Box>
                      <Chip
                        size="small"
                        sx={{ fontSize: "11px" }}
                        color="warning"
                        label="Unfriend"
                        onClick={(e) => handleUnFriend(e, item.id, "UNFRIEND")}
                      />
                    </Box>
                  </Box>
                }>
                <ListItemAvatar>
                  <CustomAvatar {...{ user_id: item?.user.id, image: item?.user?.profile?.profile_image }} />
                </ListItemAvatar>
                <ListItemText
                  sx={{
                    "& .MuiListItemText-secondary": {
                      width: '175px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    },
                  }}
                  primary={item?.user?.name}
                  secondary={item.user?.email}
                />
              </ListItem>
              <Divider
                variant="middle"
                component="li"
                sx={{ marginLeft: 0, marginRight: 0 }}
              />
            </Fragment>
          ))}
        </List>
      ) : (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
          <span>No friends available</span>
        </div>
      )}
    </Grid>
  );
};

export default FriendList;
