import { Box, Button, Grid, Typography } from "@mui/material";
import React, { memo } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { East } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import PurchaseSubscriptionModal from "components/MirrorAllModal/PurchaseSubscriptionModal";
import { setPurchaseSubscriptionModal } from "redux/features/application/applicationSlice";
import { setSelectedPackage } from "redux/features/subscription/subscriptionSlice";

const ProPackages = memo(() => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const proSubscription = useSelector((state) => state.subscription.pro);
  const purchaseSubscriptionModal = useSelector((state) => state.application.purchaseSubscriptionModal);

  const handleBuySubscription = () => {
    dispatch(setPurchaseSubscriptionModal(true));
  };

  return (
    <Box sx={{ width: "95%", margin: "0 auto" }}>
      <Grid container columnSpacing={3} className="mt-12">
        {proSubscription?.map((item) => (
          <Grid key={item.id} item xs={12} sm={12} md={6} lg={4} sx={{ marginBottom: { xs: "10px" } }}>
            <Box className="bg-[#0F3529] rounded text-[var(--color-white-1)] p-5 flex flex-col justify-between" sx={{ height: "100%" }}>
              <Box className="flex justify-center items-center">
                <Typography className="!text-3xl">
                  {item?.name}
                  <Typography className="!text-3xl !uppercase" component={"span"}>
                    {" "}
                    ({item?.package_type})
                  </Typography>
                </Typography>
              </Box>
              <Box className="flex justify-center items-center">
                <Typography>
                  <Typography component={"span"} className="!text-6xl !text-[#27D195] !font-medium">
                    <Typography component={"span"} className="!text-xl !text-[#27D195] !font-medium">
                      $
                    </Typography>
                    {item?.fee}
                  </Typography>
                  <Typography component={"span"} className="!text-sm !font-medium">
                    {item?.duration}/{item.duration > 1 ? "months" : "month"}
                  </Typography>
                </Typography>
              </Box>

              <Box sx={{ marginTop: "15px" }}>
                <Box className="grid grid-cols-1 sm:grid-cols-2 gap-2 flex-grow">
                  {item?.package_description?.map((description) => (
                    <Box key={description.id} className="flex items-center gap-2">
                      <CheckCircleIcon />
                      <Typography className="!text-sm">{description?.title}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>

              <Box className="flex justify-center items-center mt-auto">
                <Button
                  onClick={() => {
                    handleBuySubscription();
                    dispatch(setSelectedPackage(item));
                  }}
                  sx={{
                    background: "var(--color-green-1)",
                    textTransform: "capitalize",
                    color: "var(--color-white-1)",
                    marginTop: "15px",
                    "&:hover": {
                      color: "var(--color-white-1)",
                      background: "var(--color-green-2)",
                    },
                  }}
                  variant="contained"
                  endIcon={user?.subscription === "standard" || user?.subscription === "pro" || user?.subscription === "vip" ? null : <East />}
                >
                  {user?.subscription === "standard" || user?.subscription === "pro" || user?.subscription === "vip" ? "Subscribed" : "Buy subscription"}
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <PurchaseSubscriptionModal {...{ purchaseSubscriptionModal }} />
    </Box>
  );
});

export default ProPackages;
