import { Box, Grid } from "@mui/material";
import Layout from "layouts/Layout";
import React, { memo, useState } from "react";
import StandardPackages from "./StandardPackages";
import ProPackages from "./ProPackages";
import VIPPackages from "./VIPPackages";

const Packages = memo(() => {
  // const theme = useTheme();
  const [activeTab, setActiveTab] = useState("Standard");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabDetails = [{ label: "Standard" }, { label: "Pro" }, { label: "VIP" }];

  return (
    <Layout>
      <Grid container className="p-5">
        <Grid
          sx={{
            background: "#27D195",
          }}
          item
          xs={12}
          className="h-60 rounded"
        >
          <Box className="px-5 flex justify-center">
            <Grid
              container
              sx={{ minHeight: "60px", width: "625px" }}
              justifyContent={"center"}
              alignItems={"center"}
              gap={2}
              className="bg-[#0F3529] text-[var(--color-white-1)] mt-6 rounded-lg p-2"
            >
              {tabDetails.map(({ label }) => (
                <Grid
                  item
                  key={label}
                  className={`cursor-pointer w-48 h-12 rounded-md ${activeTab === label ? `bg-[var(--color-green-1)]` : "bg-transparent"} flex items-center justify-center gap-1`}
                  onClick={() => handleTabClick(label)}
                >
                  {label}
                </Grid>
              ))}
            </Grid>
          </Box>
          {activeTab === "Standard" && <StandardPackages />}
          {activeTab === "Pro" && <ProPackages />}
          {activeTab === "VIP" && <VIPPackages />}
        </Grid>
      </Grid>
    </Layout>
  );
});

export default Packages;
