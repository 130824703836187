import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import { memo } from "react";

const ClientsTableContainer = memo(({ tableRef, loading, currentClientsData }) => {
  const theme = useTheme();
  return (
    <TableContainer
      sx={{
        marginBottom: {
          xs: "-10px",
          sm: "10px",
          md: "5px",
        },
      }}
    >
      <Table aria-label="My clients" ref={tableRef}>
        <TableHead>
          <TableRow className="dark:bg-[--color-green-6] bg-[--color-dark-green] shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]">
            <TableCell
              sx={{
                borderBottom: "none",
                // borderRadius: "10px 0 0 10px",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              Name
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              Email
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              Country
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              Registration Date
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              Affiliate By
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              Current Balance
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              Total Deposit
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                // borderRadius: "0 10px 10px 0",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              Total Withdraw
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow
              sx={{
                ".MuiTableCell-root": { borderBottom: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}` },
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell
                align="center"
                colSpan={8}
                sx={{ color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)" }}
                className="!font-bold !text-base"
              >
                <CircularProgress size={28} color="success" />
              </TableCell>
            </TableRow>
          ) : (
            currentClientsData?.map((item, i) => (
              <TableRow
                className={`shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]`}
                key={i}
                sx={{
                  // background: theme.palette.mode === "dark" ? "var(--color-green-4)" : "var(--color-green-5)",
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  sx={{
                    borderBottom: "1px solid var(--color-green-7)",
                    borderRadius: "10px 0 0 10px",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                  component="th"
                  scope="row"
                >
                  {item.name}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "1px solid var(--color-green-7)",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.email}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "1px solid var(--color-green-7)",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.country}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "1px solid var(--color-green-7)",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.reg_date}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "1px solid var(--color-green-7)",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.affiliate_by}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "1px solid var(--color-green-7)",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.current_balance}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "1px solid var(--color-green-7)",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.total_deposit}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "1px solid var(--color-green-7)",
                    borderRadius: "0 10px 10px 0",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.total_withdraw}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default ClientsTableContainer;
