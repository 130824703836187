import { Box, CircularProgress } from "@mui/material";
import permissionEnum from "enum/permissionEnum";
import Layout from "layouts/Layout";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const CryptocurrencyCalendar = () => {
  const [loading, setLoading] = useState(true);
  const permission = useSelector((state) => state.permission.permission);

  const handleLoad = () => {
    setLoading(false);
  };
  
  useEffect(() => {
    if (permission.length === 0) return;
    if (!permission?.includes(permissionEnum["/cryptocurrency-calender"])) {
      window.location.href = "/";
    }
  }, [permission]);


  return (
    <Layout>
      <Box className="h-full w-full">
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress sx={{ color: "#00c990" }} />
          </Box>
        )}

        <iframe
          title="CryptocurrencyCalendar"
          src="https://api.infotraderhub.com/Cryptocurrency_Calendar/Cryptocurrency_Calendar.html"
          style={{ width: "100%", height: "100vh", display: loading ? "none" : "block" }}
          onLoad={handleLoad}
        ></iframe>
      </Box>
    </Layout>
  );
};

export default memo(CryptocurrencyCalendar);
