import { useTheme } from "@emotion/react";
import { Checkbox, FormControlLabel, Grid, Tab, Tabs, Typography } from "@mui/material";
import { memo, useState } from "react";

const FilterImportanceCalender = ({importance, setImportance}) => {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (_, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const importanceFilter = (level) => {
    if (importance.includes(level)) {
      setImportance(importance.filter((item) => item !== level));
    } else {
      setImportance([...importance, level]);
    }
  };
  return (
    <Grid
      spacing={2}
      container
      marginTop="1px"
      sx={{
        background:
          theme.palette.mode === "dark"
            ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)"
            : "var(--color-green-10)",
        overflow: "hidden",
        justifyContent: "end",
      }}
      className="h-[450px] lg:h-80 p-[20px]"
    >
      <Grid item xs={12} md={5} lg={2}>
        <Tabs
          orientation="vertical"
          centered
          value={tabIndex}
          onChange={handleTabChange}
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "transparent",
            },
            "& .MuiTab-root": {
              display: "flex",
              alignItems: "flex-start",
            },
          }}
        >
          <Tab
            disableRipple
            sx={{
              maxWidth: "none",
              minWidth: "none",
              minHeight: "25px",
              padding: "0px",
              "&.Mui-selected": {
                paddingY: "0px !important",
                background: "var(--color-green-12)",
              },
            }}
            className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !font-normal"
            label={
              <FormControlLabel
                sx={{
                  marginLeft: 0,
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    marginLeft: "10px",
                  },
                }}
                control={
                  <>
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-2)",
                        },
                      }}
                    />
                    <div className="h-5 w-5 bg-[var(--calender-high)] text-[--color-white-1] text-[12px] MuiBox-root css-0">H</div>
                  </>
                }
                label="High"
                onClick={() => importanceFilter("high")}
              />
            }
          />

          <Tab
            disableRipple
            sx={{
              maxWidth: "none",
              minWidth: "none",
              minHeight: "25px",
              padding: "0px",
              "&.Mui-selected": {
                paddingY: "0px !important",
                background: "var(--color-green-12)",
              },
            }}
            className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !font-normal"
            label={
              <FormControlLabel
                sx={{
                  marginLeft: 0,
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    marginLeft: "10px",
                  },
                }}
                control={
                  <>
                    <Checkbox
                      sx={{
                        "& .MuiCheckbox-root": {
                          padding: "0 !important",
                        },
                        "&.Mui-checked": {
                          color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-2)",
                        },
                      }}
                    />
                    <div className="h-5 w-5 bg-[var(--calender-medium)] text-[--color-white-1] text-[12px] MuiBox-root css-0">M</div>
                  </>
                }
                label="Medium"
                onClick={() => importanceFilter("medium")}
              />
            }
          />
          <Tab
            disableRipple
            sx={{
              maxWidth: "none",
              minWidth: "none",
              minHeight: "25px",
              padding: "0px",
              "&.Mui-selected": {
                paddingY: "0px !important",
                background: "var(--color-green-12)",
              },
            }}
            className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !font-normal"
            label={
              <FormControlLabel
                sx={{
                  marginLeft: 0,
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    marginLeft: "10px",
                  },
                }}
                control={
                  <>
                    <Checkbox
                      sx={{
                        "& .MuiCheckbox-root": {
                          padding: "0px !important",
                        },
                        "&.Mui-checked": {
                          color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-2)",
                        },
                      }}
                    />
                    <div className="h-5 w-5 bg-[var(--calender-low)] text-[--color-white-1] text-[12px] MuiBox-root css-0">L</div>
                  </>
                }
                label="Low"
                onClick={() => importanceFilter("low")}
              />
            }
          />
        </Tabs>
      </Grid>
      <Grid item xs={12} md={5}>
        <Typography component="p" className="!pb-2">
          High-importance events which have historically sparked market volatility.
        </Typography>
        <Typography component="p" className="!pb-2">
          Relatively less likely to force major market moves except on outsized surprises.
        </Typography>
        <Typography component="p" className="!pb-2">
          Historically less market-moving event except in the most extraordinary of circumstances.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default memo(FilterImportanceCalender);
