import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, styled, useTheme } from "@mui/material";
import React, { memo } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    background: theme.palette.mode === "dark" ? "var(--color-green-7)" : "var(--color-green-10)",
    width: "500px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    background: theme.palette.mode === "dark" ? "var(--color-green-7)" : "var(--color-green-10)",
  },
}));

const InfoDialogCommission = memo(({ openInfoDialogCommission, setOpenInfoDialogCommission }) => {
  const theme = useTheme();
  return (
    <BootstrapDialog onClose={() => setOpenInfoDialogCommission(false)} aria-labelledby="customized-dialog-title" open={openInfoDialogCommission}>
      <DialogTitle sx={{ m: 0, p: 2, background: theme.palette.mode === "dark" ? "var(--color-green-7)" : "var(--color-green-10)" }} id="customized-dialog-title">
        Note For Commission
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setOpenInfoDialogCommission(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <Typography gutterBottom>
          Investing is one of the best ways to set aside money for retirement or to grow your wealth. To do so, you’ll need to open an account through a brokerage or trading
          platform.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{ background: "var(--color-green-1)", textTransform: "capitalize", "&:hover": { background: "var(--color-green-1)" } }}
          onClick={() => setOpenInfoDialogCommission(false)}
        >
          Exit
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
});

export default InfoDialogCommission;
