import React, { memo, useEffect } from "react";
import InitialRestRequest from "./InitialRestRequest";
import { useSelector } from "react-redux";

const InitialComponent = () => {
  const user = useSelector((state) => state.chat.user);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (user?.id) {
      setLoading(false);
    }
  }, [user?.id]);
  return (
    <>
      {!loading && (
        <>
          <InitialRestRequest />
          
        </>
      )}
    </>
  );
};

export default memo(InitialComponent);
