const { createSlice } = require("@reduxjs/toolkit");

const ibDashboardSlice = createSlice({
    name: "ib",
    initialState: {
        ib: {},
        affiliateClients: [],
        commissionReport: {}
    },
    reducers: {
        setIbDashboard: (state, action) => {
            state.ib = action.payload;
        },

        setAffiliateClients: (state, action) => {
            state.affiliateClients = action.payload
        },

        setCommissionReport: (state, action) => {
            state.commissionReport = action.payload
        },


    }
})
export const { setIbDashboard, setAffiliateClients, setCommissionReport } = ibDashboardSlice.actions;
export default ibDashboardSlice.reducer;