import { West } from "@mui/icons-material";
import { Avatar, Chip, Divider, FormControl, Grid, InputAdornment, List, ListItem, ListItemAvatar, ListItemText, TextField, useTheme } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { add_group_member } from "server/chat";

const AddMember = ({ handleClose, groupId, setGetGroupMemberRender }) => {
    const theme = useTheme();
    const [searchList, setSearchList] = useState([]);
    const friendList = useSelector((state) => state.chat.friendList);
    const [sentReq, setSentReq] = useState([]);

    const handleSearchUser = async (e) => {
        if (e.target.value.length < 2) {
            return;
        }
        const result = await friendList?.filter((item) => item.user.email.toLowerCase().includes(e.target.value.toLowerCase()));
        if (result?.length > 0) {
            setSearchList(result);
        }
    };

    const addGroup = async (_, user_id) => {
        const result = await add_group_member(groupId, user_id);
        if (result.data.status) {
            setSentReq([...sentReq, user_id]);
            setGetGroupMemberRender((prev) => !prev);
        }
    };

    return (
        <Grid
            container
            sx={{
                width: "100%",
                height: "100%",
                background: "var(--color-green-8)",
                padding: "15px",
                borderRadius: "10px",
                overflow: "hidden",
            }}
        >
            <Grid item xs={12}>
                <FormControl
                    fullWidth
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            height: "30px",
                            width: "100%",
                            borderRadius: "200px",
                            fontSize: "14px",
                            color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-2)",
                            "& fieldset": {
                                border: "1px solid var(--color-green-7)",
                            },
                            "&:hover fieldset": {
                                border: "1px solid var(--color-green-1)",
                            },
                            "&.Mui-focused fieldset": {
                                border: `1px solid var(--color-green-2)`,
                            },
                        },
                    }}
                >
                    <TextField
                        onChange={handleSearchUser}
                        autoComplete="off"
                        type="text"
                        placeholder="Search..."
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <West onClick={() => handleClose()} className="cursor-pointer" />
                                </InputAdornment>
                            ),
                            sx: {
                                "&::placeholder": {
                                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                                    opacity: 1,
                                    fontSize: "12px",
                                },
                            },
                        }}
                        variant="outlined"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} className="chat-list-scrollbar" sx={{ height: "240px", width: "100%", overflowY: "auto" }}>
                <List sx={{ width: "100%" }}>
                    {searchList?.map((item) => (
                        <Fragment key={item.id}>
                            <ListItem
                                className="cursor-pointer"
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "var(--border-color)",
                                    },
                                }}
                                secondaryAction={
                                    sentReq.includes(item.user?.id) ? (
                                        <Chip size="small" label="Added" />
                                    ) : (
                                        <Chip size="small" label="Add" onClick={(e) => addGroup(e, item.user?.id)} />
                                    )
                                }>
                                <ListItemAvatar>
                                    <Avatar alt="chat list avatar" src={item?.profile?.profile_image}></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={item.user?.name} secondary={item.user?.email} />
                            </ListItem>
                            <Divider variant="middle" component="li" sx={{ marginLeft: 0, marginRight: 0 }} />
                        </Fragment>
                    ))}
                </List>
            </Grid>
        </Grid>
    );
};

export default AddMember;
