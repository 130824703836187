import mirrorApi from '../api/mirror_trade_api';
const { getAuthToken } = require("./auth");

export const getCalendar = async (page, period, title, level) => {
    const importanceParams = level?.map(level => `importance[]=${level}`).join('&');
    try {
        return await mirrorApi.get(`/calendars?page=${page}&period=${period}&title=${title}&${importanceParams}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ calendar ~ error:", error)

    }
};