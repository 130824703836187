import { store } from "redux/store";

const socketServiceConnect = () => {
  if (store.getState().chat.socket && store.getState().chat.socket.disconnected) {
    store.getState().chat.socket.connect();
  }
};
const socketServiceDisconnect = () => {
  if (store.getState().chat.socket.connected) {
    store.getState().chat.socket.disconnect();
  }
};

const socketServicePingPong = () => {
  socketServiceConnect();
  store.getState().chat.socket.emit("ping");
};

const socketServiceEmit = (event, data) => {
  socketServiceConnect();
  store.getState().chat.socket.emit(event, data);
};

const socketServiceOn = (event, callback) => {
  socketServiceConnect();
  store.getState().chat.socket.on(event, callback);
};

const socketServiceOff = (event, callback) => {
  store.getState().chat.socket.off(event, callback);
};

const socketServiceJoinChat = (chat_id) => {
  socketServiceConnect();
  store.getState().chat.socket.emit("join_chat", { chat_id });
};


const socketServiceSendChat = ({
  chat_id,
  content_id = null,
  attachment_id = null,
  file_type = "TEXT",
  content = null,
  sender_id,
  receiver_id,
  u_id = null, }) => {
  socketServiceConnect();
  store.getState().chat.socket.emit("send_chat", {
    chat_id,
    ...(content_id && { content_id }),
    ...(attachment_id && { attachment_id }),
    file_type,
    content,
    sender_id,
    receiver_id,
    ...(u_id && { u_id }),
  });
};

const socketServiceTyping = (receiver_id) => {
  socketServiceConnect();
  store.getState().chat.socket.emit("chat_typing", { receiver_id });
};

// group chat socket
const socketServiceJoinGroupChat = (group_chat_id) => {
  socketServiceConnect();
  store.getState().chat.socket.emit("join_group", { group_id: group_chat_id });
};

const socketServiceSendGroupChat = ({
  group_id,
  content_id = null,
  attachment_id = null,
  file_type = "TEXT",
  content,
  u_id,
  forwarded = false, }) => {
  socketServiceConnect();
  store.getState().chat.socket.emit("send_group", {
    group_id,
    ...(content_id && { content_id }),
    ...(attachment_id && { attachment_id }),
    file_type,
    content,
    ...(u_id && { u_id }),
    forwarded,
  });
}

export {
  socketServiceConnect,
  socketServiceEmit,
  socketServiceOn,
  socketServiceOff,
  socketServiceJoinChat,
  socketServiceSendChat,
  socketServicePingPong,
  socketServiceDisconnect,
  socketServiceTyping,
  socketServiceJoinGroupChat,
  socketServiceSendGroupChat,
};
