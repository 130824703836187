import { createSlice } from "@reduxjs/toolkit";

const paginationSlice = createSlice({
  name: "pagination",
  initialState: {
    page: 1,
    openTradePage: 1,
    closeTradePage: 1,
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setOpenTradePage: (state, action) => {
      state.openTradePage = action.payload;
    },
    setCloseTradePage: (state, action) => {
      state.closeTradePage = action.payload;
    }
  },
});

export const { setPage, setOpenTradePage, setCloseTradePage } = paginationSlice.actions;
export default paginationSlice.reducer;
