import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import MessageInput from "./MessageInput";
import TopBarChat from "./TopBarChat";
import { useDispatch, useSelector } from "react-redux";
import { socketServiceJoinChat } from "services/socketService";
import { seen_notification } from "server/notification";
import { updateUnseenNotification } from "redux/features/notification/notificationSlice";
import { chat_history } from "server/chat";
import { setActiveChat, setChatHistory, setNavigateChat } from "redux/features/chatSlice/chatSlice";

const Chat = ({ handlePopoverCloseChat }) => {
  const user = useSelector((state) => state.chat.user);
  const activeChat = useSelector((state) => state.chat.activeChat);
  const chatHistory = useSelector((state) => state.chat.chatHistory);
  const chatBoxRef = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);

  const getChatHistory = async () => {
    const chats = await chat_history(activeChat?.id);
    if (chats?.data?.status) {
      setLoading(false);
      dispatch(setChatHistory(chats.data?.data[0]?.chat_content));
    }
  };

  useEffect(() => {
    if (activeChat?.id) {
      getChatHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat?.id]);

  useEffect(() => {
    if (!activeChat?.id) return;
    socketServiceJoinChat(activeChat?.id);
  }, [activeChat?.id]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [chatHistory, loading]);

  const seenNotification = async () => {
    let for_type = 'CHAT';
    const res = await seen_notification(for_type, activeChat?.id);
    if (res?.data?.status) {
      dispatch(updateUnseenNotification(activeChat?.id))
    }
  };
  
  useEffect(() => {
    seenNotification();
    return () => {
      dispatch(setActiveChat({}));
      dispatch(setNavigateChat("chat_list"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatMessageTime = (timestamp) => {
    const options = { hour: "numeric", minute: "numeric" };
    const formattedTimestamp = new Date(timestamp).toLocaleString("en-US", options);
    return formattedTimestamp;
  };
  
  return (
    <Box
      className="relative"
      sx={{
        width: "360px",
        height: "400px",
        background: "var(--color-green-8)",
        padding: "15px",
        paddingTop: 0,
        borderRadius: "10px",
        overflow: "hidden",
      }}>
      <TopBarChat {...{ handlePopoverCloseChat }} />
      <Box
        ref={chatBoxRef}
        className="chat-list-scrollbar bg-[--color-dark-green] p-[5px]"
        sx={{ height: "280px", paddingTop: "10px", width: "100%", overflowY: "auto", borderRadius: "5px" }}>
        {loading ? (
          <Box className="flex justify-center items-center h-full">
            <CircularProgress sx={{ color: "var(--color-white-1)" }} />
          </Box>
        ) : (
            <>
              {chatHistory?.map((message, i) => (
                <Box
                  key={i}
                  className={`flex gap-[3px] items-center mt-3 mb-3 ${message.receiver_id === user?.id ? "justify-start" : "justify-end"}`}>
                  {message.receiver_id === user?.id ? (
                    <>
                      <Box className="self-end">
                        <Avatar
                          alt="User"
                          src={message?.avatar}
                          sx={{ width: 24, height: 24 }}
                        />
                      </Box>
                      <Box>
                        {message.type === "ATTACHMENT" ? (
                          <img
                            src={`${message.content}`}
                            alt={message.title}
                            loading="lazy"
                            width={200}
                            style={{ borderRadius: 5, border: "1px solid var(--color-green-1)", cursor: "pointer" }}
                            onClick={() => window.open(message.content, "_blank")}
                          />
                        ) : (
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontFamily: "Open Sans",
                              color: "var(--color-white-1)",
                              padding: "10px",
                              borderRadius: "10px",
                              borderBottomLeftRadius: "0px",
                              background: "var(--border-color)",
                              maxWidth: "240px",
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                              wordBreak: "break-word",
                            }}>
                            {message.content}
                          </Typography>
                        )}
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "10px",
                            color: "var(--color-white-2)",
                            marginTop: "5px",
                          }}>
                          {activeChat?.user?.name} {"-"} {formatMessageTime(message.createdAt)}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box>
                        {message.type === "ATTACHMENT" ? (
                          <img
                            src={`${message.content}`}
                            alt={message.title}
                            loading="lazy"
                            width={200}
                            style={{ borderRadius: 5, border: "1px solid var(--color-green-1)", cursor: "pointer" }}
                            onClick={() => window.open(message.content, "_blank")}
                          />
                        ) : (
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontFamily: "Open Sans",
                              color: "var(--color-white-1)",
                              padding: "10px",
                              borderRadius: "10px",
                              borderBottomLeftRadius: "0px",
                              background: "var(--border-color)",
                              maxWidth: "240px",
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                              wordBreak: "break-word",
                            }}>
                            {message.content}
                          </Typography>
                        )}
                        <Typography
                          sx={{
                            textAlign: "right",
                            fontSize: "10px",
                            color: "var(--color-white-2)",
                            marginTop: "5px",
                          }}>
                          {formatMessageTime(message.createdAt)} {"-"} {"you"}
                        </Typography>
                      </Box>
                      <Box className="self-end">
                        <Avatar
                          alt="User"
                          src={message?.avatar}
                          sx={{ width: 24, height: 24 }}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              ))}
            </>
        )}
        
      </Box>
      <Box className="absolute bottom-0 w-full">
        <MessageInput />
      </Box>
    </Box>
  );
};

export default Chat;
