import { Avatar, Box, Button, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import React, { memo } from "react";
import MenuIcon from "../../assets/icons/menu-icon.svg";
import buttonImage from "../../assets/img/group-1000003348.png";
import logo from "../../assets/img/image-2023-11-21t10-07-25-128z-1.png";
// import notification from "../../assets/img/clarity-notification-line.svg";
import { Brightness4Outlined, Brightness7Outlined } from "@mui/icons-material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddAccountModal from "components/MirrorAllModal/AddAccountModal";
import ChangePasswordModal from "components/MirrorAllModal/ChangePasswordModal";
import ManageAccount from "components/MirrorAllModal/ManageAccount";
import UpdateProfileModal from "components/MirrorAllModal/UpdateProfileModal";
import { AppBar } from "layouts/Layout";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toggleTheme } from "redux/features/application/applicationSlice";
import { logIn } from "redux/features/auth/authSlice";
import { refetchAuth } from "server/auth";
import { ibRequest } from "server/ib_dashboard";
import vector from "../../assets/img/vector.svg";

const TopBar = ({ open, handleDrawerOpen }) => {
  const navigate = useNavigate();
  const permission = useSelector((state) => state.permission.permission);
  const [anchorElManageAccount, setAnchorElManageAccount] = React.useState(null);
  const manageAccountOpen = Boolean(anchorElManageAccount);
  const user = useSelector((state) => state.auth.user);
  const updateProfileModalOpen = useSelector((state) => state.application.updateProfileModalOpen);
  const openAddAccountModal = useSelector((state) => state.application.openAddAccountModal);
  const openChangePasswordModal = useSelector((state) => state.application.openChangePasswordModal);
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleToggleTheme = () => {
    dispatch(toggleTheme());
  };

  const handleClickManageAccount = (event) => {
    setAnchorElManageAccount(event.currentTarget);
  };
  const handleCloseManageAccount = () => {
    setAnchorElManageAccount(null);
  };

  const handleIbRequest = async () => {
    if (user?.ib_access === 0) {
      const result = await ibRequest();
      if (result?.data?.status) {
        enqueueSnackbar(result.data.message, { variant: "success" });
        const resultRefetch = await refetchAuth();
        if (resultRefetch?.data?.status) {
          localStorage.setItem("auth-token", resultRefetch?.data?.token);
          dispatch(logIn({ ...resultRefetch?.data?.data }));
        }
      }
    } else if (user?.ib_access === 1) {
      navigate("/ib-dashboard");
    }else if (user?.ib_access === 2) {
      enqueueSnackbar("IB dashboard request already submitted.Your request is pending.", { variant: "warning" });
    }
  };

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          disableRipple
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
        >
          <Typography component={"img"} src={MenuIcon}></Typography>
        </IconButton>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "130px",
              height: "58px",
            }}
            component={"img"}
            src={logo}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "17px",
            }}
          >
            {permission?.includes("become an affiliate") && (
              <Button
                onClick={handleIbRequest}
                sx={{
                  // width: "150px",
                  height: "35px",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "6.901px",
                  background: "var(--color-green-1)",
                  boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
                  "&:hover": {
                    color: "var(--color-white-1)",
                    background: "var(--color-green-2)",
                  },
                  "@media (max-width: 640px)": {
                    display: "none",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "20.002px",
                    height: "20.001px",
                    marginRight: "5px",
                  }}
                  component={"img"}
                  src={buttonImage}
                  alt=""
                />
                <Typography
                  sx={{
                    color: "var(--color-text-2)",
                    textAlign: "center",
                    fontFamily: "Open Sans",
                    fontSize: "12.94px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20.704px",
                    textTransform: "capitalize",
                  }}
                >
                  {/* Start Trading */}
                  {user?.ib_access === 0 ? "Become an Affiliate" : user?.ib_access === 1 ? "IB Dashboard" : "IB Requested"}
                </Typography>
              </Button>
            )}

            <Box
              onClick={handleToggleTheme}
              sx={{
                width: "35px",
                height: "35px",
                borderRadius: "6px",
                background: "#00120C",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton sx={{ color: "white" }}>
                {theme.palette.mode === "dark" ? <Brightness4Outlined className="!text-[20px]" /> : <Brightness7Outlined className="!text-[20px]" />}
              </IconButton>
            </Box>
            {/* <Box
              sx={{
                width: "35px",
                height: "35px",
                borderRadius: "6px",
                background: "#00120C",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "8px",
                  height: "8px",
                  position: "absolute",
                  top: "3px",
                  right: "5px",
                }}
                component={"img"}
                src={vector}
                alt=""
              />
              <Box sx={{ width: "17px", height: "17px" }} component={"img"} src={notification} alt="" />
            </Box> */}
            {/* Profile */}
            <Box
              sx={{
                width: "42px",
                height: "42px",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <Avatar sx={{ width: "42px", height: "42px" }} variant="rounded" src={user?.photo} alt="Avatar" />
              </Box>
            </Box>
            <Box sx={{ cursor: "pointer" }} onClick={handleClickManageAccount}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    color: "var(--color-white-1)",
                    fontFamily: "Anek Telugu",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "17px",
                    "@media (max-width: 640px)": {
                      display: "none",
                    },
                  }}
                >
                  {user?.name}
                </Typography>
                <Box />
                {manageAccountOpen ? <ExpandLessIcon sx={{ color: "var(--color-green-2)" }} /> : <ExpandMoreIcon sx={{ color: "var(--color-green-2)" }} />}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "@media (max-width: 640px)": {
                    display: "none",
                  },
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    color: "var(--text-color-gray)",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "15px",
                  }}
                >
                  Active
                </Typography>
                <Box
                  sx={{
                    width: "8px",
                    height: "8px",
                  }}
                  component={"img"}
                  src={vector}
                  alt=""
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Toolbar>
      <AddAccountModal {...{ openAddAccountModal }} />
      <UpdateProfileModal {...{ updateProfileModalOpen }} />
      <ManageAccount {...{ manageAccountOpen, handleCloseManageAccount, anchorElManageAccount }} />
      <ChangePasswordModal {...{ openChangePasswordModal }} />
    </AppBar>
  );
};

export default memo(TopBar);
