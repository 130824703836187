import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import React, { memo } from "react";
import { Popover } from "@mui/material";

const DateCalender = ({ id, open, anchorEl, handleClose, selectedDate, handleSelect }) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar date={selectedDate} onChange={handleSelect} />
      </LocalizationProvider>
    </Popover>
  );
};

export default memo(DateCalender);
