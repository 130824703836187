import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, CircularProgress, IconButton, InputAdornment, TextField } from "@mui/material";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setOpenChangePasswordModal } from "redux/features/application/applicationSlice";
import { setChangePasswordNavigate } from "redux/features/navigateSlice/navigateSlice";
import { changePassword } from "server/change_password";
import { changePasswordChat } from "server/chat";
import * as yup from "yup";

const validationSchema = yup.object({
  password: yup.string("Enter your password").min(6, "Password should be of minimum 6 characters length").required("Password is required"),
  confirm: yup.string("Confirm your password").min(6, "Password should be of minimum 6 characters length").required("Confirm Password is required"),
});

const ChangePassword = memo(({ otpCode }) => {
  const chat_user_email = useSelector((state) => state?.chat?.user?.email);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.application.loading);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  const handleClickConfirmShowPassword = () => setConfirmShowPassword((show) => !show);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      dispatch(setLoading(true));
      const data = {
        code: otpCode,
        password: values.password,
        password_confirmation: values.confirm,
      };
      const result = await changePassword(data);

      const chatChangePassword = {
        email: chat_user_email,
        password: values.password,
      };

      if (result.data.status) {
        await changePasswordChat(chatChangePassword);
        enqueueSnackbar(result.data.message, { variant: "success" });
        dispatch(setLoading(false));
        dispatch(setOpenChangePasswordModal(false));
        dispatch(setChangePasswordNavigate("call_api_password_change"));
      } else {
        dispatch(setLoading(false));
        if (result?.data?.errors?.password[0]) {
          enqueueSnackbar(result?.data?.errors?.password[0], { variant: "warning" });
        } else {
          enqueueSnackbar(result.data.message, { variant: "warning" });
        }
      }
    },
  });
  return (
    <form autoComplete="off" className="space-y-6 w-full p-2" onSubmit={formik.handleSubmit}>
      <Box>
        <TextField
          label="Password"
          variant="standard"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          size="medium"
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          sx={{
            "& .MuiInput-root": {
              color: "var(--color-black)",
              fontSize: "16px",
              fontWeight: 500,
            },
            "& label": {
              fontSize: "16px",
              color: "var(--color-white-1)",
              fontWeight: 500,
            },
            "& label.Mui-focused": {
              color: "var(--color-green-2)",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "var(--color-green-2)",
            },
          }}
        />
      </Box>

      <Box>
        <TextField
          label="Confirm Password"
          variant="standard"
          type={confirmShowPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickConfirmShowPassword} edge="end">
                  {confirmShowPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          size="medium"
          id="confirm"
          name="confirm"
          value={formik.values.confirm}
          onChange={formik.handleChange}
          error={formik.touched.confirm && Boolean(formik.errors.confirm)}
          helperText={formik.touched.confirm && formik.errors.confirm}
          sx={{
            "& .MuiInput-root": {
              color: "var(--color-black)",
              fontSize: "16px",
              fontWeight: 500,
            },
            "& label": {
              fontSize: "16px",
              color: "var(--color-white-1)",
              fontWeight: 500,
            },
            "& label.Mui-focused": {
              color: "var(--color-green-2)",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "var(--color-green-2)",
            },
          }}
        />
      </Box>

      <Button
        type="submit"
        sx={{
          background: "var(--color-green-1)",
          textTransform: "none",
          "&:hover": {
            background: "var(--color-green-11)",
          },
        }}
        disableRipple
        variant="contained"
        fullWidth
      >
        {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Submit"}
      </Button>
    </form>
  );
});

export default ChangePassword;
