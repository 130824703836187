import mirrorApi from "../api/mirror_trade_api";
const { getAuthToken } = require("./auth");

export const getPosts = async (page, postType) => {
  try {
    return await mirrorApi.get(`/posts?page=${page}&type=${postType}`, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  } catch (error) {
    console.log("🚀 ~ posts ~ error:", error);
  }
};

export const getPostsDetails = async (id) => {
  try {
    return await mirrorApi.get(`/posts/${id}`, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  } catch (error) {
    console.log("🚀 ~ getPostsDetails ~ error:", error);
  }
};

export const getComments = async (id, page) => {
  try {
    return await mirrorApi.get(`/posts/${id}/comments?page=${page}`, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  } catch (error) {}
};

export const addComment = async (id, content) => {
  try {
    return await mirrorApi.post(
      `/posts/${id}/comments`,
      { content },
      {
        headers: {
          Authorization: getAuthToken(),
        },
      }
    );
  } catch (error) {}
};

// postId, commentId, editComment

export const updateComment = async (postId, commentId, content) => {
  try {
    return await mirrorApi.post(
      `/posts/${postId}/comments/${commentId}`,
      { content },
      {
        headers: {
          Authorization: getAuthToken(),
        },
      }
    );
  } catch (error) {}
};

// type of post filter
export const typePost = async (postType, page, categoryId) => {
  try {
    return await mirrorApi.get(
      `/posts?type=${postType}&page=${page}&category=${categoryId}`,
      {
        headers: {
          Authorization: getAuthToken(),
        },
      }
    );
  } catch (error) {
    console.log("🚀 ~ typePost ~ error:", error);
  }
};

// get post type count

export const getPostCount = async () => {
  try {
    return await mirrorApi.get(`/get/posts/type`, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  } catch (error) {
    console.log("🚀 ~ getPostCount ~ error:", error);
  }
};
// get post type count

export const getCategory = async () => {
  try {
    return await mirrorApi.get(`/get/posts/category`, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  } catch (error) {
    console.log("🚀 ~ getCategory ~ error:", error);
  }
};
