import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { socketServiceJoinGroupChat } from "services/socketService";
import GroupTopBar from "./GroupTopbar";
import GroupMessageInput from "./GroupMessageInput";
import { seen_notification } from "server/notification";
import { updateUnseenNotification } from "redux/features/notification/notificationSlice";
import { get_group_chat_history } from "server/chat";
import { setActiveChatGroup, setGroupChatHistory, setGroupNavigate } from "redux/features/chatSlice/chatSlice";

const GroupChat = ({ handlePopoverCloseChat }) => {
  const user = useSelector((state) => state.chat.user);
  const activeChatGroup = useSelector((state) => state.chat.activeChatGroup);
  const chatHistory = useSelector((state) => state.chat.groupChatHistory);
  const [loading, setLoading] = useState(true);
  const chatBoxRef = useRef(null);
  const dispatch = useDispatch();

  const getGroupChatHistory = async () => {
    const chats = await get_group_chat_history(activeChatGroup?.id);
    if (chats.data?.status) {
      setLoading(false);
      dispatch(setGroupChatHistory(chats.data.data[0].content));
    }
  };

  useEffect(() => {
    if (activeChatGroup?.id) {
      getGroupChatHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChatGroup?.id]);

  useEffect(() => {
    if (!activeChatGroup?.id) return;
    socketServiceJoinGroupChat(activeChatGroup?.id);
  }, [activeChatGroup?.id]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [chatHistory, loading]);

  const seenNotification = async () => {
    let for_type = "GROUP";
    const res = await seen_notification(for_type, activeChatGroup?.id);
    if (res?.data?.status) {
      dispatch(updateUnseenNotification(activeChatGroup?.id));
    }
  };
  useEffect(() => {
    seenNotification();
    return () => {
      dispatch(setActiveChatGroup({}));
      dispatch(setGroupNavigate("group_chat_list"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatMessageTime = (timestamp) => {
    const options = { hour: "numeric", minute: "numeric" };
    const formattedTimestamp = new Date(timestamp).toLocaleString("en-US", options);
    return formattedTimestamp;
  };
  return (
    <Box
      className="relative"
      sx={{
        width: "360px",
        height: "400px",
        background: "var(--color-green-8)",
        padding: "15px",
        paddingTop: 0,
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <GroupTopBar {...{ handlePopoverCloseChat }} />
      <Box
        ref={chatBoxRef}
        className="chat-list-scrollbar bg-[--color-dark-green] p-[5px]"
        sx={{ height: "280px", paddingTop: "10px", width: "100%", overflowY: "auto", borderRadius: "5px" }}
      >
        {loading ? (
          <Box className="flex justify-center items-center h-full">
            <CircularProgress sx={{ color: "var(--color-white-1)" }} />
          </Box>
        ) : (
          <>
            {chatHistory?.map((message, i) => (
              <Box key={i} className={`flex gap-[3px] items-center mt-3 mb-3 ${message.user?.id === user?.id ? "justify-end" : "justify-start"}`}>
                {message.user?.id !== user?.id ? (
                  <>
                    <Box className="self-end">
                      <Avatar alt="User" src={message?.user?.profile?.profile_image} sx={{ width: 24, height: 24 }} />
                    </Box>
                    <Box>
                      {message.type === "ATTACHMENT" ? (
                        <img
                          src={`${message.content}`}
                          alt={message.title}
                          loading="lazy"
                          width={200}
                          style={{ borderRadius: 5, border: "1px solid var(--color-green-1)", cursor: "pointer" }}
                          onClick={() => window.open(message.content, "_blank")}
                        />
                      ) : (
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontFamily: "Open Sans",
                            color: "var(--color-white-1)",
                            padding: "10px",
                            borderRadius: "10px",
                            borderBottomLeftRadius: "0px",
                            background: "var(--border-color)",
                            maxWidth: "240px",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                            wordBreak: "break-word",
                          }}
                        >
                          {message.content}
                        </Typography>
                      )}
                      <Typography
                        sx={{
                          textAlign: "left",
                          fontSize: "10px",
                          color: "var(--color-white-2)",
                          marginTop: "5px",
                        }}
                      >
                        {message.user?.name} {"-"} {formatMessageTime(message.createdAt)}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box>
                      {message.type === "ATTACHMENT" ? (
                        <img
                          src={`${message.content}`}
                          alt={message.title}
                          loading="lazy"
                          width={200}
                          style={{ borderRadius: 5, border: "1px solid var(--color-green-1)", cursor: "pointer" }}
                          onClick={() => window.open(message.content, "_blank")}
                        />
                      ) : (
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontFamily: "Open Sans",
                            color: "var(--color-white-1)",
                            padding: "10px",
                            borderRadius: "10px",
                            borderBottomLeftRadius: "0px",
                            background: "var(--border-color)",
                            maxWidth: "240px",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                            wordBreak: "break-word",
                          }}
                        >
                          {message.content}
                        </Typography>
                      )}
                      <Typography
                        sx={{
                          textAlign: "right",
                          fontSize: "10px",
                          color: "var(--color-white-2)",
                          marginTop: "5px",
                        }}
                      >
                        {formatMessageTime(message.createdAt)} {"-"} {"you"}
                      </Typography>
                    </Box>
                    <Box className="self-end">
                      <Avatar alt="User" src={message?.user.profile?.profile_image} sx={{ width: 24, height: 24 }} />
                    </Box>
                  </>
                )}
              </Box>
            ))}
          </>
        )}
      </Box>
      <Box className="absolute bottom-0 w-full">
        <GroupMessageInput />
      </Box>
    </Box>
  );
};

export default GroupChat;
