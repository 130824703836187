import { Avatar } from "@mui/material";
import React from "react";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import defaultImg from '../../assets/avatar.jpg';

function CustomAvatar({ image, user_id }) {
  const onlineUsers = useSelector((state) => state.chat.onlineUsers);
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  return (
    <StyledBadge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      variant={onlineUsers.some((i) => i === user_id) ? "dot" : "standard"}>
      <Avatar
        alt={image}
        src={image ?? defaultImg}
      />
    </StyledBadge>
  );
}

export default React.memo(CustomAvatar);
