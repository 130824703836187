import mirrorApi from '../api/mirror_trade_api';
import { getAuthToken } from './auth';

export const open_ticket = async (data) => {
    try {
        return await mirrorApi.post(`/support/ticket`, data, {
            headers: {
                Authorization: getAuthToken(),
                "Content-Type": "multipart/form-data",
            },
        });
    } catch (error) {
        console.log("🚀 ~ constopen_ticket= ~ error:", error)
    }
}

export const get_ticket = async () => {
    try {
        return await mirrorApi.get(`/support/ticket`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ constget_ticket= ~ error:", error)
    }
}
export const get_ticket_query = async (status) => {
    try {
        return await mirrorApi.get(`/support/ticket?status=${status}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ constget_ticket= ~ error:", error)
    }
}

export const get_single_ticket = async (id) => {
    try {
        return await mirrorApi.get(`/support/ticket/${id}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ constget_single_ticket= ~ error:", error)
    }
}

export const replayTicket = async (id, data) => {
    try {
        return await mirrorApi.post(`/support/ticket/${id}/reply`, data, {
            headers: {
                Authorization: getAuthToken(),
                "Content-Type": "multipart/form-data",
            },
        });
    } catch (error) {
        console.log("🚀 ~ const replayTicket= ~ error:", error)
    }
}