import chatApi from "../api/chatApi";

// Registration for chat
export const registration = async ({ name, email, password }) => {
  try {
    return await chatApi.post("/auth/register", { name, email, password });
  } catch (error) {
    console.log("🚀 ~ file: chat.js:8 ~ registration ~ error:", error);
  }
};

// Login for chat
export const login = async ({ email, password }) => {
  try {
    const result = await chatApi.post("/auth/login", { email, password });
    return result;
  } catch (error) {
    console.log("🚀 ~ file: chat.js:17 ~ login ~ error:", error);
  }
};
// Refresh token
export const refreshToken = async () => {
  try {
    const result = await chatApi.post("/auth/refresh_token", { refreshToken: localStorage.getItem("chat-refresh-token") });
    if (result.data?.status) {
      localStorage.setItem("chat-auth-token", result.data.data.access_token);
      return true;
    }
    return false;
  } catch (error) {
    console.log("🚀 ~ file: chat.js:17 ~ login ~ error:", error);
  }
};

// Get Auth Token Chat
export const getAuthTokenChat = () => {
  try {
    return localStorage.getItem("chat-auth-token") ? `Bearer ${localStorage.getItem("chat-auth-token")}` : null;
  } catch (error) { }
};

// Get chat List
export const chat_list = async () => {
  try {
    return await chatApi.get("/chat/chat_list", {
      headers: {
        Authorization: getAuthTokenChat(),
      },
    });
  } catch (error) {
    console.log("🚀 ~ file: chat.js:26 ~ chat_list= ~ error:", error);
  }
};

// Search user
export const search_user = async (searchText) => {
  try {
    return await chatApi.post(
      "/auth/search_user",
      {
        searchText,
      },
      {
        headers: {
          Authorization: getAuthTokenChat(),
        },
      }
    );
  } catch (error) {
    console.log("🚀 ~ file: chat.js:50 ~ search_user= ~ error:", error);
  }
};

// Friend request sent
export const friend_request_sent = async (user_id) => {
  try {
    return await chatApi.post(
      "/auth/friend_request_sent",
      {
        user_id,
      },
      {
        headers: {
          Authorization: getAuthTokenChat(),
        },
      }
    );
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ friend_request_sent= ~ error:", error);
  }
};
// Friend request accept
export const friend_request_accept = async (friend_id, type) => {
  try {
    return await chatApi.post(
      "/auth/friend_request_accept",
      {
        friend_id,
        type,
      },
      {
        headers: {
          Authorization: getAuthTokenChat(),
        },
      }
    );
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ friend_request_accept= ~ error:", error);
  }
};

// Friend list incoming
export const incoming_friend_list = async () => {
  try {
    return await chatApi.get("/auth/incoming_friend_list", {
      headers: {
        Authorization: getAuthTokenChat(),
      },
    });
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ incoming_friend_list ~ error:", error);
  }
};

// Friend list
export const friend_list = async () => {
  try {
    return await chatApi.get("/auth/friend_list", {
      headers: {
        Authorization: getAuthTokenChat(),
      },
    });
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ friend_request_accept= ~ error:", error);
  }
};

// Friend outgoing list
export const outgoing_friend_list = async () => {
  try {
    return await chatApi.get("/auth/outgoing_friend_list", {
      headers: {
        Authorization: getAuthTokenChat(),
      },
    });
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ outgoing_friend_list= ~ error:", error);
  }
};

// chat create
export const chat_create = async (receiver_id) => {
  try {
    return await chatApi.post(
      "/chat/create",
      {
        receiver_id,
      },
      {
        headers: {
          Authorization: getAuthTokenChat(),
        },
      }
    );
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ chat_create= ~ error:", error);
  }
};

// Chat history
export const chat_history = async (chat_id) => {
  try {
    return await chatApi.post(
      "/chat/history",
      {
        chat_id: chat_id,
        page: 1,
        perPage: 500,
      },
      {
        headers: {
          Authorization: getAuthTokenChat(),
        },
      }
    );
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ chat_history= ~ error:", error);
  }
};

// upload image
export const upload_image = async (data) => {
  try {
    return await chatApi.post("/file/upload", data, {
      headers: {
        Authorization: getAuthTokenChat(),
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ upload_image= ~ error:", error);
  }
};

// create group
export const create_group = async (data) => {
  try {
    return await chatApi.post("/group/create", data, {
      headers: {
        Authorization: getAuthTokenChat(),
      },
    });
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ create_group= ~ error:", error);
  }
}

// edit group
export const edit_group = async (data) => {
  try {
    return await chatApi.put("/group/" + data.id,
      {
        name: data.name,
        description: data.description,
        icon: data.icon,
        private: data.private,
      },
      {
        headers: {
          Authorization: getAuthTokenChat(),
        },
      });
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ edit_group= ~ error:", error);
  }
}

// get all group
export const get_group_list = async () => {
  try {
    return await chatApi.get("/group/list", {
      headers: {
        Authorization: getAuthTokenChat(),
      },
    });
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ get_all_group= ~ error:", error);
  }
};
// get group chat list
export const get_group_chat_list = async () => {
  try {
    return await chatApi.get("/group/chat_list", {
      headers: {
        Authorization: getAuthTokenChat(),
      },
    });
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ get_all_group= ~ error:", error);
  }
};

// search group
export const search_group = async (searchText) => {
  try {
    return await chatApi.post(
      "/group/search",
      {
        searchText,
      },
      {
        headers: {
          Authorization: getAuthTokenChat(),
        },
      }
    );
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ search_group= ~ error:", error);
  }
};

// join group
export const join_group = async (group_id, user_id) => {
  try {
    return await chatApi.post(
      "/group/add_member",
      {
        group_id, user_id
      },
      {
        headers: {
          Authorization: getAuthTokenChat(),
        },
      }
    );
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ join_group= ~ error:", error);
  }
};

// get group chat history
export const get_group_chat_history = async (group_id) => {
  try {
    return await chatApi.post(
      "/group/history",
      {
        group_id: group_id,
        page: 1,
        perPage: 500,
      },
      {
        headers: {
          Authorization: getAuthTokenChat(),
        },
      }
    );
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ get_group_chat_history= ~ error:", error);
  }
};

// get group members
export const get_group_members = async (group_id) => {
  try {
    return await chatApi.post(
      "/group/member_list",
      {
        group_id,
      },
      {
        headers: {
          Authorization: getAuthTokenChat(),
        },
      }
    );
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ get_group_members= ~ error:", error);
  }
}

// add group member
export const add_group_member = async (group_id, user_id) => {
  try {
    return await chatApi.post(
      "/group/add_member",
      {
        group_id, user_id
      },
      {
        headers: {
          Authorization: getAuthTokenChat(),
        },
      }
    );
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ add_group_member= ~ error:", error);
  }
}

// remove group member 
export const remove_group_member = async (id) => {
  try {
    return await chatApi.post(
      "/group/remove_member",
      {
        member_id: id
      },
      {
        headers: {
          Authorization: getAuthTokenChat(),
        },
      }
    );
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ remove_group_member= ~ error:", error);
  }
}

// single file get
export const single_file_get = async (id) => {
  try {
    return await chatApi.get(`/file/upload/${id}`, {}, {
      headers: {
        Authorization: getAuthTokenChat(),
      },
    });
  } catch (error) {
    console.log("🚀 ~ file: chat.js:214 ~ const single_file_get= ~ error:", error)
  }
};

export const updateProfileChat = async (data) => {
  try {
    return await chatApi.post(`/auth/update`, data, {
      headers: {
        Authorization: getAuthTokenChat(),
      },
    });
  } catch (error) {
    console.log("🚀 ~ file: chat.js:225 ~ updateProfileChat ~ error:", error)
  }
};

// get last message 
export const get_last_message = async () => {
  try {
    return await chatApi.get(
      "/chat/last_message",
      {
        headers: {
          Authorization: getAuthTokenChat(),
        },
      }
    );
  } catch (error) {
    console.log("🚀 ~ file: chat.js:68 ~ get_group_chat_history= ~ error:", error);
  }
};

// Change Password Chat
export const changePasswordChat = async (data) => {
  try {
    return await chatApi.post("/auth/simple_change_password", data, {
      headers: {
        Authorization: getAuthTokenChat(),
      },
    }
    );
  } catch (error) {
    console.log("🚀 ~ changePasswordChat ~ error:", error)
  }
};

// leave group
export const leaveGroup = async (group_id) => {
  try {
    return await chatApi.post("/group/leave_group", { group_id }, {
      headers: {
        Authorization: getAuthTokenChat(),
      },
    }
    );
  } catch (error) {
    console.log("🚀 ~ leaveGroup ~ error:", error)
  }
}
