import { Avatar, Box, Button, Grid, Pagination, Typography, useMediaQuery } from "@mui/material";
import Layout from "layouts/Layout";
import React, { Fragment, memo } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import AccountDetailsBottomGrowthCurve from "components/AccountDetails/AccountDetailsBottomGrowthCurve";
import MultipleAccountDropdown from "components/Dashboard/MultipleAccountDropdown";
import TradeHistory from "components/Dashboard/TradeHistory";
import SkeletonLoaderDashboard from "components/SkeletonLoader/SkeletonLoaderDashboard";
import Carousel from "react-material-ui-carousel";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setOpenAddAccount } from "redux/features/application/applicationSlice";
import { logIn } from "redux/features/auth/authSlice";
import { setPamUserLoader } from "redux/features/loader/loaderSlice";
import { setPage } from "redux/features/pagination/paginationSlice";
import { setPamUserData } from "redux/features/pam/pamSlice";
import { switchAccount } from "server/auth";
import { getUserPam } from "server/pamm";
import arrowUp from "../../assets/img/5-23-3.png";
import arrowDown from "../../assets/img/5-23-5.png";
import mainBgImg from "../../assets/img/bg-dark-1.png";
import brokerImg from "../../assets/img/broker.png";
import mt4Icon from "../../assets/img/mt4_logo.png";
import mt5Icon from "../../assets/img/mt5_logo.png";
import TradingGrowth from "../../components/Dashboard/TradingGrowth";
import PammUserChartCard from "components/Dashboard/PammUserChartCard";
import PammProfit from "components/Dashboard/PammProfit";

const Dashboard = () => {
  const navigate = useNavigate();
  const permission = useSelector((state) => state.permission.permission);
  const pamUserLoader = useSelector((state) => state.loader.pamUserLoader);
  const pamUser = useSelector((state) => state.pam.pamUser);
  const user = useSelector((state) => state.auth.user);
  const page = useSelector((state) => state.pagination.page);
  const theme = useTheme();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [anchorElMultipleAccount, setAnchorElMultipleAccount] = React.useState(null);
  const [selectedAccount, setSelectedAccount] = React.useState(user?.account_number || "Select account");
  const multipleAccountOpen = Boolean(anchorElMultipleAccount);
  const accounts = useSelector((state) => state.auth.accounts);

  const handleChangePage = async (_, value) => {
    dispatch(setPamUserLoader(true));
    const result = await getUserPam(value);
    if (result?.data?.status) {
      dispatch(setPamUserData(result.data.data));
      dispatch(setPamUserLoader(false));
    } else {
      dispatch(setPamUserLoader(false));
    }
    dispatch(setPage(value));
  };

  const handleOpenAccountModal = () => {
    dispatch(setOpenAddAccount(true));
  };

  const handleClickMultipleAccount = (event) => {
    setAnchorElMultipleAccount(event.currentTarget);
  };
  const handleCloseMultipleAccount = () => {
    setAnchorElMultipleAccount(null);
  };
  const handleSelectedAccount = async (account) => {
    if (account?.account_number === selectedAccount) return;
    setSelectedAccount(account?.account_number);
    const result = await switchAccount(account?.id);
    if (result?.data?.status) {
      localStorage.setItem("auth-token", result?.data?.token);
      localStorage.setItem("refresh-token", result?.data?.refresh_token);
      dispatch(logIn({ ...result?.data?.data }));
      window.location.reload(true);
    }
  };

  return (
    <Layout>
      <Box
        sx={{
          backgroundImage: { xs: "none", sm: "none", md: theme.palette.mode === "dark" ? `url(${mainBgImg})` : "" },
          backgroundSize: "auto 65%",
          backgroundPosition: "top right",
          backgroundRepeat: "no-repeat",
          backgroundOrigin: "content-box",
          backgroundAttachment: "fixed",
        }}
      >
        <Box className="h-full p-5">
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} lg={9}>
                <Grid container rowSpacing={4} columnSpacing={{ xs: 0, sm: 4 }} className="dashBoradLeftSide">
                  {pamUserLoader ? (
                    <SkeletonLoaderDashboard />
                  ) : (
                    <Fragment>
                      {isSmallScreen ? (
                        <Fragment>
                          <Carousel
                            navButtonsAlwaysInvisible={true}
                            indicatorIconButtonProps={{
                              style: {
                                padding: "1px", // 1
                                color: "var(--color-green-1)",
                              },
                            }}
                            activeIndicatorIconButtonProps={{
                              style: {
                                backgroundColor: "#016346",
                              },
                            }}
                            indicatorContainerProps={{
                              style: {
                                marginTop: "5px",
                                textAlign: "center",
                              },
                            }}
                            sx={{
                              width: "100% !important",
                              marginTop: "30px",
                            }}
                            className="boxMainContainer"
                          >
                            {pamUser?.data?.map((item, i) => (
                              <Grid key={i} item xs={12}>
                                <Box
                                  sx={{
                                    borderWidth: "1px",
                                    borderStyle: "solid",
                                    borderColor: theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)",
                                    background:
                                      theme.palette.mode === "dark"
                                        ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)"
                                        : "var(--color-green-10)",
                                    filter: theme.palette.mode === "dark" ? "drop-shadow(0px -13px 50.6px #051c14)" : "drop-shadow(0px -13px 21.9px rgba(5, 28, 20, 0.07))",
                                    backdropFilter: "blur(9px)",
                                    borderRadius: "10px",
                                    padding: "10px",
                                  }}
                                  className="boxContainer"
                                >
                                  <Box className="flex justify-between items-center">
                                    <Box>
                                      <Typography className="!font-[Anek Telugu] dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[17px] !font-normal !capitalize !leading-normal">
                                        {item?.name}
                                      </Typography>
                                      <Typography className="!font-[Poppins] !text-[var(--color-gray-1)] !text-[12px] !font-normal !leading-[18px]">
                                        {item?.account_number}
                                      </Typography>
                                    </Box>
                                    <Box className="space-y-2">
                                      <Avatar
                                        className="w-[46px] h-[46px] rounded-[46px]"
                                        src={item.photo === null && item?.platform === "mt5" ? mt5Icon : item.photo === null && item?.platform === "mt4" ? mt4Icon : item.photo}
                                        alt="Avatar"
                                      />
                                      <Typography className="!text-[11px] text-center">{item?.platform?.toUpperCase()}</Typography>
                                    </Box>
                                  </Box>
                                  <Box className="flex items-center mt-[10px]">
                                    <Typography className="!font-[Open Sans] dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[23px] !font-normal !leading-[18px]">
                                      {/* {item?.total_profit} % */}
                                      <PammProfit id={item?.id} /> %
                                    </Typography>
                                    <Box
                                      className="w-[9px] h-[9px] ml-[8px]"
                                      component={"img"}
                                      src={item?.total_profit < 0 ? arrowDown : item?.total_profit === 0 ? "" : arrowUp}
                                      alt=""
                                    />
                                  </Box>
                                  <PammUserChartCard profit={item?.total_profit} account={item?.id} />
                                  {/* <Box sx={{ height: "50px", width: "100%", marginY: "5px" }}>
                                    <PamUsrChart profit={item?.total_profit} chartData={item?.chart} />
                                  </Box> */}
                                  <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Link to={`/account-details/${item?.id}`}>
                                      <Button
                                        sx={{
                                          textTransform: "capitalize",
                                          padding: "10px",
                                          color: theme.palette.mode === "dark" ? "var(--color-text-2)" : "var(--color-white-1)",
                                          textAlign: "center",
                                          fontFamily: "Open Sans",
                                          fontSize: "10px",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          lineHeight: "14px",
                                          height: "24px",
                                          borderRadius: "4px",
                                          background: theme.palette.mode === "dark" ? "var(--color-green-1)" : "var(--color-black-5)",
                                          boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
                                          "&:hover": {
                                            background: theme.palette.mode === "dark" ? "var(--color-green-1)" : "var(--color-black-5)",
                                          },
                                        }}
                                      >
                                        View Details
                                      </Button>
                                    </Link>
                                    <Typography
                                      sx={{
                                        marginLeft: "10px",
                                        color: theme.palette.mode === "dark" ? "var(--color-green-1)" : "var(--color-black-5)",
                                        fontFamily: "Open Sans",
                                        fontSize: "9px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "14px",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      Connected Account Required
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            ))}
                          </Carousel>
                        </Fragment>
                      ) : (
                        <Fragment>
                          {pamUser?.data?.map((item, i) => (
                            <Grid key={i} item xs={12} md={6} lg={4} className="boxMainContainer">
                              <Box
                                sx={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)",
                                  background:
                                    theme.palette.mode === "dark"
                                      ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)"
                                      : "var(--color-green-10)",
                                  filter: theme.palette.mode === "dark" ? "drop-shadow(0px -13px 50.6px #051c14)" : "drop-shadow(0px -13px 21.9px rgba(5, 28, 20, 0.07))",
                                  backdropFilter: "blur(9px)",
                                  borderRadius: "10px",
                                  padding: "10px",
                                }}
                                className="boxContainer"
                              >
                                <Box className="flex justify-between items-center">
                                  <Box>
                                    <Typography className="!font-[Anek Telugu] dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[17px] !capitalize !font-normal !leading-normal">
                                      {item?.name}
                                    </Typography>
                                    <Typography className="!font-[Poppins] !text-[var(--color-gray-1)] !text-[12px] !font-normal !leading-[18px]">
                                      {item?.account_number}
                                    </Typography>
                                  </Box>
                                  <Box className="space-y-2">
                                    <Avatar
                                      className="w-[46px] h-[46px] rounded-[46px]"
                                      src={item.photo === null && item?.platform === "mt5" ? mt5Icon : item.photo === null && item?.platform === "mt4" ? mt4Icon : item.photo}
                                      alt="Avatar"
                                    />
                                    <Typography className="!text-[11px] text-center">{item?.platform?.toUpperCase()}</Typography>
                                  </Box>
                                </Box>
                                <Box className="flex items-center mt-[10px]">
                                  <Typography className="!font-[Open Sans] dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[23px] !font-normal !leading-[18px]">
                                    <PammProfit id={item?.id} /> %
                                  </Typography>
                                  <Box
                                    className="w-[9px] h-[9px] ml-[8px]"
                                    component={"img"}
                                    src={item?.total_profit < 0 ? arrowDown : item?.total_profit === 0 ? "" : arrowUp}
                                    alt=""
                                  />
                                </Box>
                                <PammUserChartCard profit={item?.total_profit} account={item?.id} />
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                  <Link to={`/account-details/${item?.id}`}>
                                    <Button
                                      sx={{
                                        textTransform: "capitalize",
                                        padding: "10px",
                                        color: theme.palette.mode === "dark" ? "var(--color-text-2)" : "var(--color-white-1)",
                                        textAlign: "center",
                                        fontFamily: "Open Sans",
                                        fontSize: "10px",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        lineHeight: "14px",
                                        width: "100px",
                                        height: "24px",
                                        borderRadius: "4px",
                                        background: theme.palette.mode === "dark" ? "var(--color-green-1)" : "var(--color-black-5)",
                                        boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
                                        "&:hover": {
                                          background: theme.palette.mode === "dark" ? "var(--color-green-1)" : "var(--color-black-5)",
                                        },
                                      }}
                                    >
                                      View Details
                                    </Button>
                                  </Link>
                                  <Typography
                                    sx={{
                                      marginLeft: "10px",
                                      color: theme.palette.mode === "dark" ? "var(--color-green-1)" : "var(--color-black-5)",
                                      fontFamily: "Open Sans",
                                      fontSize: "9px",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      lineHeight: "14px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Connected Account Required
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          ))}
                        </Fragment>
                      )}
                    </Fragment>
                  )}

                  {pamUser?.data?.length > 0 && (
                    <Grid item xs={12} className="flex justify-center items-center">
                      <Pagination count={pamUser?.last_page} variant="outlined" shape="rounded" page={page} onChange={handleChangePage} size={isSmallScreen ? "small" : "medium"} />
                    </Grid>
                  )}

                  <Grid item xs={12} className="dashChatTitle">
                    <Box
                      sx={{
                        paddingBottom: "5px",
                      }}
                      className="performance_chat"
                    >
                      <Box>
                        <Typography className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !font-[Poppins] !font-bold !text-[16px] !leading-normal">
                          Trading Growth Curve
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} className="dashChatMain">
                    <TradingGrowth />
                  </Grid>

                  <Grid item xs={12}>
                    <AccountDetailsBottomGrowthCurve />
                  </Grid>
                </Grid>
              </Grid>
              {/* Right side bar start */}
              <Grid item xs={12} md={4} lg={3}>
                <Grid container rowSpacing={4} columnSpacing={{ sm: 2 }} className="dashBoradRightSide">
                  <Grid item xs={12} sm={4} md={12} order={{ xs: 3, sm: 1 }}>
                    <Box
                      sx={{ filter: "lg:drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))", boxShadow: "0px 16.692px 38.949px 0px rgba(7, 71, 53, 0.10)" }}
                      className="dark:bg-[var(--color-black-9)] md:dark:bg-[var(--color-black-10)] bg-[#0F3529] rounded-[10px] flex flex-col justify-center items-center py-[50px] px-[10px] backdrop-blur-[6px] "
                    >
                      <Typography className="profileTitle !pb-[10px] !text-[22px]  !text-[var(--color-white-1)]">My Account</Typography>
                      {accounts?.length > 0 && (
                        <Button
                          onClick={handleClickMultipleAccount}
                          endIcon={multipleAccountOpen ? <ExpandLess sx={{ color: "var(--color-green-2)" }} /> : <ExpandMore sx={{ color: "var(--color-green-2)" }} />}
                          size="small"
                          className="!mb-[14px]"
                          sx={{
                            background: "var(--color-green-15)",
                            textTransform: "capitalize",
                            boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
                            color: "var(--color-white-1)",
                            fontFamily: "Poppins",
                            fontSize: "15px",
                            borderRadius: "5px",
                            minWidth: "100px !important",
                            minHeight: "40px !important",

                            "&:hover": {
                              background: "var(--color-green-15)",
                            },
                          }}
                        >
                          {selectedAccount}
                        </Button>
                      )}

                      <Box>
                        <Avatar sx={{ width: "100px", height: "95px" }} variant="rounded" src={user?.photo} alt="Avatar" />
                      </Box>
                      <Typography
                        sx={{ marginTop: "20px" }}
                        className="profileDescription !font-[Poppins] !text-[12px] dark:!text-[var(--color-gray-1)] !text-[var(--color-white-1)] !text-center"
                      >
                        Distributions of sales across platform
                      </Typography>
                      {permission?.includes("add account") && (
                        <Typography component={"button"} className={`profileBtn !capitalize cursor-pointer`} onClick={handleOpenAccountModal}>
                          Add Account
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} md={12} order={{ xs: 2, sm: 3 }}>
                    <Box
                      className="dark:bg-[var(--color-black-9)] md:dark:bg-[var(--color-black-10)] bg-[#0F3529] rounded-[10px] text-[var(--color-white-1)] py-[50px] px-[10px] text-center"
                      sx={{
                        boxShadow: "0px 16.692px 38.949px 0px rgba(7, 71, 53, 0.10)",
                        display: "flex",
                        flexFlow: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="!text-[24px] uppercase font-[poppins] !font-bold brokerTitle">Join Broker Account</Typography>
                      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="brokerImage">
                        <Typography component={"img"} src={brokerImg} className="w-full h-auto"></Typography>
                      </Box>
                      <Typography
                        onClick={() => navigate("/broker-portal")}
                        component={"button"}
                        sx={{
                          color: "var(--color-white-1)",
                          fontSize: "16px",
                          fontFamily: "Poppins",
                          padding: "10px 30px",
                          marginTop: "20px",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                        className="profileBtn"
                      >
                        Click Here
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {/* Trade history start here */}
              {user?.role !== "pamm" && (
                <Grid item xs={12}>
                  <Box className="pb-4">
                    <Typography className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !font-[Poppins] !font-bold !text-[16px] !leading-normal">
                      Trade History
                    </Typography>
                  </Box>
                  <TradeHistory />
                </Grid>
              )}
              <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none" } }} className="flex">
                <Typography className="dark:!text-[var(--color-white-1)] !text-[var( --color-black-5)] !font-[Anek Telugu] !text-[15px] !font-normal text-center">
                  © 2024 All Rights
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                <Typography className="dark:!text-[var(--color-white-1)] !text-[var( --color-black-5)] !font-[Anek Telugu] !text-[15px] !font-normal text-left">
                  © 2024 All Rights
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <MultipleAccountDropdown {...{ anchorElMultipleAccount, multipleAccountOpen, handleCloseMultipleAccount, handleSelectedAccount }} />
    </Layout>
  );
};

export default memo(Dashboard);
