import React, { memo, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { useSelector } from "react-redux";

const DailyDoughnutChart = () => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const dailyChart = useSelector((state) => state.chart.dailyChart);
  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const dummyData = {
      // labels: ["AUDJPY", "EURUSD", "EURJPY"],
      labels: dailyChart?.pieChart?.label,
      backgroundColor: ["#0F918A", "#00D094", "#014531"],
      // chartData: [30, 50, 20],
      chartData: dailyChart?.pieChart?.data,
    };

    // Adjust the cutout value to control the inner circle width
    const cutoutValue = 130;

    // Create a new Chart instance
    const newChartInstance = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: dummyData.labels,
        datasets: [
          {
            weight: 9,
            cutout: cutoutValue,
            tension: 0.9,
            pointRadius: 2,
            borderWidth: 0,
            backgroundColor: dummyData.backgroundColor,
            data: dummyData.chartData,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        plugins: {
          // legend: {
          //   position: "top",
          //   align: "center",
          //   labels: {
          //     usePointStyle: true,
          //   },
          // },
          legend: {
            display: false, // Hide legend
          },
        },
        layout: {
          padding: {
            left: 0, // Adjusted to center the chart
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
        // interaction: {
        //   intersect: false,
        //   mode: "index",
        // },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
            type: 'category', // Use 'category' scale for x-axis
          },
        },
      },
    });

    // Save the new chart instance to the ref
    chartInstanceRef.current = newChartInstance;
  }, [dailyChart]);

  return <canvas ref={chartRef} style={{ width: "100%", height: "300px" }} />;
};

export default memo(DailyDoughnutChart);
