import React, { memo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

const BlurImage = memo(({ src, alt, style = null, onClick = () => {} }) => {
  return (
    <LazyLoadImage
      placeholderSrc="/loader.gif"
      alt={alt}
      onClick={onClick}
      {...(style && { style })}
      // effect="blur"
      src={src}
    />
  );
});

export default BlurImage;
