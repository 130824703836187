import { HighlightOff, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOpenBrokerModalJoin } from "redux/features/application/applicationSlice";
import { joinBroker } from "server/broker";
import * as yup from "yup";
import { Select as AntSelect } from "antd";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const validationSchema = yup.object({
  account_number: yup.string("Please enter your account number").required("Account number is required."),
  password: yup.string("Please enter your password").required("The password field is required."),
  platform: yup.string("Please select a platform").required("The platform field is required."),
  server: yup.string("Please select a server").required("The server field is required."),
});

const BrokerJoinModal = memo(({ openBrokerModalJoin }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const currentBroker = useSelector((state) => state.broker.currentBroker);
  const [valueInputOrSelect, setValueInputOrSelect] = useState(false);

  const dispatch = useDispatch();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const menuPaperStyle = {
    background: theme.palette.mode === "dark" ? "#06241A" : "var(--color-green-1)",
  };

  const formik = useFormik({
    initialValues: {
      account_number: user?.account_number?.toString() || "",
      platform: "mt4",
      password: "",
      server: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const data = {
        affiliate_id: currentBroker?.id,
        account_number: values.account_number,
        password: values.password,
        platform: values?.platform?.toLowerCase(),
        server: valueInputOrSelect ? values.server : currentBroker?.broker_server?.find((i) => i.server_name === values.server)?.server_ip,
      };

      const result = await joinBroker(data);
      if (result?.data?.status) {
        enqueueSnackbar(result?.data?.message, { variant: "success" });
        dispatch(setOpenBrokerModalJoin(false));
        setLoading(false);
        formik.resetForm();
      } else {
        enqueueSnackbar(result?.data?.message, { variant: "warning" });
        setLoading(false);
        dispatch(setOpenBrokerModalJoin(false));
        formik.resetForm();
      }
    },
  });

  return (
    <Modal open={openBrokerModalJoin} onClose={() => dispatch(setOpenBrokerModalJoin(false))} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isSmallScreen ? "90%" : 500,
          background: theme.palette.mode === "dark" ? "var(--color-green-7)" : "var(--color-green-10)",
          borderRadius: "5px",
          padding: "15px",
          outline: "none",
        }}
      >
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <Grid container rowSpacing={3} columnSpacing={3} alignItems={"center"} padding={1}>
            <Grid item xs={6} className="text-start">
              <Typography>Broker Join</Typography>
            </Grid>
            <Grid item xs={6} className="text-end">
              <IconButton title="Close" onClick={() => dispatch(setOpenBrokerModalJoin(false))}>
                <HighlightOff />
              </IconButton>
            </Grid>

            <Grid item xs={12}>
              <InputLabel className="profileUpLabel">
                <Typography
                  component={"span"}
                  className="!text-xs"
                  sx={{
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-5)",
                  }}
                >
                  Platform
                </Typography>
              </InputLabel>
              <FormControl
                error={formik.touched.platform && Boolean(formik.errors.platform)}
                sx={{
                  width: "100%",
                  "& .MuiInput-root": {
                    color: "var(--color-black)",
                    fontSize: "16px",
                    fontWeight: 500,
                  },
                  "& label": {
                    fontSize: "16px",
                    color: "var(--color-white-1)",
                    fontWeight: 500,
                  },
                  "& label.Mui-focused": {
                    color: "var(--color-green-2)",
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "var(--color-white-1)",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "var(--color-white-1)",
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: "none",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "var(--color-green-2)",
                  },
                }}
                fullWidth
                size="medium"
                variant="standard"
              >
                <Select
                  MenuProps={{
                    PaperProps: {
                      style: menuPaperStyle,
                    },
                  }}
                  labelId="demo-simple-select-standard-label"
                  id="platform"
                  name="platform"
                  value={formik.values.platform}
                  onChange={formik.handleChange}
                  label="Platform"
                >
                  {currentBroker &&
                    currentBroker?.platform &&
                    JSON.parse(currentBroker.platform)?.map((item, i) => (
                      <MenuItem key={i} sx={{ fontSize: "12px" }} value={item?.toLowerCase()}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormHelperText error={formik.touched.platform && Boolean(formik.errors.platform)}>{formik.touched.platform && formik.errors.platform}</FormHelperText>
            </Grid>

            <Grid item xs={12}>
              <Box className="flex flex-row justify-between items-center">
                <TextField
                  label="Server"
                  variant="standard"
                  type="text"
                  fullWidth
                  size="medium"
                  id="server"
                  name="server"
                  placeholder="12.234.98.77:443"
                  value={formik.values.server}
                  onChange={(val) => {
                    formik.handleChange(val);
                    setValueInputOrSelect(true);
                  }}
                  error={formik.touched.server && Boolean(formik.errors.server)}
                  sx={{
                    "& .MuiInput-root": {
                      color: "var(--color-black) !important",
                      fontSize: "16px",
                      fontWeight: 500,
                    },
                    "& label": {
                      fontSize: "16px",
                      color: theme.palette.mode === "dark" ? "var(--color-white-1) !important" : "var(--color-black) !important",
                      fontWeight: 500,
                    },
                    "& label.Mui-focused": {
                      color: "var(--color-green-2) !important",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottomColor: "var(--color-white-1) !important",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "var(--color-white-1) !important",
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "none !important",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "var(--color-green-2) !important",
                    },
                  }}
                />
                <AntSelect
                  suffixIcon={<KeyboardArrowDownIcon sx={{ color: "#fff" }} />}
                  value=""
                  onChange={(val) => {
                    formik.setFieldValue("server", val);
                    setValueInputOrSelect(false);
                  }}
                  onSelect={(val) => {
                    formik.setFieldValue("server", val);
                    setValueInputOrSelect(false);
                  }}
                  popupMatchSelectWidth={false}
                  dropdownStyle={{
                    width: isSmallScreen ? "fit-content" : "300px",
                    background: "var(--color-green-1)",
                    zIndex: 99999,
                  }}
                  className="custom_select_with_input"
                  options={currentBroker?.broker_server
                    ?.filter((server) => server.platform === formik?.values?.platform?.toLowerCase())
                    ?.map((cred, index) => ({
                      key: index.toString(),
                      label: cred.server_name,
                      value: cred.server_name,
                    }))}
                />
              </Box>
              {formik.touched.server && formik.errors.server && (
                <Typography className="!text-[#f44336] !text-[12px] !mt-1">{formik.touched.server && formik.errors.server}</Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControl
                sx={{
                  "& .MuiInput-root": {
                    color: "var(--color-black) !important",
                    fontSize: "16px",
                    fontWeight: 500,
                  },
                  "& label": {
                    fontSize: "16px",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1) !important" : "var(--color-black) !important",
                    fontWeight: 500,
                  },
                  "& label.Mui-focused": {
                    color: "var(--color-green-2) !important",
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "var(--color-white-1) !important",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "var(--color-white-1) !important",
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: "none !important",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "var(--color-green-2) !important",
                  },
                }}
                size="small"
                fullWidth
              >
                <TextField
                  type="text"
                  label="Account Number"
                  size="small"
                  variant="standard"
                  name="account_number"
                  value={formik.values.account_number}
                  onChange={formik.handleChange}
                  error={formik.touched.account_number && Boolean(formik.errors.account_number)}
                  helperText={formik.touched.account_number && formik.errors.account_number}
                  placeholder="1005"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <TextField
                  label="Password"
                  variant="standard"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  size="medium"
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  sx={{
                    "& .MuiInput-root": {
                      color: "var(--color-black)",
                      fontSize: "16px",
                      fontWeight: 500,
                    },
                    "& label": {
                      fontSize: "16px",
                      color: theme.palette.mode === "dark" ? "var(--color-white-1) !important" : "var(--color-black) !important",
                      fontWeight: 500,
                    },
                    "& label.Mui-focused": {
                      color: "var(--color-green-2)",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottomColor: "var(--color-white-1)",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "var(--color-white-1)",
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "none",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "var(--color-green-2)",
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} display={"flex"} justifyContent={"end"} gap={2}>
              <Button
                onClick={() => dispatch(setOpenBrokerModalJoin(false))}
                size="small"
                sx={{
                  background: "var(--color-red-1)",
                  color: "var(--color-white-1)",
                  textTransform: "capitalize",
                  boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
                  "&:hover": {
                    color: "var(--color-white-1)",
                    background: "var(--color-red-1)",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                size="small"
                sx={{
                  background: "var(--color-green-1)",
                  textTransform: "capitalize",
                  color: "var(--color-white-1)",
                  boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
                  "&:hover": {
                    color: "var(--color-white-1)",
                    background: "var(--color-green-2)",
                  },
                }}
              >
                {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Ok"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
});

export default BrokerJoinModal;
