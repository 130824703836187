import { createSlice } from "@reduxjs/toolkit";

const kycSlice = createSlice({
  name: "kyc",
  initialState: {
    kycPage: false,
    idVerificationStatus: {},
    addressVerificationStatus: {},
  },
  reducers: {
    setKycPage: (state) => {
      state.kycPage = !state.kycPage;
    },
    setIdVerificationStatus: (state, action) => {
      state.idVerificationStatus = action.payload;
    },
    setAddressVerificationStatus: (state, action) => {
      state.addressVerificationStatus = action.payload;
    }
  },
});

export const { setKycPage, setIdVerificationStatus, setAddressVerificationStatus } = kycSlice.actions;
export default kycSlice.reducer;
