import { createSlice } from "@reduxjs/toolkit";

const chatFullScreenSlice = createSlice({
    name: "chatFullScreen",
    initialState: {
        fullScreen: false,
        openFullScreenChat: false,
        anchorElChat: null,
        isSelectedChatOrChannel: false,
    },
    reducers: {
        toggleFullScreen: (state, action) => {
            state.fullScreen = action.payload;
        },
        setOpenFullScreenChat: (state, action) => {
            state.openFullScreenChat = action.payload;
        },
        setAnchorElChat: (state, action) => {
            state.anchorElChat = action.payload;
        },
        clearAnchorElChat: (state) => {
            state.anchorElChat = null;
        },
        setIsSelectedChatOrChannel: (state, action) => {
            state.isSelectedChatOrChannel = action.payload;
        },
    },
});

export const { toggleFullScreen, setOpenFullScreenChat, setAnchorElChat, clearAnchorElChat, setIsSelectedChatOrChannel } = chatFullScreenSlice.actions;
export default chatFullScreenSlice.reducer;
