import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, styled, useTheme } from "@mui/material";
import React, { memo } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    background: theme.palette.mode === "dark" ? "var(--color-green-7)" : "var(--color-green-10)",
    width: "500px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    background: theme.palette.mode === "dark" ? "var(--color-green-7)" : "var(--color-green-10)",
  },
}));

const InfoDialog = memo(({ openInfoDialog, handleInfoClose }) => {
  const theme = useTheme();
  return (
    <BootstrapDialog onClose={handleInfoClose} aria-labelledby="customized-dialog-title" open={openInfoDialog}>
      <DialogTitle sx={{ m: 0, p: 2, background: theme.palette.mode === "dark" ? "var(--color-green-7)" : "var(--color-green-10)" }} id="customized-dialog-title">
        Note Referral Links
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleInfoClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <Typography gutterBottom>
          Referral marketing is a growth marketing tactic that seeks to encourage existing and past customers to recommend a brand to their friends, family, and colleagues.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{ background: "var(--color-green-1)", textTransform: "capitalize", "&:hover": { background: "var(--color-green-1)" } }}
          onClick={handleInfoClose}
        >
          Exit
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
});

export default InfoDialog;
