import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  if (loggedIn) {
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  }

  return <Outlet />;
};

export default memo(AuthLayout);
