import React, { memo, useState } from "react";
import { HighlightOff, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useTheme,
  FormHelperText,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import message from "enum/message";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setOpenAddAccount } from "redux/features/application/applicationSlice";
import { updateAccount } from "server/add_account";
import * as yup from "yup";
import { allAccount, refetchAuth } from "server/auth";
import { logIn, setAccounts } from "redux/features/auth/authSlice";
import { useSnackbar } from "notistack";
import { Select as AntSelect } from "antd";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const validationSchema = yup.object({
  server: yup.string("Enter Correct Server Address").required(message.SERVER_REQUIRED),
  login: yup.string().required(message.LOGIN_ACCOUNT),
  password: yup.string("Enter a Password").required(message.PASSWORD_REQUIRED),
  platform: yup.string("Select a Platform").required(message.PLATFORM_SELECT),
});

const AccountEditModal = ({ editAccountModal, setEditAccountModal, accountInfo }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const servers = useSelector((state) => state.auth.servers);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.application.loading);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [valueInputOrSelect, setValueInputOrSelect] = useState(false);

  const menuPaperStyle = {
    background: theme.palette.mode === "dark" ? "#06241A" : "var(--color-green-1)",
  };
  const getAllAccount = async () => {
    const res = await allAccount();
    if (res?.data?.status) {
      dispatch(setAccounts(res?.data?.data));
    }
  };
  const formik = useFormik({
    initialValues: {
      server: accountInfo
        ? servers.find((server) => server.server_ip === accountInfo.server_ip + ":" + accountInfo.server_port)?.server_name
        : accountInfo?.server_ip + ":" + accountInfo?.server_port || "",
      password: accountInfo?.password || "",
      login: accountInfo?.account_number || "",
      platform: accountInfo?.platform || "mt4",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      dispatch(setLoading(true));
      const data = {
        account_id: accountInfo?.id,
        account: {
          account_number: values.login,
          server: valueInputOrSelect ? values.server : servers.find((i) => i.server_name === values.server)?.server_ip,
          platform: values.platform,
          password: values.password,
        },
      };

      const result = await updateAccount(data);
      if (result && result?.data?.status) {
        setEditAccountModal(false);
        getAllAccount();
        dispatch(setLoading(false));
        enqueueSnackbar(result.data.message, { variant: "success" });
        const resultRefetch = await refetchAuth();
        if (resultRefetch?.data?.status) {
          localStorage.setItem("auth-token", resultRefetch?.data?.token);
          dispatch(logIn({ ...resultRefetch?.data?.data }));
        }
        dispatch(setOpenAddAccount(false));
      } else {
        dispatch(setLoading(false));
        enqueueSnackbar(result?.data?.message ?? "Account Update Failed.", { variant: "warning" });
      }
    },
  });

  return (
    <Modal open={editAccountModal} onClose={() => setEditAccountModal(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "95%",
              sm: "80%",
              md: 500,
            },
            background: theme.palette.mode === "dark" ? "var(--color-green-7)" : "var(--color-green-10)",
            borderRadius: "5px",
            padding: "15px",
            outline: "none",
          }}
        >
          <Grid className="flex items-center justify-between">
            <Typography component={"p"} className="uppercase">
              Update Account
            </Typography>
            <IconButton title="Close" onClick={() => setEditAccountModal(false)}>
              <HighlightOff className="!cursor-pointer" />
            </IconButton>
          </Grid>
          <Grid>
            <Grid className="!pt-8">
              <Grid container className="!p-4 !pt-0" rowSpacing={{ xs: 1, sm: 2, md: 2 }} spacing={2}>
                <Grid item xs={12}>
                  <InputLabel className="profileUpLabel">
                    <Typography
                      component={"span"}
                      className="!text-xs"
                      sx={{
                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-5)",
                      }}
                    >
                      Platform
                    </Typography>
                  </InputLabel>
                  <FormControl
                    error={formik.touched.platform && Boolean(formik.errors.platform)}
                    sx={{
                      width: "100%",
                      "& .MuiInput-root": {
                        color: "var(--color-black)",
                        fontSize: "16px",
                        fontWeight: 500,
                      },
                      "& label": {
                        fontSize: "16px",
                        color: theme.palette.mode === "dark" ? "var(--color-white-1) !important" : "var(--color-black) !important",
                        fontWeight: 500,
                      },
                      "& label.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "var(--color-white-1)",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "var(--color-white-1)",
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "none",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "var(--color-green-2)",
                      },
                    }}
                    fullWidth
                    size="medium"
                    variant="standard"
                  >
                    <Select
                      MenuProps={{
                        PaperProps: {
                          style: menuPaperStyle,
                        },
                      }}
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      name="platform"
                      value={formik.values.platform}
                      onChange={formik.handleChange}
                      label="Platform"
                    >
                      <MenuItem value="mt4">MT4</MenuItem>
                      <MenuItem value="mt5">MT5</MenuItem>
                    </Select>
                  </FormControl>
                  <FormHelperText error={formik.touched.platform && Boolean(formik.errors.platform)}>{formik.touched.platform && formik.errors.platform}</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <Box className="flex flex-row justify-between items-center">
                    <TextField
                      label="Server"
                      variant="standard"
                      type="text"
                      fullWidth
                      size="medium"
                      id="server"
                      name="server"
                      placeholder="12.234.98.77:443"
                      value={formik.values.server}
                      onChange={(val) => {
                        formik.handleChange(val);
                        setValueInputOrSelect(true);
                      }}
                      error={formik.touched.server && Boolean(formik.errors.server)}
                      sx={{
                        "& .MuiInput-root": {
                          color: "var(--color-black) !important",
                          fontSize: "16px",
                          fontWeight: 500,
                        },
                        "& label": {
                          fontSize: "16px",
                          color: theme.palette.mode === "dark" ? "var(--color-white-1) !important" : "var(--color-black) !important",
                          fontWeight: 500,
                        },
                        "& label.Mui-focused": {
                          color: "var(--color-green-2) !important",
                        },
                        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                          borderBottomColor: "var(--color-white-1) !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "var(--color-white-1) !important",
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "none !important",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "var(--color-green-2) !important",
                        },
                      }}
                    />
                    <AntSelect
                      suffixIcon={<KeyboardArrowDownIcon sx={{ color: "#fff" }} />}
                      value=""
                      onChange={(val) => {
                        formik.setFieldValue("server", val);
                        setValueInputOrSelect(false);
                      }}
                      onSelect={(val) => {
                        formik.setFieldValue("server", val);
                        setValueInputOrSelect(false);
                      }}
                      popupMatchSelectWidth={false}
                      dropdownStyle={{
                        width: isSmallScreen ? "fit-content" : "300px",
                        background: "var(--color-green-1)",
                        zIndex: 99999,
                      }}
                      className="custom_select_with_input"
                      options={servers
                        .filter((server) => server.platform === formik.values.platform)
                        .map((cred, index) => ({
                          key: index.toString(),
                          label: cred.server_name,
                          value: cred.server_name,
                        }))}
                    />
                  </Box>
                  {formik.touched.server && formik.errors.server && (
                    <Typography className="!text-[#f44336] !text-[12px] !mt-1">{formik.touched.server && formik.errors.server}</Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <TextField
                      label="Login"
                      variant="standard"
                      type="text"
                      fullWidth
                      size="medium"
                      id="login"
                      login="login"
                      value={formik.values.login}
                      onChange={formik.handleChange}
                      error={formik.touched.login && Boolean(formik.errors.login)}
                      helperText={formik.touched.login && formik.errors.login}
                      sx={{
                        "& .MuiInput-root": {
                          color: "var(--color-black)",
                          fontSize: "16px",
                          fontWeight: 500,
                        },
                        "& label": {
                          fontSize: "16px",
                          color: theme.palette.mode === "dark" ? "var(--color-white-1) !important" : "var(--color-black) !important",
                          fontWeight: 500,
                        },
                        "& label.Mui-focused": {
                          color: "var(--color-green-2)",
                        },
                        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                          borderBottomColor: "var(--color-white-1)",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "var(--color-white-1)",
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "var(--color-green-2)",
                        },
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <TextField
                      label="Password"
                      variant="standard"
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      size="medium"
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                      sx={{
                        "& .MuiInput-root": {
                          color: "var(--color-black)",
                          fontSize: "16px",
                          fontWeight: 500,
                        },
                        "& label": {
                          fontSize: "16px",
                          color: theme.palette.mode === "dark" ? "var(--color-white-1) !important" : "var(--color-black) !important",
                          fontWeight: 500,
                        },
                        "& label.Mui-focused": {
                          color: "var(--color-green-2)",
                        },
                        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                          borderBottomColor: "var(--color-white-1)",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "var(--color-white-1)",
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "var(--color-green-2)",
                        },
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} container className="flex justify-end items-end">
                  <Button
                    type="submit"
                    size="small"
                    sx={{
                      width: "150px",
                      background: "var(--color-green-1)",
                      textTransform: "capitalize",
                      color: "var(--color-white-1)",
                      boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
                      "&:hover": {
                        color: "var(--color-white-1)",
                        background: "var(--color-green-2)",
                      },
                    }}
                  >
                    {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Update Account"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Modal>
  );
};

export default memo(AccountEditModal);
