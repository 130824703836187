import { useSnackbar } from "notistack";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logIn, signOut } from "redux/features/auth/authSlice";
import { signOutChat } from "redux/features/chatSlice/chatSlice";
import { logOut, refetchAuth } from "server/auth";

const LoginRefetch = memo(() => {
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const handlerRefetch = async () => {
    const resultRefetch = await refetchAuth();
    if (resultRefetch?.data?.status) {
      localStorage.setItem("auth-token", resultRefetch?.data?.token);
      dispatch(logIn({ ...resultRefetch?.data?.data }));
    }
  };

  const logoutWhenBlock = async () => {
    return await logOut();
  };

  React.useEffect(() => {
    handlerRefetch();
    if (user?.status === "block") {
      dispatch(signOut());
      dispatch(signOutChat());
      logoutWhenBlock();
      enqueueSnackbar(`You are blocked ! Contact support for more information.`, { variant: "warning" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.status]);

  return null;
});

export default LoginRefetch;
