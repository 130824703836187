import { createSlice } from "@reduxjs/toolkit";

const channelSlice = createSlice({
    name: "channel",
    initialState: {
        channelChatList: [],
        channelList: [],
        channelSearchList: [],
        activeChannel: {},
        channelHistory: [],
        activeChannelContentPost: {}
    },
    reducers: {
        setChannelChatList: (state, action) => {
            state.channelChatList = action.payload;
        },
        setChannelList: (state, action) => {
            state.channelList = action.payload;
        },
        addChannelList: (state, action) => {
            if (state.channelList.some((i) => i.id === action.payload.id)) {
                return;
            }
            state.channelList.push(action.payload);
        },
        setChannelSearchList: (state, action) => {
            state.channelSearchList = action.payload;
        },
        setActiveChannel: (state, action) => {
            state.activeChannel = action.payload;
        },
        setActiveChannelContentPost: (state, action) => {
            state.activeChannelContentPost = action.payload;
        },
        setChannelHistory: (state, action) => {
            state.channelHistory = action.payload;
        },
        addChannelHistory: (state, action) => {
            if (state.channelHistory.some((i) => i.id === action.payload.id)) {
                return;
            }
            state.channelHistory.push(action.payload)
        },
        removeChannelHistory: (state, action) => {
            return {
                ...state,
                channelHistory: state.channelHistory.filter((i) => i.id !== action.payload)
            }
        },
        updateChannelHistory: (state, action) => {
            const updateData = state.channelHistory.map(item => {
                if (item.id === action.payload.id) {
                    return action.payload;
                }
                return item;
            })
            return {
                ...state,
                channelHistory: updateData

            }
        }
    },
});

export const { setChannelChatList, addChannelList, setChannelList, setChannelSearchList, setActiveChannel, setChannelHistory, addChannelHistory, setActiveChannelContentPost, removeChannelHistory, updateChannelHistory } = channelSlice.actions;
export default channelSlice.reducer;
