import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
    name: "notification",
    initialState: {
        unseenNotification: [],
    },
    reducers: {
        setUnseenNotifications: (state, action) => {
            state.unseenNotification = action.payload;
        },
        addUnseenNotification: (state, action) => {
            if (state.unseenNotification.some(i => i.id === action.payload.id)) {
                return;
            }
            state.unseenNotification.push(action.payload);
        },
        updateUnseenNotification: (state, action) => {
            return {
                ...state,
                unseenNotification: state.unseenNotification.filter(i => i.for_id !== action.payload),
            };
        }
        
    },
});

export const { setUnseenNotifications, addUnseenNotification, updateUnseenNotification } = notificationSlice.actions;

export default notificationSlice.reducer;