import React, { useRef, useState } from "react";
import { Box, Button, FormControl, IconButton, TextareaAutosize, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { socketServiceSendChat, socketServiceTyping } from "services/socketService";
import { useDispatch, useSelector } from "react-redux";
import { Collections } from "@mui/icons-material";
import styled from "@emotion/styled";
import { upload_image } from "server/chat";
import { addChatHistory } from "redux/features/chatSlice/chatSlice";
import moment from "moment";
import { useSnackbar } from "notistack";
import { v4 as uuid } from "uuid";

const MessageInput = () => {
  const [message, setMessage] = useState("");
  const chat = useSelector((state) => state.chat);
  const user = useSelector((state) => state.chat.user);
  const chatTyping = useSelector((state) => state.chat.typing);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const receiver_id = useRef();
  const socketTypingCheck = useRef(true);
  // Typing
  if (chat.activeChat.receiver_id === chat.user?.id) {
    receiver_id.current = chat.activeChat.sender_id;
  } else {
    receiver_id.current = chat.activeChat.receiver_id;
  }

  
  const handleInputChange = (event) => {
    setMessage(event.target.value);
    if (socketTypingCheck.current) {
      socketTypingCheck.current = false;
      socketServiceTyping(receiver_id.current);
      setTimeout(() => {
        socketTypingCheck.current = true;
      }, 5000);
    }
  };

  const handleSendMessage = () => {
    const timestamp = moment().toISOString();
    const uniqueId = uuid();


    const trimmedMessage = message.trim();
    if (trimmedMessage) {
      const data = {
        content: trimmedMessage,
        sender_id: user?.id,
        receiver_id: receiver_id?.current,
        id: uuid(),
        u_id: uniqueId,
        createdAt: timestamp,
        type: "TEXT",
      };
      dispatch(addChatHistory(data));
      socketServiceSendChat({
        chat_id: chat?.activeChat?.id,
        content: trimmedMessage,
        sender_id: user?.id,
        receiver_id: receiver_id.current,
        u_id: uniqueId,
      });
    }
    setMessage("");
  };

  const handleKeyDown = (event) => {
    if (message === "") return;
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  // Upload file
  const handleUploadFile = async (event) => {
    const timestamp = moment().toISOString();
    const uniqueId = uuid();
    const img = event.target.files[0];
    // check file type i want only jpg,jpeg,png is allowed
    if (!img) return;
    if (img.type !== "image/jpeg" && img.type !== "image/png") {
      enqueueSnackbar("Only jpg,jpeg,png is allowed", {
        variant: "error",
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", img);
    formData.append("attachment_for", "chat");
    formData.append("for_id", chat.activeChat.id);
    dispatch(
      addChatHistory({
        content: "https://placehold.co/200x200/237259/FFF?text=uploading+please+wait+...",
        sender_id: user?.id,
        receiver_id: receiver_id?.current,
        id: uuid(),
        u_id: uniqueId,
        createdAt: timestamp,
        type: "ATTACHMENT",
      })
    );
    const result = await upload_image(formData);
    if (result?.data?.status) {
      const file_type = "ATTACHMENT";
      let content = process.env.REACT_APP_CHAT_API_BASEURL + "/file/upload/" + result?.data?.data?.id;
      socketServiceSendChat({
        chat_id: chat?.activeChat?.id,
        content: content,
        sender_id: user?.id,
        receiver_id: receiver_id?.current,
        attachment_id: result?.data?.data?.id,
        file_type,
        u_id: uniqueId,
      });
    }
  };

  // get the input element
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
      }}
    >
      <FormControl
        sx={{
          mr: "5px",
          width: "40px",
        }}
      >
        <IconButton component="label">
          <Collections />
          <VisuallyHiddenInput onChange={(e) => handleUploadFile(e)} type="file" />
        </IconButton>
      </FormControl>
      <FormControl
        id="street"
        fullWidth
        value={message}
        onChange={handleInputChange}
        sx={{
          width: "240px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "6px",
            "& fieldset": {
              border: "1px solid var(--color-green-7)",
            },
            "&:hover fieldset": {
              border: "1px solid var(--color-green-1)",
            },
            "&.Mui-focused fieldset": {
              border: `1px solid var(--color-green-1)`,
            },
            "& .MuiInputBase-input": {
              padding: "3px",
              fontSize: "12px",
            },
          },
        }}
      >
        <TextareaAutosize
          minRows={2}
          placeholder="Type your message..."
          className="chat-list-scrollbar"
          value={message}
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
          style={{
            resize: "none",
            width: "100%",
            padding: "5px",
            color: "var(--color-white-1)",
            outline: "none",
            border: "1px solid var(--color-green-7)",
            borderRadius: "10px",
            fontFamily: "Open Sans",
            backgroundColor: "var(--color-dark-green-2)",
            minHeight: "40px",
            maxHeight: "50px",
            overflow: "auto",
            height: "50px",
          }}
        />
      </FormControl>
      <Button
        variant="contained"
        onClick={handleSendMessage}
        sx={{
          padding: "5px",
          marginLeft: "10px",
          borderRadius: "40px",
          minHeight: "40px",
          width: "40px",
          maxWidth: "40px",
          maxHeight: "40px",
          minWidth: "40px",
        }}
        style={{ borderRadius: "50%" }}
        disabled={message === "" ? true : false}
      >
        <SendIcon sx={{ marginLeft: "5px" }} />
      </Button>
      <Typography className="absolute left-1 bottom-1 !text-[10px]">{chatTyping && "Typing..."}</Typography>
    </Box>
  );
};

export default MessageInput;
