import { Box, CircularProgress } from "@mui/material";
import React, { memo } from "react";

const Loader = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw", backgroundColor: "#001811" }}>
      <CircularProgress sx={{ color: "#00c990" }} />
    </Box>
  );
};

export default memo(Loader);
