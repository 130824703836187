import { Avatar, Box, CircularProgress, IconButton, Typography } from "@mui/material";
import React, { Fragment, memo, useEffect, useRef, useState } from "react";
import { channelHistory } from "server/channel";
import { useDispatch, useSelector } from "react-redux";
import { setActiveChannelContentPost, setChannelHistory } from "redux/features/channel/channelSlice";
import moment from "moment";
import { MoreVert } from "@mui/icons-material";
import MoreChannelContentPopover from "./MoreChannelContentPopover";
import { seen_notification } from "server/notification";
import { updateUnseenNotification } from "redux/features/notification/notificationSlice";

const ChannelContent = memo(() => {
  const [anchorElMoreChannelContent, setAnchorElMoreChannelContent] = React.useState(null);
  const moreChannelContentOpen = Boolean(anchorElMoreChannelContent);
  const dispatch = useDispatch();
  const activeChannel = useSelector((state) => state.channel.activeChannel);
  const channelHistoryData = useSelector((state) => state.channel.channelHistory);
  const channelBoxRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const getChannelHistory = async () => {
    setLoading(true);
    const historyChannel = await channelHistory(activeChannel?.id);
    if (historyChannel?.data?.status) {
      setLoading(false);
      dispatch(setChannelHistory(historyChannel?.data?.data[0]?.content));
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeChannel?.id) {
      getChannelHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChannel?.id]);

  useEffect(() => {
    if (channelBoxRef.current) {
      channelBoxRef.current.scrollTop = channelBoxRef.current.scrollHeight;
    }
  }, [channelHistoryData, activeChannel, channelBoxRef, loading]);

  const handleClickMoreChannelContent = (event, item) => {
    setAnchorElMoreChannelContent(event.currentTarget);
    dispatch(setActiveChannelContentPost(item));
  };
  const handleCloseMoreChannelContent = () => {
    setAnchorElMoreChannelContent(null);
  };

  const seenNotification = async () => {
    let for_type = 'CHANNEL';
    const res = await seen_notification(for_type, activeChannel?.id);
    if (res?.data?.status) {
      dispatch(updateUnseenNotification(activeChannel?.id))
    }
  };

  useEffect(() => {
    seenNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChannel?.id]);

  return (
    <Fragment>
      <Box className="chat-list-scrollbar" sx={{ height: "calc(100vh - 130px)", overflowY: "auto" }} ref={channelBoxRef}>
        <Box className="p-2 pt-8">
          {channelHistoryData && channelHistoryData.length > 0 ? (
            loading ? (
              <Box sx={{ height: "calc(100vh - 250px)" }} className="flex items-center justify-center">
                <CircularProgress color="success" />
              </Box>
            ) : (
              <>
                {channelHistoryData?.map((item) => (
                  <Box
                    className="flex items-center gap-2 mb-4"
                    key={item?.id}
                    sx={{
                      "&:hover .moreIconChannelContent": {
                        visibility: "visible",
                      },
                    }}
                  >
                    <Box className="self-start">
                      <Avatar src={activeChannel?.icon} alt="News" />
                    </Box>
                    <Box sx={{ backgroundColor: "var(--color-green-8)", width: "390px", borderRadius: "10px", padding: "4px 8px 4px 8px" }}>
                      <Typography sx={{ lineHeight: 1.2, margin: "1px 0px 8px 0px", fontSize: "16px", marginTop: "5px" }}>{item?.title}</Typography>
                      <Box className="space-y-2" sx={{ backgroundColor: "var(--color-green-7)", borderRadius: "5px" }}>
                        <Box sx={{ width: "100%", height: "250px" }}>
                          <img className="w-full h-full rounded-t-[5px]" src={item?.attachment_content} alt="bit coin" />
                        </Box>
                        <Typography
                          sx={{ color: "rgb(255 255 255 / 70%)", lineHeight: 1.1, fontSize: "15px", paddingX: 1, paddingBottom: "8px" }}
                          dangerouslySetInnerHTML={{ __html: item?.content }}
                        ></Typography>
                      </Box>
                      <Box className="mt-1">
                        <Typography className="!p-0 !m-0 !text-[12px] !text-end !text-[var(--color-gray-3)]">{moment(item?.createdAt).format("h:mm")}</Typography>
                      </Box>
                    </Box>
                    <Box className="self-center">
                      <IconButton onClick={(event) => handleClickMoreChannelContent(event, item)} className="moreIconChannelContent">
                        <MoreVert />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </>
            )
          ) : (
            <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
              <Typography>No news found!</Typography>
            </Box>
          )}
        </Box>
      </Box>
      {anchorElMoreChannelContent && <MoreChannelContentPopover {...{ moreChannelContentOpen, anchorElMoreChannelContent, handleCloseMoreChannelContent }} />}
    </Fragment>
  );
});

export default ChannelContent;
