import { Box, Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import React, { Fragment, memo, useState } from "react";
import WestIcon from "@mui/icons-material/West";
import ReactFileReader from "react-file-reader";
import { enqueueSnackbar } from "notistack";
import pdfImg from "../../assets/img/pdf_icon.jpg";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDispatch } from "react-redux";
import { addNewTicket, setComposeTicket } from "redux/features/supportTicket/supportTicketSlice";
import preview_img_ticket from "../../assets/img/preview_img_ticket.png";
import { useFormik } from "formik";

import * as yup from "yup";
import { open_ticket } from "server/support_ticket";

const validationSchema = yup.object({
  subject: yup.string("Enter your ticket subject").required("Ticket subject is required!"),
  priority: yup.string("Select your ticket priority").required("Ticket priority is required!"),
});

const ComposeTicket = memo(() => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState(null);
  const [typeChk, setTypeChk] = useState("");
  const menuPaperStyle = {
    background: theme.palette.mode === "dark" ? "#06241A" : "var(--color-green-1)",
  };

  let allowedFileTypes = ["image/jpeg", "image/png", "application/pdf"];

  const handleFiles = (files) => {
    const maxFileSizeMB = 2;
    if (!files?.fileList[0]) {
      return;
    }
    const fileSizeMB = files.fileList[0].size / (1024 * 1024);
    if (fileSizeMB > maxFileSizeMB) {
      enqueueSnackbar(`File size exceeds the maximum limit of ${maxFileSizeMB} MB`, {
        variant: "error",
      });
      return;
    }
    if (!allowedFileTypes.includes(files.fileList[0].type)) {
      enqueueSnackbar("Only jpg, jpeg, png, pdf is allowed", {
        variant: "error",
      });
      return;
    }

    setImage(files.fileList[0]);
    setUrl(files.base64);
    setTypeChk(files.fileList[0].type);
  };

  const formik = useFormik({
    initialValues: {
      subject: "",
      priority: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const data = {
        ...values,
        attachment: image,
      };
      const result = await open_ticket(data);
      if (result.data.status) {
        enqueueSnackbar(result.data.message, { variant: "success" });
        dispatch(addNewTicket(result.data.data));
        setLoading(false);
        formik.resetForm();
        setImage(null);
        setUrl(null);
      } else {
        setLoading(false);
        enqueueSnackbar(result?.data?.message, { variant: "warning" });
      }
    },
  });

  return (
    <Box>
      <Box className="h-14 shadow flex items-center">
        <IconButton onClick={() => dispatch(setComposeTicket(false))} sx={{ color: "var(--color-green-1)", marginRight: "4px" }}>
          <WestIcon />
        </IconButton>
        <Box>Compose Your Ticket</Box>
      </Box>
      <Box className="p-4">
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <FormControl className="!w-full !pb-4">
                <TextField
                  id="subject"
                  name="subject"
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  error={formik.touched.subject && Boolean(formik.errors.subject)}
                  helperText={formik.touched.subject && formik.errors.subject}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      width: "100%",
                      color: "white",
                      "& fieldset": {
                        border: "1px solid var(--color-gray-1)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                    "& .MuiInputBase-root": {
                      "& .MuiOutlinedInput-input": {
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                        "&::-webkit-scrollbar": {
                          width: "5px",
                        },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "none",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          borderRadius: "8px",
                          backgroundColor: "var(--color-green-7)",
                        },
                      },
                    },
                  }}
                  label="Subject"
                  variant="outlined"
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                className="!w-full !pb-4"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    color: "white",
                    "& fieldset": {
                      border: "1px solid var(--color-gray-1)",
                    },
                    "&:hover fieldset": {
                      border: "1px solid var(--color-green-1)",
                    },
                    "&.Mui-focused fieldset": {
                      border: `1px solid var(--color-green-2)`,
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                    "&.Mui-focused": {
                      color: "var(--color-green-2)",
                    },
                  },
                }}
                size="small"
              >
                <InputLabel id="demo-select-small-label">Priority</InputLabel>
                <Select
                  // displayEmpty
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  label="Priority"
                  name="priority"
                  value={formik.values.priority}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.priority && Boolean(formik.errors.priority)}
                  sx={{
                    ".MuiSvgIcon-root ": {
                      fill: "white",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: menuPaperStyle,
                    },
                  }}
                >
                  <MenuItem value="high">High</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="low">Low</MenuItem>
                </Select>
                <Typography className="!text-[#f44336] !text-[13px] !mt-1">{formik.touched.priority && formik.errors.priority}</Typography>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className="!w-full">
                <TextField
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      width: "100%",
                      color: "white",
                      "& fieldset": {
                        border: "1px solid var(--color-gray-1)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                    "& .MuiInputBase-root": {
                      "& .MuiOutlinedInput-input": {
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                        "&::-webkit-scrollbar": {
                          width: "5px",
                        },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "none",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          borderRadius: "8px",
                          backgroundColor: "var(--color-green-7)",
                        },
                      },
                    },
                  }}
                  label="Description"
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            {/* Preview Start xs & sm screen*/}
            <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
              <Box className="!mb-5">Preview</Box>
              <Grid container>
                {typeChk === "application/pdf" ? (
                  <Grid item xs={12} className="!ps-0 !text-center !cursor-pointer">
                    {url ? <img src={pdfImg} alt="" className="w-fit !object-cover !h-40" /> : <img src={preview_img_ticket} alt="" className="!h-40" />}
                  </Grid>
                ) : (
                  <Grid item xs={12} className="!ps-0 !text-center !cursor-pointer">
                    {url ? <img src={url} alt="" className="w-full !object-cover h-40" /> : <img src={preview_img_ticket} alt="" className="!h-40" />}
                  </Grid>
                )}
              </Grid>
            </Grid>
            {/* Preview End md screen*/}
            {/* Upload Start */}
            <Grid item xs={12} sm={12} md={8}>
              <Box className="!mb-5">Attachment</Box>
              <FormControl
                fullWidth
                className="!cursor-pointer relative"
                sx={{
                  position: "relative",
                  "& .MuiOutlinedInput-root": {
                    height: "40px",
                    width: "100%",
                    color: "#080808",
                    "& fieldset": {
                      border: "1px solid #E3E6EA",
                    },
                    "&:hover fieldset": {
                      border: "1px solid var(--color-green-1)",
                    },
                    "&.Mui-focused fieldset": {
                      border: `1px solid var(--color-green-2)`,
                    },
                  },
                }}
              >
                <ReactFileReader fileTypes={["jpg", "jpeg", "png", "pdf"]} base64={true} handleFiles={handleFiles} className="!relative">
                  <Fragment>
                    <CloudUploadIcon
                      sx={{
                        position: "absolute",
                        left: "50%",
                        top: "0%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "60px",
                        background: "white",
                        padding: "10px",
                        borderRadius: "50%",
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        color: "var(--color-green-1)",
                      }}
                    />
                    <Box className="!text-center rounded" sx={{ padding: "30px", background: "var(--color-green-1)" }}>
                      <Typography component={"p"} variant="p" className="!text-lg" sx={{ color: "var(--color-white-1)" }}>
                        Choose Your Files here
                      </Typography>
                    </Box>
                  </Fragment>
                </ReactFileReader>
              </FormControl>
            </Grid>
            {/* Upload End */}
            {/* Preview Start md screen*/}
            <Grid item md={4} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
              <Box className="!mb-5 !text-center">Preview</Box>
              <Grid container>
                {typeChk === "application/pdf" ? (
                  <Grid item xs={12} className="!ps-0 !text-center !cursor-pointer">
                    {url ? <img src={pdfImg} alt="" className="w-full !object-cover !h-40 !rounded-md" /> : <img src={preview_img_ticket} alt="" className="!h-20 !rounded-md" />}
                  </Grid>
                ) : (
                  <Grid item xs={12} className="!ps-0 !text-center !cursor-pointer">
                    {url ? <img src={url} alt="" className="w-full !object-cover !h-40 !rounded-md" /> : <img src={preview_img_ticket} alt="" className="!h-20 !rounded-md" />}
                  </Grid>
                )}
              </Grid>
            </Grid>
            {/* Preview End md screen*/}
            <Grid item xs={12} className="">
              <Button
                type="submit"
                className="!p-2 !rounded !float-right !border-0 !text-[var(--color-white-1)] !cursor-pointer !ml-2"
                sx={{ background: "var(--color-green-1)", ":hover": { background: "var(--color-green-1)" } }}
              >
                {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Submit"}
              </Button>
              <Button
                type="reset"
                onClick={() => {
                  formik.resetForm();
                  setImage(null);
                  setUrl(null);
                }}
                className="!p-2 !rounded !float-right !border-0 !text-[var(--color-white-1)] !cursor-pointer "
                sx={{ background: "var(--calender-high)", ":hover": { background: "var(--calender-high)" } }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
});

export default ComposeTicket;
