import { FormControl, Grid, Pagination, TextField, useTheme, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState, memo } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useDispatch, useSelector } from "react-redux";
import { setCloseTradeHistoryLoading, setOpenTradeHistoryLoading } from "redux/features/loader/loaderSlice";
import { closeTradeHistory } from "server/close_trade";
import { setCloseTrade, setCloseTradeHistory, setOpenTradeHistory } from "redux/features/trade/tradeSlice";
import { getTradeOpenHistory } from "server/open_trade";
import FilterTrades from "components/AccountDetails/FilterTrades";
import OpenAndCloseTradeLoader from "components/SkeletonLoader/OpenAndCloseTradeLoader";
import CloseTradeHistoryTable from "./CloseTradeHistoryTable";
import OpenTradeHistoryTable from "./OpenTradeHistoryTable";

const TradeHistory = memo(() => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const openTradeHistoryLoading = useSelector((state) => state.loader.openTradeHistoryLoading);
  const closeTradeHistoryLoading = useSelector((state) => state.loader.closeTradeHistoryLoading);

  const theme = useTheme();
  const [TableTab, setTableTab] = React.useState("1");
  const [filterTrades, setFilterTrades] = useState(10);
  const [searchText, setSearchText] = useState("");
  const closeHistoryTrade = useSelector((state) => state.trade.closeTradeHistory);
  const openTradeHistory = useSelector((state) => state.trade.openTradeHistory);
  const [openTradePage, setOpenTradePage] = useState(1);
  const [closeTradePage, setCloseTradePage] = useState(1);

  const handleTableTab = (_, newValue) => {
    setTableTab(newValue);
    setOpenTradePage(1);
    setCloseTradePage(1);
    setSearchText("");
  };

  const handleChangeOpenTradePageHistory = async (_, value) => {
    dispatch(setOpenTradeHistoryLoading(true));
    const result = await getTradeOpenHistory(openTradePage, filterTrades, searchText);
    if (result?.status === 200) {
      dispatch(setOpenTradeHistory(result.data));
      dispatch(setOpenTradeHistoryLoading(false));
    } else {
      dispatch(setOpenTradeHistoryLoading(false));
    }
    setOpenTradePage(value);
  };

  const getOpenTradeHistory = async () => {
    dispatch(setOpenTradeHistoryLoading(true));
    const result = await getTradeOpenHistory(openTradePage, filterTrades, searchText);
    if (result?.status === 200) {
      dispatch(setOpenTradeHistory(result?.data));
      dispatch(setOpenTradeHistoryLoading(false));
    } else {
      dispatch(setOpenTradeHistoryLoading(false));
    }
  };

  const getCloseTradeHistory = async () => {
    dispatch(setCloseTradeHistoryLoading(true));
    const result = await closeTradeHistory(closeTradePage, filterTrades, searchText);
    if (result?.status === 200) {
      dispatch(setCloseTradeHistory(result?.data));
      dispatch(setCloseTradeHistoryLoading(false));
    } else {
      dispatch(setCloseTradeHistoryLoading(false));
    }
  };

  const handleChangeCloseTradeHistoryPage = async (_, value) => {
    dispatch(setCloseTradeHistoryLoading(true));
    const result = await closeTradeHistory(closeTradePage, filterTrades, searchText);
    if (result?.status === 200) {
      dispatch(setCloseTrade(result?.data));
      dispatch(setCloseTradeHistoryLoading(false));
    } else {
      dispatch(setCloseTradeHistoryLoading(false));
    }
    setCloseTradePage(value);
  };

  useEffect(() => {
    getOpenTradeHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, filterTrades, openTradePage]);

  useEffect(() => {
    getCloseTradeHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, filterTrades, closeTradePage]);

  return (
    <TabContext value={TableTab}>
      <Box
        sx={{
          display: { xs: "inline-flex", sm: "flex", md: "flex" },
          flexWrap: { xs: "wrap" },
          justifyContent: { sm: "space-between", md: "space-between" },
          gap: 2,
        }}
      >
        <TabList
          sx={{
            border: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}`,
            backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-3)" : "var(--color-green-10)",
            borderRadius: "10px 10px 0px 0px",
            "& .MuiTabs-indicator": {
              backgroundColor: "transparent",
            },
            "& .MuiTab-root": {
              color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var!(--color-white-2)",
              "&.Mui-selected": {
                backgroundColor: "var(--color-green-2)",
              },
            },
          }}
          className="w-fit !h-[30px]"
          onChange={handleTableTab}
          aria-label="lab API tabs example"
        >
          <Tab
            onClick={() => getOpenTradeHistory()}
            className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !font-medium !normal-case"
            label="Open Trades"
            value="1"
          />
          <Tab
            onClick={() => getCloseTradeHistory()}
            className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !font-medium !normal-case"
            label="Closed Trades"
            value="2"
          />
        </TabList>
        <Box className="flex items-center space-x-2" sx={{ paddingBottom: "10px", paddingTop: "10px" }}>
          <Box>
            <FormControl
              id="street"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "30px",
                  width: "120px",
                  color: theme.palette.mode === "dark" ? "var(--color-gray-3)" : "var(--color-black-2)",
                  backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-3)" : "var(--color-green-10)",
                  padding: 1,
                  boxSizing: "border-box",
                  borderRadius: "6px",
                  "& fieldset": {
                    border: "1px solid var(--color-green-7)",
                  },
                  "&:hover fieldset": {
                    border: "1px solid var(--color-green-1)",
                  },
                  "&.Mui-focused fieldset": {
                    border: `1px solid var(--color-green-2)`,
                  },
                  "& .MuiInputBase-input": {
                    padding: "3px",
                    fontSize: "12px",
                  },
                },
              }}
            >
              <TextField variant="outlined" value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Search" />
            </FormControl>
          </Box>
          <FilterTrades {...{ filterTrades, setFilterTrades }} />
          <Box
            className="dark:text-[var(--color-gray-3)] text-[var(--color-black-2)] font-[Open Sans] text-[12px] font-normal pr-1"
            sx={{ display: { xs: "none", sm: "none", md: "block" } }}
          >
            records per page
          </Box>
        </Box>
      </Box>
      <TabPanel sx={{ paddingLeft: "16px", paddingRight: "0px" }} value="1">
        <Grid
          container
          spacing={2}
          sx={{
            borderRadius: "10px",
          }}
        >
          {openTradeHistoryLoading ? <OpenAndCloseTradeLoader /> : <OpenTradeHistoryTable />}

          {openTradeHistory?.data?.length > 0 && (
            <Grid item xs={12} className="flex justify-center items-center !pt-5">
              <Pagination
                count={openTradeHistory?.pagination?.last_page}
                variant="outlined"
                shape="rounded"
                page={openTradePage}
                onChange={handleChangeOpenTradePageHistory}
                size={isSmallScreen ? "small" : "medium"}
              />
            </Grid>
          )}
        </Grid>
      </TabPanel>
      <TabPanel sx={{ paddingLeft: "16px", paddingRight: "0px" }} value="2">
        <Grid
          container
          spacing={2}
          sx={{
            borderRadius: "10px",
          }}
        >
          {closeTradeHistoryLoading ? <OpenAndCloseTradeLoader /> : <CloseTradeHistoryTable />}
          {closeHistoryTrade?.data?.length > 0 && (
            <Grid item xs={12} className="flex justify-center items-center">
              <Pagination
                count={closeHistoryTrade?.pagination?.last_page}
                variant="outlined"
                shape="rounded"
                page={closeTradePage}
                onChange={handleChangeCloseTradeHistoryPage}
                size={isSmallScreen ? "small" : "medium"}
              />
            </Grid>
          )}
        </Grid>
      </TabPanel>
    </TabContext>
  );
});

export default TradeHistory;
