import mirrorApi from '../api/mirror_trade_api';
const { getAuthToken } = require("./auth");

export const getTradeOpen = async (user_id, page, filterTrades, searchText) => {
    try {
        return await mirrorApi.get(`/open-orders/${user_id}?page=${page}&per_page=${filterTrades}&search=${searchText}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ file: open_trade.js:12 ~ symbols ~ error:", error)
    }
};
export const getTradeOpenHistory = async (page, filterTrades, searchText) => {
    try {
        return await mirrorApi.get(`user/open/trades?page=${page}&per_page=${filterTrades}&search=${searchText}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ getTradeOpenHistory ~ error:", error)
    }
};
