import mirrorApi from '../api/mirror_trade_api';
import { getAuthToken } from './auth';

export const getIbDashboard = async () => {
    try {
        return await mirrorApi.get(`/ib/dashbaord`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ getIbDashboard ~ error:", error)
    }
}
export const ibRequest = async () => {
    try {
        return await mirrorApi.post(`/user/ib-request`, {}, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ ibRequest ~ error:", error)
    }
}

export const affiliateClients = async () => {
    try {
        return await mirrorApi.get(`/ib/affiliate`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
    }
}

export const getAffiliateClientsByQuery = async (queryParams) => {
    try {
        const queryString = Object.entries(queryParams)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join("&");
        return await mirrorApi.get(`/ib/affiliate?${queryString}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};

export const commissionReports = async ({
    page = 1,
    package_name = "",
    package_type = "",
    commission_level = "",
    min_price = "",
    max_price = "",
    min_amount = "",
    max_amount = "",
}) => {
    try {
        return await mirrorApi.get(`/reports/ib-commission?page=${page}&package_name=${package_name}&package_type=${package_type}&commission_level=${commission_level}&min_price=${min_price}&max_price=${max_price}&min_amount=${min_amount}&max_amount=${max_amount}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ commissionReports ~ error:", error)
    }
}