import { East, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import message from "enum/message";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "redux/features/application/applicationSlice";
import { logIn } from "redux/features/auth/authSlice";
import { signInChat } from "redux/features/chatSlice/chatSlice";
import { setNavigateAuth } from "redux/features/navigateSlice/navigateSlice";
import { signIn } from "server/auth";
import { login } from "server/chat";
import * as yup from "yup";

const validationSchema = yup.object({
  username: yup.string("Enter a valid username or email").required(message.USER_OR_EMAIL),
  password: yup.string("Enter your password").min(6, message.MIN_PASSWORD).required(message.PASSWORD_REQUIRED),
});

const Login = () => {
  const [verifiedError, setVerifiedError] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.application.loading);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      dispatch(setLoading(true));

      const mirrorResult = await signIn({
        username: values.username,
        password: values.password,
      });

      if (mirrorResult && mirrorResult?.data?.status) {
        if (mirrorResult?.data?.data?.email) {
          const result = await login({
            email: mirrorResult?.data?.data?.email,
            password: values.password,
          });

          if (result?.data?.status) {
            localStorage.setItem("chat-auth-token", result?.data?.data?.accessToken);
            localStorage.setItem("chat-refresh-token", result?.data?.data?.refreshToken);
            dispatch(signInChat({ ...result?.data?.data?.data }));
          } else {
          }
        } else {
          const result = await login({
            email: values.username,
            password: values.password,
          });

          if (result?.data?.status) {
            localStorage.setItem("chat-auth-token", result?.data?.data?.accessToken);
            localStorage.setItem("chat-refresh-token", result?.data?.data?.refreshToken);
            dispatch(signInChat({ ...result?.data?.data?.data }));
          } else {
            dispatch(setLoading(false));
          }
        }
        localStorage.setItem("auth-token", mirrorResult?.data?.token);
        localStorage.setItem("refresh-token", mirrorResult?.data?.refresh_token);
        dispatch(logIn({ ...mirrorResult?.data?.data }));
        dispatch(setLoading(false));
      } else {
        if (!mirrorResult?.data?.errors?.verified) {
          setVerifiedError(mirrorResult?.data?.errors?.verified);
        }
        if (mirrorResult?.data?.username) {
          enqueueSnackbar(mirrorResult?.data?.username, { variant: "warning" });
        }
        if (mirrorResult?.data?.password) {
          enqueueSnackbar(mirrorResult?.data?.password, { variant: "warning" });
        }
        if (mirrorResult?.data?.message) {
          enqueueSnackbar(mirrorResult?.data?.message, { variant: "warning" });
        }
        dispatch(setLoading(false));
      }
    },
  });

  return (
    <form autoComplete="off" className="w-[90%] md:w-[335px] mx-auto space-y-6" onSubmit={formik.handleSubmit}>
      <Box>
        <TextField
          label="Username or Email"
          variant="standard"
          type="text"
          fullWidth
          size="medium"
          id="username"
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
          sx={{
            "& .MuiInput-root": {
              color: "var(--color-black)",
              fontSize: "16px",
              fontWeight: 500,
            },
            "& label": {
              fontSize: "16px",
              color: "var(--color-white-1)",
              fontWeight: 500,
            },
            "& label.Mui-focused": {
              color: "var(--color-green-2)",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "var(--color-green-2)",
            },
          }}
        />
        {verifiedError === false && (
          <Box className="text-end mt-2">
            <Typography onClick={() => dispatch(setNavigateAuth("verifyEmailOtp"))} className="text-sm hover:text-[var(--color-green-1)] cursor-pointer hover:underline">
              Unverified?
            </Typography>
          </Box>
        )}
      </Box>
      <Box>
        <TextField
          label="Password"
          variant="standard"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          size="medium"
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          sx={{
            "& .MuiInput-root": {
              color: "var(--color-black)",
              fontSize: "16px",
              fontWeight: 500,
            },
            "& label": {
              fontSize: "16px",
              color: "var(--color-white-1)",
              fontWeight: 500,
            },
            "& label.Mui-focused": {
              color: "var(--color-green-2)",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "var(--color-green-2)",
            },
          }}
        />
        <Box className="text-end mt-2">
          <Typography
            onClick={() => dispatch(setNavigateAuth("forgot_password_send_mail"))}
            className="text-sm hover:text-[var(--color-green-1)] cursor-pointer hover:underline"
            component={"p"}
          >
            Forgot password?
          </Typography>
        </Box>
      </Box>
      <Button
        type="submit"
        sx={{
          background: "var(--color-green-1)",
          textTransform: "none",
          "&:hover": {
            background: "var(--color-green-11)",
          },
        }}
        disableRipple
        variant="contained"
        fullWidth
      >
        {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Login"}
      </Button>
      <Box className="flex justify-center items-center gap-2 pb-3">
        <Typography>Don't have an account?</Typography>
        <Typography
          component={"p"}
          className="hover:!text-[var(--color-green-1)] !cursor-pointer !flex !justify-center !items-center"
          onClick={() => dispatch(setNavigateAuth("registration"))}
        >
          Registration <East className="!ml-1" />
        </Typography>
      </Box>
    </form>
  );
};

export default memo(Login);
