import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addChatHistory,
  addCurrentChatHistory,
  addFriendList,
  addGroupChatHistory,
  addIncomingFriendList,
  addOutGoingFriendList,
  removeFriendList,
  removeIncomingFriendList,
  removeOutGoingFriendList,
} from "redux/features/chatSlice/chatSlice";
import { addUnseenNotification } from "redux/features/notification/notificationSlice";
import { socket, socketServiceDisconnect, socketServicePingPong } from "services/socketServiceFullScreenChat";
import notificationSoundSrc from "../assets/notification/notification.mp3";
import { Howl } from "howler";
import { addChannelHistory, removeChannelHistory, updateChannelHistory } from "redux/features/channel/channelSlice";
import { store } from "redux/store";

const InitialFullScreenChatSocket = memo(() => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.chat.user);

  let notificationSound = new Howl({
    src: [notificationSoundSrc],
  });

  const socketStreamService = async () => {
    socket.on("TokenInvalid", async (res) => {
      console.log("🚀 ~ socket.on ~ res:", res);
    });
    socket.on("chat", (res) => {
      if (store.getState().chat.activeChat?.id !== res?.chat_id) {
        notificationSound.play();
        const data = {
          user_id: user?.id,
          for_id: res?.chat_id,
          for_type: "CHAT",
        };
        dispatch(addUnseenNotification(data));
      } else {
        if (res?.sender_id !== user?.id) {
          dispatch(addChatHistory(res));
        } else {
          dispatch(addCurrentChatHistory(res));
        }
      }
    });
    socket.on("friendRequest", (res) => {
      if (res.user_one_id === user.id) {
        dispatch(
          addOutGoingFriendList({
            id: res.id,
            user: res.user_two,
          })
        );
      } else {
        dispatch(
          addIncomingFriendList({
            id: res.id,
            user: res.user_one,
          })
        );
      }
    });
    socket.on("friendRequestAccept", (res) => {
      if (res.status === "WITHDRAW") {
        if (res.user_one_id === user.id) {
          dispatch(
            removeOutGoingFriendList({
              id: res.id,
            })
          );
        } else {
          dispatch(
            removeIncomingFriendList({
              id: res.id,
            })
          );
        }
      } else if (res.status === "ACCEPTED") {
        if (res.user_one_id === user.id) {
          dispatch(
            removeOutGoingFriendList({
              id: res.id,
            })
          );
          dispatch(
            addFriendList({
              id: res.id,
              user: res.user_two,
            })
          );
        } else {
          dispatch(
            removeIncomingFriendList({
              id: res.id,
            })
          );
          dispatch(
            addFriendList({
              id: res.id,
              user: res.user_one,
            })
          );
        }
      } else if (res.status === "REJECTED") {
        if (res.user_one_id === user.id) {
          dispatch(
            removeOutGoingFriendList({
              id: res.id,
            })
          );
        } else {
          dispatch(
            removeIncomingFriendList({
              id: res.id,
            })
          );
        }
      } else if (res.status === "UNFRIEND") {
        dispatch(
          removeFriendList({
            id: res.id,
          })
        );
      }
    });
    // group
    socket.on("groupChat", (res) => {
      console.log("🚀 ~ socket.on ~ res:", res);
      dispatch(addGroupChatHistory(res));
    });
    // On message delete event
    // socket.on("deleteContent", (res) => {
    //   dispatch(deleteChatHistory(res));
    // });

    // Channel socket start here

    socket.on("channelChat", (res) => {
      // console.log("🚀 ~ socket.on ~ res:", res);
      dispatch(addChannelHistory(res));
    });

    socket.on("channelNotFound", (res) => {
      // console.log("🚀 ~ socket.on ~ res:", res);
    });

    socket.on("deleteChannelContent", (res) => {
      // console.log("🚀 ~ socket.on ~ res:", res);
      dispatch(removeChannelHistory(res));
    });

    socket.on("editChannelChat", (res) => {
      // console.log("🚀 ~ socket.on ~ res:", res);
      dispatch(updateChannelHistory(res));
    });

    // Notification
    socket.on("notification", (res) => {
      // console.log("🚀 ~ socket.on ~ res:", res);

      if (res.for_type === "CHAT" || res.for_type === "GROUP_CHAT" || res.for_type === "CHANNEL") {
        dispatch(addUnseenNotification(res));
      }
    });
  };
  useEffect(() => {
    if (!socket) return;
    // socketServiceConnect();
    socketStreamService();

    const intId = setInterval(() => {
      socketServicePingPong();
    }, 1000 * 30);
    return () => {
      clearInterval(intId);
      socketServiceDisconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, user]);
  return null;
});

export default InitialFullScreenChatSocket;
