import { Avatar, Box, CircularProgress, Divider, IconButton, InputBase, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import WestIcon from "@mui/icons-material/West";
import { useDispatch, useSelector } from "react-redux";
import PhotoIcon from "@mui/icons-material/Photo";
import SendIcon from "@mui/icons-material/Send";
import { setChatTicket } from "redux/features/supportTicket/supportTicketSlice";
import moment from "moment";
import { replayTicket } from "server/support_ticket";
import AdminReplay from "./Conversation/AdminReplay";
import UserReplay from "./Conversation/UserReplay";
import ReactFileReader from "react-file-reader";
import { enqueueSnackbar } from "notistack";
import { Close } from "@mui/icons-material";

const ChatTicket = memo(() => {
  const singleTicketLoading = useSelector((state) => state.support.singleTicketLoading);
  const [replyDescription, setReplyDescription] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [replayFromUser, setReplayFromUser] = useState({});
  const dispatch = useDispatch();
  const singleTicket = useSelector((state) => state.support.singleTicket);

  const handleFiles = (files) => {
    const maxFileSizeMB = 2;
    if (!files?.fileList[0]) {
      return;
    }
    const fileSizeMB = files.fileList[0].size / (1024 * 1024);
    if (fileSizeMB > maxFileSizeMB) {
      enqueueSnackbar(`File size exceeds the maximum limit of ${maxFileSizeMB} MB`, {
        variant: "error",
      });
      return;
    }
    if (files.fileList[0].type !== "image/jpeg" && files.fileList[0].type !== "image/png") {
      enqueueSnackbar("Only jpg, jpeg, png is allowed", {
        variant: "error",
      });
      return;
    }

    setAttachment(files.fileList[0]);
    setImageUrl(files.base64);
  };

  const handleReplayDescription = async () => {
    const data = {
      ...(attachment && { attachment }),
      reply_description: replyDescription,
    };

    const result = await replayTicket(singleTicket?.id, data);
    if (result?.data?.status) {
      setReplayFromUser(result?.data?.data);
      setImageUrl(null);
      setReplyDescription(null);
    } else {
      if (result.data?.errors?.reply_description[0]) {
        enqueueSnackbar(result.data?.errors?.reply_description[0], { variant: "warning" });
      } else {
        enqueueSnackbar(result.data.message, { variant: "error" });
      }
    }
  };

  const handleKeyDown = (event) => {
    // if (replyDescription === "") return;
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleReplayDescription();
    }
  };

  return (
    <Box className="relative">
      <Box className="flex justify-between items-center bg-[#0F3529] !shadow">
        <Box className="h-14 flex items-center">
          <IconButton sx={{ color: "var(--color-green-1)", marginRight: "4px" }} onClick={() => dispatch(setChatTicket(false))}>
            <WestIcon />
          </IconButton>
          <Box>
            <Typography component={"p"} variant="p">
              {singleTicket?.subject}
            </Typography>
            <Typography component={"p"} variant="p">
              {moment(singleTicket?.updated_at).format("DD MMM YYYY hh:mm:ss A")}
            </Typography>
          </Box>
        </Box>
        <Box className="pe-2">
          <Typography
            component={"p"}
            variant="p"
            className="!text-xs !text-center !capitalize"
            sx={{ border: "1px solid var(--color-green-2)", borderRadius: "15px", padding: "5px", width: "70px" }}
          >
            {singleTicket?.priority}
          </Typography>
        </Box>
      </Box>

      {singleTicketLoading ? (
        <Box className="w-full h-[550px] flex justify-center">
          <CircularProgress sx={{ mt: 5 }} color="success" size={50} />
        </Box>
      ) : (
        <>
          {/* Ticket Description */}
          <Box className="p-2 h-[230px]" sx={{ borderBottom: "1px solid var(--color-green-2)" }}>
            {singleTicket?.attachment && <Typography component={"img"} src={singleTicket?.attachment} alt="" className="!h-28" />}
            <Typography component={"p"} variant="p">
              {singleTicket?.description}
            </Typography>
          </Box>

          {/* Chat History */}
          <Box className="!h-80 overflow-y-scroll chatTicketScroll">
            {/* ================Admin Messages Replay Start================ */}
            <AdminReplay />
            {/* ================Admin Messages Replay End================ */}
            {/* ++++++++++++++++User Messages Replay Start++++++++++++++++ */}
            <UserReplay />
            {replayFromUser && Object.keys(replayFromUser)?.length > 0 && (
              <Box key={replayFromUser?.id} className="p-2">
                <Box className="flex flex-row-reverse">
                  <Box className="flex items-end justify-start !ml-2">
                    <Avatar src={replayFromUser?.reply_by?.photo} alt="Avatar"></Avatar>
                  </Box>
                  <Box className="p-3 shadow-lg rounded-lg bg-[var(--color-green-7)]">
                    <Typography component={"p"} variant="p" className="!text-xs !capitalize !mb-1">
                      {replayFromUser?.reply_by?.name}
                    </Typography>
                    {replayFromUser?.attachment && <Typography component={"img"} src={replayFromUser?.attachment} alt="" className="!h-28" />}
                    <Typography component={"p"} variant="p">
                      {replayFromUser?.reply_description}
                    </Typography>
                    <Typography component={"p"} variant="p" className="!text-xs">
                      {moment(replayFromUser?.created_at).format("DD MMM YYYY hh:mm:ss A")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            {/* ++++++++++++++++User Messages Replay End++++++++++++++++ */}
          </Box>
        </>
      )}

      {imageUrl && (
        <Box className="flex justify-between items-start w-[90%] mx-auto p-4 bg-[var(--color-green-7)] rounded absolute bottom-16 right-3">
          <Box className="w-[90%]">
            <img className="h-[200px] w-full rounded-md" src={imageUrl} alt="imageUrl" />
          </Box>
          <Box className="!ml-6">
            <IconButton onClick={() => setImageUrl(null)}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      )}

      {/* ----------------------Chat Box Start---------------------- */}
      <Box className="p-2">
        <Box
          className=" !w-full"
          sx={{
            p: "2px 4px",
            borderRadius: "20px",
            display: "flex",
            alignItems: "center",
            width: 400,
            boxShadow:
              "inset 0 0 0.5px 1px hsla(0, 0%,100%, 0.075), 0 0 0 1px hsla(0, 0%, 0%, 0.05),0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),0 0.9px 1.5px hsla(0, 0%, 0%, 0.045),0 3.5px 6px hsla(0, 0%, 0%, 0.09)",
          }}
        >
          <InputBase
            value={replyDescription || ""}
            onKeyDown={handleKeyDown}
            onChange={(e) => setReplyDescription(e.target.value)}
            sx={{ ml: 1, flex: 1, color: "white" }}
            className="!w-full"
            placeholder="Type Here"
            multiline
            inputProps={{ color: "white", "aria-label": "Type Here" }}
          />
          <ReactFileReader fileTypes={["jpg", "jpeg", "png"]} base64={true} handleFiles={handleFiles}>
            <IconButton type="button" aria-label="upload_image">
              <PhotoIcon sx={{ color: "var(--color-green-1)" }} />
            </IconButton>
          </ReactFileReader>
          <Divider sx={{ height: 28, m: 0.5, backgroundColor: "#b0b0b0" }} orientation="vertical" />
          <IconButton sx={{ p: "2px" }} aria-label="directions" onClick={handleReplayDescription}>
            <SendIcon sx={{ color: "var(--color-green-1)" }} />
          </IconButton>
        </Box>
      </Box>
      {/* ----------------------Chat Box End---------------------- */}
    </Box>
  );
});

export default ChatTicket;
