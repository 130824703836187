import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import React, { memo } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import logo from "../../assets/img/image-2023-11-21t10-07-25-128z-1.png";
import MenuIcon from "../../assets/icons/menu-icon.svg";
// import notification from "../../assets/img/clarity-notification-line.svg";
// import vector from "../../assets/img/vector.svg";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "redux/features/application/applicationSlice";
import { Brightness4Outlined, Brightness7Outlined, ExpandLess, ExpandMore } from "@mui/icons-material";
import { AppBar } from "layouts/Layout";
import Grid from "@mui/material/Grid";
import UpdateProfileModal from "components/MirrorAllModal/UpdateProfileModal";
import ManageAccount from "components/MirrorAllModal/ManageAccount";

const MiniTopBar = ({ open, handleDrawerOpen }) => {
  const [anchorElManageAccount, setAnchorElManageAccount] = React.useState(null);
  const manageAccountOpen = Boolean(anchorElManageAccount);
  const user = useSelector((state) => state.auth.user);
  const updateProfileModalOpen = useSelector((state) => state.application.updateProfileModalOpen);
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleToggleTheme = () => {
    dispatch(toggleTheme());
  };

  const handleClickManageAccount = (event) => {
    setAnchorElManageAccount(event.currentTarget);
  };
  const handleCloseManageAccount = () => {
    setAnchorElManageAccount(null);
  };

  return (
    <AppBar position="fixed">
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2} sx={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={2}>
                  <Typography aria-label="open drawer" onClick={handleDrawerOpen} sx={{ ...open }} component={"img"} src={MenuIcon} />
                </Grid>
                <Grid item xs={10} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Typography sx={{ width: "90%", height: "48px" }} component={"img"} src={logo} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0 auto",
                  }}
                >
                  <Box
                    onClick={handleToggleTheme}
                    sx={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "6px",
                      background: "#00120C",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <IconButton sx={{ color: "white" }}>
                      {theme.palette.mode === "dark" ? <Brightness4Outlined className="!text-[20px]" /> : <Brightness7Outlined className="!text-[20px]" />}
                    </IconButton>
                  </Box>
                </Grid>
                {/* <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0 auto",
                  }}
                >
                  <Box
                    sx={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "6px",
                      background: "#00120C",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: "8px",
                        height: "8px",
                        position: "absolute",
                        top: "3px",
                        right: "5px",
                      }}
                      component={"img"}
                      src={vector}
                      alt=""
                    />
                    <Box sx={{ width: "17px" }} component={"img"} src={notification} alt="" />
                  </Box>
                </Grid> */}
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0 auto",
                  }}
                >
                  <Grid container spacing={1} onClick={handleClickManageAccount}>
                    <Grid item xs={10} sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Box
                        sx={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Avatar sx={{ width: "35px", height: "35px" }} variant="rounded" src={user?.photo} alt="Avatar" />
                      </Box>
                    </Grid>
                    <Grid item xs={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      {manageAccountOpen ? <ExpandLess sx={{ color: "var(--color-green-2)" }} /> : <ExpandMore sx={{ color: "var(--color-green-2)" }} />}
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <UpdateProfileModal {...{ updateProfileModalOpen }} />
        <ManageAccount {...{ manageAccountOpen, handleCloseManageAccount, anchorElManageAccount }} />
      </Toolbar>
    </AppBar>
  );
};

export default memo(MiniTopBar);
