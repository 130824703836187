import { Add, HighlightOff, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCopyTradeModalOpen } from "redux/features/application/applicationSlice";
import { logIn } from "redux/features/auth/authSlice";
import { setSymbols } from "redux/features/pam/pamSlice";
import { refetchAuth } from "server/auth";
import { copy } from "server/copy_un_copy";

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
    color: "var(--color-white-1)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const CopyTradeModal = ({ copyTradeModalOpen, master }) => {
  const symbols = useSelector((state) => state.pam.symbols);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      className: "mirror-trade-global-scrollbar",
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        background: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
      },
    },
  };

  const [allocation, setAllocation] = useState("");
  const [maxVolume, setMaxVolume] = useState("");
  const [maxVolumeError, setMaxVolumeError] = useState("");
  const [minVolume, setMinVolume] = useState("");
  const [minVolumeError, setMinVolumeError] = useState("");
  const [tradeLimit, setTradeLimit] = useState("");
  const [tradeLimitError, setTradeLimitError] = useState("");
  const [allocationError, setAllocationError] = useState("");
  const [symbolError, setSymbolError] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [newSymbol, setNewSymbol] = useState("");
  const [selectSymbol, setSelectSymbol] = useState([]);
  const [symbolObj, setSymbolObj] = useState({});

  const handleChangedSymbol = (event) => {
    const value = event.target.value;
    const object = Object.assign({}, value);
    setSymbolObj(object);
    setSelectSymbol(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeAllocation = (event) => {
    setAllocation(event.target.value);
  };

  const handleCopyTrade = async () => {
    setLoading(true);
    const newSymbols = {};
    Object.values(symbolObj).forEach((k) => {
      const found = symbols.find((s) => s.symbol === k);
      if (found) {
        newSymbols[found.symbol] = found.symbol_org;
      } else {
        newSymbols[k] = k;
      }
    });

    const copyValue = {
      symbols: newSymbols,
      allocation: allocation,
      master: parseInt(master),
      max_volume: parseFloat(maxVolume),
      min_volume: parseFloat(minVolume),
      trade_limit: parseFloat(tradeLimit),
    };
    const result = await copy(copyValue);
    if (result?.data?.status) {
      enqueueSnackbar(result.data.message, { variant: "success" });
      const resultRefetch = await refetchAuth();
      if (resultRefetch?.data?.status) {
        localStorage.setItem("auth-token", resultRefetch?.data?.token);
        dispatch(logIn({ ...resultRefetch?.data?.data }));
      }
      dispatch(setCopyTradeModalOpen(false));
      // setSelectSymbol([]);
      // setSymbolObj({});
      setAllocation("");
      setAllocationError("");
      setSymbolError("");
      setMaxVolumeError("");
      setMinVolumeError("");
      setMaxVolume("");
      setMinVolume("");
      setTradeLimit("");
      setTradeLimitError("");
      setLoading(false);
    } else {
      setLoading(false);
      const message = result?.data?.message === "Not found." ? "This master account doesn't exist." : result?.data?.message;
      dispatch(setCopyTradeModalOpen(true));
      const allocation = result?.data?.errors?.allocation;
      if (allocation && allocation.length > 0) {
        setAllocationError(allocation[0]);
      }
      const symbols = result?.data?.errors?.symbols;
      if (symbols && symbols.length > 0) {
        setSymbolError(symbols[0]);
      }
      const max_volume = result?.data?.errors?.max_volume;
      if (max_volume && max_volume.length > 0) {
        setMaxVolumeError(max_volume[0]);
      }
      const min_volume = result?.data?.errors?.min_volume;
      if (min_volume && min_volume.length > 0) {
        setMinVolumeError(min_volume[0]);
      }
      const trade_limit = result?.data?.errors?.trade_limit;
      if (trade_limit && trade_limit.length > 0) {
        setTradeLimitError(trade_limit[0]);
      }
      enqueueSnackbar(message, { variant: "warning" });
    }
  };

  useEffect(() => {
    if (symbols) {
      const defaultSelection = symbols.map((symbol) => symbol.symbol);
      setSelectSymbol(defaultSelection);
      setSymbolObj(Object.assign({}, defaultSelection));
    }
  }, [symbols]);

  const handleChangeNewSymbol = (event) => {
    setNewSymbol(event.target.value?.toUpperCase());
  };

  const handleAddNewSymbol = () => {
    if (newSymbol.trim() !== "") {
      if (symbols.some((i) => i.symbol === newSymbol.trim())) {
        enqueueSnackbar("Symbol already exists", { variant: "warning" });
      } else {
        const updatedSymbols = [...symbols, { id: symbols.length + 2, symbol: newSymbol.trim() }];
        dispatch(setSymbols(updatedSymbols));
        setSelectSymbol((prevSelectSymbol) => [...prevSelectSymbol, newSymbol.trim()]);
        setNewSymbol("");
      }
    }
  };

  return (
    <Modal
      open={copyTradeModalOpen}
      onClose={() => dispatch(setCopyTradeModalOpen(false))}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isSmallScreen ? "90%" : 500,
          background: theme.palette.mode === "dark" ? "var(--color-green-7)" : "var(--color-green-10)",
          borderRadius: "5px",
          padding: "15px",
          outline: "none",
        }}
      >
        <Grid container rowSpacing={3} columnSpacing={3} alignItems={"center"}>
          <Grid item xs={6} className="text-start">
            <Typography>Copy Trade</Typography>
          </Grid>
          <Grid item xs={6} className="text-end">
            <IconButton onClick={() => dispatch(setCopyTradeModalOpen(false))} title="Close">
              <HighlightOff />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1px solid var(--color-gray-2)",
                  },
                  "&:hover fieldset": {
                    border: "1px solid var(--color-green-1)",
                  },
                  "&.Mui-focused fieldset": {
                    border: `1px solid var(--color-green-2)`,
                  },
                },
                "& .MuiInputLabel-root": {
                  color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                  "&.Mui-focused": {
                    color: "var(--color-green-2)",
                  },
                },
              }}
              size="small"
            >
              <InputLabel id="demo-select-small-label">Allocation</InputLabel>
              <Select
                MenuProps={{
                  PaperProps: {
                    style: {
                      background: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
                      height: isSmallScreen && 150,
                    },
                  },
                }}
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={allocation}
                label="Allocation"
                onChange={handleChangeAllocation}
              >
                <MenuItem value={"50"}>50%</MenuItem>
                <MenuItem value={"100"}>100%</MenuItem>
                <MenuItem value={"150"}>150%</MenuItem>
                <MenuItem value={"200"}>200%</MenuItem>
                <MenuItem value={"250"}>250%</MenuItem>
                <MenuItem value={"300"}>300%</MenuItem>
              </Select>
            </FormControl>
            {allocationError && (
              <Typography component={"p"} className="text-red-500 !font-semibold">
                {allocationError}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1px solid var(--color-gray-2)",
                  },
                  "&:hover fieldset": {
                    border: "1px solid var(--color-green-1)",
                  },
                  "&.Mui-focused fieldset": {
                    border: `1px solid var(--color-green-2)`,
                  },
                },
                "& .MuiInputLabel-root": {
                  color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                  "&.Mui-focused": {
                    color: "var(--color-green-2)",
                  },
                },
              }}
              size="small"
            >
              <InputLabel id="demo-multiple-chip-label">Symbol</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={selectSymbol}
                onChange={handleChangedSymbol}
                input={<OutlinedInput id="select-multiple-chip" label="Symbol" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}> Total selected symbol : {selected?.length}</Box>
                )}
                MenuProps={MenuProps}
              >
                <MenuItem disableRipple onKeyDown={(e) => e.stopPropagation()}>
                  <FormControl
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid var(--color-gray-2)",
                        },
                        "&:hover fieldset": {
                          border: "1px solid var(--color-green-1)",
                        },
                        "&.Mui-focused fieldset": {
                          border: `1px solid var(--color-green-2)`,
                        },
                      },
                    }}
                    size="small"
                  >
                    <TextField
                      value={newSymbol}
                      onKeyDown={(e) => e.key === "Enter" && handleAddNewSymbol()}
                      onChange={handleChangeNewSymbol}
                      type="text"
                      placeholder="Add new symbol"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleAddNewSymbol}>
                              <Add />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </MenuItem>
                {symbols?.map((symbol) => (
                  <MenuItem key={symbol.id} value={symbol.symbol}>
                    <Checkbox
                      sx={{
                        color: "var(--color-gray-1)",
                        "&.Mui-checked": {
                          color: "var(--color-white-1)",
                        },
                      }}
                      checked={selectSymbol.indexOf(symbol.symbol) > -1}
                    />
                    <ListItemText primary={symbol.symbol} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {symbolError && (
              <Typography component={"p"} className="text-red-500 !font-semibold">
                {symbolError}
              </Typography>
            )}
          </Grid>

          <Grid item xs={6}>
            <FormControl
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  height: "100%",
                  "& fieldset": {
                    border: "1px solid var(--color-gray-2)",
                  },
                  "&:hover fieldset": {
                    border: "1px solid var(--color-green-1)",
                  },
                  "&.Mui-focused fieldset": {
                    border: `1px solid var(--color-green-2)`,
                  },
                },
                "& .MuiInputLabel-root": {
                  color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                  "&.Mui-focused": {
                    color: "var(--color-green-2)",
                  },
                },
              }}
              size="small"
            >
              <TextField
                type="text"
                value={minVolume}
                onChange={(e) => setMinVolume(e.target.value)}
                label="Min Volume"
                size="small"
                variant="outlined"
                placeholder="0.00"
              />
            </FormControl>
            {minVolumeError && (
              <Typography component={"p"} className="text-red-500 !font-semibold">
                {minVolumeError}
              </Typography>
            )}
          </Grid>

          <Grid item xs={6}>
            <FormControl
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  height: "100%",
                  "& fieldset": {
                    border: "1px solid var(--color-gray-2)",
                  },
                  "&:hover fieldset": {
                    border: "1px solid var(--color-green-1)",
                  },
                  "&.Mui-focused fieldset": {
                    border: `1px solid var(--color-green-2)`,
                  },
                },
                "& .MuiInputLabel-root": {
                  color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                  "&.Mui-focused": {
                    color: "var(--color-green-2)",
                  },
                },
              }}
              size="small"
            >
              <TextField
                type="text"
                value={maxVolume}
                onChange={(e) => setMaxVolume(e.target.value)}
                label="Max Volume"
                size="small"
                variant="outlined"
                placeholder="0.00"
              />
            </FormControl>
            {maxVolumeError && (
              <Typography component={"p"} className="text-red-500 !font-semibold">
                {maxVolumeError}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  height: "100%",
                  "& fieldset": {
                    border: "1px solid var(--color-gray-2)",
                  },
                  "&:hover fieldset": {
                    border: "1px solid var(--color-green-1)",
                  },
                  "&.Mui-focused fieldset": {
                    border: `1px solid var(--color-green-2)`,
                  },
                },
                "& .MuiInputLabel-root": {
                  color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                  "&.Mui-focused": {
                    color: "var(--color-green-2)",
                  },
                },
              }}
              size="small"
            >
              <TextField
                placeholder="10"
                type="text"
                onChange={(e) => setTradeLimit(e.target.value)}
                value={tradeLimit}
                label="Trade Limit"
                size="small"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CustomTooltip title="This is the maximum trade you can copy of a master." placement="top-end">
                        <IconButton edge="end">
                          <InfoOutlined />
                        </IconButton>
                      </CustomTooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            {tradeLimitError && (
              <Typography component={"p"} className="text-red-500 !font-semibold">
                {tradeLimitError}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"end"}>
            <Button
              onClick={handleCopyTrade}
              size="small"
              sx={{
                background: "var(--color-green-1)",
                textTransform: "capitalize",
                boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
                "&:hover": {
                  color: "var(--color-white-1)",
                  background: "var(--color-green-2)",
                },
              }}
            >
              {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Submit"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default memo(CopyTradeModal);
