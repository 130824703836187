export default Object.freeze({
    "/posts": "news",
    "/calender": "calendar",
    "/education": "education",
    "/video-academy": "video academy",
    "/cryptocurrency-calender": "crypto calendar",
    "/economic-calendar": "economic calendar",
    "/financial-news": "financial news",
    "/glossary": "glosary",
    "/broker-portal": "broker portal",
});