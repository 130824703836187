import { Box, Grid, Typography, useTheme } from "@mui/material";
import AccountInfoLoader from "components/SkeletonLoader/AccountInfoLoader";
import React, { Fragment, memo } from "react";
import { useSelector } from "react-redux";

const BalanceInfo = () => {
  const pamUser = useSelector((state) => state.pam.pamUserDetails);
  console.log("🚀 ~ BalanceInfo ~ pamUser:", pamUser)
  const pamUserDetailsLoader = useSelector((state) => state.loader.pamUserDetailsLoader);
  const balanceEquity = useSelector((state) => state.pam.balanceEquity);
  const theme = useTheme();
  return (
    <Fragment>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        {pamUserDetailsLoader ? (
          <AccountInfoLoader />
        ) : (
          <Box
            sx={{
              border: "1px solid rgba(0, 208, 148, 0.3)",
              background:
                theme.palette.mode === "dark"
                  ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)"
                  : "var(--color-green-10)",
              borderRadius: "10px",
            }}
            className="!p-[8px]"
          >
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">
                Total Deposit
              </Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser?.total_deposit} USD
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">
                Starting Balance
              </Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser?.starting_balance ?? 0} USD
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">
                Total Profit
              </Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser?.actual_profit} USD
              </Typography>
            </Box>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        {pamUserDetailsLoader ? (
          <AccountInfoLoader />
        ) : (
          <Box
            sx={{
              border: "1px solid rgba(0, 208, 148, 0.3)",
              background:
                theme.palette.mode === "dark"
                  ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)"
                  : "var(--color-green-10)",
              borderRadius: "10px",
            }}
            className="!p-[8px]"
          >
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">
                Total Withdraw
              </Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser?.total_withdraw} USD
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">
                Current Balance
              </Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal !capitalize">
                {balanceEquity?.Balance ? balanceEquity?.Balance?.toFixed(2) : 0} USD
              </Typography>
            </Box>
            <Box className="flex items-center w-full p-2" sx={{ borderBottom: "1px solid var(--border-color)" }}>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !text-[17px] !leading-normal !font-normal">
                Total Loss
              </Typography>
              <Typography className="!w-1/2 !font-[Anek Telugu] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-sm !sm:text-xs !leading-normal !font-normal">
                {pamUser?.loss} USD
              </Typography>
            </Box>
          </Box>
        )}
      </Grid>
    </Fragment>
  );
};

export default memo(BalanceInfo);
