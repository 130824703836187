import { createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
    name: "loader",
    initialState: {
        pamUserLoader: false,
        growthChartLoader: false,
        pamUserDetailsLoader: false,
        performanceChartLoader: false,
        closeTradeLoading: false,
        openTradeHistoryLoading: false,
        closeTradeHistoryLoading: false,
        monthlyChartLoading: false,
        dailyChartLoading: false,
        hourlyChartLoading: false,
        postLoading: false,
        commentLoading: false,
        brokerLoading: false,
        chatListLoading: false,
        calenderLoading: false,
        slaveLoading: false,
        pamUserChartLoading: false,
        commissionReportLoading: false,
        accountListLoading: false,
    },
    reducers: {
        setPamUserLoader: (state, action) => {
            state.pamUserLoader = action.payload;
        },
        setGrowthChartLoader: (state, action) => {
            state.growthChartLoader = action.payload;
        },
        setPamUserDetailsLoader: (state, action) => {
            state.pamUserDetailsLoader = action.payload;
        },
        setPerformanceChartLoader: (state, action) => {
            state.performanceChartLoader = action.payload;
        },
        setOpenTradeLoading: (state, action) => {
            state.openTradeLoading = action.payload;
        },
        setCloseTradeLoading: (state, action) => {
            state.closeTradeLoading = action.payload;
        },
        setOpenTradeHistoryLoading: (state, action) => {
            state.openTradeHistoryLoading = action.payload;
        },
        setCloseTradeHistoryLoading: (state, action) => {
            state.closeTradeHistoryLoading = action.payload;
        },
        setMonthlyChartLoading: (state, action) => {
            state.monthlyChartLoading = action.payload;
        },
        setDailyChartLoading: (state, action) => {
            state.dailyChartLoading = action.payload;
        },
        setHourlyChartLoading: (state, action) => {
            state.hourlyChartLoading = action.payload;
        },
        setPostLoading: (state, action) => {
            state.postLoading = action.payload;
        },
        setCommentLoading: (state, action) => {
            state.commentLoading = action.payload;
        },
        setBrokerLoading: (state, action) => {
            state.brokerLoading = action.payload;
        },
        setChatListLoading: (state, action) => {
            state.chatListLoading = action.payload;
        },
        setCalenderLoading: (state, action) => {
            state.calenderLoading = action.payload;
        },
        setSlaveLoadingLoading: (state, action) => {
            state.slaveLoading = action.payload;
        },
        setPamUserChartLoading: (state, action) => {
            state.pamUserChartLoading = action.payload;
        },
        setCommissionReportLoading: (state, action) => {
            state.commissionReportLoading = action.payload;
        },
        setAccountListLoading: (state, action) => {
            state.accountListLoading = action.payload;
        },
    },
});
export const { setChatListLoading, setPamUserLoader, setGrowthChartLoader, setPamUserDetailsLoader, setOpenTradeLoading, setCloseTradeLoading, setOpenTradeHistoryLoading, setCloseTradeHistoryLoading, setMonthlyChartLoading, setDailyChartLoading, setHourlyChartLoading, setPostLoading, setCommentLoading, setBrokerLoading, setCalenderLoading, setSlaveLoadingLoading, setPerformanceChartLoader, setPamUserChartLoading, setCommissionReportLoading, setAccountListLoading } = loaderSlice.actions;
export default loaderSlice.reducer;
