/* eslint-disable no-unused-vars */
import { Avatar, Box, Chip, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUnseenNotification } from "redux/features/notification/notificationSlice";
import { friend_request_accept } from "server/chat";
import { seen_notification } from "server/notification";

const IncomingFriendList = () => {
  const incomingFriendList = useSelector((state) => state.chat.incomingFriendList);
  const dispatch = useDispatch();

  const handleAcceptFriendRequest = async (e, friend_id, accepted) => {
    e.stopPropagation();
    const result = await friend_request_accept(friend_id, accepted);
  };

  const handleRejectFriendRequest = async (e, friend_id, rejected) => {
    e.stopPropagation();
    // eslint-disable-next-line no-unused-vars
    const result = await friend_request_accept(friend_id, rejected);
  };

  const seenNotification = async (for_type) => {
    const res = await seen_notification(for_type);
    if (res.data?.status) {
      dispatch(updateUnseenNotification());
    }
  };
  
  useEffect(() => {
    seenNotification("FRIEND_REQUEST");
    seenNotification("FRIEND_ACCEPT");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  // const showUserProfile = async (item) => {
  //   dispatch(setNavigateChat("user_profile"));
  //   setFriendId(item.user.id);
  // }

  return (
    <Grid item xs={12} className="chat-list-scrollbar" sx={{ height: "300px", width: "100%", overflowY: "auto" }}>
      {incomingFriendList && incomingFriendList.length > 0 ? (
        <List sx={{ width: "100%" }}>
          {incomingFriendList.map((item) => (
            <Fragment key={item.id}>
              <ListItem
                className="cursor-pointer"
                sx={{
                  "&:hover": {
                    backgroundColor: "var(--border-color)",
                  },
                }}
                secondaryAction={
                  <Box className="flex flex-col items-center gap-y-[2px]">
                    <Box>
                      <Chip size="small" sx={{ fontSize: "11px" }} label="Accept" onClick={(e) => handleAcceptFriendRequest(e, item.id, "ACCEPTED")} />
                    </Box>
                    <Box>
                      <Chip size="small" sx={{ fontSize: "11px" }} color="warning" label="Reject" onClick={(e) => handleRejectFriendRequest(e, item.id, "REJECTED")} />
                    </Box>
                  </Box>
                }
              >
                <ListItemAvatar>
                  <Avatar alt="chat list avatar" src={item?.profile?.profile_image}></Avatar>
                </ListItemAvatar>
                <ListItemText primary={item?.user?.name} secondary={item.user?.email} />
              </ListItem>
              <Divider variant="middle" component="li" sx={{ marginLeft: 0, marginRight: 0 }} />
            </Fragment>
          ))}
        </List>
      ) : (
        <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <span>No incoming friend requests</span>
        </Box>
      )}
    </Grid>
  );
};

export default IncomingFriendList;
