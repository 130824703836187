import mirrorApi from '../api/mirror_trade_api';
const { getAuthToken } = require("./auth");

export const permission = async () => {
    try {
        return await mirrorApi.get(`/user/permission`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
    console.log("🚀 ~ permission ~ error:", error)
    }
};