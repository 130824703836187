import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Fragment, memo, useEffect, useState } from "react";
import { CircularProgress, Grid, Pagination, useMediaQuery, useTheme } from "@mui/material";
import { setBrokerLoading } from "redux/features/loader/loaderSlice";
import { brokerList } from "server/broker";
import { useDispatch, useSelector } from "react-redux";
import { setAllBroker } from "redux/features/broker/brokerSlice";
import BrokerTableRow from "./BrokerTableRow";
import BrokerJoinModal from "components/MirrorAllModal/BrokerJoinModal";

const BrokerPortalTable = memo(({ searchText }) => {
  const openBrokerModalJoin = useSelector((state) => state.application.openBrokerModalJoin);
  const theme = useTheme();
  const brokers = useSelector((state) => state.broker.broker_list);
  const searchedBrokers = useSelector((state) => state.broker.searchedBrokerList);
  const dispatch = useDispatch();
  const brokerLoading = useSelector((state) => state.loader.brokerLoading);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [page, setPage] = useState(1);

  const handleChangePage = async (_, value) => {
    dispatch(setBrokerLoading(true));
    const result = await brokerList(value, searchText);
    if (result?.data?.status) {
      dispatch(setAllBroker(result?.data?.data));
      dispatch(setBrokerLoading(false));
    } else {
      dispatch(setBrokerLoading(false));
    }
    setPage(value);
  };

  // Broker list
  const getBrokerList = async () => {
    dispatch(setBrokerLoading(true));
    const result = await brokerList(page, searchText);
    if (result?.data?.status) {
      dispatch(setAllBroker(result?.data?.data));
      dispatch(setBrokerLoading(false));
    } else {
      dispatch(setBrokerLoading(false));
    }
  };

  useEffect(() => {
    getBrokerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <Fragment>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "var(--color-green-7)",
            borderRadius: "5px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "none",
          },
        }}
        className="!rounded-b !rounded-t-none"
      >
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: theme.palette.mode === "dark" ? "var(--color-black-4)" : "#DBFFEE",
                ".MuiTableCell-root": { borderBottom: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}` },
              }}
              className="!shadow-md"
            >
              <TableCell
                align="center"
                sx={{
                  borderRight: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}`,
                  color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                }}
                className="!font-semibold !text-base"
              >
                Brand
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}`,
                  color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                }}
                className="!font-semibold !text-base"
              >
                Trading firm
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}`,
                  color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                }}
                className="!font-semibold !text-base"
              >
                Broker type
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}`,
                  color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                }}
                className="!font-semibold !text-base"
              >
                Regulation
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}`,
                  color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                }}
                className="!font-semibold !text-base"
              >
                Platform
              </TableCell>
              <TableCell align="center" sx={{ color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)" }} className="!font-semibold !text-base">
                Open an account
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {brokerLoading ? (
              <TableRow
                sx={{
                  ".MuiTableCell-root": { borderBottom: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}` },
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  align="center"
                  colSpan={6}
                  sx={{ color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)" }}
                  className="!font-bold !text-base"
                >
                  <CircularProgress size={28} color="success" />
                </TableCell>
              </TableRow>
            ) : brokers?.data?.length > 0 ? (
              brokers?.data?.map((item) => <BrokerTableRow key={item.id} item={item} />)
            ) : (
              <TableRow className="!h-5 flex justify-center items-center">
                <TableCell
                  align="center"
                  colSpan={6}
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  No data found!
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {(searchedBrokers?.data?.length ? searchedBrokers?.data?.length : brokers?.data?.length) > 0 && (
        <Grid item xs={12} className="flex justify-center items-center !mt-6">
          <Pagination count={brokers?.last_page} variant="outlined" shape="rounded" page={page} onChange={handleChangePage} size={isSmallScreen ? "small" : "medium"} />
        </Grid>
      )}
      <BrokerJoinModal {...{ openBrokerModalJoin }} />
    </Fragment>
  );
});

export default BrokerPortalTable;
