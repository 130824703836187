import { createSlice } from "@reduxjs/toolkit";

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    user: {},
    account: null,
    loggedInChat: false,
    chatList: [],
    chatUser: null,
    incomingFriendList: [],
    chatNavigate: "chat_list",
    groupNavigate: "group_chat_list",
    friendList: [],
    friendListOutgoing: [],
    chatHistory: [],
    typing: false,
    activeChat: {},
    socket: null,
    groupList: [],
    groupChatList: [],
    activeChatGroup: {},
    editGroup: {},
    groupChatHistory: [],
    onlineUsers: [],
    lastChatContent: [],
    lastGroupChatContent: [],
    detailsShowingGroup: null
  },
  reducers: {
    loggedInChat: (state, action) => {
      state.loggedInChat = true;
      state.user = action.payload;
    },
    signInChat: (state, action) => {
      state.loggedInChat = true;
      state.user = action.payload;
      state.account = action.payload?.login;
      const json_user = JSON.stringify(action.payload);
      localStorage.setItem("chatUser", json_user);
    },
    signOutChat: (state) => {
      localStorage.removeItem("chatUser");
      localStorage.removeItem("chat-auth-token");
      localStorage.removeItem("chat-refresh-token");
      state.loggedInChat = false;
      state.user = null;
    },
    setChatList: (state, action) => {
      state.chatList = action.payload;
    },
    setChatUser: (state, action) => {
      state.chatUser = action.payload;
    },
    setIncomingFriendList: (state, action) => {
      state.incomingFriendList = action.payload;
    },
    addIncomingFriendList: (state, action) => {
      if (state.incomingFriendList.some((i) => i.id === action.payload.id)) {
        return;
      }
      state.incomingFriendList.push(action.payload);
    },
    removeIncomingFriendList: (state, action) => {
      return {
        ...state,
        incomingFriendList: state.incomingFriendList.filter((i) => i.id !== action.payload.id),
      };
    },
    setNavigateChat: (state, action) => {
      state.chatNavigate = action.payload;
    },
    setFriendList: (state, action) => {
      state.friendList = action.payload;
    },
    addFriendList: (state, action) => {
      if (state.friendList.some((i) => i.id === action.payload.id)) {
        return;
      }
      state.friendList.push(action.payload);
    },
    removeFriendList: (state, action) => {
      return {
        ...state,
        friendList: state.friendList.filter((i) => i.id !== action.payload.id),
      };
    },
    setOutGoingFriendList: (state, action) => {
      state.friendListOutgoing = action.payload;
    },
    addOutGoingFriendList: (state, action) => {
      if (state.friendListOutgoing.some((i) => i.id === action.payload.id)) {
        return;
      }
      state.friendListOutgoing.push(action.payload);
    },
    removeOutGoingFriendList: (state, action) => {
      return {
        ...state,
        friendListOutgoing: state.friendListOutgoing.filter((i) => i.id !== action.payload.id),
      };
    },

    setChatHistory: (state, action) => {
      state.chatHistory = action.payload;
    },

    addChatHistory: (state, action) => {
      if (state.chatHistory.some((i) => i.id === action.payload.id)) {
        return;
      }
      state.chatHistory.push(action.payload);
    },

    addCurrentChatHistory: (state, action) => {
      const newData = state.chatHistory.map((i) => {
        if (i.u_id === action.payload.u_id) {
          return action.payload;
        }
        return i;
      });
      return {
        ...state,
        chatHistory: newData,
      };
    },

    setTyping: (state, action) => {
      return {
        ...state,
        typing: action.payload,
      };
    },
    setActiveChat: (state, action) => {
      return {
        ...state,
        activeChat: action.payload,
      };
      // state.activeChat = action.payload;
    },
    setSocket: (state, action) => {
      return {
        ...state,
        socket: action.payload,
      };
    },
  
    setGroupNavigate: (state, action) => {
      state.groupNavigate = action.payload;
    },
    setGroupList: (state, action) => {
      state.groupList = action.payload;
    },
    addGroupList: (state, action) => {
      if (state.groupList.some((i) => i.id === action.payload.id)) {
        return;
      }
      state.groupList.push(action.payload);
    },
    removeGroupList: (state, action) => {
      return {
        ...state,
        groupList: state.groupList.filter((i) => i.id !== action.payload.id),
      };
    },
    setGroupChatList: (state, action) => {
      state.groupChatList = action.payload;
    },
    addGroupChatList: (state, action) => {
      if (state.groupChatList.some((i) => i.id === action.payload.id)) {
        return;
      }
      state.groupChatList.push(action.payload);
    },
    removeGroupChatList: (state, action) => {
      return {
        ...state,
        groupChatList: state.groupChatList.filter((i) => i.id !== action.payload.id),
      };
    },
    setActiveChatGroup: (state, action) => {
      state.activeChatGroup = action.payload;
    },
    setEditGroup: (state, action) => {
      state.editGroup = action.payload;
    },
    removeEditGroup: (state) => {
      state.editGroup = {};
    },
    setGroupChatHistory: (state, action) => {
      state.groupChatHistory = action.payload;
    },
    addGroupChatHistory: (state, action) => {
      if (state.groupChatHistory.some((i) => i.id === action.payload.id)) {
        return;
      }
      state.groupChatHistory.push(action.payload);
    },
    removeGroupChatHistory: (state, action) => {
      return {
        ...state,
        groupChatHistory: state.groupChatHistory.filter((i) => i.id !== action.payload.id),
      };
    },
    removeTempGroupChatHistory: (state) => {
      return {
        ...state,
        groupChatHistory: state.groupChatHistory.filter((i) => !i?.temp),
      };
    },
    setOnlineUser: (state, action) => {
      return {
        ...state,
        onlineUsers: action.payload,
      };
    },
    setLastChatContent: (state, action) => {
      state.lastChatContent = action.payload;
    },
    removeLastChatContent: (state, action) => {
      return {
        ...state,
        lastChatContent: state.lastChatContent.filter((i) => i.id !== action.payload.id),
      };
    },
    setLastGroupChatContent: (state, action) => {
      state.lastGroupChatContent = action.payload;
    },
    removeLastGroupChatContent: (state, action) => {
      return {
        ...state,
        lastGroupChatContent: state.lastGroupChatContent.filter((i) => i.id !== action.payload.id),
      };
    },
    setDetailsShowingGroup: (state, action) => {
      state.detailsShowingGroup = action.payload;
    },
    removeMemberFromGroup: (state, action) => {
      const group = state.groupList.find((i) => i.id === action.payload.group_id);
      if (group) {
        group.members = group.members.filter((i) => i.user_id !== action.payload.user_id);
      }
    }
  },
});
export const {
  signInChat,
  signOutChat,
  loggedInChat,
  setChatList,
  setNavigateChat,
  setIncomingFriendList,
  addIncomingFriendList,
  removeIncomingFriendList,
  setFriendList,
  addFriendList,
  removeFriendList,
  setOutGoingFriendList,
  addOutGoingFriendList,
  removeOutGoingFriendList,
  setChatHistory,
  addChatHistory,
  addCurrentChatHistory,
  setTyping,
  setActiveChat,
  setSocket,
  setGroupNavigate,
  setGroupList,
  addGroupList,
  removeGroupList,
  setGroupChatList,
  removeEditGroup,
  setEditGroup,
  addGroupChatList,
  removeGroupChatList,
  setActiveChatGroup,
  setGroupChatHistory,
  addGroupChatHistory,
  removeGroupChatHistory,
  removeTempGroupChatHistory,
  setOnlineUser,
  setLastChatContent,
  removeLastChatContent,
  setLastGroupChatContent,
  removeLastGroupChatContent,
  setDetailsShowingGroup,
  removeMemberFromGroup
} = chatSlice.actions;
export default chatSlice.reducer;
