import { Delete, Edit } from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeAccount, signOut } from "redux/features/auth/authSlice";
import { signOutChat } from "redux/features/chatSlice/chatSlice";
import { delete_account, logOut } from "server/auth";
import AccountEditModal from "./AccountEditModal";

const AccountListTable = memo(({ data }) => {
    const accountListLoading = useSelector((state) => state.loader.accountListLoading);
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [selectedAccount, setSelectedAccount] = React.useState(null);
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const [editAccountModal, setEditAccountModal] = React.useState(false);
    const [accountInfo, setAccountInfo] = React.useState({});

    const handleClose = () => {
        setOpen(false);
    };

    const confirmationHandler = async (id) => {
        setSelectedAccount(id);
        setOpen(true);
    };

    const editAccount = async (account) => {
        setEditAccountModal(true);
        setAccountInfo(account);
    };

    const deleteAccount = async () => {
        try {
            setOpen(false);
            const res = await delete_account(selectedAccount);
            if (res?.data?.status) {
                enqueueSnackbar(res.data.message, { variant: "success" });
                dispatch(removeAccount(selectedAccount));
                if (selectedAccount === user.id) {
                    await logOut();
                    dispatch(signOut());
                    dispatch(signOutChat());
                }
            } else {
                enqueueSnackbar(res.data.message, { variant: "error" });
            }
        } catch (error) {
            console.error("Error deleting account:", error);
        }
    };

    return (
        <TableContainer
            sx={{
                marginBottom: {
                    xs: "-10px",
                    sm: "10px",
                    md: "5px",
                },
            }}
        >
            <Table aria-label="My clients">
                <TableHead>
                    <TableRow className="dark:bg-[--color-green-6] bg-[--color-dark-green] shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]">
                        <TableCell
                            sx={{
                                borderBottom: "none",
                                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                                fontFamily: "Anek Telugu",
                                fontWeight: 500,
                                fontSize: "16px",
                            }}
                        >
                            Name
                        </TableCell>
                        <TableCell
                            sx={{
                                borderBottom: "none",
                                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                                fontFamily: "Anek Telugu",
                                fontWeight: 500,
                                fontSize: "16px",
                            }}
                        >
                            Account Number
                        </TableCell>
                        <TableCell
                            sx={{
                                borderBottom: "none",
                                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                                fontFamily: "Anek Telugu",
                                fontWeight: 500,
                                fontSize: "16px",
                            }}
                        >
                            Platform
                        </TableCell>
                        <TableCell
                            sx={{
                                borderBottom: "none",
                                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                                fontFamily: "Anek Telugu",
                                fontWeight: 500,
                                fontSize: "16px",
                            }}
                        >
                            Subscription
                        </TableCell>
                        <TableCell
                            sx={{
                                borderBottom: "none",

                                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                                fontFamily: "Anek Telugu",
                                fontWeight: 500,
                                fontSize: "16px",
                            }}
                        >
                            Type
                        </TableCell>
                        <TableCell
                            sx={{
                                borderBottom: "none",
                                // borderRadius: "0 10px 10px 0",
                                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                                fontFamily: "Anek Telugu",
                                fontWeight: 500,
                                fontSize: "16px",
                                textAlign: "end",
                            }}
                        >
                            Action
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {accountListLoading ? (
                        <TableRow
                            sx={{
                                ".MuiTableCell-root": { borderBottom: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}` },
                                "&:last-child td, &:last-child th": { border: 0 },
                            }}
                        >
                            <TableCell
                                align="center"
                                colSpan={6}
                                sx={{ color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)" }}
                                className="!font-bold !text-base"
                            >
                                <CircularProgress size={28} color="success" />
                            </TableCell>
                        </TableRow>
                    ) : data?.length ? (
                        data?.map((item, i) => (
                            <TableRow
                                className={`shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]`}
                                key={i}
                                sx={{
                                    "&:last-child td, &:last-child th": { border: 0 },
                                }}
                            >
                                <TableCell
                                    sx={{
                                        borderBottom: "none",
                                        borderRadius: "10px 0 0 10px",
                                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                                        fontFamily: "Anek Telugu",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                    }}
                                    component="th"
                                    scope="row"
                                >
                                    {item?.name}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        borderBottom: "none",
                                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                                        fontFamily: "Anek Telugu",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                    }}
                                >
                                    {item.account_number}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        borderBottom: "none",
                                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                                        fontFamily: "Anek Telugu",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                    }}
                                >
                                    {item.platform}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        borderBottom: "none",
                                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                                        fontFamily: "Anek Telugu",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                    }}
                                >
                                    {item.subscription}
                                </TableCell>

                                <TableCell
                                    sx={{
                                        borderBottom: "none",
                                        borderRadius: "0 10px 10px 0",
                                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                                        fontFamily: "Anek Telugu",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                    }}
                                >
                                    {item.role === "pamm" ? "Master" : "Trader"}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        borderBottom: "none",

                                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                                        fontFamily: "Anek Telugu",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        textAlign: "end",
                                    }}
                                >
                                    <IconButton
                                        onClick={() => editAccount(item)}
                                        size="small"
                                        sx={{
                                            color: "var(--color-green-1)",
                                            ":hover": {
                                                color: "var(--color-green-7)",
                                            },
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => confirmationHandler(item.id)}
                                        size="small"
                                        sx={{
                                            color: "var(--color-green-1)",
                                            ":hover": {
                                                color: "var(--color-green-7)",
                                            },
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow className="flex justify-center items-center">
                            <TableCell
                                align="center"
                                colSpan={12}
                                sx={{
                                    borderBottom: "none",
                                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                                    fontFamily: "Anek Telugu",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                }}
                            >
                                No data found!
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    ".MuiDialog-paper": {
                        background: theme.palette.mode === "dark" ? "var(--color-green-7)" : "var(--color-green-10)",
                        color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                        borderRadius: "10px",
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure? You want to delete this account?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">If you delete this account you will lost your data and accessibility of this account.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        sx={{
                            background: "var(--color-green-3)",
                            color: "var(--color-white-1)",
                            fontSize: "12px",
                        }}
                    >
                        cancel
                    </Button>
                    <Button
                        onClick={deleteAccount}
                        autoFocus
                        sx={{
                            background: "var(--color-green-2)",
                            color: "var(--color-white-1)",
                            fontSize: "12px",
                        }}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            {editAccountModal && <AccountEditModal {...{ editAccountModal, setEditAccountModal, accountInfo }} />}
        </TableContainer>
    );
});

export default AccountListTable;
