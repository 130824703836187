import chatApi from "../api/chatApi";
import { getAuthTokenChat } from "./chat";

export const get_unseen_notification = async () => {
    try {
        return await chatApi.get(`/notification/unseen`, {
            headers: {
                Authorization: getAuthTokenChat(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ file: auth.js:46 ~ pamUser ~ error:", error);
    }
}

export const seen_notification = async (for_type, for_id) => {
    try {
        return await chatApi.post(`/notification/seen`, {
            for_type, for_id
        }, {
            headers: {
                Authorization: getAuthTokenChat(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ file: auth.js:46 ~ pamUser ~ error:", error);
    }
}