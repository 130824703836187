import { Grid } from "@mui/material";
import InitialFullScreenChatRequest from "initialFullScreenChatLoad/InitialFullScreenChatRequest";
import InitialFullScreenChatSocket from "initialFullScreenChatLoad/InitialFullScreenChatSocket";
import Layout from "layouts/Layout";
import { memo, useEffect, } from "react";
import { useSelector } from "react-redux";
import Channel from "./Channel/Channel";
import ChannelFullScreen from "./Channel/ChannelFullScreen";
import CreateChannel from "./Channel/CreateChannel";
import CreatePost from "./Channel/CreatePost";
import EditPostChannel from "./Channel/EditPostChannel";
import Chatting from "./ChatBox/Chatting";
import ChatChannelDrawer from "./ChatChannelDrawer/ChatChannelDrawer";
import ChatListFullScreen from "./ChatList/ChatListFullScreen";

const FullScreenChat = memo(() => {
  const toggleChatOrChannel = useSelector((state) => state.toggle.toggleChatOrChannel);
  const toggleChatOrGroupList = useSelector((state) => state.toggle.toggleChatOrGroupList);
  const toggleChannelOrCreateChannel = useSelector((state) => state.toggle.toggleChannelOrCreateChannel);
  const drawerOpen = useSelector((state) => state.toggle.drawerOpen);
  const permission = useSelector((state) => state.permission.permission);

  useEffect(() => {
    if (permission.length === 0) return;
    if (!permission?.includes("chatting")) {
      window.location.href = "/";
    }
  }, [permission]);

  return (
    <Layout>
      <InitialFullScreenChatRequest />
      <InitialFullScreenChatSocket />
      <Grid container columns={24}>
        <Grid
          item
          lg={6}
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "block" },
            borderStyle: "solid",
            borderRightWidth: "thin",
            borderColor: "#2F4E43",
          }}
        >
          {toggleChatOrGroupList ? <ChatListFullScreen /> : "Group"}
        </Grid>
        <Grid
          item
          lg={12}
          md={24}
          sm={24}
          xs={24}
          sx={{
            borderStyle: "solid",
            borderRightWidth: "thin",
            borderColor: "#2F4E43",
          }}
        >
          {toggleChatOrChannel === "chat" && <Chatting />}
          {toggleChatOrChannel === "channel" && <Channel />}
        </Grid>
        <Grid item lg={6} sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
          {toggleChannelOrCreateChannel === "channel" && <ChannelFullScreen />}
          {toggleChannelOrCreateChannel === "createChannel" && <CreateChannel />}
          {toggleChannelOrCreateChannel === "createPost" && <CreatePost />}
          {toggleChannelOrCreateChannel === "editPost" && <EditPostChannel />}
        </Grid>
      </Grid>
      <ChatChannelDrawer {...{ drawerOpen }} />
    </Layout>
  );
});

export default FullScreenChat;
