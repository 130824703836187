import { Box, Button, FormControl, Grid, Pagination, TextField, useTheme } from "@mui/material";
import { useFormik } from "formik";
import Layout from "layouts/Layout";
import { memo, useEffect, useRef, useState } from "react";
import { getFollows } from "server/auth";
import FollowsReportTable from "./FollowsReportTable";

const FollowsReport = memo(() => {
  const allReportsData = useRef([]);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(reports?.data?.length / itemsPerPage);

  const formik = useFormik({
    initialValues: {
      account_number: "",
    },

    onSubmit: async (values) => {
      try {
        setLoading(true);
        const filteredData = allReportsData.current?.filter((i) => i.account_number.toString().includes(values.account_number));
        setReports((prev) => ({ ...prev, data: filteredData }));
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    onReset: () => {
      setReports(prev => ({ ...prev, data: allReportsData.current }));
  },
  });

  const getFollowsReport = async () => {
    setLoading(true);
    const result = await getFollows();
    if (result?.data?.status) {
      setReports(result?.data);
      allReportsData.current = result?.data?.data;
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFollowsReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle page change
  const handlePageChange = (_, value) => {
    setCurrentPage(value);
  };

  // Sliced data for the current page
  const currentClientsData = reports?.data?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <Layout>
      <Box className="p-5 space-y-10">
        <Box
          className="rounded-md p-5 !pb-0"
          sx={{
            backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
          }}
        >
          <Box className="flex justify-between items-center mb-12">
            <Box>Filter Reports</Box>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Grid container columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      height: "100%",
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                  }}
                  size="small"
                >
                  <TextField
                    type="text"
                    label="Account Number"
                    placeholder=""
                    size="small"
                    variant="outlined"
                    name="account_number"
                    value={formik.values.account_number}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Button
                  type="reset"
                  onClick={() => formik.handleReset()}
                  variant="contained"
                  disableRipple
                  fullWidth
                  sx={{
                    marginTop: "2px",
                    backgroundColor: "var(--color-green-5)",
                    ":hover": {
                      backgroundColor: "var(--color-green-4)",
                    },
                  }}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Button
                  type="submit"
                  variant="contained"
                  disableRipple
                  fullWidth
                  sx={{
                    marginTop: "2px",
                    backgroundColor: "var(--color-green-1)",
                    ":hover": {
                      backgroundColor: "var(--color-green-7)",
                    },
                  }}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </form>
        </Box>

        <Box
          className="rounded-md p-5"
          sx={{
            backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <FollowsReportTable {...{ loading }} reports={currentClientsData} />
            </Grid>
          </Grid>

          {reports?.data?.length > 0 && (
            <Box className="flex justify-center items-center mt-1">
              <Box>
                <Pagination count={totalPages} variant="outlined" shape="rounded" page={currentPage} onChange={handlePageChange} size={"medium"} />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Layout>
  );
});

export default FollowsReport;
