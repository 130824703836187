import { Box, CircularProgress, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import SearchBar from "../SearchBar/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import CustomAvatar from "components/Auth/CustomAvatar";
import { chat_create } from "server/chat";
import { setActiveChat } from "redux/features/chatSlice/chatSlice";
import moment from "moment";
import { socketServiceJoinChat } from "services/socketServiceFullScreenChat";
import { setToggleChatOrChannel } from "redux/features/toggle/toggleSlice";
import { setIsSelectedChatOrChannel } from "redux/features/chatFullScreen/chatFullScreenSlice";

const ChatListFullScreen = memo(() => {
  const unseenNotification = useSelector((state) => state.notification.unseenNotification);
  const chatList = useSelector((state) => state.chat.chatList);
  const friendList = useSelector((state) => state.chat.friendList);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.chat.user);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedListItem, setSelectedListItem] = useState("");
  const [searchText, setSearchText] = useState("");

  const handleSearchContact = (e) => {
    setSearchText(e.target.value);
  };

  const filteredFriendList = friendList.filter((item) => item?.user?.name.toLowerCase().includes(searchText.toLowerCase()));

  const handleSelectedChatItem = async (chat, section) => {
    dispatch(setIsSelectedChatOrChannel(true));
    if (section === "chat") {
      setSelectedListItem(null);
      setSelectedItem(chat?.id);
      dispatch(setToggleChatOrChannel("chat"));
      socketServiceJoinChat(chat?.id);
      dispatch(setActiveChat(chat));
    } else {
      setSelectedListItem(chat?.id);
      setSelectedItem(null);
      const res = await chat_create(chat?.user?.id);
      if (res?.data?.status) {
        const ac_user = res.data?.data?.sender_id === user.id ? res.data?.data?.receiver : res.data?.data?.sender;
        const ac_chat = {
          id: res.data?.data?.id,
          sender_id: res.data?.data?.sender_id,
          receiver_id: res.data?.data?.receiver_id,
          createdAt: res.data?.data?.createdAt,
          user: ac_user,
        };
        socketServiceJoinChat(res?.data?.data?.id);
        dispatch(setActiveChat(ac_chat));
        dispatch(setToggleChatOrChannel("chat"));
      }
    }
  };

  const calculateUnseenNotification = (id) => {
    const notification = unseenNotification.filter((item) => item.for_id === id);
    return notification.length;
  };

  return (
    <Box
      sx={{
        height: "calc(100vh - 64px)",
        background: "var(--color-green-8)",
      }}
    >
      <Box className="px-1 py-[9px]">
        <SearchBar {...{ handleSearchContact }} />
      </Box>
      <Divider />

      <Box className="chat-list-scrollbar" sx={{ height: "calc(100vh - 134px)", overflowY: "auto" }}>
        <Box>
          <Typography sx={{ color: "var(--color-green-1)", fontWeight: 500, fontSize: 20 }} className="px-6 pt-4">
            Chats
          </Typography>
        </Box>

        <Box className="p-1 md:p-2 lg:p-3">
          {chatList && chatList.length > 0 ? (
            <List>
              {chatList?.map((item, i) => (
                <Box
                  key={i}
                  onClick={() => handleSelectedChatItem(item, "chat")}
                  className={`w-full flex justify-between mb-2 px-2`}
                  sx={{
                    cursor: "pointer",
                    backgroundImage: selectedItem === item?.id ? "linear-gradient(to right, var(--color-green-1), var(--color-green-14))" : "transparent",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.08)",
                    },
                  }}
                >
                  <Box className="flex items-center pt-3 pb-3">
                    <Box>
                      <CustomAvatar {...{ user_id: item?.user.id, image: item?.user?.profile?.profile_image }} />
                    </Box>
                    <Box className="flex flex-col ps-3">
                      <Typography variant="h6" className="!text-[13px] !font-bold !capitalize">
                        {item.user.name}
                      </Typography>
                      <Typography variant="caption">
                        {(item?.chat_content[0].content?.length > 25 ? item?.chat_content[0]?.content?.slice(0, 25) + "..." : item?.chat_content[0]?.content) || "No message"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="flex items-center flex-col justify-center gap-1">
                    <Box className="text-xs text-[#F0F0F0)]">{moment.duration(moment().diff(item?.chat_content[0]?.updatedAt)).humanize()}</Box>
                    {calculateUnseenNotification(item.id) > 0 && (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          borderRadius: 100,
                          border: "",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "var(--color-red-1)",
                          fontSize: 10,
                          marginBottom: 1,
                        }}
                      >
                        {calculateUnseenNotification(item.id)}
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}
            </List>
          ) : (
            <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
              <Typography>No chats available</Typography>
            </Box>
          )}
        </Box>

        <Box>
          <Typography sx={{ color: "var(--color-green-1)", fontWeight: 500, fontSize: 20 }} className="px-6">
            Contacts
          </Typography>
        </Box>

        <Box className="p-3">
          {friendList ? (
            filteredFriendList.length > 0 ? (
              <List>
                {filteredFriendList.map((item) => (
                  <ListItemButton
                    key={item?.id}
                    onClick={() => handleSelectedChatItem(item, "contacts")}
                    disableRipple
                    sx={{
                      borderRadius: "10px",
                      backgroundImage: selectedListItem === item?.id ? "linear-gradient(to right, var(--color-green-1), var(--color-green-14))" : "transparent",
                      mb: 1,
                    }}
                  >
                    <ListItem
                      sx={{
                        padding: 0,
                      }}
                      dense
                    >
                      <ListItemAvatar>
                        <CustomAvatar {...{ user_id: item?.user.id, image: item?.user?.profile?.profile_image }} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={item?.user?.name}
                        secondary={item.user?.email}
                        secondaryTypographyProps={{
                          sx: {
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          },
                        }}
                      />
                    </ListItem>
                  </ListItemButton>
                ))}
              </List>
            ) : (
              <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                <Typography>No matching friends found</Typography>
              </Box>
            )
          ) : (
            <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
              <CircularProgress color="success" />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
});

export default ChatListFullScreen;
