import { Box, Grid, useTheme } from "@mui/material";
import MonthlyBarChart from "components/Chart/MonthlyBarChart";
import React, { Fragment, memo, useEffect } from "react";
import MonthlyDoughnutChart from "./MonthlyDoughnutChart";
import { monthlyChart } from "server/chart_data";
import { setMonthlyChart } from "redux/features/chart/chartSlice";
import { useDispatch, useSelector } from "react-redux";
import AccountSkeletonLoader from "components/SkeletonLoader/AccountSkeletonLoader";
import { setMonthlyChartLoading } from "redux/features/loader/loaderSlice";

let doubleCall = false;
const MonthlyChart = ({ id, dailyHourlyMonthlyChartFilterData }) => {
  const theme = useTheme();
  const monthlyChartLoading = useSelector((state) => state.loader.monthlyChartLoading);
  const dispatch = useDispatch();
  const getMonthlyChart = async () => {
    dispatch(setMonthlyChartLoading(true));
    const result = await monthlyChart(id, dailyHourlyMonthlyChartFilterData);
    if (result?.data?.status) {
      dispatch(setMonthlyChart(result.data.data));
      dispatch(setMonthlyChartLoading(false));
    } else {
      dispatch(setMonthlyChartLoading(false));
    }
  };

  useEffect(() => {
    if(doubleCall) return;
    doubleCall = true;
    setTimeout(() => {
      doubleCall = false;
    }, 1000);
    
    getMonthlyChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyHourlyMonthlyChartFilterData]);

  return (
    <Fragment>
      <Grid item xs={12} sm={12} md={6} lg={7}>
        <Box
          sx={{
            border: "1px solid rgba(0, 208, 148, 0.3)",
            background:
              theme.palette.mode === "dark" ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)" : "var(--color-green-10)",
            borderRadius: "10px",
          }}
          className="!p-[8px]"
        >
          {monthlyChartLoading ? <AccountSkeletonLoader /> : <MonthlyBarChart />}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={5}>
        <Box
          sx={{
            border: "1px solid rgba(0, 208, 148, 0.3)",
            background:
              theme.palette.mode === "dark" ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)" : "var(--color-green-10)",
            borderRadius: "10px",
          }}
          className="!p-[8px] h-full flex justify-center items-center"
        >
          {monthlyChartLoading ? <AccountSkeletonLoader /> : <MonthlyDoughnutChart />}
        </Box>
      </Grid>
    </Fragment>
  );
};

export default memo(MonthlyChart);
