import { FormControl, MenuItem, Select, useTheme } from "@mui/material";
import React, { memo } from "react";

const FilterDailyHourlyMonthlyChart = memo(({ dailyHourlyMonthlyChartFilterData, setDailyHourlyMonthlyChartFilterData }) => {
  const theme = useTheme();
  return (
    <FormControl
      sx={{
        "& .MuiOutlinedInput-root": {
          backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-8)" : "var(--color-green-10)",
          height: "28px",
          fontSize: '14px',
          "& fieldset": {
            border: "none",
          },
          "&:hover fieldset": {
            border: "1px solid var(--color-green-1)",
          },
          "&.Mui-focused fieldset": {
            border: `1px solid var(--color-green-2)`,
          },
        },
        "& .MuiInputLabel-root": {
          color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
          "&.Mui-focused": {
            color: "var(--color-green-2)",
          },
        },
      }}
      size="small"
    >
      <Select
        value={dailyHourlyMonthlyChartFilterData}
        onChange={(e) => setDailyHourlyMonthlyChartFilterData(e.target.value)}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        MenuProps={{
          PaperProps: {
            sx: {
              background: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
            },
          },
        }}
      >
        <MenuItem className="!text-sm" value={2024}>2024</MenuItem>
        <MenuItem className="!text-sm" value={2023}>2023</MenuItem>
        <MenuItem className="!text-sm" value={2022}>2022</MenuItem>
        <MenuItem className="!text-sm" value={2021}>2021</MenuItem>
      </Select>
    </FormControl>
  );
});

export default FilterDailyHourlyMonthlyChart;
