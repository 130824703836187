import {
  Box,
  Grid,
  Pagination,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCategory, typePost } from "server/posts";
import { getPostCount } from "./../../server/posts";
import Skeleton from "@mui/material/Skeleton";
import { CloudOutlined } from "@mui/icons-material";
function PostAsideDetails() {
  const theme = useTheme();
  const [typeCount, setTypeCount] = useState([]);
  const [typeOf, setTypeOf] = useState({});
  const [page, setPage] = useState(1);
  const [postType, setPostType] = useState("");
  const [postCategoryId, setPostCategoryId] = useState("");
  const [categoryPost, setCategoryPost] = useState([]);
  const [loader, setLoader] = useState(true);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  //type of post

  const handlePostType = (type) => {
    if (type === postType) {
      setPostType("");
      setPage(1);
    } else {
      setPostType(type);
      setPage(1);
    }
  };
  const handleCategoryPostID = (category_id) => {
    if (category_id === postCategoryId) {
      setPostCategoryId("");
      setPage(1);
    } else {
      setPostCategoryId(category_id);
      setPage(1);
    }
  };

  const typePostCount = async () => {
    const res = await getPostCount();
    if (res?.data?.status) {
      const typeCountData = res?.data?.data;
      const findData = typeCountData.filter(
        (item) => item.type === "daily news" || item.type === "market analysis"
      );
      setTypeCount(findData);
    }
  };
  useEffect(() => {
    typePostCount();
  }, []);

  //get category
  const categoryData = async () => {
    setLoader(true);
    try {
      const res = await getCategory();
      if (res?.data?.status) {
        setCategoryPost(res?.data?.data?.data);
      }
    } catch (error) {
      console.log("🚀 ~ categoryData ~ error:", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    categoryData();
  }, []);

  const getPost = async () => {
    try {
      const res = await typePost(postType, page, postCategoryId);
      if (res?.data?.status) {
        setTypeOf(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postType, postCategoryId, page]);

  const handleChangePage = async (e, pageValue) => {
    setPage(pageValue);
    setLoader(true);
    try {
      const res = await typePost(postType, pageValue, postCategoryId);
      if (res?.data?.status) {
        setTypeOf(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setLoader(false);
    }
  };
  return (
    <>
      <Typography className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-lg">
        Different type of post
      </Typography>
      <Box className="mt-2 mb-2 space-y-2">
        {typeCount?.map((item, index) => (
          <Box
            key={index}
            className="flex justify-between"
            onClick={() => handlePostType(item.type)}
          >
            <Typography
              className={`!text-sm !font-[Poppins] !font-normal hover:!text-[--color-blue-1] ${
                item.type === postType
                  ? "!text-[--color-blue-1]"
                  : "!text-[--color-gray-1]"
              } transition-all cursor-pointer !capitalize`}
            >
              {item.type}
            </Typography>
            <Typography className="!text-sm !font-[Poppins] !font-normal !text-[--color-gray-1]">
              {item.total_post}
            </Typography>
          </Box>
        ))}
      </Box>
      <Typography className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-lg">
        Different category of post
      </Typography>
      <Box className="mt-2 mb-2 space-y-2">
        {categoryPost?.map((item, index) => (
          <Box
            key={index}
            className="flex justify-between"
            onClick={() => handleCategoryPostID(item.id)}
          >
            <Typography
              className={`!text-sm !font-[Poppins] !font-normal hover:!text-[--color-blue-1] ${
                item.id === postCategoryId
                  ? "!text-[--color-blue-1]"
                  : "!text-[--color-gray-1]"
              } transition-all cursor-pointer !capitalize`}
            >
              {item.name}
            </Typography>
          </Box>
        ))}
      </Box>
      <Typography className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-lg !mb-2">
        Recent Posts
      </Typography>

      <>
        {loader ? (
          <Box className="!mt-24">
            <Typography component="div" variant="h3">
              <Skeleton />
            </Typography>
            <Typography component="div" variant="h3">
              <Skeleton />
            </Typography>
            <Typography component="div" variant="h3">
              <Skeleton />
            </Typography>
            <Typography component="div" variant="h3">
              <Skeleton />
            </Typography>
            <Typography component="div" variant="h3">
              <Skeleton />
            </Typography>
            <Typography component="div" variant="h3">
              <Skeleton />
            </Typography>
            <Typography component="div" variant="h3">
              <Skeleton />
            </Typography>
            <Typography component="div" variant="h3">
              <Skeleton />
            </Typography>
          </Box>
        ) : (
          <>
            {typeOf?.data?.length > 0 ? (
              <>
                {typeOf?.data?.map((post, i) => (
                  <Grid key={i} container spacing={2} className="mb-3">
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                      <Link to={`/posts/${post?.id}`}>
                        <Box
                          className="w-full rounded"
                          component={"img"}
                          src={post?.image}
                          alt=""
                        />
                      </Link>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={8}>
                      <Typography
                        variant="h6"
                        component="div"
                        className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)]"
                        sx={{
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                        }}
                      >
                        {post.title}.
                      </Typography>
                      <Typography
                        variant="body2"
                        className="!text-[var(--color-gray-1)] text-justify"
                        sx={{
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                        }}
                        dangerouslySetInnerHTML={{ __html: post?.post }}
                      ></Typography>
                    </Grid>
                  </Grid>
                ))}
                {typeOf?.data?.length > 0 && (
                  <Grid className="flex justify-center items-center">
                    <Pagination
                      count={typeOf?.last_page}
                      variant="outlined"
                      shape="rounded"
                      page={page}
                      onChange={handleChangePage}
                      size={isSmallScreen ? "small" : "medium"}
                    />
                  </Grid>
                )}
              </>
            ) : (
              <Box className="flex justify-center items-center flex-col !mt-8">
                <CloudOutlined sx={{ fontSize: "40px" }} />
                <Typography
                  className="text-center !mt-1"
                  sx={{
                    color:
                      theme.palette.mode === "dark"
                        ? "var(--color-white-1)"
                        : "var(--color-green-3)",
                  }}
                >
                  No data found.
                </Typography>
              </Box>
            )}
          </>
        )}
      </>
    </>
  );
}

export default PostAsideDetails;
