import mirrorApi from '../api/mirror_trade_api';
import { getAuthToken } from './auth';

export const kycUpload = async (data) => {
    try {
        return await mirrorApi.post(`/user/kyc`, data, {
            headers: {
                Authorization: getAuthToken(),
                "Content-Type": "multipart/form-data",
            },
        });
    } catch (error) {
    console.log("🚀 ~ kycUpload ~ error:", error)
    }
}

export const kyc_id_verification_status = async () => {
    try {
        return await mirrorApi.get(`/user/kyc/id-verification/status`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ const kyc_id_verification_status= ~ error:", error)
    }
}
export const kyc_address_verification_status = async () => {
    try {
        return await mirrorApi.get(`/user/kyc/address-verification/status`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ const kyc_address_verification_status= ~ error:", error)
        
    }
}