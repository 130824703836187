import mirrorApi from '../api/mirror_trade_api';
import { getAuthToken } from './auth';

export const copy = async (copy) => {
    try {
        return await mirrorApi.post("/copy", copy, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ file: copy.js:7 ~ copy ~ error:", error)
    }
}
export const unCopy = async (id) => {
    try {
        return await mirrorApi.post(`/uncopy`, { master_id: id }, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ file: copy_un_copy.js:23 ~ unCopy ~ error:", error)
    }
}