/* eslint-disable react-hooks/exhaustive-deps */
import { Chart, registerables } from "chart.js/auto";
import React, { memo, useEffect, useRef } from "react";
const CommissionDailyChart = () => {
  const chartRef = useRef(null);

  const dataL = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  const data = [50, 70, 60, 80, 90];

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");

      Chart.register(...registerables);

      const chartInstance = new Chart(ctx, {
        type: "bar",
        data: {
          labels: dataL,
          datasets: [
            {
              label: "",
              backgroundColor: "#00D094",
              borderColor: "#27D195",
              data: data,
              barThickness: 14,
            },
          ],
        },
        options: {
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: "Day",
              },
              ticks: {
                autoSkip: false, // prevent skipping of ticks in x-axis
              },
              grid: {
                display: false,
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: "Data",
              },
              grid: {
                display: false,
              },
              ticks: {
                autoSkip: false,
                beginAtZero: true,
                // min: 0,
                // max: 3,
                // stepSize: 1,
              },
            },
          },
          elements: {
            bar: {
              borderWidth: 1,
              borderRadius: 14,
            },
          },
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 10,
              bottom: 10,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          chartArea: {
            backgroundColor: "transparent",
          },
          responsive: true,
          maintainAspectRatio: false,
          maxHeight: "100vh",
        },
      });

      return () => {
        chartInstance.destroy();
      };
    }
  }, [data, dataL]);

  return <canvas ref={chartRef} style={{ height: "350px", width: "100%" }} />;
};

export default memo(CommissionDailyChart);
