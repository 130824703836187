import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../redux/features/counter/counterSlice";
import applicationReducer from "./features/application/applicationSlice";
import drawerReducer from "./features/drawer/drawerSlice";
import navigateReducer from "./features/navigateSlice/navigateSlice";
import chatReducer, { loggedInChat } from "./features/chatSlice/chatSlice";
import authReducer, { loggedIn } from "./features/auth/authSlice";
import paginationReducer from "./features/pagination/paginationSlice";
import tradeReducer from "./features/trade/tradeSlice";
import pamReducer from "./features/pam/pamSlice";
import chartReducer from "./features/chart/chartSlice";
import notificationReducer from "./features/notification/notificationSlice";
import loaderReducer from "./features/loader/loaderSlice";
import kycReducer from "./features/toggleKycDashboard/kycSlice";
import postsReducer from "./features/posts/postsSlice";
import brokerReducer from "./features/broker/brokerSlice";
import supportTicketReducer from "./features/supportTicket/supportTicketSlice";
import permissionReducer from "./features/permission/permissionSlice";
import calendarReducer from "./features/calendar/calendarSlice";
import subscriptionReducer from "./features/subscription/subscriptionSlice";
import ibDashboardReducer from "./features/ibDashboard/ibDashboardSlice";
import chatFullScreenReducer from "./features/chatFullScreen/chatFullScreenSlice";
import toggleReducer from "./features/toggle/toggleSlice";
import channelReducer from "./features/channel/channelSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    application: applicationReducer,
    drawer: drawerReducer,
    navigate: navigateReducer,
    chat: chatReducer,
    auth: authReducer,
    pagination: paginationReducer,
    trade: tradeReducer,
    pam: pamReducer,
    chart: chartReducer,
    notification: notificationReducer,
    loader: loaderReducer,
    kyc: kycReducer,
    posts: postsReducer,
    broker: brokerReducer,
    support: supportTicketReducer,
    permission: permissionReducer,
    calender: calendarReducer,
    subscription: subscriptionReducer,
    ib: ibDashboardReducer,
    chatFullScreen: chatFullScreenReducer,
    toggle: toggleReducer, 
    channel: channelReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const loadDataFromStorageChat = async () => {
  try {
    const chatUser = await localStorage.getItem("chatUser");
    if (chatUser) {
      store.dispatch(loggedInChat(JSON.parse(chatUser)));
    }
  } catch (error) {
    console.log("Error loading data from store.js:", error);
  }
};
const loadDataFromStorage = async () => {
  try {
    const user = await localStorage.getItem("user");
    if (user) {
      store.dispatch(loggedIn(JSON.parse(user)));
    }
  } catch (error) {
    console.log("Error loading data from store.js:", error);
  }
};

loadDataFromStorage();
loadDataFromStorageChat();
