import { Close, KeyboardArrowLeft } from "@mui/icons-material";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { setToggleChannelOrCreateChannel } from "redux/features/toggle/toggleSlice";
import EditPostFormChannel from "./EditPostFormChannel";

const EditPostChannel = memo(() => {
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        height: "calc(100vh - 64px)",
        background: "var(--color-green-8)",
      }}
    >
      <Box className="h-[64px] flex justify-between items-center px-2">
        <Box className="w-full flex justify-between items-center p-4 ">
          <Box className="flex justify-between items-center">
            <KeyboardArrowLeft
              onClick={() => dispatch(setToggleChannelOrCreateChannel("channel"))}
              style={{
                cursor: "pointer",
              }}
            />
            <Typography className="!text-[12px] uppercase !font-bold" sx={{ letterSpacing: "0.03em" }}>
              Edit channel post news
            </Typography>
          </Box>
          <IconButton
            title="Close"
            onClick={() => dispatch(setToggleChannelOrCreateChannel("channel"))}
            sx={{
              backgroundColor: "var(--btn-bg-color)",
            }}
          >
            <Close sx={{ fontSize: "18px" }} />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box>
        <EditPostFormChannel />
      </Box>
    </Box>
  );
});

export default EditPostChannel;
