import { ListItemIcon, Menu, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { setToggleChannelOrCreateChannel, setToggleChatOrChannelDrawer } from "redux/features/toggle/toggleSlice";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import { Groups } from "@mui/icons-material";

const MoreChannelPopover = memo(({ moreChannelOpen, anchorElMoreChannel, handleCloseMoreChannel }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const handleCreatePost = () => {
    if (isMediumScreen) {
      dispatch(setToggleChatOrChannelDrawer("createPost"));
    } else {
      dispatch(setToggleChannelOrCreateChannel("createPost"));
    }
  };

  return (
    <Menu
      anchorEl={anchorElMoreChannel}
      open={moreChannelOpen}
      onClose={handleCloseMoreChannel}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{
        sx: {
          background: theme.palette.mode === "dark" ? "var(--color-green-7)" : "var(--color-green-10)",
        },
      }}
    >
      <MenuItem className="capitalize" onClick={handleCreatePost}>
        <ListItemIcon>
          <AddCircleOutlineIcon fontSize="medium" />
        </ListItemIcon>
        Create post
      </MenuItem>
      {/* <MenuItem className="capitalize">
        <ListItemIcon>
          <Groups fontSize="medium" />
        </ListItemIcon>
        Add Member
      </MenuItem> */}
    </Menu>
  );
});

export default MoreChannelPopover;
