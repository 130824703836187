import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { List, Typography } from "@mui/material";
// Menu Icons
import activeIcon from "../../assets/icons/activeIcon.svg";
import DashboardIcon from "@mui/icons-material/Dashboard";
// import leaderboardIcon from "../../assets/icons/Leaderboard.svg";
import applyIcon from "../../assets/icons/apply.svg";
import CommunityIcon from "../../assets/icons/community.svg";
import mt4Icon from "../../assets/icons/mt45.svg";
import calenderIcon from "../../assets/icons/calender.svg";
import helpIcon from "../../assets/icons/help.svg";
import educationIcon from "../../assets/icons/education.svg";
import brokerIcon from "../../assets/icons/broker.svg";
import affiliateIcon from "../../assets/icons/affiliate.svg";
import dividerIcon from "../../assets/icons/divider.svg";
import { Drawer, DrawerHeader } from "layouts/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { navigationMenu } from "data/navigationMenu";

const MiniSidebar = ({ open, handleDrawerClose }) => {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = React.useState("Dashboard");

  const menuHandler = (active) => {
    setActiveMenu(active);
  };

  React.useEffect(() => {
    const matchingRoute = navigationMenu.find((item) => location.pathname === item.routeUrl);
    if (matchingRoute) {
      setActiveMenu(matchingRoute.navigationName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Drawer variant="persistent">
      <DrawerHeader sx={{ justifyContent: "flex-start" }}>
        <IconButton disableRipple sx={{ color: "var(--color-green-1)" }} onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? <CloseIcon /> : <CloseIcon />}
          <Typography sx={{ color: "var(--color-white-1)" }}>Compact</Typography>
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Box>
        <List>
          {navigationMenu.map((item, index) => (
            <Typography
              onClick={() => {
                menuHandler(item.navigationName);
                navigate(item.routeUrl);
              }}
              key={item.navigationName}
              sx={{
                display: "flex",
                alignItems: "normal",
                alignContent: "center",
                paddingTop: "8px",
                cursor: "pointer",
                fontFamily: "Anek Telugu",
                fontSize: "15px",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  marginTop: "20px",
                  visibility: activeMenu === item.navigationName ? "visible" : "hidden",
                }}
              >
                <img src={activeIcon} alt="icon"></img>
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  color: activeMenu === item.navigationName ? "var(--color-black-8)" : "var(--color-white-1)",
                  backgroundColor: activeMenu === item.navigationName ? "var(--color-green-2)" : "transparent",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    height: "45px",
                    alignItems: "center",
                    display: "flex",
                    paddingLeft: "10px",
                    marginLeft: "5px",
                  }}
                >
                  <Typography component={"span"} sx={{ width: "100%", paddingRight: "10px", paddingLeft: "5px", paddingTop: "3px" }}>
                    {item.navigationName === "Dashboard" && <DashboardIcon />}
                    {/* {item.navigationName === "Leaderboard" && <img src={leaderboardIcon} alt="Leaderboard Icon" />} */}
                    {item.navigationName === "News" && <img src={applyIcon} alt="apply Icon" />}
                    {item.navigationName === "Calender" && <img src={calenderIcon} alt="pamm Icon" />}
                    {item.navigationName === "Crypto Calendar" && <img src={CommunityIcon} alt="Community Icon" />}
                    {item.navigationName === "Economic Calendar" && <img src={mt4Icon} alt="mt4 Icon" />}
                    {item.navigationName === "Education" && <img src={educationIcon} alt="education Icon" />}
                    {item.navigationName === "Video Academy" && <img src={calenderIcon} alt="calender Icon" />}
                    {item.navigationName === "Financial News" && <img src={helpIcon} alt="help Icon" />}
                    {item.navigationName === "Glossary" && <img src={brokerIcon} alt="broker Icon" />}
                  </Typography>
                  <Typography component={"span"} sx={{ paddingLeft: "10px" }}>
                    {item.navigationName}
                  </Typography>
                  <Divider variant="inset" component="li" />
                </Typography>
              </Typography>
              {index !== 0 && (
                <img
                  src={dividerIcon}
                  alt="bottom icon"
                  style={{
                    width: "200px",
                    position: "absolute",
                    marginLeft: "15px",
                  }}
                />
              )}
            </Typography>
          ))}
        </List>
        <Typography
          component={"button"}
          sx={{
            display: "flex",
            border: "none",
            padding: "10px 20px",
            background: "var(--color-green-1)",
            marginLeft: "15px",
            borderRadius: "7px",
          }}
        >
          <img src={affiliateIcon} alt="affiliate Icon" sx={{ paddingRight: "10px" }} />
          <Typography sx={{ paddingLeft: "10px" }}>Become an Affiliate</Typography>
        </Typography>
      </Box>
    </Drawer>
  );
};

export default React.memo(MiniSidebar);
