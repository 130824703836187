import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Pagination, Select, TextField, useTheme } from "@mui/material";

import { useFormik } from "formik";
import Layout from "layouts/Layout";
import { memo, useEffect, useState } from "react";
import { getWithdrawReport } from "server/subscription";
import WithdrawReportTable from "./WithdrawReportTable";

const WithdrawReport = memo(() => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const handleChangePage = (_, value) => {
    setPage(value);
  };

  const fetchWithdrawReport = async (values) => {
    try {
      setLoading(true);
      const res = await getWithdrawReport({ page, ...values });
      if (res?.data?.status) {
        setData(res?.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      crypto_currency: "",
      block_chain: "",
      min_amount: "",
      max_amount: "",
      crypto_address: "",
      transaction_id: "",
    },
    onSubmit: async (values) => {
      fetchWithdrawReport(values);
    },
    onReset: () => {
      fetchWithdrawReport();
      setPage(1);
    },
  });

  useEffect(() => {
    fetchWithdrawReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Layout>
      <Box className="p-5 space-y-10">
        <Box
          className="rounded-md p-5 !pb-0"
          sx={{
            backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
          }}
        >
          <Box className="flex justify-between items-center mb-12">
            <Box>Filter Reports</Box>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Grid container columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                  }}
                  size="small"
                >
                  <InputLabel id="crypto_currency">Crypto Currency</InputLabel>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        style: {
                          background: theme.palette.mode === "dark" ? "var(--color-green-8)" : "var(--color-green-10)",
                        },
                      },
                    }}
                    labelId="crypto_currency"
                    id="demo-select-small"
                    name="crypto_currency"
                    label="Crypto Currency"
                    value={formik.values.crypto_currency}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value={"USDT"}>USDT</MenuItem>
                    <MenuItem value={"BTC"}>BTC</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                  }}
                  size="small"
                >
                  <InputLabel id="block_chain">Blockchain</InputLabel>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        style: {
                          background: theme.palette.mode === "dark" ? "var(--color-green-8)" : "var(--color-green-10)",
                        },
                      },
                    }}
                    labelId="block_chain"
                    id="demo-select-small"
                    name="block_chain"
                    label="Blockchain"
                    value={formik.values.block_chain}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value={"ERC20"}>ERC20</MenuItem>
                    <MenuItem value={"TRC20"}>TRC20</MenuItem>
                    <MenuItem value={"BEP20"}>BEP20</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      height: "100%",
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                  }}
                  size="small"
                >
                  <TextField
                    type="text"
                    label="Min Amount"
                    placeholder=""
                    size="small"
                    variant="outlined"
                    name="min_amount"
                    value={formik.values.min_amount}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      height: "100%",
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                  }}
                  size="small"
                >
                  <TextField
                    type="text"
                    label="Max Amount"
                    placeholder=""
                    size="small"
                    variant="outlined"
                    name="max_amount"
                    value={formik.values.max_amount}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      height: "100%",
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                  }}
                  size="small"
                >
                  <TextField
                    type="text"
                    label="Crypto Address"
                    placeholder=""
                    size="small"
                    variant="outlined"
                    name="crypto_address"
                    value={formik.values.crypto_address}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      height: "100%",
                      "& fieldset": {
                        border: "1px solid var(--color-green-7)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--color-green-1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--color-green-2)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                      "&.Mui-focused": {
                        color: "var(--color-green-2)",
                      },
                    },
                  }}
                  size="small"
                >
                  <TextField
                    type="text"
                    label="Transaction ID"
                    placeholder=""
                    size="small"
                    variant="outlined"
                    name="transaction_id"
                    value={formik.values.transaction_id}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Button
                  type="reset"
                  onClick={() => formik.handleReset()}
                  variant="contained"
                  disableRipple
                  fullWidth
                  sx={{
                    marginTop: "2px",
                    backgroundColor: "var(--color-green-5)",
                    ":hover": {
                      backgroundColor: "var(--color-green-4)",
                    },
                  }}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Button
                  type="submit"
                  variant="contained"
                  disableRipple
                  fullWidth
                  sx={{
                    marginTop: "2px",
                    backgroundColor: "var(--color-green-1)",
                    ":hover": {
                      backgroundColor: "var(--color-green-7)",
                    },
                  }}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </form>
        </Box>

        <Box
          className="rounded-md p-5"
          sx={{
            backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <WithdrawReportTable {...{ data, loading }} />
            </Grid>
          </Grid>

          <Box className="flex justify-center items-center mt-1">
            {data?.data?.length > 0 && (
              <Box>
                <Pagination count={data?.last_page} variant="outlined" shape="rounded" page={page} onChange={handleChangePage} size={"medium"} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
});

export default WithdrawReport;
