import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, useTheme } from "@mui/material";
import permissionEnum from "enum/permissionEnum";
import Layout from "layouts/Layout";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPostLoading } from "redux/features/loader/loaderSlice";
import { setAllPost } from "redux/features/posts/postsSlice";
import { getPosts } from "server/posts";
import AllNews from "./AllNews";
import ForexAnalysis from "./ForexAnalysis";
import MarketAnalysis from "./MarketAnalysis";
import TechnicalAnalysis from "./TechnicalAnalysis";

const Posts = () => {
  const dispatch = useDispatch();
  const permission = useSelector((state) => state.permission.permission);
  const postTypes = useSelector((state) => state.posts.postType);
  const theme = useTheme();
  const [postTypeValue, setPostTypeValue] = useState("1");
  const [page, setPage] = useState(1);

  const handleChange = async (_, newValue) => {
    if (newValue !== postTypeValue) {
      setPostTypeValue(newValue);
      setPage(1);
      await getAllPost(1, newValue);
    }
  };

  useEffect(() => {
    if (permission.length === 0) return;
    if (!permission?.includes(permissionEnum["/posts"])) {
      window.location.href = "/";
    }
  }, [permission]);

  const getAllPost = async (page, newValue) => {
    dispatch(setPostLoading(true));
    const result = await getPosts(page, postTypes[newValue - 1]?.type);
    if (result?.data?.status) {
      dispatch(setAllPost(result.data.data));
      dispatch(setPostLoading(false));
    } else {
      dispatch(setPostLoading(false));
    }
  };

  useEffect(() => {
    getAllPost(page, postTypeValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, postTypeValue]);

  const handleChangePage = async (_, value) => {
    setPage(value);
  };

  return (
    <Layout>
      <Box className="h-full">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={postTypeValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "transparent",
                  },
                  "& .MuiTab-root": {
                    color:
                      theme.palette.mode === "dark"
                        ? "var(--color-white-1)"
                        : "var(--color-white-2)",
                    "&.Mui-selected": {
                      backgroundColor: "var(--color-green-2)",
                    },
                  },
                }}
                className="dark:bg-[var(--color-green-3)] bg-[#C5F0DC] !h-[30px]"
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {postTypes?.map((item, i) => (
                  <Tab
                    key={item.type}
                    className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !capitalize dark:!font-normal !font-medium"
                    label={item.type}
                    value={(i + 1).toString()}
                  />
                ))}
              </TabList>
            </Box>

            {postTypes?.map((item, index) => (
              <TabPanel key={item.type} value={(index + 1).toString()}>
                {postTypeValue === (index + 1).toString() && (
                  <>
                    {item.type === "daily news" && (index + 1).toString() && (
                      <Box className="p-6">
                        <AllNews {...{ handleChangePage, page }} />
                      </Box>
                    )}
                    {item.type === "market analysis" &&
                      (index + 1).toString() && (
                        <MarketAnalysis {...{ handleChangePage, page }} />
                      )}
                    {item.type === "trading signal" &&
                      (index + 1).toString() && (
                        <ForexAnalysis {...{ handleChangePage, page }} />
                      )}
                    {item.type === "technical analysis" &&
                      (index + 1).toString() && (
                        <TechnicalAnalysis {...{ handleChangePage, page }} />
                      )}
                  </>
                )}
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      </Box>
    </Layout>
  );
};

export default memo(Posts);
