import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import moment from "moment";
import { memo } from "react";

const PackagePurchaseTable = memo(({ data, loading }) => {
  const theme = useTheme();
  return (
    <TableContainer
      sx={{
        marginBottom: {
          xs: "-10px",
          sm: "10px",
          md: "5px",
        },
      }}
    >
      <Table aria-label="My clients">
        <TableHead>
          <TableRow className="dark:bg-[--color-green-6] bg-[--color-dark-green] shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]">
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              Name
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              Account Number
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              Package Name
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              Package Type
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              Status
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                color: theme.palette.mode === "dark" ? "var(--color-gray-1)" : "var(--color-white-1)",
                fontFamily: "Anek Telugu",
                fontWeight: 500,
                fontSize: "16px",
                textAlign: "end",
              }}
            >
              Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow
              sx={{
                ".MuiTableCell-root": { borderBottom: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}` },
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell
                align="center"
                colSpan={6}
                sx={{ color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)" }}
                className="!font-bold !text-base"
              >
                <CircularProgress size={28} color="success" />
              </TableCell>
            </TableRow>
          ) : data?.data?.length > 0 ? (
            data?.data?.map((item, i) => (
              <TableRow
                className={`shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]`}
                key={i}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                  component="th"
                  scope="row"
                >
                  {item?.user_name}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.account_number}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.package_name}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.package_type}
                </TableCell>

                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {item.status}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                    fontFamily: "Anek Telugu",
                    fontWeight: 500,
                    fontSize: "14px",
                    textAlign: "end",
                  }}
                >
                  {moment(item.created_at).format("DD-MM-YYYY HH:mm")}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow className="flex justify-center items-center">
              <TableCell
                align="center"
                colSpan={12}
                sx={{
                  borderBottom: "none",
                  color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)",
                  fontFamily: "Anek Telugu",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
              >
                No data found!
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default PackagePurchaseTable;
