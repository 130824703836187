import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import React, { Fragment, memo, useEffect, useRef, useState } from "react";
import { DoneAll } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { chat_history } from "server/chat";
import { setChatHistory } from "redux/features/chatSlice/chatSlice";
import moment from "moment";
import { seen_notification } from "server/notification";
import { updateUnseenNotification } from "redux/features/notification/notificationSlice";

const ChattingContent = memo(() => {
  const user = useSelector((state) => state.chat.user);
  const isSelectedChatOrChannel = useSelector((state) => state.chatFullScreen.isSelectedChatOrChannel);
  const activeChat = useSelector((state) => state.chat.activeChat);
  const chatHistory = useSelector((state) => state.chat.chatHistory);
  const chatBoxRef = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const getChatHistory = async () => {
    setLoading(true);
    const chats = await chat_history(activeChat?.id);
    if (chats?.data?.status) {
      setLoading(false);
      dispatch(setChatHistory(chats.data?.data[0]?.chat_content));
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeChat?.id) {
      getChatHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat?.id]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [chatHistory, loading]);

  const seenNotification = async () => {
    let for_type = "CHAT";
    const res = await seen_notification(for_type, activeChat?.id);
    if (res?.data?.status) {
      dispatch(updateUnseenNotification(activeChat?.id));
    }
  };

  useEffect(() => {
    seenNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatHistory, activeChat?.id]);

  return (
    <Box className="chat-list-scrollbar" sx={{ height: "calc(100vh - 193px)", overflowY: "auto" }} ref={chatBoxRef}>
      <Box className="p-2 pt-8">
        {loading ? (
          <Box sx={{ height: "calc(100vh - 250px)" }} className="flex items-center justify-center">
            <CircularProgress color="success" />
          </Box>
        ) : (
          <Fragment>
            {chatHistory && chatHistory?.length > 0 ? (
              chatHistory?.map((message, i) => (
                <Box key={i} sx={{ display: "flex", justifyContent: message.receiver_id === user?.id ? "flex-start" : "flex-end", alignItems: "center", mb: 1 }}>
                  {message.receiver_id === user?.id ? (
                    // Receiver
                    <Fragment>
                      <Box alignSelf={"start"}>
                        <Avatar src={message?.avatar} alt="Sender" sx={{ ml: 1 }} />
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", maxWidth: "85%", ml: 1 }}>
                        <>
                          {message.type === "ATTACHMENT" ? (
                            <Box sx={{ padding: "8px 8px 2px 8px", borderRadius: "0px 10px 10px 10px", backgroundColor: "var(--color-green-14)" }}>
                              <img
                                src={`${message.content}`}
                                alt={message.title}
                                loading="lazy"
                                width={200}
                                style={{ borderRadius: 5, border: "1px solid var(--color-green-1)", cursor: "pointer" }}
                                onClick={() => window.open(message.content, "_blank")}
                              />
                            </Box>
                          ) : (
                            <Box sx={{ padding: 1, borderRadius: "0px 10px 10px 10px", backgroundColor: "var(--color-green-14)" }}>
                              <Typography variant="body1">{message.content}</Typography>
                            </Box>
                          )}
                        </>

                        <Box className="flex items-center gap-1 mt-1">
                          <DoneAll sx={{ fontSize: "14px" }} />
                          <Typography sx={{ fontSize: "14px" }} variant="caption">
                            {moment(message?.createdAt).local().format("h:mm A")}
                          </Typography>
                        </Box>
                      </Box>
                    </Fragment>
                  ) : (
                    // Sender
                    <Fragment>
                      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", maxWidth: "85%" }}>
                        <>
                          {message.type === "ATTACHMENT" ? (
                            <Box sx={{ padding: "8px 8px 2px 8px", borderRadius: "10px 0px 10px 10px", backgroundColor: "var(--color-green-14)" }}>
                              <img
                                src={`${message.content}`}
                                alt={message.title}
                                loading="lazy"
                                width={200}
                                style={{ borderRadius: 5, border: "1px solid var(--color-green-1)", cursor: "pointer" }}
                                onClick={() => window.open(message.content, "_blank")}
                              />
                            </Box>
                          ) : (
                            <Box sx={{ padding: 1, borderRadius: "10px 0px 10px 10px", backgroundColor: "var(--color-green-14)" }}>
                              <Typography variant="body1">{message.content}</Typography>
                            </Box>
                          )}
                        </>
                        <Box className="flex items-center gap-1 mt-1">
                          <DoneAll sx={{ fontSize: "14px" }} />
                          <Typography sx={{ fontSize: "14px" }} variant="caption">
                            {moment(message?.createdAt).local().format("h:mm A")}
                          </Typography>
                        </Box>
                      </Box>
                      <Box alignSelf={"start"}>
                        <Avatar src={user?.profile?.profile_image} alt="Sender" sx={{ ml: 1 }} />
                      </Box>
                    </Fragment>
                  )}
                </Box>
              ))
            ) : isSelectedChatOrChannel ? (
              <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                <Typography>No chats found.</Typography>
              </Box>
            ) : (
              <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                <Typography>Please select chat or channel first.</Typography>
              </Box>
            )}
          </Fragment>
        )}
      </Box>
    </Box>
  );
});

export default ChattingContent;
