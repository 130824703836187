import { HighlightOff } from "@mui/icons-material";
import { Box, Grid, IconButton, Modal, Typography, useMediaQuery, useTheme } from "@mui/material";
import ChangePassword from "components/Auth/ChangePassword/ChangePassword";
import VerifyOtpChangePassword from "components/Auth/ChangePassword/VerifyOtpChangePassword";
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOpenChangePasswordModal } from "redux/features/application/applicationSlice";

const ChangePasswordModal = memo(({ openChangePasswordModal }) => {
  const [otpCode, setOtpCode] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const changePasswordNavigate = useSelector((state) => state.navigate.changePasswordNavigate);
  const dispatch = useDispatch();

  return (
    <Modal
      open={openChangePasswordModal}
      onClose={() => dispatch(setOpenChangePasswordModal(false))}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isSmallScreen ? "90%" : 400,
          background: theme.palette.mode === "dark" ? "var(--color-green-7)" : "var(--color-green-10)",
          borderRadius: "5px",
          padding: "15px",
          outline: "none",
        }}
      >
        <Grid container rowSpacing={3} alignItems={"center"}>
          <Grid item xs={6} className="text-start">
            <Typography>Change Password</Typography>
          </Grid>
          <Grid item xs={6} className="text-end">
            <IconButton onClick={() => dispatch(setOpenChangePasswordModal(false))} title="Close">
              <HighlightOff />
            </IconButton>
          </Grid>
          {changePasswordNavigate === "verify_code" && <VerifyOtpChangePassword {...{ setOtpCode }} />}
          {changePasswordNavigate === "change_password" && <ChangePassword {...{otpCode}} />}
        </Grid>
      </Box>
    </Modal>
  );
});

export default ChangePasswordModal;
