import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, useTheme } from "@mui/material";
import permissionEnum from "enum/permissionEnum";
import Layout from "layouts/Layout";
import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import AdvancedTechnicalAnalysis from "./AdvancedTechnicalAnalysis";
import CryptoTradingInvesting from "./CryptoTradingInvesting";
import CryptocurrencyFundamentals from "./CryptocurrencyFundamentals";
import IntroToFinancialMarkets from "./IntroToFinancialMarkets";
import TechnicalAnalysisArticles from "./TechnicalAnalysisArticles";
import TechnicalAnalysisEssentials from "./TechnicalAnalysisEssentials";
import TradingSignals from "./TradingSignals";

const Education = () => {
  const permission = useSelector((state) => state.permission.permission);
  const theme = useTheme();
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (permission.length === 0) return;
    if (!permission?.includes(permissionEnum["/education"])) {
      window.location.href = "/";
    }
  }, [permission]);

  return (
    <Layout>
      <Box
        sx={{
          height: "100%",
        }}
        className="h-screen"
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "transparent",
                  },
                  "& .MuiTab-root": {
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-white-2)",
                    "&.Mui-selected": {
                      backgroundColor: "var(--color-green-2)",
                    },
                  },
                }}
                className="dark:bg-[var(--color-green-3)] bg-[#C5F0DC] !h-[30px]"
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case dark:!font-normal !font-medium"
                  label="Advanced Technical Analysis"
                  value="1"
                />
                <Tab
                  className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case dark:!font-normal !font-medium"
                  label="Crypto Trading Investing"
                  value="2"
                />
                <Tab
                  className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case dark:!font-normal !font-medium"
                  label="Cryptocurrency Fundamentals"
                  value="3"
                />
                <Tab
                  className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case dark:!font-normal !font-medium"
                  label="Intro To Financial Markets"
                  value="4"
                />
                <Tab
                  className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case dark:!font-normal !font-medium"
                  label="Technical Analysis Essentials"
                  value="5"
                />
                <Tab
                  className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case dark:!font-normal !font-medium"
                  label="Trading Signals"
                  value="6"
                />
                <Tab
                  className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case dark:!font-normal !font-medium"
                  label="Technical Analysis Articles"
                  value="7"
                />
              </TabList>
            </Box>
            <TabPanel className="!p-0" value="1">
              <AdvancedTechnicalAnalysis />
            </TabPanel>
            <TabPanel className="!p-0" value="2">
              <CryptoTradingInvesting />
            </TabPanel>
            <TabPanel className="!p-0" value="3">
              <CryptocurrencyFundamentals />
            </TabPanel>
            <TabPanel className="!p-0" value="4">
              <IntroToFinancialMarkets />
            </TabPanel>
            <TabPanel className="!p-0" value="5">
              <TechnicalAnalysisEssentials />
            </TabPanel>
            <TabPanel className="!p-0" value="6">
              <TradingSignals />
            </TabPanel>
            <TabPanel className="!p-0" value="7">
              <TechnicalAnalysisArticles />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>

    </Layout>
  );
};

export default memo(Education);
