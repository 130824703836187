import { Box, CircularProgress } from "@mui/material";
import Layout from "layouts/Layout";
import React, { memo, useState } from "react";

const TechnicalAnalysisArticles = () => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <Layout>
      <Box className="h-full w-full">
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress sx={{ color: "#00c990" }} />
          </Box>
        )}

        <iframe
          title="TechnicalAnalysisArticles"
          src="https://api.infotraderhub.com/Technical_Analysis_Articles/Technical_Analysis_Articles.html"
          style={{
            width: "100%",
            height: "100vh",
            display: loading ? "none" : "block",
          }}
          onLoad={handleLoad}
        ></iframe>
      </Box>
    </Layout>
  );
};

export default memo(TechnicalAnalysisArticles);
