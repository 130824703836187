import { Image, Mic } from "@mui/icons-material";
import { Box, Button, FormControl, IconButton, TextField, styled } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { memo, useRef, useState } from "react";
import { BsSend } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { addChatHistory } from "redux/features/chatSlice/chatSlice";
import { upload_image } from "server/chat";
import { socketServiceSendChat } from "services/socketServiceFullScreenChat";
import { v4 as uuid } from "uuid";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ChattingInput = memo(() => {
  const [message, setMessage] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const chat = useSelector((state) => state.chat);
  const user = useSelector((state) => state.chat.user);
  const receiver_id = useRef();

  if (chat.activeChat.receiver_id === chat.user?.id) {
    receiver_id.current = chat.activeChat.sender_id;
  } else {
    receiver_id.current = chat.activeChat.receiver_id;
  }

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSendMessage = () => {
    const timestamp = moment().toISOString();
    const uniqueId = uuid();
    const trimmedMessage = message.trim();
    if (trimmedMessage) {
      const data = {
        content: trimmedMessage,
        sender_id: user?.id,
        receiver_id: receiver_id.current,
        id: uuid(),
        u_id: uniqueId,
        createdAt: timestamp,
        type: "TEXT",
      };
      dispatch(addChatHistory(data));
      socketServiceSendChat({
        chat_id: chat.activeChat.id,
        content: trimmedMessage,
        sender_id: user?.id,
        receiver_id: receiver_id.current,
        u_id: uniqueId,
      });
    }
    setMessage("");
  };

  const handleKeyDown = (event) => {
    if (message === "") return;
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  // Upload file
  const handleUploadFile = async (event) => {
    const uniqueId = uuid();
    const img = event.target.files[0];
    if (!img) return;
    if (img.type !== "image/jpeg" && img.type !== "image/png") {
      enqueueSnackbar("Only jpg,jpeg,png is allowed", {
        variant: "error",
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", img);
    formData.append("attachment_for", "chat");
    formData.append("for_id", chat.activeChat.id);

    dispatch(
      addChatHistory({
        content: "https://placehold.co/200x200/237259/FFF?text=uploading+please+wait+...",
        sender_id: user.id,
        receiver_id: receiver_id.current,
        id: uuid(),
        u_id: uniqueId,
        createdAt: moment().toISOString(),
        type: "ATTACHMENT",
      })
    );
    const result = await upload_image(formData);
    if (result?.data?.status) {
      const file_type = "ATTACHMENT";
      let content = process.env.REACT_APP_CHAT_API_BASEURL + "/file/upload/" + result.data.data.id;

      socketServiceSendChat({
        chat_id: chat.activeChat.id,
        content,
        sender_id: user.id,
        receiver_id: receiver_id.current,
        attachment_id: result.data.data.id,
        file_type,
        u_id: uniqueId,
      });
    }
  };

  return (
    <Box className="!h-28 bg-[var(--color-green-7)] rounded-md flex justify-between items-center mx-5 px-2 mb-2">
      <FormControl
        fullWidth
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
            "&:hover fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
          },
        }}
      >
        <TextField
          autoComplete="off"
          value={message}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          size="small"
          placeholder="Type your message here..."
          variant="outlined"
        />
      </FormControl>
      <Box className="flex items-center gap-1">
        <IconButton>
          <Mic />
        </IconButton>

        <IconButton component="label">
          <Image />
          <VisuallyHiddenInput onChange={(e) => handleUploadFile(e)} type="file" accept="image/*" />
        </IconButton>
        <Button
          disabled={message === "" ? true : false}
          onClick={handleSendMessage}
          sx={{
            marginLeft: 1,
            background: "var(--color-green-1)",
            "&:hover": {
              color: "var(--color-white-1)",
              background: "var(--color-green-2)",
            },
          }}
          variant="contained"
          startIcon={<BsSend />}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
});

export default ChattingInput;
