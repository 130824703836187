import { Grid, Skeleton, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { memo } from "react";

const SkeletonLoaderDashboard = memo(() => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const placeholderArray = new Array(isSmallScreen ? 1 : 6).fill(null);
  return (
    <Grid container sx={{ justifyContent: "center" }}>
      {placeholderArray.map((_, index) => (
        <Grid key={index} item xs={12} md={6} lg={4}>
          <Box sx={{ marginRight: 0.5, m: 3 }}>
            <Skeleton variant="rectangular" animation="wave" height={120} />
            <br />
            <Box>
              <Skeleton variant="circular" animation={false} width={50} height={50} />
              <br />
              <Skeleton variant="text" />
              <Skeleton variant="rounded" />
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
});

export default SkeletonLoaderDashboard;
