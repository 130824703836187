import { Menu } from "@mui/icons-material";
import { Avatar, Box, IconButton, Typography, useMediaQuery } from "@mui/material";
import React, { Fragment, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerOpen } from "redux/features/toggle/toggleSlice";

const ChannelHeader = memo(() => {
  const activeChannel = useSelector((state) => state.channel.activeChannel);
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const dispatch = useDispatch();

  return (
    <Fragment>
      <Box
        className="flex justify-between items-center"
        sx={{
          padding: "0px 10px",
          boxSizing: "border-box",
          height: "64px",
        }}
      >
        <Box className="w-full flex items-center pt-3 pb-2">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <Avatar src={activeChannel?.icon} alt="News" />
            </Box>
          </Box>
          <Box className="flex flex-col ps-2">
            <Typography variant="h6" className="!text-[14px] capitalize" sx={{ color: "var(--color-white)", fontWeight: 600 }}>
              {activeChannel?.name}
            </Typography>
          </Box>
        </Box>
        <Box className="flex items-center gap-1">
          {/* <FormControl>
            <TextField
              // onChange={handleSearchChannel}
              autoComplete="off"
              fullWidth
              variant="outlined"
              placeholder="Search news..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      sx={{
                        color: "var(--color-white-1)",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "38px",
                  width: "100%",
                  color: "var(--color-white-1)",
                  padding: 1,
                  boxSizing: "border-box",
                  borderRadius: "20px",
                  "& fieldset": {
                    border: "1px solid var(--color-gray-2)",
                    opacity: 1,
                  },
                  "&:hover fieldset": {
                    border: "1px solid var(--color-green-1)",
                  },
                  "&.Mui-focused fieldset": {
                    border: `1px solid var(--color-green-2)`,
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "14px",
                  },
                },
              }}
            />
          </FormControl> */}
          {isMediumScreen && (
            <IconButton title="Chat / Channel" onClick={() => dispatch(setDrawerOpen(true))}>
              <Menu />
            </IconButton>
          )}
        </Box>
      </Box>
    </Fragment>
  );
});

export default ChannelHeader;
