import React, { memo } from 'react';

const About = memo(() => {
    return (
        <div>
            About
        </div>
    );
});

export default About;