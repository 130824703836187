import { Article, Feed, KeyboardArrowDown, KeyboardArrowUp, Payment, PeopleAltOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListIcon from "@mui/icons-material/List";
import { List, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import { navigationMenu } from "data/navigationMenu";
import { Drawer, DrawerHeader } from "layouts/Layout";
import * as React from "react";
import { Fragment, memo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import activeIcon from "../../assets/icons/activeIcon.svg";
import affiliateIcon from "../../assets/icons/affiliate.svg";
import applyIcon from "../../assets/icons/apply.svg";
import brokerIcon from "../../assets/icons/broker.svg";
import calenderIcon from "../../assets/icons/calender.svg";
import dividerIcon from "../../assets/icons/divider.svg";
import educationIcon from "../../assets/icons/education.svg";
import pammDashIcon from "../../assets/icons/pamm.svg";

const LeftSidebar = ({ open, handleDrawerClose }) => {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const permission = useSelector((state) => state.permission.permission);
  const theme = useTheme();
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = React.useState("");
  const menuHandler = (active) => {
    setActiveMenu(active);
  };

  React.useEffect(() => {
    const matchingRoute = navigationMenu.find((item) => location.pathname === item.routeUrl);
    if (matchingRoute) {
      setActiveMenu(matchingRoute.navigationName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const [isDropdownOpen, setDropdownOpen] = React.useState(false);
  const [isMenuSelected, setIsMenuSelected] = React.useState("");
  const handleAffiliateButtonClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const [isDropdownOpenReport, setDropdownOpenReport] = React.useState(false);
  const [isMenuSelectedReport, setIsMenuSelectedReport] = React.useState("");
  const handleReportButtonClick = () => {
    setDropdownOpenReport(!isDropdownOpenReport);
  };

  const [isDropdownOpenEducation, setDropdownOpenEducation] = React.useState(false);
  const [isMenuSelectedEducation, setIsMenuSelectedEducation] = React.useState("");
  const handleEducationOpenOrClose = () => {
    setDropdownOpenEducation(!isDropdownOpenEducation);
  };

  const [isDropdownOpenCalender, setDropdownOpenCalender] = React.useState(false);
  const [isMenuSelectedCalender, setIsMenuSelectedCalender] = React.useState("");
  const handleCalenderOpenOrClose = () => {
    setDropdownOpenCalender(!isDropdownOpenCalender);
  };

  const [isDropdownOpenVideoAcademy, setDropdownOpenVideoAcademy] = React.useState(false);
  const [isMenuSelectedVideoAcademy, setIsMenuSelectedVideoAcademy] = React.useState("");
  const handleVideoAcademyOpenOrClose = () => {
    setDropdownOpenVideoAcademy(!isDropdownOpenVideoAcademy);
  };

  const [isDropdownOpenWithdraw, setDropdownOpenWithdraw] = React.useState(false);
  const [isMenuSelectedWithdraw, setIsMenuSelectedWithdraw] = React.useState("");
  const handleWithdrawOpenOrClose = () => {
    setDropdownOpenWithdraw(!isDropdownOpenWithdraw);
  };

  React.useEffect(() => {
    if (location.pathname === "/advanced-technical") {
      setDropdownOpenEducation(true);
      setIsMenuSelectedEducation("Advanced Technical Analysis");
    } else if (location.pathname === "/crypto-trading") {
      setDropdownOpenEducation(true);
      setIsMenuSelectedEducation("Crypto Trading");
    } else if (location.pathname === "/crypto-fundamental") {
      setDropdownOpenEducation(true);
      setIsMenuSelectedEducation("Cryptocurrency Fundamentals");
    } else if (location.pathname === "/intro-financial-market") {
      setDropdownOpenEducation(true);
      setIsMenuSelectedEducation("Introduction to Financial Markets");
    } else if (location.pathname === "/technical-analysis-essential") {
      setDropdownOpenEducation(true);
      setIsMenuSelectedEducation("Technical Analysis Essentials");
    } else if (location.pathname === "/trading-signals") {
      setDropdownOpenEducation(true);
      setIsMenuSelectedEducation("Trading Signals");
    } else if (location.pathname === "/technical-analysis-articles") {
      setDropdownOpenEducation(true);
      setIsMenuSelectedEducation("Technical Analysis Articles");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  React.useEffect(() => {
    if (location.pathname === "/advanced-technical-video") {
      setDropdownOpenVideoAcademy(true);
      setIsMenuSelectedVideoAcademy("Advanced Technical Analysis");
    } else if (location.pathname === "/crypto-trading-video") {
      setDropdownOpenVideoAcademy(true);
      setIsMenuSelectedVideoAcademy("Crypto Trading");
    } else if (location.pathname === "/crypto-fundamental-video") {
      setDropdownOpenVideoAcademy(true);
      setIsMenuSelectedVideoAcademy("Cryptocurrency Fundamentals");
    } else if (location.pathname === "/intro-financial-market-video") {
      setDropdownOpenVideoAcademy(true);
      setIsMenuSelectedVideoAcademy("Introduction to Financial Markets");
    } else if (location.pathname === "/expert-technical-analysis") {
      setDropdownOpenVideoAcademy(true);
      setIsMenuSelectedVideoAcademy("Expert Technical Analysis");
    } else if (location.pathname === "/metaTrader-tutorials") {
      setDropdownOpenVideoAcademy(true);
      setIsMenuSelectedVideoAcademy("MetaTrader Tutorials");
    } else if (location.pathname === "/technical-analysis-essentials") {
      setDropdownOpenVideoAcademy(true);
      setIsMenuSelectedVideoAcademy("Technical Analysis Essentials");
    } else if (location.pathname === "/market-highlights-TV") {
      setDropdownOpenVideoAcademy(true);
      setIsMenuSelectedVideoAcademy("Market Highlights TV");
    } else if (location.pathname === "/market-research-videos") {
      setDropdownOpenVideoAcademy(true);
      setIsMenuSelectedVideoAcademy("Market Research Videos");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  React.useEffect(() => {
    if (location.pathname === "/economic-calendar") {
      setDropdownOpenCalender(true);
      setIsMenuSelectedCalender("Economic Calendar");
    } else if (location.pathname === "/cryptocurrency-calender") {
      setDropdownOpenCalender(true);
      setIsMenuSelectedCalender("Crypto Calendar");
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (location.pathname === "/my-clients") {
      setDropdownOpen(true);
      setIsMenuSelected("my-clients");
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (location.pathname === "/commission") {
      setDropdownOpenReport(true);
      setIsMenuSelectedReport("commission");
    }
    if (location.pathname === "/withdraw-report") {
      setDropdownOpenReport(true);
      setIsMenuSelectedReport("withdraw-report");
    }
    if (location.pathname === "/account-list") {
      setDropdownOpenReport(true);
      setIsMenuSelectedReport("account_list");
    }
    if (location.pathname === "/follows") {
      setDropdownOpenReport(true);
      setIsMenuSelectedReport("follows");
    }
    if (location.pathname === "/package-purchase-report") {
      setDropdownOpenReport(true);
      setIsMenuSelectedReport("package_purchase_report");
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (location.pathname === "/crypto-withdraw") {
      setDropdownOpenWithdraw(true);
      setIsMenuSelectedWithdraw("crypto-withdraw");
    }
  }, [location.pathname]);

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader sx={{ justifyContent: "flex-start" }}>
        <IconButton disableRipple sx={{ color: "var(--color-green-1)" }} onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? <CloseIcon /> : <CloseIcon />}
          <Typography sx={{ color: "var(--color-white-1)" }}>Compact</Typography>
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Box>
        <List>
          <Typography
            onClick={() => {
              menuHandler("Dashboard");
              navigate("/");
            }}
            sx={{
              display: "flex",
              alignItems: "normal",
              alignContent: "center",
              paddingTop: "8px",
              cursor: "pointer",
              fontFamily: "Anek Telugu",
              fontSize: "15px",
            }}
          >
            <Typography
              component={"span"}
              sx={{
                marginTop: "20px",
                visibility: activeMenu === "Dashboard" ? "visible" : "hidden",
              }}
            >
              <img src={activeIcon} alt="icon"></img>
            </Typography>
            <Typography
              component={"span"}
              sx={{
                width: "100%",
                marginTop: "10px",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
                color: activeMenu === "Dashboard" ? "var(--color-black-8)" : "var(--color-white-1)",
                backgroundColor: activeMenu === "Dashboard" ? "var(--color-green-2)" : "transparent",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  height: "45px",
                  alignItems: "center",
                  display: "flex",
                  paddingLeft: "10px",
                  marginLeft: "5px",
                }}
              >
                <Typography
                  container="true"
                  display={{ sm: "block", xs: "none" }}
                  component={"span"}
                  sx={{
                    paddingRight: "10px",
                    paddingLeft: "5px",
                    paddingTop: "3px",
                  }}
                >
                  <DashboardIcon />
                </Typography>
                <Typography component={"span"} sx={{ paddingLeft: "10px" }}>
                  Dashboard
                </Typography>
                <Divider variant="inset" component="li" />
              </Typography>
            </Typography>
          </Typography>
          {/* Master Dashboard */}
          {user?.role === "pamm" && (
            <Typography
              onClick={() => {
                menuHandler("PAMM Dashboard");
                navigate("/pamm-dashboard");
              }}
              sx={{
                display: "flex",
                alignItems: "normal",
                alignContent: "center",
                paddingTop: "8px",
                cursor: "pointer",
                fontFamily: "Anek Telugu",
                fontSize: "15px",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  marginTop: "20px",
                  visibility: activeMenu === "PAMM Dashboard" ? "visible" : "hidden",
                }}
              >
                <img src={activeIcon} alt="icon"></img>
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  color: activeMenu === "PAMM Dashboard" ? "var(--color-black-8)" : "var(--color-white-1)",
                  backgroundColor: activeMenu === "PAMM Dashboard" ? "var(--color-green-2)" : "transparent",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    height: "45px",
                    alignItems: "center",
                    display: "flex",
                    paddingLeft: "10px",
                    marginLeft: "5px",
                  }}
                >
                  <Typography
                    container="true"
                    display={{ sm: "block", xs: "none" }}
                    component={"span"}
                    sx={{
                      paddingRight: "10px",
                      paddingLeft: "0px",
                      paddingTop: "3px",
                    }}
                  >
                    <img src={pammDashIcon} alt="pammDashIcon" />
                  </Typography>
                  <Typography component={"span"} sx={{ paddingLeft: "10px" }}>
                    Master Dashboard
                  </Typography>
                  <Divider variant="inset" component="li" />
                </Typography>
              </Typography>
              <img
                src={dividerIcon}
                alt="bottom icon"
                style={{
                  width: "200px",
                  position: "absolute",
                  marginLeft: "15px",
                }}
              />
            </Typography>
          )}

          {/* Education Route */}
          {permission?.includes("education") && (
            <Fragment>
              <Typography
                onClick={handleEducationOpenOrClose}
                sx={{
                  display: "flex",
                  alignItems: "normal",
                  alignContent: "center",
                  paddingTop: "8px",
                  cursor: "pointer",
                  fontFamily: "Anek Telugu",
                  fontSize: "15px",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    marginTop: "20px",
                    visibility: isDropdownOpenEducation ? "visible" : "hidden",
                  }}
                >
                  <img src={activeIcon} alt="icon"></img>
                </Typography>
                <Typography
                  component={"span"}
                  sx={{
                    width: "100%",
                    marginTop: "10px",
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                  }}
                >
                  <Typography
                    component={"span"}
                    sx={{
                      height: "45px",
                      alignItems: "center",
                      display: "flex",
                      paddingLeft: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    <Typography
                      container="true"
                      display={{ sm: "block", xs: "none" }}
                      component={"span"}
                      sx={{
                        paddingRight: "10px",
                        paddingLeft: "0px",
                        paddingTop: "3px",
                      }}
                    >
                      <img src={educationIcon} alt="education Icon" sx={{ paddingRight: "10px" }} />
                    </Typography>
                    <Typography component={"span"} sx={{ paddingLeft: "10px" }}>
                      Education
                    </Typography>
                    {isDropdownOpenEducation ? (
                      <KeyboardArrowUp className="!ml-16 !text-[var(--color-white-1)]" />
                    ) : (
                      <KeyboardArrowDown className="!ml-16 !text-[var(--color-white-1)]" />
                    )}
                    <Divider variant="inset" component="li" />
                  </Typography>
                </Typography>
                <img
                  src={dividerIcon}
                  alt="bottom icon"
                  style={{
                    width: "200px",
                    position: "absolute",
                    marginLeft: "15px",
                  }}
                />
              </Typography>
              {isDropdownOpenEducation && (
                <>
                  <Typography
                    component={"span"}
                    title="Advanced Technical Analysis"
                    variant="span"
                    onClick={() => {
                      navigate("/advanced-technical");
                      setIsMenuSelectedEducation("Advanced Technical Analysis");
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedEducation === "Advanced Technical Analysis" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedEducation === "Advanced Technical Analysis" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <img src={educationIcon} alt="education Icon" sx={{ paddingRight: "10px" }} />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Advanced Technical...
                      </Typography>
                    </Typography>
                    <img
                      src={dividerIcon}
                      alt="bottom icon"
                      style={{
                        width: "200px",
                        position: "absolute",
                        marginLeft: "15px",
                      }}
                    />
                  </Typography>
                  <Typography
                    component={"span"}
                    title="Crypto Trading and Investing"
                    variant="span"
                    onClick={() => {
                      navigate("/crypto-trading");
                      setIsMenuSelectedEducation("Crypto Trading");
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedEducation === "Crypto Trading" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedEducation === "Crypto Trading" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <img src={educationIcon} alt="education Icon" sx={{ paddingRight: "10px" }} />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Crypto Trading And...
                      </Typography>
                    </Typography>
                    <img
                      src={dividerIcon}
                      alt="bottom icon"
                      style={{
                        width: "200px",
                        position: "absolute",
                        marginLeft: "15px",
                      }}
                    />
                  </Typography>
                  <Typography
                    title="Cryptocurrency Fundamentals"
                    component={"span"}
                    variant="span"
                    onClick={() => {
                      navigate("/crypto-fundamental");
                      setIsMenuSelectedEducation("Cryptocurrency Fundamentals");
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedEducation === "Cryptocurrency Fundamentals" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedEducation === "Cryptocurrency Fundamentals" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <img src={educationIcon} alt="education Icon" sx={{ paddingRight: "10px" }} />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Cryptocurrency Fun...
                      </Typography>
                    </Typography>
                    <img
                      src={dividerIcon}
                      alt="bottom icon"
                      style={{
                        width: "200px",
                        position: "absolute",
                        marginLeft: "15px",
                      }}
                    />
                  </Typography>
                  <Typography
                    title="Introduction to Financial Markets"
                    component={"span"}
                    variant="span"
                    onClick={() => {
                      navigate("/intro-financial-market");
                      setIsMenuSelectedEducation("Introduction to Financial Markets");
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedEducation === "Introduction to Financial Markets" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedEducation === "Introduction to Financial Markets" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <img src={educationIcon} alt="education Icon" sx={{ paddingRight: "10px" }} />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Introduction to Fina...
                      </Typography>
                    </Typography>
                    <img
                      src={dividerIcon}
                      alt="bottom icon"
                      style={{
                        width: "200px",
                        position: "absolute",
                        marginLeft: "15px",
                      }}
                    />
                  </Typography>
                  <Typography
                    title="Technical Analysis Essentials"
                    component={"span"}
                    variant="span"
                    onClick={() => {
                      navigate("/technical-analysis-essential");
                      setIsMenuSelectedEducation("Technical Analysis Essentials");
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedEducation === "Technical Analysis Essentials" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedEducation === "Technical Analysis Essentials" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <img src={educationIcon} alt="education Icon" sx={{ paddingRight: "10px" }} />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Technical Analysis E...
                      </Typography>
                    </Typography>
                    <img
                      src={dividerIcon}
                      alt="bottom icon"
                      style={{
                        width: "200px",
                        position: "absolute",
                        marginLeft: "15px",
                      }}
                    />
                  </Typography>
                  <Typography
                    component={"span"}
                    variant="span"
                    onClick={() => {
                      navigate("/trading-signals");
                      setIsMenuSelectedEducation("Trading Signals");
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedEducation === "Trading Signals" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedEducation === "Trading Signals" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <img src={educationIcon} alt="education Icon" sx={{ paddingRight: "10px" }} />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Trading Signals
                      </Typography>
                    </Typography>
                    <img
                      src={dividerIcon}
                      alt="bottom icon"
                      style={{
                        width: "200px",
                        position: "absolute",
                        marginLeft: "15px",
                      }}
                    />
                  </Typography>
                  <Typography
                    component={"span"}
                    title="Technical Analysis Articles"
                    variant="span"
                    onClick={() => {
                      navigate("/technical-analysis-articles");
                      setIsMenuSelectedEducation("Technical Analysis Articles");
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedEducation === "Technical Analysis Articles" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedEducation === "Technical Analysis Articles" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <img src={educationIcon} alt="education Icon" sx={{ paddingRight: "10px" }} />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Technical Analysis A...
                      </Typography>
                    </Typography>
                    {/* <img
                      src={dividerIcon}
                      alt="bottom icon"
                      style={{
                        width: "200px",
                        position: "absolute",
                        marginLeft: "15px",
                      }}
                    /> */}
                  </Typography>
                </>
              )}
            </Fragment>
          )}

          {/* Video academy Route */}
          {permission?.includes("video academy") && (
            <Fragment>
              <Typography
                onClick={handleVideoAcademyOpenOrClose}
                sx={{
                  display: "flex",
                  alignItems: "normal",
                  alignContent: "center",
                  paddingTop: "8px",
                  cursor: "pointer",
                  fontFamily: "Anek Telugu",
                  fontSize: "15px",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    marginTop: "20px",
                    visibility: isDropdownOpenVideoAcademy ? "visible" : "hidden",
                  }}
                >
                  <img src={activeIcon} alt="icon"></img>
                </Typography>
                <Typography
                  component={"span"}
                  sx={{
                    width: "100%",
                    marginTop: "10px",
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                  }}
                >
                  <Typography
                    component={"span"}
                    sx={{
                      height: "45px",
                      alignItems: "center",
                      display: "flex",
                      paddingLeft: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    <Typography
                      container="true"
                      display={{ sm: "block", xs: "none" }}
                      component={"span"}
                      sx={{
                        paddingRight: "10px",
                        paddingLeft: "0px",
                        paddingTop: "3px",
                      }}
                    >
                      <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                    </Typography>
                    <Typography component={"span"} sx={{ paddingLeft: "15px" }}>
                      Video Academy
                    </Typography>
                    {isDropdownOpenVideoAcademy ? (
                      <KeyboardArrowUp className="!ml-6 !text-[var(--color-white-1)]" />
                    ) : (
                      <KeyboardArrowDown className="!ml-6 !text-[var(--color-white-1)]" />
                    )}
                    <Divider variant="inset" component="li" />
                  </Typography>
                </Typography>
                <img
                  src={dividerIcon}
                  alt="bottom icon"
                  style={{
                    width: "200px",
                    position: "absolute",
                    marginLeft: "15px",
                  }}
                />
              </Typography>
              {isDropdownOpenVideoAcademy && (
                <>
                  <Typography
                    component={"span"}
                    title="Advanced Technical Analysis"
                    variant="span"
                    onClick={() => {
                      navigate("/advanced-technical-video");
                      setIsMenuSelectedVideoAcademy("Advanced Technical Analysis");
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedVideoAcademy === "Advanced Technical Analysis" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedVideoAcademy === "Advanced Technical Analysis" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Advanced Technical...
                      </Typography>
                    </Typography>
                    <img
                      src={dividerIcon}
                      alt="bottom icon"
                      style={{
                        width: "200px",
                        position: "absolute",
                        marginLeft: "15px",
                      }}
                    />
                  </Typography>
                  <Typography
                    component={"span"}
                    title="Crypto Trading and Investing"
                    variant="span"
                    onClick={() => {
                      navigate("/crypto-trading-video");
                      setIsMenuSelectedVideoAcademy("Crypto Trading");
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedVideoAcademy === "Crypto Trading" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedVideoAcademy === "Crypto Trading" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Crypto Trading And...
                      </Typography>
                    </Typography>
                    {/* <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "200px",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  /> */}
                  </Typography>
                  <Typography
                    title="Cryptocurrency Fundamentals"
                    component={"span"}
                    variant="span"
                    onClick={() => {
                      navigate("/crypto-fundamental-video");
                      setIsMenuSelectedVideoAcademy("Cryptocurrency Fundamentals");
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedVideoAcademy === "Cryptocurrency Fundamentals" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedVideoAcademy === "Cryptocurrency Fundamentals" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Cryptocurrency Fun...
                      </Typography>
                    </Typography>
                    <img
                      src={dividerIcon}
                      alt="bottom icon"
                      style={{
                        width: "200px",
                        position: "absolute",
                        marginLeft: "15px",
                      }}
                    />
                  </Typography>
                  <Typography
                    title="Introduction to Financial Markets"
                    component={"span"}
                    variant="span"
                    onClick={() => {
                      navigate("/intro-financial-market-video");
                      setIsMenuSelectedVideoAcademy("Introduction to Financial Markets");
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedVideoAcademy === "Introduction to Financial Markets" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedVideoAcademy === "Introduction to Financial Markets" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Introduction to Fina...
                      </Typography>
                    </Typography>
                    <img
                      src={dividerIcon}
                      alt="bottom icon"
                      style={{
                        width: "200px",
                        position: "absolute",
                        marginLeft: "15px",
                      }}
                    />
                  </Typography>
                  <Typography
                    title="Expert Technical Analysis"
                    component={"span"}
                    variant="span"
                    onClick={() => {
                      navigate("/expert-technical-analysis");
                      setIsMenuSelectedVideoAcademy("Expert Technical Analysis");
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedVideoAcademy === "Expert Technical Analysis" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedVideoAcademy === "Expert Technical Analysis" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Expert Technical Ana...
                      </Typography>
                    </Typography>
                    <img
                      src={dividerIcon}
                      alt="bottom icon"
                      style={{
                        width: "200px",
                        position: "absolute",
                        marginLeft: "15px",
                      }}
                    />
                  </Typography>
                  <Typography
                    component={"span"}
                    variant="span"
                    onClick={() => {
                      navigate("/metaTrader-tutorials");
                      setIsMenuSelectedVideoAcademy("MetaTrader Tutorials");
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedVideoAcademy === "MetaTrader Tutorials" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedVideoAcademy === "MetaTrader Tutorials" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        MetaTrader Tutorials
                      </Typography>
                    </Typography>
                    <img
                      src={dividerIcon}
                      alt="bottom icon"
                      style={{
                        width: "200px",
                        position: "absolute",
                        marginLeft: "15px",
                      }}
                    />
                  </Typography>
                  <Typography
                    component={"span"}
                    title="Technical Analysis Essentials"
                    variant="span"
                    onClick={() => {
                      navigate("/technical-analysis-essentials");
                      setIsMenuSelectedVideoAcademy("Technical Analysis Essentials");
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedVideoAcademy === "Technical Analysis Essentials" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedVideoAcademy === "Technical Analysis Essentials" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Technical Analysis E...
                      </Typography>
                    </Typography>
                    <img
                      src={dividerIcon}
                      alt="bottom icon"
                      style={{
                        width: "200px",
                        position: "absolute",
                        marginLeft: "15px",
                      }}
                    />
                  </Typography>
                  <Typography
                    component={"span"}
                    variant="span"
                    onClick={() => {
                      navigate("/market-highlights-TV");
                      setIsMenuSelectedVideoAcademy("Market Highlights TV");
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedVideoAcademy === "Market Highlights TV" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedVideoAcademy === "Market Highlights TV" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Market Highlights TV
                      </Typography>
                    </Typography>
                    <img
                      src={dividerIcon}
                      alt="bottom icon"
                      style={{
                        width: "200px",
                        position: "absolute",
                        marginLeft: "15px",
                      }}
                    />
                  </Typography>
                  <Typography
                    component={"span"}
                    title="Market Research Videos"
                    variant="span"
                    onClick={() => {
                      navigate("/market-research-videos");
                      setIsMenuSelectedVideoAcademy("Market Research Videos");
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedVideoAcademy === "Market Research Videos" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedVideoAcademy === "Market Research Videos" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <img src={applyIcon} alt="applyIcon" sx={{ paddingRight: "10px" }} />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Market Research Vi...
                      </Typography>
                    </Typography>
                    {/* <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "200px",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  /> */}
                  </Typography>
                </>
              )}
            </Fragment>
          )}

          {/* Calender */}
          {permission?.includes("calendar") && (
            <Fragment>
              <Typography
                onClick={handleCalenderOpenOrClose}
                sx={{
                  display: "flex",
                  alignItems: "normal",
                  alignContent: "center",
                  paddingTop: "8px",
                  cursor: "pointer",
                  fontFamily: "Anek Telugu",
                  fontSize: "15px",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    marginTop: "20px",
                    visibility: isDropdownOpenCalender ? "visible" : "hidden",
                  }}
                >
                  <img src={activeIcon} alt="icon"></img>
                </Typography>
                <Typography
                  component={"span"}
                  sx={{
                    width: "100%",
                    marginTop: "10px",
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                  }}
                >
                  <Typography
                    component={"span"}
                    sx={{
                      height: "45px",
                      alignItems: "center",
                      display: "flex",
                      paddingLeft: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    <Typography
                      container="true"
                      display={{ sm: "block", xs: "none" }}
                      component={"span"}
                      sx={{
                        paddingRight: "10px",
                        paddingLeft: "0px",
                        paddingTop: "3px",
                      }}
                    >
                      <img src={calenderIcon} alt="calenderIcon" sx={{ paddingRight: "10px" }} />
                    </Typography>
                    <Typography component={"span"} sx={{ paddingLeft: "10px" }}>
                      Calender
                    </Typography>
                    {isDropdownOpenCalender ? (
                      <KeyboardArrowUp className="!ml-[70px] !text-[var(--color-white-1)]" />
                    ) : (
                      <KeyboardArrowDown className="!ml-[70px] !text-[var(--color-white-1)]" />
                    )}
                    <Divider variant="inset" component="li" />
                  </Typography>
                </Typography>
                <img
                  src={dividerIcon}
                  alt="bottom icon"
                  style={{
                    width: "200px",
                    position: "absolute",
                    marginLeft: "15px",
                  }}
                />
              </Typography>

              {isDropdownOpenCalender && (
                <>
                  {permission?.includes("economic calendar") && (
                    <Typography
                      component={"span"}
                      variant="span"
                      onClick={() => {
                        navigate("/economic-calendar");
                        setIsMenuSelectedCalender("Economic Calendar");
                      }}
                    >
                      <Typography
                        component={"button"}
                        sx={{
                          width: "100%",
                          display: "flex",
                          border: "none",
                          padding: "10px 20px",
                          background: isMenuSelectedCalender === "Economic Calendar" ? "var(--color-green-1)" : "transparent",
                          marginLeft: "9px",
                          borderRadius: "7px",
                          cursor: "pointer",
                          marginTop: "10px",
                          "&:active": {
                            background: isMenuSelectedCalender === "Economic Calendar" ? "var(--color-green-1)" : "transparent",
                          },
                        }}
                      >
                        <img src={calenderIcon} alt="calenderIcon" sx={{ paddingRight: "10px" }} />
                        <Typography
                          sx={{
                            marginLeft: "18px",
                            color: "var(--color-white-1)",
                            textTransform: "capitalize",
                          }}
                        >
                          Economic Calendar
                        </Typography>
                      </Typography>
                      <img
                        src={dividerIcon}
                        alt="bottom icon"
                        style={{
                          width: "200px",
                          position: "absolute",
                          marginLeft: "15px",
                        }}
                      />
                    </Typography>
                  )}
                  {permission?.includes("crypto calendar") && (
                    <Typography
                      component={"span"}
                      variant="span"
                      onClick={() => {
                        navigate("/cryptocurrency-calender");
                        setIsMenuSelectedCalender("Crypto Calendar");
                      }}
                    >
                      <Typography
                        component={"button"}
                        sx={{
                          width: "100%",
                          display: "flex",
                          border: "none",
                          padding: "10px 20px",
                          background: isMenuSelectedCalender === "Crypto Calendar" ? "var(--color-green-1)" : "transparent",
                          marginLeft: "9px",
                          borderRadius: "7px",
                          cursor: "pointer",
                          marginTop: "10px",
                          "&:active": {
                            background: isMenuSelectedCalender === "Crypto Calendar" ? "var(--color-green-1)" : "transparent",
                          },
                        }}
                      >
                        <img src={calenderIcon} alt="calenderIcon" sx={{ paddingRight: "10px" }} />
                        <Typography
                          sx={{
                            marginLeft: "18px",
                            color: "var(--color-white-1)",
                            textTransform: "capitalize",
                          }}
                        >
                          Crypto Calendar
                        </Typography>
                      </Typography>
                      {/* <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "200px",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  /> */}
                    </Typography>
                  )}
                </>
              )}
            </Fragment>
          )}
          {permission?.includes("finance news") && (
            <Typography
              onClick={() => {
                menuHandler("Financial News");
                navigate("/financial-news");
              }}
              sx={{
                display: "flex",
                alignItems: "normal",
                alignContent: "center",
                paddingTop: "8px",
                cursor: "pointer",
                fontFamily: "Anek Telugu",
                fontSize: "15px",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  marginTop: "20px",
                  visibility: activeMenu === "Financial News" ? "visible" : "hidden",
                }}
              >
                <img src={activeIcon} alt="icon"></img>
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  color: activeMenu === "Financial News" ? "var(--color-black-8)" : "var(--color-white-1)",
                  backgroundColor: activeMenu === "Financial News" ? "var(--color-green-2)" : "transparent",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    height: "45px",
                    alignItems: "center",
                    display: "flex",
                    paddingLeft: "10px",
                    marginLeft: "5px",
                  }}
                >
                  <Typography
                    container="true"
                    display={{ sm: "block", xs: "none" }}
                    component={"span"}
                    sx={{
                      paddingRight: "10px",
                      paddingLeft: "0px",
                      paddingTop: "3px",
                    }}
                  >
                    <Feed sx={{ color: "#e1e4e3" }} />
                  </Typography>
                  <Typography component={"span"} sx={{ paddingLeft: "10px" }}>
                    Financial News
                  </Typography>
                  <Divider variant="inset" component="li" />
                </Typography>
              </Typography>
              <img
                src={dividerIcon}
                alt="bottom icon"
                style={{
                  width: "200px",
                  position: "absolute",
                  marginLeft: "15px",
                }}
              />
            </Typography>
          )}

          {permission?.includes("glosary") && (
            <Typography
              onClick={() => {
                menuHandler("Glossary");
                navigate("/glossary");
              }}
              sx={{
                display: "flex",
                alignItems: "normal",
                alignContent: "center",
                paddingTop: "8px",
                cursor: "pointer",
                fontFamily: "Anek Telugu",
                fontSize: "15px",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  marginTop: "20px",
                  visibility: activeMenu === "Glossary" ? "visible" : "hidden",
                }}
              >
                <img src={activeIcon} alt="icon"></img>
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  color: activeMenu === "Glossary" ? "var(--color-black-8)" : "var(--color-white-1)",
                  backgroundColor: activeMenu === "Glossary" ? "var(--color-green-2)" : "transparent",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    height: "45px",
                    alignItems: "center",
                    display: "flex",
                    paddingLeft: "10px",
                    marginLeft: "5px",
                  }}
                >
                  <Typography
                    container="true"
                    display={{ sm: "block", xs: "none" }}
                    component={"span"}
                    sx={{
                      paddingRight: "10px",
                      paddingLeft: "0px",
                      paddingTop: "3px",
                    }}
                  >
                    <ListIcon sx={{ color: "#e1e4e3" }} />
                  </Typography>
                  <Typography component={"span"} sx={{ paddingLeft: "10px" }}>
                    Glossary
                  </Typography>
                  <Divider variant="inset" component="li" />
                </Typography>
              </Typography>
              <img
                src={dividerIcon}
                alt="bottom icon"
                style={{
                  width: "200px",
                  position: "absolute",
                  marginLeft: "15px",
                }}
              />
            </Typography>
          )}

          {permission?.includes("broker portal") && (
            <Typography
              onClick={() => {
                menuHandler("Broker Portal");
                navigate("/broker-portal");
              }}
              sx={{
                display: "flex",
                alignItems: "normal",
                alignContent: "center",
                paddingTop: "8px",
                cursor: "pointer",
                fontFamily: "Anek Telugu",
                fontSize: "15px",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  marginTop: "20px",
                  visibility: activeMenu === "Broker Portal" ? "visible" : "hidden",
                }}
              >
                <img src={activeIcon} alt="icon"></img>
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  color: activeMenu === "Broker Portal" ? "var(--color-black-8)" : "var(--color-white-1)",
                  backgroundColor: activeMenu === "Broker Portal" ? "var(--color-green-2)" : "transparent",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    height: "45px",
                    alignItems: "center",
                    display: "flex",
                    paddingLeft: "10px",
                    marginLeft: "5px",
                  }}
                >
                  <Typography
                    container="true"
                    display={{ sm: "block", xs: "none" }}
                    component={"span"}
                    sx={{
                      paddingRight: "10px",
                      paddingLeft: "5px",
                      paddingTop: "3px",
                    }}
                  >
                    <img src={brokerIcon} alt="broker Icon" />
                  </Typography>
                  <Typography component={"span"} sx={{ paddingLeft: "10px" }}>
                    Broker Portal
                  </Typography>
                  <Divider variant="inset" component="li" />
                </Typography>
              </Typography>
              <img
                src={dividerIcon}
                alt="bottom icon"
                style={{
                  width: "200px",
                  position: "absolute",
                  marginLeft: "15px",
                }}
              />
            </Typography>
          )}

          {user?.ib_access === 1 && (
            <Fragment>
              <Typography
                onClick={handleAffiliateButtonClick}
                sx={{
                  display: "flex",
                  alignItems: "normal",
                  alignContent: "center",
                  paddingTop: "8px",
                  cursor: "pointer",
                  fontFamily: "Anek Telugu",
                  fontSize: "15px",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    marginTop: "20px",
                    visibility: isDropdownOpen ? "visible" : "hidden",
                  }}
                >
                  <img src={activeIcon} alt="icon"></img>
                </Typography>
                <Typography
                  component={"span"}
                  sx={{
                    width: "100%",
                    marginTop: "10px",
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                  }}
                >
                  <Typography
                    component={"span"}
                    sx={{
                      height: "45px",
                      alignItems: "center",
                      display: "flex",
                      paddingLeft: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    <Typography
                      container="true"
                      display={{ sm: "block", xs: "none" }}
                      component={"span"}
                      sx={{
                        paddingRight: "10px",
                        paddingLeft: "0px",
                        paddingTop: "3px",
                      }}
                    >
                      <img src={affiliateIcon} alt="affiliate Icon" sx={{ paddingRight: "10px" }} />
                    </Typography>
                    <Typography component={"span"} sx={{ paddingLeft: "10px" }}>
                      Affiliate
                    </Typography>
                    {isDropdownOpen ? <KeyboardArrowUp className="!ml-[74px] !text-[var(--color-white-1)]" /> : <KeyboardArrowDown className="!ml-[74px] !text-[var(--color-white-1)]" />}
                    <Divider variant="inset" component="li" />
                  </Typography>
                </Typography>
                <img
                  src={dividerIcon}
                  alt="bottom icon"
                  style={{
                    width: "200px",
                    position: "absolute",
                    marginLeft: "15px",
                  }}
                />
              </Typography>

              {isDropdownOpen && (
                <Typography
                  component={"span"}
                  variant="span"
                  onClick={() => {
                    navigate("/my-clients");
                    setIsMenuSelected("my-clients");
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelected === "my-clients" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelected === "my-clients" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <PeopleAltOutlined className="!text-[var(--color-white-1)]" />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      My Clients
                    </Typography>
                  </Typography>
                  {/* <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "200px",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  /> */}
                </Typography>
              )}
            </Fragment>
          )}
          {/* All reports here */}
          <Fragment>
            <Typography
              onClick={handleReportButtonClick}
              sx={{
                display: "flex",
                alignItems: "normal",
                alignContent: "center",
                paddingTop: "8px",
                cursor: "pointer",
                fontFamily: "Anek Telugu",
                fontSize: "15px",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  marginTop: "20px",
                  visibility: isDropdownOpenReport ? "visible" : "hidden",
                }}
              >
                <img src={activeIcon} alt="icon"></img>
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    height: "45px",
                    alignItems: "center",
                    display: "flex",
                    paddingLeft: "10px",
                    marginLeft: "5px",
                  }}
                >
                  <Typography
                    container="true"
                    display={{ sm: "block", xs: "none" }}
                    component={"span"}
                    sx={{
                      paddingRight: "10px",
                      paddingLeft: "0px",
                      paddingTop: "3px",
                    }}
                  >
                    <Article />
                  </Typography>
                  <Typography component={"span"} sx={{ paddingLeft: "10px" }}>
                    Reports
                  </Typography>
                  {isDropdownOpenReport ? (
                    <KeyboardArrowUp className="!ml-[68px] !text-[var(--color-white-1)]" />
                  ) : (
                    <KeyboardArrowDown className="!ml-[68px] !text-[var(--color-white-1)]" />
                  )}
                  <Divider variant="inset" component="li" />
                </Typography>
              </Typography>
              <img
                src={dividerIcon}
                alt="bottom icon"
                style={{
                  width: "200px",
                  position: "absolute",
                  marginLeft: "15px",
                }}
              />
            </Typography>
            {isDropdownOpenReport && (
              <>
                {user?.ib_access === 1 && (
                  <>
                    <Typography
                      component={"span"}
                      variant="span"
                      onClick={() => {
                        navigate("/commission");
                        setIsMenuSelectedReport("commission");
                      }}
                    >
                      <Typography
                        component={"button"}
                        sx={{
                          width: "100%",
                          display: "flex",
                          border: "none",
                          padding: "10px 20px",
                          background: isMenuSelectedReport === "commission" ? "var(--color-green-1)" : "transparent",
                          marginLeft: "9px",
                          borderRadius: "7px",
                          cursor: "pointer",
                          marginTop: "10px",
                          "&:active": {
                            background: isMenuSelectedReport === "commission" ? "var(--color-green-1)" : "transparent",
                          },
                        }}
                      >
                        <Article className="!text-[var(--color-white-1)]" />
                        <Typography
                          sx={{
                            marginLeft: "18px",
                            color: "var(--color-white-1)",
                            textTransform: "capitalize",
                          }}
                        >
                          Commission Report
                        </Typography>
                      </Typography>
                      <img
                        src={dividerIcon}
                        alt="bottom icon"
                        style={{
                          width: "200px",
                          position: "absolute",
                          marginLeft: "15px",
                        }}
                      />
                    </Typography>
                    <Typography
                      component={"span"}
                      variant="span"
                      onClick={() => {
                        navigate("/withdraw-report");
                        setIsMenuSelectedReport("withdraw-report");
                      }}
                    >
                      <Typography
                        component={"button"}
                        sx={{
                          width: "100%",
                          display: "flex",
                          border: "none",
                          padding: "10px 20px",
                          background: isMenuSelectedReport === "withdraw-report" ? "var(--color-green-1)" : "transparent",
                          marginLeft: "9px",
                          borderRadius: "7px",
                          cursor: "pointer",
                          marginTop: "10px",
                          "&:active": {
                            background: isMenuSelectedReport === "withdraw-report" ? "var(--color-green-1)" : "transparent",
                          },
                        }}
                      >
                        <Article className="!text-[var(--color-white-1)]" />
                        <Typography
                          sx={{
                            marginLeft: "18px",
                            color: "var(--color-white-1)",
                            textTransform: "capitalize",
                          }}
                        >
                          Withdraw Report
                        </Typography>
                      </Typography>
                      <img
                        src={dividerIcon}
                        alt="bottom icon"
                        style={{
                          width: "200px",
                          position: "absolute",
                          marginLeft: "15px",
                        }}
                      />
                    </Typography>
                  </>
                )}
                {/* Here all normal report will be added */}
                <Typography
                  component={"span"}
                  variant="span"
                  onClick={() => {
                    navigate("/account-list");
                    setIsMenuSelectedReport("account_list");
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedReport === "account_list" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedReport === "account_list" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <Article className="!text-[var(--color-white-1)]" />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Account List
                    </Typography>
                  </Typography>
                  <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "200px",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  />
                </Typography>
                {permission?.includes("copy") && (
                  <Typography
                    component={"span"}
                    variant="span"
                    onClick={() => {
                      navigate("/follows");
                      setIsMenuSelectedReport("follows");
                    }}
                  >
                    <Typography
                      component={"button"}
                      sx={{
                        width: "100%",
                        display: "flex",
                        border: "none",
                        padding: "10px 20px",
                        background: isMenuSelectedReport === "follows" ? "var(--color-green-1)" : "transparent",
                        marginLeft: "9px",
                        borderRadius: "7px",
                        cursor: "pointer",
                        marginTop: "10px",
                        "&:active": {
                          background: isMenuSelectedReport === "follows" ? "var(--color-green-1)" : "transparent",
                        },
                      }}
                    >
                      <Article className="!text-[var(--color-white-1)]" />
                      <Typography
                        sx={{
                          marginLeft: "18px",
                          color: "var(--color-white-1)",
                          textTransform: "capitalize",
                        }}
                      >
                        Follows Report
                      </Typography>
                    </Typography>
                    <img
                      src={dividerIcon}
                      alt="bottom icon"
                      style={{
                        width: "200px",
                        position: "absolute",
                        marginLeft: "15px",
                      }}
                    />
                  </Typography>
                )}
                <Typography
                  component={"span"}
                  variant="span"
                  onClick={() => {
                    navigate("/package-purchase-report");
                    setIsMenuSelectedReport("package_purchase_report");
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedReport === "package_purchase_report" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedReport === "package_purchase_report" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <Article className="!text-[var(--color-white-1)]" />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Subscription Report
                    </Typography>
                  </Typography>
                  {/* <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "200px",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  /> */}
                </Typography>
              </>
            )}
          </Fragment>

          {/* Withdraw */}
          {user?.ib_access === 1 && (
            <Fragment>
              <Typography
                onClick={handleWithdrawOpenOrClose}
                sx={{
                  display: "flex",
                  alignItems: "normal",
                  alignContent: "center",
                  paddingTop: "8px",
                  cursor: "pointer",
                  fontFamily: "Anek Telugu",
                  fontSize: "15px",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    marginTop: "20px",
                    visibility: isDropdownOpenWithdraw ? "visible" : "hidden",
                  }}
                >
                  <img src={activeIcon} alt="icon"></img>
                </Typography>
                <Typography
                  component={"span"}
                  sx={{
                    width: "100%",
                    marginTop: "10px",
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                  }}
                >
                  <Typography
                    component={"span"}
                    sx={{
                      height: "45px",
                      alignItems: "center",
                      display: "flex",
                      paddingLeft: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    <Typography
                      container="true"
                      display={{ sm: "block", xs: "none" }}
                      component={"span"}
                      sx={{
                        paddingRight: "10px",
                        paddingLeft: "0px",
                        paddingTop: "3px",
                      }}
                    >
                      <Payment className="!text-[var(--color-white-1)]" />
                    </Typography>
                    <Typography component={"span"} sx={{ paddingLeft: "10px" }}>
                      Withdraw
                    </Typography>
                    {isDropdownOpenWithdraw ? (
                      <KeyboardArrowUp className="!ml-14 !text-[var(--color-white-1)]" />
                    ) : (
                      <KeyboardArrowDown className="!ml-14 !text-[var(--color-white-1)]" />
                    )}
                    <Divider variant="inset" component="li" />
                  </Typography>
                </Typography>
                <img
                  src={dividerIcon}
                  alt="bottom icon"
                  style={{
                    width: "200px",
                    position: "absolute",
                    marginLeft: "15px",
                  }}
                />
              </Typography>

              {isDropdownOpenWithdraw && (
                <Typography
                  component={"span"}
                  variant="span"
                  onClick={() => {
                    navigate("/crypto-withdraw");
                    setIsMenuSelectedWithdraw("crypto-withdraw");
                  }}
                >
                  <Typography
                    component={"button"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      border: "none",
                      padding: "10px 20px",
                      background: isMenuSelectedWithdraw === "crypto-withdraw" ? "var(--color-green-1)" : "transparent",
                      marginLeft: "9px",
                      borderRadius: "7px",
                      cursor: "pointer",
                      marginTop: "10px",
                      "&:active": {
                        background: isMenuSelectedWithdraw === "crypto-withdraw" ? "var(--color-green-1)" : "transparent",
                      },
                    }}
                  >
                    <Payment className="!text-[var(--color-white-1)]" />
                    <Typography
                      sx={{
                        marginLeft: "18px",
                        color: "var(--color-white-1)",
                        textTransform: "capitalize",
                      }}
                    >
                      Crypto Withdraw
                    </Typography>
                  </Typography>
                  <img
                    src={dividerIcon}
                    alt="bottom icon"
                    style={{
                      width: "200px",
                      position: "absolute",
                      marginLeft: "15px",
                    }}
                  />
                </Typography>
              )}
            </Fragment>
          )}
        </List>
      </Box>
    </Drawer>
  );
};

export default memo(LeftSidebar);
