import mirrorApi from '../api/mirror_trade_api';
const { getAuthToken } = require("./auth");

export const brokerList = async (page, searchText) => {
    try {
        return await mirrorApi.get(`/broker-list?page=${page}&search=${searchText}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {

    }
};

export const joinBroker = async (brokerJoinData) => {
    try {
        return await mirrorApi.post(`/join/to/broker`, brokerJoinData, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {

    }
};
