import mirrorApi from '../api/mirror_trade_api';
import { getAuthToken } from './auth';

export const updateProfile = async (data) => {
    try {
        return await mirrorApi.post(`/profile-update`, data, {
            headers: {
                Authorization: getAuthToken(),
                "Content-Type": "multipart/form-data",
            },
        });
    } catch (error) {
    console.log("🚀 ~ file: update_profile.js:12 ~ updateProfile ~ error:", error)
    }
}