import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch, useSelector } from "react-redux";
import { setGroupList, setGroupNavigate } from "redux/features/chatSlice/chatSlice";
import { get_group_list, join_group, search_group } from "server/chat";
import GroupTopBar from "./GroupTopbar";
import { CloudOutlined } from "@mui/icons-material";

const SearchGroup = ({ handlePopoverCloseChat }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [searchList, setSearchList] = useState([]);
  const groupList = useSelector((state) => state.chat.groupList);
  const user = useSelector((state) => state.chat.user);
  const [sentReq, setSentReq] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleSearchUser = async (e) => {
    if (e.target.value.length < 3) {
      return;
    }
    setSearchText(e.target.value)
    setLoading(true);
    const result = await search_group(e.target.value);
    if (result?.data?.status) {
      // i want to check if the user is already in the friend list then i will not show this user in the search list
      setLoading(false);
      if (result?.data?.data?.length > 0) {
        const filtered = result?.data?.data?.filter((item) => {
          return !groupList.some((group) => group.id === item.id);
        });
        setSearchList(filtered);
      }
    } else {
      setLoading(false);
    }
  };

  const joinGroupHandler = async (_, group_id) => {
    const result = await join_group(group_id, user.id);
    if (result.data.status) {
      setSentReq([...sentReq, group_id]);
      addMemberToGroup();
    }
  };

  // add member to group when join
  const addMemberToGroup = async () => {
    const updateGroupList = await get_group_list();
    if (updateGroupList.data?.status) {
      dispatch(setGroupList(updateGroupList.data.data));
    }
  };
  return (
    <Grid
      container
      sx={{
        width: "360px",
        height: "400px",
        background: "var(--color-green-8)",
        padding: "15px",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <Grid item xs={12}>
        <GroupTopBar {...{ handlePopoverCloseChat }} />
      </Grid>
      <Grid item xs={12}>
        <FormControl
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              height: "30px",
              width: "100%",
              borderRadius: "200px",
              fontSize: "14px",
              color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-2)",
              "& fieldset": {
                border: "1px solid var(--color-green-7)",
              },
              "&:hover fieldset": {
                border: "1px solid var(--color-green-1)",
              },
              "&.Mui-focused fieldset": {
                border: `1px solid var(--color-green-2)`,
              },
            },
          }}
        >
          <TextField
            onChange={handleSearchUser}
            autoComplete="off"
            type="text"
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <KeyboardBackspaceIcon onClick={() => dispatch(setGroupNavigate("group_chat_list"))} className="cursor-pointer" />
                </InputAdornment>
              ),
              sx: {
                "&::placeholder": {
                  color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                  opacity: 1,
                  fontSize: "12px",
                },
              },
            }}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} className="chat-list-scrollbar" sx={{ height: "300px", width: "100%", overflowY: "auto" }}>
        {loading ? (
          <Grid container justifyContent="center" alignItems="center" height="100%">
            <CircularProgress sx={{ color: "var(--color-white-1)" }} />
          </Grid>
        ) : (
          <List sx={{ width: "100%" }}>
            {searchList?.length ? (
              searchList?.map((item) => (
                <Fragment key={item.id}>
                  <ListItem
                    className="cursor-pointer"
                    sx={{
                      "&:hover": {
                        backgroundColor: "var(--border-color)",
                      },
                    }}
                    secondaryAction={
                      sentReq.includes(item.id) ? <Chip size="small" label="joined" /> : <Chip size="small" label="Join" onClick={(e) => joinGroupHandler(e, item.id)} />
                    }
                  >
                    <ListItemAvatar>
                      <Avatar alt="chat list avatar" src={item?.icon}></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{
                        "& .MuiListItemText-secondary": {
                          width: "175px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        },
                      }}
                      primary={item.name}
                      secondary={item.description}
                    />
                  </ListItem>
                  <Divider variant="middle" component="li" sx={{ marginLeft: 0, marginRight: 0 }} />
                </Fragment>
              ))
            ) : searchText ? (
              <Box className="flex justify-center items-center flex-col h-full">
                <CloudOutlined sx={{ fontSize: "40px" }} />
                <Typography
                  className="text-center !mt-1"
                  sx={{
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-green-3)",
                  }}
                >
                  No data found.
                </Typography>
              </Box>
            ) : (
              <Box className="flex justify-center items-center flex-col h-full">
                <Typography
                  className="text-center !mt-1"
                  sx={{
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-green-3)",
                  }}
                >
                  Please enter a minimum of three words before searching.
                </Typography>
              </Box>
            )}
          </List>
        )}
      </Grid>
    </Grid>
  );
};

export default SearchGroup;
