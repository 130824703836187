import { Box, Button, CircularProgress, FormControl, Grid, Pagination, TextField } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import ComposeTicket from "./ComposeTicket";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { setChatTicket, setComposeTicket, setSingleTicket, setSingleTicketLoading, setTicket, setTicketLoading } from "redux/features/supportTicket/supportTicketSlice";
import ChatTicket from "./ChatTicket";
import { get_single_ticket, get_ticket_query } from "server/support_ticket";

const CloseTicket = memo(() => {
  const ticket = useSelector((state) => state.support.ticket);
  const ticketLoading = useSelector((state) => state.support.ticketLoading);

  const dispatch = useDispatch();
  const composeTicket = useSelector((state) => state.support.composeTicket);
  const chatTicket = useSelector((state) => state.support.chatTicket);
  const [selectedTicketId, setSelectedTicketId] = useState(null);

  const handleChatTicket = async (id) => {
    setSelectedTicketId(id);
    if (composeTicket) {
      dispatch(setComposeTicket(false));
    } else {
      dispatch(setChatTicket(true));
      dispatch(setSingleTicketLoading(true));
      const result = await get_single_ticket(id);
      if (result?.data?.status) {
        dispatch(setSingleTicket(result.data.data));
        dispatch(setSingleTicketLoading(false));
      } else {
        dispatch(setSingleTicketLoading(false));
      }
    }
  };

  const getTicketQuery = async () => {
    dispatch(setTicketLoading(true));
    const result = await get_ticket_query("closed");
    if (result.data.status) {
      dispatch(setTicket(result.data.data));
      dispatch(setTicketLoading(false));
    } else {
      dispatch(setTicketLoading(false));
    }
  };

  useEffect(() => {
    getTicketQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container columnSpacing={2} className="mt-5">
      <Grid item xs={12} sm={12} md={6} lg={4} sx={{ marginBottom: { xs: "10px" } }}>
        <Box className="bg-[#0F3529] rounded-md text-[var(--color-white-1)] p-3 space-y-6">
          <Box className="flex justify-between items-center">
            <Box className="font-bold">My Tickets</Box>
            <Button
              onClick={() => (dispatch(setComposeTicket(true)) ? dispatch(setChatTicket(false)) : "")}
              sx={{
                background: "var(--color-green-1)",
                textTransform: "capitalize",
                color: "var(--color-white-1)",
                "&:hover": {
                  color: "var(--color-white-1)",
                  background: "var(--color-green-2)",
                },
              }}
              variant="contained"
              startIcon={<DraftsOutlinedIcon />}
            >
              Open Ticket
            </Button>
          </Box>
          <FormControl className="!w-full !pb-4">
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  width: "100%",
                  color: "white",
                  "& fieldset": {
                    border: "1px solid var(--color-gray-1)",
                  },
                  "&:hover fieldset": {
                    border: "1px solid var(--color-green-1)",
                  },
                  "&.Mui-focused fieldset": {
                    border: `1px solid var(--color-green-2)`,
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                  "&.Mui-focused": {
                    color: "var(--color-green-2)",
                  },
                },
                "& .MuiInputBase-root": {
                  "& .MuiOutlinedInput-input": {
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    "&::-webkit-scrollbar": {
                      width: "5px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "none",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "8px",
                      backgroundColor: "var(--color-green-7)",
                    },
                  },
                },
              }}
              label="Search Tickets"
              variant="outlined"
              size="small"
            />
          </FormControl>
          <Box className="h-[450px] overflow-y-auto chatTicketScroll">
            {ticketLoading ? (
              <Box className="w-full flex justify-center items-center">
                <CircularProgress color="success" size={50} />
              </Box>
            ) : (
              ticket?.map((item) => (
                <Box
                  key={item.id}
                  className={`hover:bg-[#06241A]  ${item.id === selectedTicketId ? "bg-[var(--color-green-1)]" : ""} px-3 py-3 rounded-md ${
                    item.id === selectedTicketId ? "no-underline" : "underline"
                  } hover:no-underline cursor-pointer`}
                  onClick={() => handleChatTicket(item?.id)}
                >
                  <Box className="flex items-center">
                    <Box className={`h-3 w-3 rounded-full mr-2 bg-lime-500`}></Box>
                    <Box>{item?.subject}</Box>
                  </Box>
                  <Box
                    className="ml-5 text-sm"
                    sx={{
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                    }}
                  >
                    {item?.description}...
                  </Box>
                </Box>
              ))
            )}
          </Box>

          <Box className="flex justify-between items-center">
            <Box className="text-[13px] font-medium">
              Total <span className="font-bold">{ticket?.length}</span> Records Found
            </Box>
            <Pagination
              count={1}
              size="small"
              sx={{
                "& .MuiPaginationItem-root": {
                  background: "#EEEEEE",
                  color: "var(--color-black-1)",
                },
                "& .MuiPaginationItem-root.Mui-selected": {
                  backgroundColor: "var(--color-green-1)",
                },
                "& .MuiPaginationItem-icon": {
                  backgroundColor: "#787878",
                  fontSize: "24px",
                  borderRadius: "50%",
                  color: "var(--color-white-1)",
                },
              }}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={8}>
        <Box className="bg-[#0F3529] rounded-md text-[var(--color-white-1)]">
          {composeTicket && (
            <Fade>
              <ComposeTicket />
            </Fade>
          )}
          {chatTicket && <ChatTicket />}
        </Box>
      </Grid>
    </Grid>
  );
});

export default CloseTicket;
