import { Box, Grid, } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";

const AddressVerificationMessage = memo(() => {
  const addressVerificationStatus = useSelector((state) => state.kyc.addressVerificationStatus);
  return (
    <Box className="p-2">
      <Box className="p-5 dark:bg-[#182d27] bg-[var(--color-green-5)] rounded-md text-[var(--color-white-1)] text-center space-y-1">
        {addressVerificationStatus?.doc_type && (
          <Grid container>
            <Grid item xs={4} className="text-right text-base">
              Uploaded document
            </Grid>
            <Grid item xs={4}>
              :
            </Grid>
            <Grid item xs={4} className="font-bold text-green-600 capitalize text-base text-left">
              {addressVerificationStatus?.doc_type}
            </Grid>
          </Grid>
        )}
        {addressVerificationStatus?.id_number && (
          <Grid container>
            <Grid item xs={4} className="text-right text-base">
              Id number
            </Grid>
            <Grid item xs={4}>
              :
            </Grid>
            <Grid item xs={4} className="font-bold text-green-600 capitalize text-base text-left">
              {addressVerificationStatus?.id_number}
            </Grid>
          </Grid>
        )}
        {addressVerificationStatus?.issue_date && (
          <Grid container>
            <Grid item xs={4} className="text-right text-base">
              Issue date
            </Grid>
            <Grid item xs={4}>
              :
            </Grid>
            <Grid item xs={4} className="font-bold text-green-600 capitalize text-base text-left">
              {addressVerificationStatus?.issue_date}
            </Grid>
          </Grid>
        )}
        {addressVerificationStatus?.expire_date && (
          <Grid container>
            <Grid item xs={4} className="text-right text-base">
              Expire date
            </Grid>
            <Grid item xs={4}>
              :
            </Grid>
            <Grid item xs={4} className="font-bold text-green-600 capitalize text-base text-left">
              {addressVerificationStatus?.expire_date}
            </Grid>
          </Grid>
        )}
        {addressVerificationStatus?.status && (
          <Grid container>
            <Grid item xs={4} className="text-right text-base">
              KYC status
            </Grid>
            <Grid item xs={4}>
              :
            </Grid>
            <Grid item xs={4} className="font-bold text-green-600 capitalize text-base text-left">
              {addressVerificationStatus?.status}
            </Grid>
          </Grid>
        )}

        <Grid container>
          <Grid item xs={4} className="text-right ">
            Approve date
          </Grid>
          <Grid item xs={4}>
            :
          </Grid>
          <Grid item xs={4} className="font-bold text-green-600 capitalize text-base text-left">
            {addressVerificationStatus?.approved_date ? addressVerificationStatus?.approved_date : "...."}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

export default AddressVerificationMessage;
