import React, { memo, useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const IbDashboardDailyChart = () => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const DATAL = ["Category A", "Category B", "Category C", "Category D"];
    const data = [30, 20, 25, 15];
    const dataColor = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"];
    

    // Adjust the cutout value to control the inner circle width
    const cutoutValue = 90;

    // Create a new Chart instance
    const newChartInstance = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: DATAL,
        datasets: [
          {
            weight: 9,
            cutout: cutoutValue,
            tension: 0.9,
            pointRadius: 2,
            borderWidth: 0,
            backgroundColor: dataColor,
            data: data,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        plugins: {
          // legend: {
          //   position: "top",
          //   align: "center",
          //   labels: {
          //     usePointStyle: true,
          //   },
          // },
          legend: {
            display: false, // Hide legend
          },
        },
        layout: {
          padding: {
            left: 0, // Adjusted to center the chart
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
        // interaction: {
        //   intersect: false,
        //   mode: "index",
        // },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
            type: 'category', // Use 'category' scale for x-axis
          },
        },
      },
    });

    // Save the new chart instance to the ref
    chartInstanceRef.current = newChartInstance;
  }, []);

  return <canvas ref={chartRef} style={{ width: "100%", height: "300px" }} />;
};

export default memo(IbDashboardDailyChart);
