import { createSlice } from "@reduxjs/toolkit";

const calendar = createSlice({
    name: "calendar",
    initialState: {
        calendar: {},
    },
    reducers: {
        setCalendar: (state, action) => {
            state.calendar = action.payload;
        },
        setComments: (state, action) => {
            state.comments = action.payload;
        },
    },
});
export const { setCalendar, setComments} = calendar.actions;
export default calendar.reducer;
