import { Logout } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import FitbitIcon from "@mui/icons-material/Fitbit";
import InventoryIcon from "@mui/icons-material/Inventory";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { ListItemIcon, Menu, MenuItem, useTheme } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setOpenChangePasswordModal, setUpdateProfileModalOpen } from "redux/features/application/applicationSlice";
import { logIn, setChangePasswordEmail, signOut } from "redux/features/auth/authSlice";
import { signOutChat } from "redux/features/chatSlice/chatSlice";
import { setChangePasswordNavigate } from "redux/features/navigateSlice/navigateSlice";
import { logOut, refetchAuth } from "server/auth";
import { changePasswordRequest } from "server/change_password";
import { pamRequest } from "server/pam_request";

const ManageAccount = memo(({ anchorElManageAccount, manageAccountOpen, handleCloseManageAccount }) => {
  const permission = useSelector((state) => state.permission.permission);
  const user = useSelector((state) => state.auth.user);
  const theme = useTheme();
  const dispatch = useDispatch();
  const handleUpdateProfile = () => {
    dispatch(setUpdateProfileModalOpen(true));
    handleCloseManageAccount();
  };

  const handleChangePassModal = async () => {
    const result = await changePasswordRequest();
    if (result?.data?.status) {
      enqueueSnackbar(result.data.message, { variant: "success" });
      dispatch(setChangePasswordEmail(result.data.data.email));
      dispatch(setChangePasswordNavigate("verify_code"));
      dispatch(setOpenChangePasswordModal(true));
    } else {
      enqueueSnackbar(result.data.message, { variant: "warning" });
    }
    handleCloseManageAccount();
  };

  const logoutHandler = async () => {
    await logOut();
    dispatch(signOut());
    dispatch(signOutChat());
    handleCloseManageAccount();
    // window.location.reload(true);
  };

  const handlePumRequest = async () => {
    if (user?.affiliat_status?.status === "pending" || user?.affiliat_status?.status === "approved") {
      if (user?.affiliat_status?.status === "pending") {
        enqueueSnackbar("Master request already submitted.Your request is pending.", { variant: "warning" });
      }
      if (user?.affiliat_status?.status === "approved") {
        enqueueSnackbar("Master request already submitted.Your request is approved.", { variant: "success" });
      }
      return;
    }
    const result = await pamRequest();
    if (result?.data?.status) {
      handleCloseManageAccount();
      enqueueSnackbar(result.data.message, { variant: "success" });
      const resultRefetch = await refetchAuth();
      if (resultRefetch?.data?.status) {
        localStorage.setItem("auth-token", resultRefetch?.data?.token);
        dispatch(logIn({ ...resultRefetch?.data?.data }));
      }
    }
  };

  return (
    <Menu
      anchorEl={anchorElManageAccount}
      open={manageAccountOpen}
      onClose={handleCloseManageAccount}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        marginTop: "12px",
        marginLeft: "5px",
        "& .MuiPaper-root": {
          background: theme.palette.mode === "dark" ? "var(--color-green-7)" : "var(--color-green-10)",
        },
      }}
    >
      {permission?.includes("profile update") && (
        <MenuItem onClick={handleUpdateProfile}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="medium" />
          </ListItemIcon>
          Profile Update
        </MenuItem>
      )}
      <Link className="dark:text-[var(--color-white-1)] text-black no-underline" to={"/kyc"}>
        <MenuItem>
          <ListItemIcon>
            <FitbitIcon fontSize="medium" />
          </ListItemIcon>
          KYC
        </MenuItem>
      </Link>
      {permission?.includes("change password") && (
        <MenuItem onClick={handleChangePassModal}>
          <ListItemIcon>
            <ChangeCircleOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          Change Password
        </MenuItem>
      )}
      {permission?.includes("join as master") && (
        <MenuItem onClick={handlePumRequest}>
          <ListItemIcon>
            <CompareArrowsIcon fontSize="medium" />
          </ListItemIcon>
          {user?.affiliat_status === null
            ? "Request for Master"
            : user?.affiliat_status?.status === "pending"
            ? "Master (pending)"
            : user?.affiliat_status?.status === "declined"
            ? "Request for Master"
            : "Master (approved)"}
        </MenuItem>
      )}
      <Link className="dark:text-[var(--color-white-1)] text-black no-underline" to={"/support-ticket"}>
        <MenuItem className="capitalize">
          <ListItemIcon>
            <SupportAgentIcon fontSize="medium" />
          </ListItemIcon>
          Support Ticket
        </MenuItem>
      </Link>
      <Link className="dark:text-[var(--color-white-1)] text-black no-underline" to={"/packages"}>
        <MenuItem className="capitalize">
          <ListItemIcon>
            <InventoryIcon fontSize="medium" />
          </ListItemIcon>
          Buy Subscription
        </MenuItem>
      </Link>
      <MenuItem onClick={logoutHandler}>
        <ListItemIcon>
          <Logout fontSize="medium" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );
});

export default ManageAccount;
