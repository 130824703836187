import { Avatar, Box, Button, FormControl, Grid, Pagination, Tab, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
// import playStore from "../../assets/icons/playStore.png";
// import appleStore from "../../assets/icons/apple.png";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import AccountDetailsBottomPamGrowthCurve from "components/AccountDetails/AccountDetailsBottomPamGrowthCurve";
import CloseTrades from "components/AccountDetails/CloseTrades";
import FilterTrades from "components/AccountDetails/FilterTrades";
import OpenTrades from "components/AccountDetails/OpenTrades";
import MultipleAccountDropdown from "components/Dashboard/MultipleAccountDropdown";
import MySlaves from "components/Dashboard/MySlaves";
import OpenAndCloseTradeLoader from "components/SkeletonLoader/OpenAndCloseTradeLoader";
import Layout from "layouts/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setOpenAddAccount } from "redux/features/application/applicationSlice";
import { logIn } from "redux/features/auth/authSlice";
import { setCloseTradeLoading, setOpenTradeLoading, setSlaveLoadingLoading } from "redux/features/loader/loaderSlice";
import { setCloseTradePage, setOpenTradePage } from "redux/features/pagination/paginationSlice";
import { setMySlaves } from "redux/features/pam/pamSlice";
import { setCloseTrade, setOpenTrade } from "redux/features/trade/tradeSlice";
import { switchAccount } from "server/auth";
import { tradeClose } from "server/close_trade";
import { getTradeOpen } from "server/open_trade";
import { getMySlaves } from "server/pamm";
import mainBgImg from "../../assets/img/bg-dark-1.png";
import brokerImg from "../../assets/img/broker.png";
import TradingGrowth from "../../components/Dashboard/TradingGrowth";

const PammDashboard = () => {
  const slaves = useSelector((state) => state.pam.slaves);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const permission = useSelector((state) => state.permission.permission);
  const user = useSelector((state) => state.auth.user);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [TableTab, setTableTab] = React.useState("1");
  const openTrade = useSelector((state) => state.trade.openTrade);
  const openTradePage = useSelector((state) => state.pagination.openTradePage);
  const closeTradePage = useSelector((state) => state.pagination.closeTradePage);
  const closeTrade = useSelector((state) => state.trade.closeTrade);
  const [filterTrades, setFilterTrades] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [slavePage, setSlavePage] = useState(1);
  const openTradeLoading = useSelector((state) => state.loader.openTradeLoading);
  const closeTradeLoading = useSelector((state) => state.loader.closeTradeLoading);
  const slaveLoading = useSelector((state) => state.loader.slaveLoading);
  const [anchorElMultipleAccount, setAnchorElMultipleAccount] = React.useState(null);
  const [selectedAccount, setSelectedAccount] = React.useState(user?.account_number || "Select account");
  const multipleAccountOpen = Boolean(anchorElMultipleAccount);
  const accounts = useSelector((state) => state.auth.accounts);

  const handleOpenAccountModal = () => {
    dispatch(setOpenAddAccount(true));
  };

  const handleTableTab = (_, newValue) => {
    setTableTab(newValue);
    dispatch(setOpenTradePage(1));
    dispatch(setCloseTradePage(1));
    setSearchText("");
    setFilterTrades(10);
  };
  const handleChangeCloseTradePage = async (_, value) => {
    dispatch(setCloseTradeLoading(true));
    const result = await tradeClose(user?.id, closeTradePage, filterTrades, searchText);
    if (result?.status === 200) {
      dispatch(setCloseTrade(result.data));
      dispatch(setCloseTradeLoading(false));
    } else {
      dispatch(setCloseTradeLoading(false));
    }
    dispatch(setCloseTradePage(value));
  };

  const handleChangeOpenTradePage = async (_, value) => {
    dispatch(setOpenTradeLoading(true));
    const result = await getTradeOpen(user?.id, openTradePage, filterTrades, searchText);
    if (result?.status === 200) {
      dispatch(setOpenTrade(result.data));
      dispatch(setOpenTradeLoading(false));
    } else {
      dispatch(setOpenTradeLoading(false));
    }
    dispatch(setOpenTradePage(value));
  };

  const getOpenTrade = async () => {
    dispatch(setOpenTradeLoading(true));
    const result = await getTradeOpen(user?.id, openTradePage, filterTrades, searchText);
    if (result?.status === 200) {
      dispatch(setOpenTrade(result.data));
      dispatch(setOpenTradeLoading(false));
    } else {
      dispatch(setOpenTradeLoading(false));
    }
  };

  const getCloseTrade = async () => {
    dispatch(setCloseTradeLoading(true));
    const result = await tradeClose(user?.id, closeTradePage, filterTrades, searchText);
    if (result?.status === 200) {
      dispatch(setCloseTrade(result.data));
      dispatch(setCloseTradeLoading(false));
    } else {
      dispatch(setCloseTradeLoading(false));
    }
  };

  const getPammSlaves = async () => {
    dispatch(setSlaveLoadingLoading(true));
    const result = await getMySlaves(slavePage);
    if (result?.data?.status) {
      dispatch(setMySlaves(result?.data?.data));
      dispatch(setSlaveLoadingLoading(false));
    } else {
      dispatch(setSlaveLoadingLoading(false));
    }
  };

  const handleChangeSlavesPage = async (_, value) => {
    dispatch(setSlaveLoadingLoading(true));
    const result = await getMySlaves(value);
    if (result?.data?.status) {
      dispatch(setMySlaves(result.data.data));
      dispatch(setSlaveLoadingLoading(false));
    } else {
      dispatch(setSlaveLoadingLoading(false));
    }
    setSlavePage(value);
  };

  useEffect(() => {
    getOpenTrade();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, filterTrades, openTradePage]);

  useEffect(() => {
    getCloseTrade();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, filterTrades, closeTradePage]);

  useEffect(() => {
    getPammSlaves();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slavePage]);

  useEffect(() => {
    if (user?.role !== "pamm") {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.role]);

  const handleClickMultipleAccount = (event) => {
    setAnchorElMultipleAccount(event.currentTarget);
  };
  const handleCloseMultipleAccount = () => {
    setAnchorElMultipleAccount(null);
  };
  const handleSelectedAccount = async (account) => {
    if (account?.account_number === selectedAccount) return;
    setSelectedAccount(account?.account_number);
    const result = await switchAccount(account?.id);
    if (result?.data?.status) {
      localStorage.setItem("auth-token", result?.data?.token);
      localStorage.setItem("refresh-token", result?.data?.refresh_token);
      dispatch(logIn({ ...result?.data?.data }));
      window.location.reload(true);
    }
  };

  return (
    <Layout>
      <Box
        sx={{
          backgroundImage: { xs: "none", sm: "none", md: theme.palette.mode === "dark" ? `url(${mainBgImg})` : "" },
          backgroundSize: "auto 65%",
          backgroundPosition: "top right",
          backgroundRepeat: "no-repeat",
          backgroundOrigin: "content-box",
          backgroundAttachment: "fixed",
        }}
      >
        <Box className="h-full p-5">
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} lg={9}>
                <Grid container rowSpacing={4} columnSpacing={{ xs: 0, sm: 4 }} className="dashBoradLeftSide">
                  <Grid item xs={12} className="dashChatTitle">
                    <Box
                      sx={{
                        paddingBottom: "5px",
                      }}
                      className="performance_chat"
                    >
                      <Box>
                        <Typography className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !font-[Poppins] !font-bold !text-[16px] !leading-normal">
                          Trading Growth Curve
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} className="dashChatMain">
                    <TradingGrowth />
                  </Grid>
                  <Grid item xs={12}>
                    <AccountDetailsBottomPamGrowthCurve />
                  </Grid>

                  <Grid item xs={12} className="!mt-10">
                    <TabContext value={TableTab}>
                      <Box
                        sx={{
                          display: { xs: "inline-flex", sm: "flex", md: "flex" },
                          flexWrap: { xs: "wrap" },
                          justifyContent: { sm: "space-between", md: "space-between" },
                          gap: 2,
                        }}
                      >
                        <TabList
                          sx={{
                            border: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}`,
                            backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-3)" : "var(--color-green-10)",
                            borderRadius: "10px 10px 0px 0px",
                            "& .MuiTabs-indicator": {
                              backgroundColor: "transparent",
                            },
                            "& .MuiTab-root": {
                              color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var!(--color-white-2)",
                              "&.Mui-selected": {
                                backgroundColor: "var(--color-green-2)",
                              },
                            },
                          }}
                          className="w-fit !h-[30px]"
                          onChange={handleTableTab}
                          aria-label="lab API tabs example"
                        >
                          <Tab
                            onClick={() => getOpenTrade()}
                            sx={{
                              borderRight: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}`,
                            }}
                            className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !font-medium !normal-case"
                            label="Open Trades"
                            value="1"
                          />
                          <Tab
                            onClick={() => getCloseTrade()}
                            sx={{
                              borderRight: `1px solid ${theme.palette.mode === "dark" ? "rgba(65, 255, 200, 0.31)" : "rgba(0, 208, 148, 0.30)"}`,
                            }}
                            className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !font-medium !normal-case"
                            label="Closed Trades"
                            value="2"
                          />
                          <Tab
                            onClick={() => getPammSlaves()}
                            className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !font-medium !normal-case"
                            label="My Slaves"
                            value="3"
                          />
                        </TabList>
                        {TableTab !== "3" && (
                          <Box className="flex items-center space-x-2" sx={{ paddingBottom: "10px", paddingTop: "10px" }}>
                            <Box>
                              <FormControl
                                id="street"
                                fullWidth
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    height: "30px",
                                    width: "120px",
                                    color: theme.palette.mode === "dark" ? "var(--color-gray-3)" : "var(--color-black-2)",
                                    backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-3)" : "var(--color-green-10)",
                                    padding: 1,
                                    boxSizing: "border-box",
                                    borderRadius: "6px",
                                    "& fieldset": {
                                      border: "1px solid var(--color-green-7)",
                                    },
                                    "&:hover fieldset": {
                                      border: "1px solid var(--color-green-1)",
                                    },
                                    "&.Mui-focused fieldset": {
                                      border: `1px solid var(--color-green-2)`,
                                    },
                                    "& .MuiInputBase-input": {
                                      padding: "3px",
                                      fontSize: "12px",
                                    },
                                  },
                                }}
                              >
                                <TextField variant="outlined" value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Search" />
                              </FormControl>
                            </Box>
                            <FilterTrades {...{ filterTrades, setFilterTrades }} />
                            <Box
                              className="dark:text-[var(--color-gray-3)] text-[var(--color-black-2)] font-[Open Sans] text-[12px] font-normal pr-1"
                              sx={{ display: { xs: "none", sm: "none", md: "block" } }}
                            >
                              records per page
                            </Box>
                          </Box>
                        )}
                      </Box>
                      <TabPanel sx={{ paddingLeft: "16px", paddingRight: "0px" }} value="1">
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            borderRadius: "10px",
                          }}
                        >
                          {openTradeLoading ? <OpenAndCloseTradeLoader /> : <OpenTrades />}
                          {openTrade?.data?.length > 0 && (
                            <Grid item xs={12} className="flex justify-center items-center !pt-5">
                              <Pagination
                                count={openTrade?.pagination?.last_page}
                                variant="outlined"
                                shape="rounded"
                                page={openTradePage}
                                onChange={handleChangeOpenTradePage}
                                size={isSmallScreen ? "small" : "medium"}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </TabPanel>
                      <TabPanel sx={{ paddingLeft: "16px", paddingRight: "0px" }} value="2">
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            borderRadius: "10px",
                          }}
                        >
                          {closeTradeLoading ? <OpenAndCloseTradeLoader /> : <CloseTrades />}
                          {closeTrade?.data?.length > 0 && (
                            <Grid item xs={12} className="flex justify-center items-center">
                              <Pagination
                                count={closeTrade?.pagination?.last_page}
                                variant="outlined"
                                shape="rounded"
                                page={closeTradePage}
                                onChange={handleChangeCloseTradePage}
                                size={isSmallScreen ? "small" : "medium"}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </TabPanel>
                      <TabPanel sx={{ paddingLeft: "16px", paddingRight: "0px" }} value="3">
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            borderRadius: "10px",
                          }}
                        >
                          {slaveLoading ? <OpenAndCloseTradeLoader /> : <MySlaves />}
                          {slaves?.data?.length > 0 && (
                            <Grid item xs={12} className="flex justify-center items-center !pt-5">
                              <Pagination
                                count={slaves?.pagination?.last_page}
                                variant="outlined"
                                shape="rounded"
                                page={slavePage}
                                onChange={handleChangeSlavesPage}
                                size={isSmallScreen ? "small" : "medium"}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </TabPanel>
                    </TabContext>
                  </Grid>
                </Grid>
              </Grid>
              {/* Right side bar start */}
              <Grid item xs={12} md={4} lg={3}>
                <Grid container rowSpacing={4} columnSpacing={{ sm: 2 }} className="dashBoradRightSide">
                  <Grid item xs={12} sm={4} md={12} order={{ xs: 3, sm: 1 }}>
                    <Box
                      sx={{ filter: "lg:drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))", boxShadow: "0px 16.692px 38.949px 0px rgba(7, 71, 53, 0.10)" }}
                      className="dark:bg-[var(--color-black-9)] md:dark:bg-[var(--color-black-10)] bg-[#0F3529] rounded-[10px] flex flex-col justify-center items-center py-[50px] px-[10px] backdrop-blur-[6px] "
                    >
                      <Typography className="profileTitle !pb-[10px] !text-[22px]  !text-[var(--color-white-1)]">My Account</Typography>
                      {accounts?.length > 0 && (
                        <Button
                          onClick={handleClickMultipleAccount}
                          endIcon={multipleAccountOpen ? <ExpandLess sx={{ color: "var(--color-green-2)" }} /> : <ExpandMore sx={{ color: "var(--color-green-2)" }} />}
                          size="small"
                          className="!mb-[14px]"
                          sx={{
                            background: "var(--color-green-15)",
                            textTransform: "capitalize",
                            boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
                            color: "var(--color-white-1)",
                            fontFamily: "Poppins",
                            fontSize: "15px",
                            borderRadius: "5px",
                            minWidth: "100px !important",
                            minHeight: "40px !important",

                            "&:hover": {
                              background: "var(--color-green-15)",
                            },
                          }}
                        >
                          {selectedAccount}
                        </Button>
                      )}

                      <Box>
                        <Avatar sx={{ width: "100px", height: "95px" }} variant="rounded" src={user?.photo} alt="Avatar" />
                      </Box>
                      <Typography
                        sx={{ marginTop: "20px" }}
                        className="profileDescription !font-[Poppins] !text-[12px] dark:!text-[var(--color-gray-1)] !text-[var(--color-white-1)] !text-center"
                      >
                        Distributions of sales across platform
                      </Typography>
                      {permission?.includes("add account") && (
                        <Typography component={"button"} className={`profileBtn !capitalize cursor-pointer`} onClick={handleOpenAccountModal}>
                          Add Account
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} sm={4} md={12} order={{ xs: 1, sm: 2 }} sx={{ display: "flex", flexDirection: { xs: "column", sm: "column-reverse" } }}>
                    <Box className="flex items-center justify-center text-center p-[20px] dark:bg-[var(--color-black-9)] md:dark:bg-[var(--color-black-10)] bg-[var(--color-green-1)] rounded-[10px] backdrop-blur-[6px]">
                      <Typography className="text-[var(--color-white-1)] !text-[20px] !font-[poppins] title availabeText">Available On</Typography>
                      <Typography component={"img"} src={playStore} className="downloadBtn ps-[10px] playBtn"></Typography>
                      <Typography component={"img"} src={appleStore} className="downloadBtn ps-[10px] appleBtn"></Typography>
                    </Box>
                  </Grid> */}
                  <Grid item xs={12} sm={4} md={12} order={{ xs: 2, sm: 3 }}>
                    <Box
                      className="dark:bg-[var(--color-black-9)] md:dark:bg-[var(--color-black-10)] bg-[#0F3529] rounded-[10px] text-[var(--color-white-1)] py-[50px] px-[10px] text-center"
                      sx={{
                        boxShadow: "0px 16.692px 38.949px 0px rgba(7, 71, 53, 0.10)",
                        display: "flex",
                        flexFlow: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="!text-[24px] uppercase font-[poppins] !font-bold brokerTitle">Join Broker Account</Typography>
                      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="brokerImage">
                        <Typography component={"img"} src={brokerImg} className="w-full h-auto"></Typography>
                      </Box>
                      <Typography
                        onClick={() => navigate("/broker-portal")}
                        component={"button"}
                        sx={{
                          color: "var(--color-white-1)",
                          fontSize: "16px",
                          fontFamily: "Poppins",
                          padding: "10px 30px",
                          marginTop: "20px",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                        className="profileBtn"
                      >
                        Click Here
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none" } }} className="flex">
                <Typography className="dark:!text-[var(--color-white-1)] !text-[var( --color-black-5)] !font-[Anek Telugu] !text-[15px] !font-normal text-center">
                  © 2024 All Rights
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                <Typography className="dark:!text-[var(--color-white-1)] !text-[var( --color-black-5)] !font-[Anek Telugu] !text-[15px] !font-normal text-left">
                  © 2024 All Rights
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <MultipleAccountDropdown {...{ anchorElMultipleAccount, multipleAccountOpen, handleCloseMultipleAccount, handleSelectedAccount }} />
    </Layout>
  );
};

export default memo(PammDashboard);
