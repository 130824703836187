import { Chart, registerables } from "chart.js/auto";
import React, { memo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
const HourBarChart = () => {
  const chartRef = useRef(null);
  const hourChart = useSelector((state) => state.chart.hourChart);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      
      Chart.register(...registerables);

      const chartInstance = new Chart(ctx, {
        type: "bar",
        data: {
          labels: hourChart.lineChart?.label,
          datasets: [
            {
              label: "",
              backgroundColor: "#00D094",
              borderColor: "#27D195",
              data: hourChart.lineChart?.data,
              barThickness: 14,
            },
          ],
        },
        options: {
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                // text: "Hour",
              },
              grid: {
                display: false,
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                // text: "Data",
              },
              grid: {
                display: false,
              },
              ticks: {
                min: 0,
                max: 3,
                stepSize: 1,
              },
            },
          },
          elements: {
            bar: {
              borderWidth: 1,
              borderRadius: 14,
            },
          },
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 10,
              bottom: 10,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          chartArea: {
            backgroundColor: "transparent",
          },
          responsive: true,
          maintainAspectRatio: false,
          maxHeight: "100vh",
        },
      });

      return () => {
        chartInstance.destroy();
      };
    }
  }, [chartRef, hourChart.lineChart?.data, hourChart.lineChart?.label]);

  return <canvas ref={chartRef} style={{ height: "350px", width: "100%" }} />;
};

export default memo(HourBarChart);
