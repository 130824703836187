import mirrorApi from '../api/mirror_trade_api';
import { getAuthToken } from './auth';

export const changePasswordRequest = async () => {
    try {
        return await mirrorApi.post(`/password/change/email`, {}, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
    console.log("🚀 ~ changePasswordRequest ~ error:", error)
    }
}
export const checkOtpCode = async (code) => {
    try {
        return await mirrorApi.post(`/password/change/check-code`, code, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
    console.log("🚀 ~ checkOtpCode ~ error:", error)
    }
}

export const changePassword = async (data) => {
    try {
        return await mirrorApi.post(`/password/change`, data, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
    console.log("🚀 ~ changePassword ~ error:", error)
    }
}