import countries from "data/countryList";
import { useDispatch, useSelector } from "react-redux";
import React, { memo, useState } from "react";
import { useSnackbar } from "notistack";
import { Autocomplete, Box, Button, CircularProgress, FormControl, Grid, IconButton, Modal, TextField, Typography, useTheme } from "@mui/material";
import preAvatar from "../../assets/pre avatar/pre-avatar.jpg";
import { updateProfileChat, upload_image } from "server/chat";
import { setLoading } from "redux/features/application/applicationSlice";
import { updateProfile } from "server/update_profile";
import { refetchAuth } from "server/auth";
import { logIn } from "redux/features/auth/authSlice";
import { signInChat } from "redux/features/chatSlice/chatSlice";
import { HighlightOff } from "@mui/icons-material";
import ReactFileReader from "react-file-reader";

const profilePic = {
  width: "100px",
  aspectRatio: 1,
  clipPath: "circle(50% at 50% 50%)",
};
const ProfileUpdateIBDashboard = ({ setIBUpdateProfileModalOpen, updateIBProfileModalOpen }) => {
  const user = useSelector((state) => state.auth.user);
  const defaultCountry = user.country ? countries.find((country) => country?.label === user?.country) : null;
  const [selectedCountry, setSelectedCountry] = React.useState(defaultCountry || {});

  const { enqueueSnackbar } = useSnackbar();
  const loading = useSelector((state) => state.application.loading);
  const chat = useSelector((state) => state.chat);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [location, setLocation] = React.useState(user?.country || "");
  const [locationError, setLocationError] = useState("");
  const [name, setName] = React.useState(user?.name || "");
  const [phone, setPhone] = React.useState(user?.phone || "");
  const [url, setUrl] = useState(user?.photo ? user?.photo : preAvatar || "");
  const [image, setImage] = useState(null);

  const handleFiles = (files) => {
    const maxFileSizeMB = 2;

    if (!files?.fileList[0]) {
      return;
    }

    const fileSizeMB = files.fileList[0].size / (1024 * 1024);

    if (fileSizeMB > maxFileSizeMB) {
      enqueueSnackbar(`File size exceeds the maximum limit of ${maxFileSizeMB} MB`, {
        variant: "error",
      });
      return;
    }

    if (files.fileList[0].type !== "image/jpeg" && files.fileList[0].type !== "image/png") {
      enqueueSnackbar("Only jpg, jpeg, png is allowed", {
        variant: "error",
      });
      return;
    }

    setImage(files.fileList[0]);
    setUrl(files.base64);
  };

  // Handler for changing the selected country
  const handleCountryChange = (event, newValue) => {
    setLocation(newValue?.label);
    setSelectedCountry(newValue);
  };

  const handleServerUpload = async (formData) => {
    const upload = await upload_image(formData);
    if (!upload) {
      return await handleServerUpload(formData);
    }
    return upload;
  };

  const updateProfileHandler = async (e) => {
    e.preventDefault();
    setLocationError("");
    dispatch(setLoading(true));

    const profile = {
      name: name,
      photo: image,
      country: location,
      phone: phone,
    };

    const result = await updateProfile(profile);

    if (result && result?.data?.status) {
      dispatch(setLoading(false));
      setIBUpdateProfileModalOpen(false)
      enqueueSnackbar(result?.data?.message, { variant: "success" });
      const resultRefetch = await refetchAuth();
      if (resultRefetch?.data?.status) {
        localStorage.setItem("auth-token", resultRefetch?.data?.token);
        dispatch(logIn({ ...resultRefetch?.data?.data }));
      }

      let madeImgLink = null;
      const chatImageUpdate = {
        file: image,
        attachment_for: "profile",
        for_id: chat?.user?.id,
      };
      const resultChatImage = await handleServerUpload(chatImageUpdate);
      if (resultChatImage && resultChatImage?.data?.status) {
        madeImgLink = process.env.REACT_APP_CHAT_API_BASEURL + "/file/upload/" + resultChatImage?.data?.data?.id;
      }
      const data = {
        ...(madeImgLink && { profile_image: madeImgLink }),
        ...(name && { name }),
        ...(location && { country: location }),
        ...(phone && { phone }),
      };

      const updateProfileChatResult = await updateProfileChat(data);
      if (updateProfileChatResult && updateProfileChatResult?.data?.status) {
        dispatch(signInChat({ ...updateProfileChatResult?.data?.data }));
      } else {
        enqueueSnackbar(result?.data?.message, { variant: "warning" });
      }
    } else {
      dispatch(setLoading(false));
      enqueueSnackbar(result?.data?.message, { variant: "warning" });
      if (result?.data?.errors?.country[0]) {
        setLocationError(result?.data?.errors?.country[0]);
      }
    }
  };

  return (
    <Modal open={updateIBProfileModalOpen} onClose={() => setIBUpdateProfileModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: {
            xs: "95%",
            sm: "80%",
            md: 500,
          },
          background: theme.palette.mode === "dark" ? "var(--color-green-7)" : "var(--color-green-10)",
          borderRadius: "5px",
          padding: "15px",
          outline: "none",
        }}
      >
        <Grid className="flex items-center justify-between">
          <Typography component={"p"} className="!capitalize">
            Profile Update
          </Typography>
          <IconButton onClick={() => setIBUpdateProfileModalOpen(false)} title="Close">
            <HighlightOff className="!cursor-pointer" />
          </IconButton>
        </Grid>
        <Box className="!pt-8">
          <Grid container className="!p-4 !pt-0" rowSpacing={{ xs: 2, sm: 2, md: 2 }} spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid var(--color-gray-2)",
                    },
                    "&:hover fieldset": {
                      border: "1px solid var(--color-green-1)",
                    },
                    "&.Mui-focused fieldset": {
                      border: `1px solid var(--color-green-2)`,
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                    "&.Mui-focused": {
                      color: "var(--color-green-2)",
                    },
                  },
                }}
                size="small"
              >
                <TextField
                  label="User Name"
                  variant="outlined"
                  inputProps={{ sx: { fontSize: 12, WebkitTextFillColor: theme.palette.mode === "dark" ? "white" : "black" } }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid var(--color-gray-2)",
                    },
                    "&:hover fieldset": {
                      border: "1px solid var(--color-green-1)",
                    },
                    "&.Mui-focused fieldset": {
                      border: `1px solid var(--color-green-2)`,
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                    "&.Mui-focused": {
                      color: "var(--color-green-2)",
                    },
                  },
                }}
              >
                <TextField
                  label="Phone"
                  variant="outlined"
                  inputProps={{ sx: { fontSize: 12, WebkitTextFillColor: theme.palette.mode === "dark" ? "white" : "black" } }}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    height: "52px",
                    "& .MuiInputBase-input": {
                      padding: "13.5px 14px",
                    },
                    "& fieldset": {
                      border: "1px solid var(--color-gray-2)",
                    },
                    "&:hover fieldset": {
                      border: "1px solid var(--color-green-1)",
                    },
                    "&.Mui-focused fieldset": {
                      border: `1px solid var(--color-green-2)`,
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                    "&.Mui-focused": {
                      color: "var(--color-green-2)",
                    },
                  },
                }}
              >
                <Autocomplete
                  id="country-select-demo"
                  options={countries}
                  autoHighlight
                  ListboxProps={{
                    className: "mirror-trade-global-scrollbar",
                    sx: { background: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)" },
                  }}
                  getOptionLabel={(option) => option?.label || "Select location"}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Location"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                />
              </FormControl>

              <Typography component={"p"} className="text-red-500 !text-sm !font-semibold">
                {locationError}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid var(--color-gray-2)",
                    },
                    "&:hover fieldset": {
                      border: "1px solid var(--color-green-1)",
                    },
                    "&.Mui-focused fieldset": {
                      border: `1px solid var(--color-green-2)`,
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                    "&.Mui-focused": {
                      color: "var(--color-green-2)",
                    },
                  },
                }}
                size="small"
              >
                <TextField
                  label="Email"
                  variant="outlined"
                  value={user?.email}
                  inputProps={{
                    style: { fontSize: 12, WebkitTextFillColor: theme.palette.mode === "dark" ? "white" : "black" },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <FormControl>
                <Box sx={profilePic}>
                  <Box className="profilePicUpdate">
                    <img src={url} alt="" className="w-full h-[100px]" />
                    <ReactFileReader fileTypes={["jpg", "jpeg", "png"]} base64={true} handleFiles={handleFiles}>
                      <Typography component="input" className="profilePicUpdateBtn" type="button" value={"Edit"} />
                    </ReactFileReader>
                  </Box>
                </Box>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={6} container className="flex justify-end items-end">
              <Button
                onClick={updateProfileHandler}
                size="small"
                sx={{
                  background: "var(--color-green-1)",
                  textTransform: "capitalize",
                  boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
                  "&:hover": {
                    color: "var(--color-white-1)",
                    background: "var(--color-green-2)",
                  },
                }}
              >
                {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Update"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default memo(ProfileUpdateIBDashboard);
