import { FormControl, MenuItem, Select, useTheme } from "@mui/material";
import React, { memo } from "react";
import { CSVLink } from "react-csv";
import { DownloadTableExcel } from "react-export-table-to-excel";

const ExportTypeSelect = memo(({ exportType, setExportType, clients, tableRef }) => {
  const theme = useTheme();
  return (
    <FormControl
      sx={{
        "& .MuiOutlinedInput-root": {
          backgroundColor: theme.palette.mode === "dark" ? "var(--color-green-8)" : "var(--color-green-10)",
          height: "35px",
          width: "120px",
          fontSize: "14px",
          "& fieldset": {
            border: "1px solid var(--color-green-7)",
          },
          "&:hover fieldset": {
            border: "1px solid var(--color-green-1)",
          },
          "&.Mui-focused fieldset": {
            border: `1px solid var(--color-green-2)`,
          },
        },
        "& .MuiInputLabel-root": {
          color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
          "&.Mui-focused": {
            color: "var(--color-green-2)",
          },
        },
      }}
      size="small"
    >
      <Select
        value={exportType}
        onChange={(e) => setExportType(e.target.value)}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        MenuProps={{
          PaperProps: {
            sx: {
              background: theme.palette.mode === "dark" ? "var(--color-green-9)" : "var(--color-green-10)",
            },
          },
        }}
      >
        <MenuItem className="!text-sm" value={"export"}>
          Export to
        </MenuItem>
        <MenuItem className="!text-sm" value={"csv"}>
          <CSVLink data={clients} className="!no-underline dark:!text-[var(--color-white-1)] !text-[var(--color-black-1)]">
            CSV
          </CSVLink>
        </MenuItem>
        <MenuItem className="!text-sm" value={"excel"}>
          <DownloadTableExcel filename="clients" sheet="clients" currentTableRef={tableRef.current}>
            Excel
          </DownloadTableExcel>
        </MenuItem>
      </Select>
    </FormControl>
  );
});

export default ExportTypeSelect;
