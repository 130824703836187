import chatApi from "../api/chatApi";
import { getAuthTokenChat } from "./chat";

export const getChannelList = async (perPage = 500, page = 1) => {
    try {
        return await chatApi.get(`/channel/list/NEWS?perPage=${perPage}&page=${page}`, {
            headers: {
                Authorization: getAuthTokenChat(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ getChannelList ~ error:", error)
    }
}

export const searchChannelList = async (searchText) => {
    try {
        return await chatApi.post(`/channel/search/NEWS`, searchText, {
            headers: {
                Authorization: getAuthTokenChat(),
            },
        });
    } catch (error) {
    console.log("🚀 ~ searchChannelList ~ error:", error)
    }
}

export const getChannelChatList = async () => {
    try {
        return await chatApi.get(`/channel/chat_list`, {
            headers: {
                Authorization: getAuthTokenChat(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ getChannelChatList ~ error:", error)
    }
}

export const channelHistory = async (channel_id) => {
    try {
        return await chatApi.post(
            "/channel/history",
            {
                channel_id: channel_id,
                page: 1,
                perPage: 500,
            },
            {
                headers: {
                    Authorization: getAuthTokenChat(),
                },
            }
        );
    } catch (error) {
        console.log("🚀 ~ file: chat.js:68 ~ channelHistory= ~ error:", error);
    }
};

export const createChannel = async (data) => {
    try {
        return await chatApi.post(
            "/channel/create",
            data,
            {
                headers: {
                    Authorization: getAuthTokenChat(),
                },
            }
        );
    } catch (error) {
        console.log("🚀 ~ file: chat.js:68 ~ createChannel= ~ error:", error);
    }
};

export const updateChannelContent = async (id, data) => {
    try {
        return await chatApi.put(
            `/channel/news/${id}`, data,
            {
                headers: {
                    Authorization: getAuthTokenChat(),
                },
            }
        );
    } catch (error) {
        console.log("🚀 ~ updateChannelContent ~ error:", error)
    }
};
export const deleteChannelContent = async (id) => {
    try {
        return await chatApi.delete(
            `/channel/content/${id}`,
            {
                headers: {
                    Authorization: getAuthTokenChat(),
                },
            }
        );
    } catch (error) {
        console.log("🚀 ~ deleteChannelContent ~ error:", error)
    }
};