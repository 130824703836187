import { createSlice } from "@reduxjs/toolkit";

const permissionSlice = createSlice({
  name: "permission",
  initialState: {
    permission: []
  },
  reducers: {
    setPermission: (state, action) => {
      state.permission = action.payload;
    },
  },
});

export const { setPermission } = permissionSlice.actions;
export default permissionSlice.reducer;
