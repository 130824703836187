import { East, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from "@mui/material";
import message from "enum/message";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "redux/features/application/applicationSlice";
import { setNavigateAuth } from "redux/features/navigateSlice/navigateSlice";
import { signUp } from "server/auth";
import { registration } from "server/chat";
import * as yup from "yup";
import { Select } from "antd";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const validationSchema = yup.object({
  email: yup.string("Enter your email").email("Enter a valid email").required(message.EMAIL_REQUIRED),
  login: yup.string("Enter your login").required(message.LOGIN_REQUIRED),
  server: yup.string("Enter your server").required(message.SERVER_REQUIRED),
  password: yup.string("Enter your password").min(6, message.MIN_PASSWORD).required(message.PASSWORD_REQUIRED),
});

const MT4Registration = ({ servers }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();
  const loading = useSelector((state) => state.application.loading);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [valueInputOrSelect, setValueInputOrSelect] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      login: "",
      server: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      dispatch(setLoading(true));
      const mirrorResult = await signUp({
        email: values.email,
        auth_type: "mt4",
        login: values.login,
        server: valueInputOrSelect ? values.server : servers.find((i) => i.server_name === values.server)?.server_ip,
        password: values.password,
        subscription: "free",
      });

      if (mirrorResult?.data?.status) {
        enqueueSnackbar(mirrorResult?.data?.message, { variant: "success" });
        dispatch(setLoading(false));
        dispatch(setNavigateAuth("login"));
        const result = await registration({
          name: mirrorResult?.data.data.name,
          email: mirrorResult?.data.data.email,
          password: values.password,
        });

        if (result?.data?.status) {
          dispatch(setLoading(false));
        } else {
          dispatch(setLoading(false));
        }
      } else {
        dispatch(setLoading(false));
        enqueueSnackbar(mirrorResult.data.message, { variant: "warning" });
      }
    },
  });


  return (
    <form autoComplete="off" className="w-[335px] space-y-6" onSubmit={formik.handleSubmit}>
      <Box>
        <TextField
          label="Email"
          variant="standard"
          type="email"
          fullWidth
          size="medium"
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          sx={{
            "& .MuiInput-root": {
              color: "var(--color-black)",
              fontSize: "16px",
              fontWeight: 500,
            },
            "& label": {
              fontSize: "16px",
              color: "var(--color-white-1)",
              fontWeight: 500,
            },
            "& label.Mui-focused": {
              color: "var(--color-green-2)",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "var(--color-green-2)",
            },
          }}
        />
      </Box>

      <Box>
        <TextField
          label="Login"
          variant="standard"
          type="text"
          fullWidth
          size="medium"
          id="login"
          login="login"
          value={formik.values.login}
          onChange={formik.handleChange}
          error={formik.touched.login && Boolean(formik.errors.login)}
          helperText={formik.touched.login && formik.errors.login}
          sx={{
            "& .MuiInput-root": {
              color: "var(--color-black)",
              fontSize: "16px",
              fontWeight: 500,
            },
            "& label": {
              fontSize: "16px",
              color: "var(--color-white-1)",
              fontWeight: 500,
            },
            "& label.Mui-focused": {
              color: "var(--color-green-2)",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "var(--color-green-2)",
            },
          }}
        />
      </Box>

      <Box>
        <TextField
          label="Password"
          variant="standard"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          size="medium"
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          sx={{
            "& .MuiInput-root": {
              color: "var(--color-black)",
              fontSize: "16px",
              fontWeight: 500,
            },
            "& label": {
              fontSize: "16px",
              color: "var(--color-white-1)",
              fontWeight: 500,
            },
            "& label.Mui-focused": {
              color: "var(--color-green-2)",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "var(--color-white-1)",
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "var(--color-green-2)",
            },
          }}
        />
      </Box>

      <Box className="flex flex-row justify-between items-center">
        <TextField
          label="Server"
          variant="standard"
          type="text"
          fullWidth
          size="medium"
          id="server"
          name="server"
          placeholder="12.234.98.77:443"
          value={formik.values.server}
          onChange={(val) => {
            formik.handleChange(val);
            setValueInputOrSelect(true);
          }}
          error={formik.touched.server && Boolean(formik.errors.server)}
          sx={{
            "& .MuiInput-root": {
              color: "var(--color-black) !important",
              fontSize: "16px",
              fontWeight: 500,
            },
            "& label": {
              fontSize: "16px",
              color: "var(--color-white-1) !important",
              fontWeight: 500,
            },
            "& label.Mui-focused": {
              color: "var(--color-green-2) !important",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottomColor: "var(--color-white-1) !important",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "var(--color-white-1) !important",
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "none !important",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "var(--color-green-2) !important",
            },
          }}
        />
        <Select
          suffixIcon={<KeyboardArrowDownIcon sx={{ color: "#fff" }} />}
          value=""
          onChange={(val) => {
            formik.setFieldValue("server", val);
            setValueInputOrSelect(false);
          }}
          onSelect={(val) => {
            formik.setFieldValue("server", val);
            setValueInputOrSelect(false);
          }}
          popupMatchSelectWidth={false}
          dropdownStyle={{
            width: isSmallScreen ? "fit-content" : "300px",
            background: "var(--color-green-1)",
          }}
          className="custom_select_with_input"
          options={servers?.map((cred, index) => ({
            key: index.toString(),
            label: cred.server_name,
            value: cred.server_name,
          }))}
          showSearch={true}
        />
      </Box>
      {formik.touched.server && formik.errors.server && <Typography className="!text-[#f44336] !text-[12px] !mt-1">{formik.touched.server && formik.errors.server}</Typography>}

      <Button
        type="submit"
        sx={{
          background: "var(--color-green-1)",
          textTransform: "none",
          "&:hover": {
            background: "var(--color-green-11)",
          },
        }}
        disableRipple
        variant="contained"
        fullWidth
      >
        {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Registration"}
      </Button>
      <Box className="flex justify-center items-center gap-2 pb-3">
        <Typography>Already have an account?</Typography>
        <Typography
          component={"p"}
          className="hover:!text-[var(--color-green-1)] !cursor-pointer !flex !justify-center !items-center"
          onClick={() => dispatch(setNavigateAuth("login"))}
        >
          Login <East className="!ml-1" />
        </Typography>
      </Box>
    </form>
  );
};

export default memo(MT4Registration);
