import { Avatar, Chip, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import React, { Fragment } from "react";
import {  useSelector } from "react-redux";
import { friend_request_accept } from "server/chat";

const FriendOutgoingList = () => {
  const friendListOutgoing = useSelector((state) => state.chat.friendListOutgoing);

  const handleCancelFriendRequest = async (e, friend_id, withdraw) => {
    e.stopPropagation();
    const result = await friend_request_accept(friend_id, withdraw);
    if (result.data.status) {
    }
  };

  // const showUserProfile = async (item) => {
  //   dispatch(setNavigateChat("user_profile"));
  //   setFriendId(item.user.id);
  // }
  return (
    <Grid item xs={12} className="chat-list-scrollbar" sx={{ height: "300px", width: "100%", overflowY: "auto" }}>
      {friendListOutgoing && friendListOutgoing.length > 0 ? (
        <List sx={{ width: "100%" }}>
          {friendListOutgoing.map((item) => (
            <Fragment key={item.id}>
              <ListItem
                // onClick={() => showUserProfile(item)}
                className="cursor-pointer"
                sx={{
                  "&:hover": {
                    backgroundColor: "var(--border-color)",
                  },
                }}
                secondaryAction={<Chip size="small" color="error" sx={{ fontSize: "11px" }} label="Cancel" onClick={(e) => handleCancelFriendRequest(e, item.id, "WITHDRAW")} />}
              >
                <ListItemAvatar>
                  <Avatar alt="chat list avatar" src={item?.user?.profile?.profile_image}></Avatar>
                </ListItemAvatar>
                <ListItemText primary={item?.user?.name} secondary={item.user?.email} />
              </ListItem>
              <Divider variant="middle" component="li" sx={{ marginLeft: 0, marginRight: 0  }} />
            </Fragment>
          ))}
        </List>
      ) : (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
          <span>No outgoing friend requests</span>
        </div>
      )}
    </Grid>
  );
};

export default FriendOutgoingList;
