import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { DrawerHeader } from "layouts/Layout";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Fragment, useEffect, useState } from "react";
import { IconButton, useMediaQuery } from "@mui/material";
import { Close } from "@mui/icons-material";
import ChatListFullScreenDrawer from "../ChatList/ChatListFullScreenDrawer";
import ChannelFullScreenDrawer from "../Channel/ChannelFullScreenDrawer";
import { useDispatch, useSelector } from "react-redux";
import CreateChannelDrawer from "../Channel/CreateChannelDrawer";
import CreatePostDrawer from "../Channel/CreatePostDrawer";
import EditPostChannelDrawer from "../Channel/EditPostChannelDrawer";
import { setDrawerOpen } from "redux/features/toggle/toggleSlice";

const ChatChannelDrawer = () => {
  const drawerOpen = useSelector((state) => state.toggle.drawerOpen);
  const toggleChatOrChannelDrawer = useSelector((state) => state.toggle.toggleChatOrChannelDrawer);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [tabValue, setTabValue] = useState("chat");
  const dispatch = useDispatch();

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
    localStorage.setItem("tabValue", newValue);
  };

  useEffect(() => {
    const storedTabValue = localStorage.getItem("tabValue");
    if (storedTabValue) {
      setTabValue(storedTabValue);
    }
  }, []);

  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      sx={{
        "& .MuiDrawer-paper": {
          overflowY: "hidden",
        },
      }}
    >
      <DrawerHeader />
      <Box sx={{ width: isSmallScreen ? "310px" : 350 }} role="presentation">
        <Box sx={{ background: "var(--color-dark-green)", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Tabs
            TabIndicatorProps={{
              style: { display: "none" },
            }}
            value={tabValue}
            onChange={handleTabChange}
            sx={{
              "& .MuiTab-root": {
                color: "var(--color-white-1)",
                "&.Mui-selected": {
                  color: "var(--color-green-1)",
                  background: "var(--color-green-8)",
                },
              },
            }}
          >
            <Tab value="chat" label="Chat" />
            <Tab value="channel" label="Channel" />
          </Tabs>
          <IconButton title="Close" onClick={() => dispatch(setDrawerOpen(false))}>
            <Close />
          </IconButton>
        </Box>
        {tabValue === "chat" && <ChatListFullScreenDrawer />}
        {tabValue === "channel" && (
          <Fragment>
            {toggleChatOrChannelDrawer === "channel" && <ChannelFullScreenDrawer />}
            {toggleChatOrChannelDrawer === "createChannel" && <CreateChannelDrawer />}
            {toggleChatOrChannelDrawer === "createPost" && <CreatePostDrawer />}
            {toggleChatOrChannelDrawer === "editPost" && <EditPostChannelDrawer />}
          </Fragment>
        )}
      </Box>
    </Drawer>
  );
};

export default ChatChannelDrawer;
