import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, useTheme } from "@mui/material";
import permissionEnum from "enum/permissionEnum";
import Layout from "layouts/Layout";
import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import AdvancedTechnicalAnalysisVideo from "./AdvancedTechnicalAnalysisVideo";
import CryptoTradingInvestingVideo from "./CryptoTradingInvestingVideo";
import CryptocurrencyFundamentalsVideo from "./CryptocurrencyFundamentalsVideo";
import ExpertTechnicalAnalysisVideo from "./ExpertTechnicalAnalysisVideo";
import IntroductionFinancialMarketsVideo from "./IntroductionFinancialMarketsVideo";
import MarketHighlightsTVVideo from "./MarketHighlightsTVVideo";
import MarketResearchVideos from "./MarketResearchVideos";
import MetaTraderTutorialsVideo from "./MetaTraderTutorialsVideo";
import TechnicalAnalysisEssentialsVideo from "./TechnicalAnalysisEssentialsVideo";


const VideoAcademy = () => {
  const permission = useSelector((state) => state.permission.permission);
  const theme = useTheme();
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (permission.length === 0) return;
    if (!permission?.includes(permissionEnum["/video-academy"])) {
      window.location.href = "/";
    }
  }, [permission]);

  return (
    <Layout>
      <Box
        sx={{
          height: "100%",
        }}
        className="h-screen bg-[#051C14]"
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "transparent",
                  },
                  "& .MuiTab-root": {
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-white-2)",
                    "&.Mui-selected": {
                      backgroundColor: "var(--color-green-2)",
                    },
                  },
                }}
                className="dark:bg-[var(--color-green-3)] bg-[#C5F0DC] !h-[30px]"
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case dark:!font-normal !font-medium"
                  label="Advanced Technical Analysis"
                  value="1"
                />
                <Tab
                  className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case dark:!font-normal !font-medium"
                  label="Crypto Trading Investing"
                  value="2"
                />
                <Tab
                  className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case dark:!font-normal !font-medium"
                  label="Cryptocurrency Fundamentals"
                  value="3"
                />
                <Tab
                  className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case dark:!font-normal !font-medium"
                  label="Expert Technical Analysis"
                  value="4"
                />
                <Tab
                  className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case dark:!font-normal !font-medium"
                  label="Introduction Financial Markets"
                  value="5"
                />
                <Tab
                  className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case dark:!font-normal !font-medium"
                  label="MetaTrader Tutorials"
                  value="6"
                />
                <Tab
                  className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case dark:!font-normal !font-medium"
                  label="Technical Analysis Essentials"
                  value="7"
                />
                <Tab
                  className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case dark:!font-normal !font-medium"
                  label="Market High Lights TV"
                  value="8"
                />
                <Tab
                  className="dark:!text-[var(--color-white-1)] !text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case dark:!font-normal !font-medium"
                  label="Market Research Videos"
                  value="9"
                />
              </TabList>
            </Box>
            <TabPanel className="!p-0" value="1">
              <AdvancedTechnicalAnalysisVideo />
            </TabPanel>
            <TabPanel className="!p-0" value="2">
              <CryptoTradingInvestingVideo />
            </TabPanel>
            <TabPanel className="!p-0" value="3">
              <CryptocurrencyFundamentalsVideo />
            </TabPanel>
            <TabPanel className="!p-0" value="4">
              <ExpertTechnicalAnalysisVideo />
            </TabPanel>
            <TabPanel className="!p-0" value="5">
              <IntroductionFinancialMarketsVideo />
            </TabPanel>
            <TabPanel className="!p-0" value="6">
              <MetaTraderTutorialsVideo />
            </TabPanel>
            <TabPanel className="!p-0" value="7">
              <TechnicalAnalysisEssentialsVideo />
            </TabPanel>
            <TabPanel className="!p-0" value="8">
              <MarketHighlightsTVVideo />
            </TabPanel>
            <TabPanel className="!p-0" value="9">
              <MarketResearchVideos />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Layout>
  );
};

export default memo(VideoAcademy);
