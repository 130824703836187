export const timeZoneTab = [
    {
        id: 1,
        title: "Americas",
    },
    {
        id: 2,
        title: "Europe and Africa",
    },
    {
        id: 3,
        title: "Middle East",
    },
    {
        id: 4,
        title: "Asia and Pacific",
    },
];

export const americasTab = [
    {
        id: 3,
        timeZone: "GMT -01:00 -",
        title: "Central European Summer Time, South Africa Standard Time",
        des: "Amsterdam, Berlin, Brussels, Budapest, Cape Town, Madrid, Oslo, Paris, Prague, Rome, Stockholm, Vienna, Warsaw, Zürich",
    },
    {
        id: 2,
        timeZone: "GMT -01:00 -",
        title: "British Summer Time, Irish Standard Time, Western European Summer Time",
        des: "Douglas, Dublin, Las Palmas de Gran Canaria, Lisbon, London",
    },
    {
        id: 4,
        timeZone: "GMT -01:00 -",
        title: "Central European Summer Time, South Africa Standard Time",
        des: " Amsterdam, Berlin, Brussels, Budapest, Cape Town, Madrid, Oslo, Paris, Prague, Rome, Stockholm, Vienna, Warsaw, Zürich",
    },
    {
        id: 1,
        timeZone: "GMT -01:00 -",
        title: "Cape Verde Time",
        des: "Cape Verde",
    },
    {
        id: 5,
        timeZone: "GMT -01:00 -",
        title: "Samara Time",
        des: "Samara",
    },

]
export const europeTab = [
    {
        id: 1,
        timeZone: "GMT -01:00 -",
        title: "Cape Verde Time",
        des: "Cape Verde",
    },
    {
        id: 2,
        timeZone: "GMT +01:00 -",
        title: "British Summer Time, Irish Standard Time, Western European Summer Time",
        des: "Douglas, Dublin, Las Palmas de Gran Canaria, Lisbon, London",
    },
    {
        id: 3,
        timeZone: "GMT +02:00 -",
        title: "Central European Summer Time, South Africa Standard Time",
        des: "Amsterdam, Berlin, Brussels, Budapest, Cape Town, Madrid, Oslo, Paris, Prague, Rome, Stockholm, Vienna, Warsaw, Zürich",
    },

    {
        id: 4,
        timeZone: "GMT +03:00 -",
        title: "Central European Summer Time, South Africa Standard Time",
        des: " Amsterdam, Berlin, Brussels, Budapest, Cape Town, Madrid, Oslo, Paris, Prague, Rome, Stockholm, Vienna, Warsaw, Zürich",
    },
    {
        id: 5,
        timeZone: "GMT +04:00 -",
        title: "Samara Time",
        des: "Samara",
    },

]
export const middleEastTab = [
    {
        id: 1,
        timeZone: "GMT +03:00 -",
        title: "Israel Daylight Time",
        des: " Jerusalem",
    },
    {
        id: 2,
        timeZone: "GMT +04:00 -",
        title: " Georgia Standard Time, Gulf Standard Time",
        des: " Dubai, Tbilisi",
    },
    {
        id: 3,
        timeZone: "GMT +04:30 -",
        title: "Afghanistan Time, Iran Daylight Time",
        des: " Kabul, Tehran",
    },
]
export const asiaPacificTab = [
    {
        id: 1,
        timeZone: "GMT +11:00 -",
        title: "Niue Time",
        des: "Niue",
    },
    {
        id: 2,
        timeZone: "GMT +10:00 -",
        title: "Cook Island Time, Hawaii Standard Time, Tahiti Time",
        des: "Avarua, Honolulu, Papeete",
    },
    {
        id: 3,
        timeZone: "GMT +09:30 -",
        title: "Marquesas Time",
        des: "Marquesas Islands",
    },
    {
        id: 4,
        timeZone: "GMT +09:00 -",
        title: "Gambier Time",
        des: " Gambier Islands",
    },
    {
        id: 5,
        timeZone: "GMT +08:00 -",
        title: "Pitcairn Standard Time",
        des: "Adamstown",
    },
    {
        id: 6,
        timeZone: "GMT +04:00 -",
        title: "Armenia Time",
        des: "Yerevan",
    },
    {
        id: 7,
        timeZone: "GMT +05:00 -",
        title: "Pakistan Standard Time, Turkmenistan Time,Yekaterinburg Time",
        des: "Ashgabat, Islamabad, Yekaterinburg",
    },
    {
        id: 8,
        timeZone: "GMT +05:30 -",
        title: "India Standard Time",
        des: "Colombo, Mumbai",
    },
    {
        id: 9,
        timeZone: "GMT +05:45 -",
        title: "Nepal Time",
        des: "Kathmandu",
    },
    {
        id: 10,
        timeZone: "GMT +06:00 -",
        title: "Alma-Ata Time, Kyrgyzstan Time, Omsk Standard Time",
        des: "Almaty, Bishkek, Omsk",
    },
    {
        id: 11,
        timeZone: "GMT +06:30 -",
        title: "Cocos Islands Time, Myanmar Time",
        des: "Cocos Islands, Naypyidaw",
    },
]

export const americasCountries = [
    { id: 1, country: "United States", flag: require('../assets/flag/US.gif') },
    { id: 2, country: "Canada", flag: require('../assets/flag/CA.gif') },
    { id: 3, country: "Colombia", flag: require('../assets/flag/CO.gif') },
    { id: 4, country: "Mexico", flag: require('../assets/flag/MX.gif') },
    { id: 5, country: "Brazil", flag: require('../assets/flag/BR.gif') },
]

export const euroAreaCountries = [
    { id: 1, country: "Italy", flag: require('../assets/flag/IT.gif') },
    { id: 2, country: "Finland", flag: require('../assets/flag/FI.gif') },
    { id: 3, country: "Germany", flag: require('../assets/flag/DE.gif') },
    { id: 4, country: "Greece", flag: require('../assets/flag/GR.gif') },
    { id: 5, country: "Netherlands", flag: require('../assets/flag/NL.gif') },
    { id: 6, country: "France", flag: require('../assets/flag/FR.gif') },
    { id: 7, country: "Austria", flag: require('../assets/flag/AT.gif') },
    { id: 8, country: "Portugal", flag: require('../assets/flag/PT.gif') },
    { id: 9, country: "Spain", flag: require('../assets/flag/ES.gif') },
    { id: 10, country: "Ireland", flag: require('../assets/flag/IE.gif') },
    { id: 11, country: "Belgium", flag: require('../assets/flag/BE.gif') },
]

export const nonEuroZone = [
    { id: 1, country: "Sweden", flag: require('../assets/flag/SE.gif') },
    { id: 2, country: "Norway", flag: require('../assets/flag/NO.gif') },
    { id: 3, country: "Czech Republic", flag: require('../assets/flag/CZ.gif') },
    { id: 4, country: "Switzerland", flag: require('../assets/flag/CH.gif') },
    { id: 5, country: "Hungary", flag: require('../assets/flag/HU.gif') },
    { id: 6, country: "Denmark", flag: require('../assets/flag/DK.gif') },
    { id: 7, country: "Poland", flag: require('../assets/flag/PL.gif') },
    { id: 8, country: "United Kingdom", flag: require('../assets/flag/UK.gif') },
]

export const asiaPacific = [
    { id: 1, country: "Japan", flag: require('../assets/flag/JP.gif') },
    { id: 2, country: "India", flag: require('../assets/flag/IN.gif') },
    { id: 3, country: "Hong Kong", flag: require('../assets/flag/HK.gif') },
    { id: 4, country: "Australia", flag: require('../assets/flag/AU.gif') },
    { id: 5, country: "South Korea", flag: require('../assets/flag/ZA.gif') },
    { id: 6, country: "Taiwan", flag: require('../assets/flag/TW.gif') },
    { id: 7, country: "New Zealand", flag: require('../assets/flag/NZ.gif') },
    { id: 8, country: "Thailand", flag: require('../assets/flag/TH.gif') },
    { id: 9, country: "Indonesia", flag: require('../assets/flag/ID.gif') },
    { id: 10, country: "China", flag: require('../assets/flag/CN.gif') },
    { id: 11, country: "Singapore", flag: require('../assets/flag/SG.gif') },
    { id: 12, country: "Philippines", flag: require('../assets/flag/PH.gif') },
]

export const middleEastAfrica = [
    { id: 1, country: "Israel", flag: require('../assets/flag/IL.gif') },
    { id: 2, country: "South Africa", flag: require('../assets/flag/SA.gif') },
]