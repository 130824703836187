import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Box, Button, Collapse, FormControl, Grid, TextField, Typography, useTheme } from "@mui/material";
import React, { Fragment, memo, useState } from "react";
import flag1 from "../../assets/flag/ER.gif";
import flag2 from "../../assets/flag/BS.gif";
import flag3 from "../../assets/flag/CI.gif";
import FilterTimeZone from "./FilterTimeZone";
import FilterCountryRegion from "./FilterCountryRegion";
import FilterImportanceCalender from "./FilterImportanceCalender";

const FilterSectionCalender = ({ handleFindTile, handlePrint, importance, setImportance }) => {
  const theme = useTheme();
  const [openTime, setOpenTime] = useState(false);
  const [openCountry, setOpenCountry] = useState(false);
  const [openImportance, setOpenImportance] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");

  const handleActiveFilter = (active) => {
    if (active === activeFilter) {
      setActiveFilter("");
      setOpenTime(false);
      setOpenCountry(false);
      setOpenImportance(false);
    } else {
      switch (active) {
        case "time":
          setOpenTime((prevOpenTime) => !prevOpenTime);
          setOpenCountry(false);
          setOpenImportance(false);
          break;
        case "country":
          setOpenCountry((prevOpenCountry) => !prevOpenCountry);
          setOpenTime(false);
          setOpenImportance(false);
          break;
        case "importance":
          setOpenImportance((prevOpenImportance) => !prevOpenImportance);
          setOpenTime(false);
          setOpenCountry(false);
          break;
        default:
          break;
      }
      setActiveFilter(active);
    }
  };

  return (
    <Fragment>
      <Grid container alignItems={"center"} spacing={2} marginTop={"1px"}>
        <Grid item xs={12} md={4} lg={2.5}>
          <Grid
            container
            sx={{
              // width: "176px",
              height: "70px",
              background: activeFilter === "time" ? "var(--color-green-12)" : "var(--color-green-13)",
              color: activeFilter === "time" ? "var(--color-white-1)" : "var(--color-black-1)",
              borderRadius: "0px",
              border: "1px solid #e6e6ff",
            }}
            onClick={() => handleActiveFilter("time")}
            className="flex items-center cursor-pointer"
          >
            <Grid item xs={9} className="text-center">
              <Typography className="!text-[14px] !font-normal">Timezone</Typography>
              <Typography className="!text-[14px] !font-normal">GMT+06:00</Typography>
            </Grid>
            <Grid item xs={3} className="h-full flex justify-center items-center border-l border-solid border-[--color-gray-3]">
              {openTime ? <ArrowDropUp /> : <ArrowDropDown />}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} lg={2.5}>
          <Grid
            container
            sx={{
              // width: "176px",
              height: "70px",
              background: activeFilter === "country" ? "var(--color-green-12)" : "var(--color-green-13)",
              color: activeFilter === "country" ? "var(--color-white-1)" : "var(--color-black-1)",
              borderRadius: "0px",
              border: "1px solid var(--color-white-1)",
            }}
            onClick={() => handleActiveFilter("country")}
            className="flex items-center cursor-pointer"
          >
            <Grid item xs={9} className="text-center">
              <Typography className="!text-[14px] !font-normal">Country/Region</Typography>
              <Box className="flex items-center justify-center gap-1">
                <Box className="h-4 w-4" component={"img"} src={flag1} alt=""></Box>
                <Box className="h-4 w-4" component={"img"} src={flag2} alt=""></Box>
                <Box className="h-4 w-4" component={"img"} src={flag3} alt=""></Box>
                <Box className="!text-[14px] !font-normal">+388</Box>
              </Box>
            </Grid>
            <Grid item xs={3} className="h-full flex justify-center items-center border-l border-solid border-[--color-gray-3]">
              {openCountry ? <ArrowDropUp /> : <ArrowDropDown />}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} lg={2.5}>
          <Grid
            container
            sx={{
              // width: "176px",
              height: "70px",
              background: activeFilter === "importance" ? "var(--color-green-12)" : "var(--color-green-13)",
              color: activeFilter === "importance" ? "var(--color-white-1)" : "var(--color-black-1)",
              borderRadius: "0px",
              border: "1px solid var(--color-white-1)",
            }}
            onClick={() => handleActiveFilter("importance")}
            className="flex items-center cursor-pointer"
          >
            <Grid item xs={9} className="text-center">
              <Typography className="!text-[14px] !font-normal">Importance</Typography>
              <Box className="flex items-center justify-center gap-2">
                <Box className="h-5 w-5 bg-[var(--calender-high)] text-[--color-white-1] text-[12px]">H</Box>
                <Box className="h-5 w-5 bg-[var(--calender-medium)] text-[--color-white-1] text-[12px]">M</Box>
                <Box className="h-5 w-5 bg-[var(--calender-low)] text-[--color-white-1] text-[12px]">L</Box>
              </Box>
            </Grid>
            <Grid item xs={3} className="h-full flex justify-center items-center border-l border-solid border-[--color-gray-3]">
              {openImportance ? <ArrowDropUp /> : <ArrowDropDown />}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={3.5}>
          <FormControl
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "40px",
                width: "100%",
                color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-2)",
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)"
                    : "var(--color-green-10)",
                "& fieldset": {
                  border: "1px solid var(--color-gray-1)",
                },
                "&:hover fieldset": {
                  border: "1px solid var(--color-green-1)",
                },
                "&.Mui-focused fieldset": {
                  border: `1px solid var(--color-green-2)`,
                },
              },
            }}
          >
            <TextField
              onChange={(e) => handleFindTile(e.target.value)}
              inputProps={{
                sx: {
                  "&::placeholder": {
                    color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
                    opacity: 1,
                  },
                },
              }}
              placeholder="Find Events"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} lg={1}>
          <Button
            onClick={handlePrint}
            variant="contained"
            sx={{
              textTransform: "capitalize",
              padding: "8px 25px",
              color: theme.palette.mode === "dark" ? "var(--color-text-2)" : "var(--color-white-1)",
              textAlign: "center",
              fontFamily: "Open Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "14px",
              height: "38px",
              borderRadius: "4px",
              background: theme.palette.mode === "dark" ? "var(--color-green-1)" : "var(--color-black-5)",
              // boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
              "&:hover": {
                background: theme.palette.mode === "dark" ? "var(--color-green-1)" : "var(--color-black-5)",
              },
            }}
          >
            Print
          </Button>
        </Grid>
      </Grid>

      <Collapse in={openTime && activeFilter === "time"} className={"my-dropdown-collapse"}>
        <FilterTimeZone {...{ setOpenTime, setActiveFilter }} />
      </Collapse>
      <Collapse in={openCountry && activeFilter === "country"} className={"my-dropdown-collapse"}>
        <FilterCountryRegion />
      </Collapse>
      <Collapse in={openImportance && activeFilter === "importance"} className={"my-dropdown-collapse"}>
        <FilterImportanceCalender {...{ importance, setImportance }} />
      </Collapse>
    </Fragment>
  );
};

export default memo(FilterSectionCalender);
