import { Box, Grid, } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";

const IdentityVerificationMessage = memo(() => {
  const idVerificationStatus = useSelector((state) => state.kyc.idVerificationStatus);
  return (
    <Box className="p-2">
      <Box className="p-5 dark:bg-[#182d27] bg-[var(--color-green-5)] rounded-md text-[var(--color-white-1)] text-center space-y-1">
        {idVerificationStatus?.doc_type && (
          <Grid container>
            <Grid item xs={4} className="text-right text-base">
              Uploaded document
            </Grid>
            <Grid item xs={4}>
              :
            </Grid>
            <Grid item xs={4} className="font-bold text-green-600 capitalize text-base text-left">
              {idVerificationStatus?.doc_type}
            </Grid>
          </Grid>
        )}
        {idVerificationStatus?.id_number && (
          <Grid container>
            <Grid item xs={4} className="text-right text-base">
              Id number
            </Grid>
            <Grid item xs={4}>
              :
            </Grid>
            <Grid item xs={4} className="font-bold text-green-600 capitalize text-base text-left">
              {idVerificationStatus?.id_number}
            </Grid>
          </Grid>
        )}
        {idVerificationStatus?.issue_date && (
          <Grid container>
            <Grid item xs={4} className="text-right text-base">
              Issue date
            </Grid>
            <Grid item xs={4}>
              :
            </Grid>
            <Grid item xs={4} className="font-bold text-green-600 capitalize text-base text-left">
              {idVerificationStatus?.issue_date}
            </Grid>
          </Grid>
        )}
        {idVerificationStatus?.expire_date && (
          <Grid container>
            <Grid item xs={4} className="text-right text-base">
              Expire date
            </Grid>
            <Grid item xs={4}>
              :
            </Grid>
            <Grid item xs={4} className="font-bold text-green-600 capitalize text-base text-left">
              {idVerificationStatus?.expire_date}
            </Grid>
          </Grid>
        )}
        {idVerificationStatus?.status && (
          <Grid container>
            <Grid item xs={4} className="text-right text-base">
              KYC status
            </Grid>
            <Grid item xs={4}>
              :
            </Grid>
            <Grid item xs={4} className="font-bold text-green-600 capitalize text-base text-left">
              {idVerificationStatus?.status}
            </Grid>
          </Grid>
        )}

        <Grid container>
          <Grid item xs={4} className="text-right ">
            Approve date
          </Grid>
          <Grid item xs={4}>
            :
          </Grid>
          <Grid item xs={4} className="font-bold text-green-600 capitalize text-base text-left">
            {idVerificationStatus?.approved_date ? idVerificationStatus?.approved_date : "...."}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

export default IdentityVerificationMessage;
