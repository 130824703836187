import React, { useEffect, useState } from "react";
import { Alert, AlertTitle, Avatar, Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Layout from "layouts/Layout";
import { useDispatch, useSelector } from "react-redux";
import brokerImg from "../../assets/img/broker.png";
import { setOpenAddAccount } from "redux/features/application/applicationSlice";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IdVerify from "./IdVerify";
import AddVerify from "./AddVerify";
import IdentityVerificationMessage from "./IdentityVerificationMessage";
import ApproveVerification from "./ApproveVerification";
import AddressVerificationMessage from "./AddressVerificationMessage";
import { useNavigate } from "react-router-dom";
import KYCStepsIdVerify from "./KYCStepsIdVerify";
import KYCStepsAddressVerify from "./KYCStepsAddressVerify";
import { switchAccount } from "server/auth";
import { logIn } from "redux/features/auth/authSlice";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import MultipleAccountDropdown from "components/Dashboard/MultipleAccountDropdown";

const Kyc = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const user = useSelector((state) => state.auth.user);
  const idVerificationStatus = useSelector((state) => state.kyc.idVerificationStatus);
  const addressVerificationStatus = useSelector((state) => state.kyc.addressVerificationStatus);
  const theme = useTheme();
  const [status, setStatus] = useState(idVerificationStatus);
  const [addressStatus, setAddressStatus] = useState(addressVerificationStatus);
  const [anchorElMultipleAccount, setAnchorElMultipleAccount] = React.useState(null);
  const [selectedAccount, setSelectedAccount] = React.useState(user?.account_number || "Select account");
  const multipleAccountOpen = Boolean(anchorElMultipleAccount);
  const accounts = useSelector((state) => state.auth.accounts);
  // tab start
  const [tabIndex, setTabIndex] = useState(0);
  // tab end
  const dispatch = useDispatch();

  //tab handle
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const handleOpenAccountModal = () => {
    if (user?.account_number) return;
    dispatch(setOpenAddAccount(true));
  };

  useEffect(() => {
    if (idVerificationStatus) {
      setStatus(idVerificationStatus);
    } else {
      setStatus(idVerificationStatus);
    }

    if (addressVerificationStatus) {
      setAddressStatus(addressVerificationStatus);
    } else {
      setAddressStatus(addressVerificationStatus);
    }
  }, [idVerificationStatus, addressVerificationStatus]);

  const handleClickMultipleAccount = (event) => {
    setAnchorElMultipleAccount(event.currentTarget);
  };
  const handleCloseMultipleAccount = () => {
    setAnchorElMultipleAccount(null);
  };
  const handleSelectedAccount = async (account) => {
    if (account?.account_number === selectedAccount) return;
    setSelectedAccount(account?.account_number);
    const result = await switchAccount(account?.id);
    if (result?.data?.status) {
      localStorage.setItem("auth-token", result?.data?.token);
      localStorage.setItem("refresh-token", result?.data?.refresh_token);
      dispatch(logIn({ ...result?.data?.data }));
      window.location.reload(true);
    }
  };

  return (
    <Layout>
      <Grid container spacing={2} className="!px-4 !pt-4">
        <Grid item xs={12} sm={12} md={12} lg={9}>
          <Box className="!text-center !pb-8">
            <Typography component={"h2"} variant="h4">
              KYC Verifications
            </Typography>
            <Typography component={"p"} variant="p">
              Need Your Identity Verification
            </Typography>
          </Box>
          {/* Tab Start */}
          <Box>
            <Tabs
              sx={{
                marginBottom: "10px",
                "& .Mui-selected": {},
                "& .MuiTabs-flexContainer": {
                  justifyContent: "center",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "transparent",
                },
              }}
              value={tabIndex}
              onChange={handleTabChange}
            >
              <Tab
                disableRipple
                sx={{
                  width: isSmallScreen ? "30%" : "20%",
                  "&.Mui-selected": {
                    background: "var(--color-green-12)",
                  },
                }}
                className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-normal"
                label={
                  <Box className="space-y-2">
                    <Box sx={{ fontSize: isSmallScreen ? "12px" : "14px" }} className="text-base dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] font-semibold">
                      ID Verification
                    </Box>
                  </Box>
                }
              />
              <Tab
                disableRipple
                sx={{
                  width: isSmallScreen ? "30%" : "20%",
                  "&.Mui-selected": {
                    background: "var(--color-green-12)",
                  },
                }}
                className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !text-[12px] !font-[Poppins] !normal-case !font-normal"
                label={
                  <Box className="space-y-2">
                    <Box sx={{ fontSize: isSmallScreen ? "12px" : "14px" }} className="text-base dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] font-semibold">
                      Address Verification
                    </Box>
                  </Box>
                }
              />
            </Tabs>
          </Box>
          {/* Tab End */}
          <Grid container columnSpacing={2}>
            <Grid
              item
              lg={3}
              sx={{
                display: { xs: "none", sm: "none", md: "none", lg: "block" },
              }}
            >
              <Box
                sx={{ boxShadow: theme.palette.mode === "dark" ? "0 -2px 10px rgba(0, 0, 0, 1)" : "0 -2px 10px rgba(0, 0, 0, 0.5)", height: "530px", overflowY: "auto" }}
                className="!p-2 rounded mirror-trade-global-scrollbar"
              >
                {tabIndex === 0 && <KYCStepsIdVerify />}
                {tabIndex === 1 && <KYCStepsAddressVerify />}

                {tabIndex === 0 && idVerificationStatus?.note && (
                  <Alert className="mt-4" variant="outlined" severity="warning">
                    <AlertTitle>
                      <Typography className="!text-sm">Your kyc ID verification is declined.</Typography>
                    </AlertTitle>
                    <Typography className="!text-sm">For reason is : {idVerificationStatus?.note}.</Typography>
                  </Alert>
                )}
                {tabIndex === 1 && addressVerificationStatus?.note && (
                  <Alert className="mt-4" variant="outlined" severity="warning">
                    <AlertTitle>
                      <Typography className="!text-sm">Your kyc address verification is declined.</Typography>
                    </AlertTitle>
                    <Typography className="!text-sm">For reason is : {addressVerificationStatus?.note}.</Typography>
                  </Alert>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={6} lg={6}>
              <Box sx={{ boxShadow: theme.palette.mode === "dark" ? "0 -2px 10px rgba(0, 0, 0, 1)" : "0 -2px 10px rgba(0, 0, 0, 0.5)", height: "530px" }} className="!py-4 rounded">
                <Typography component={"h4"} variant="h6" sx={{ color: theme.palette.mode === "dark" ? "var(--color-gray-2)" : "var(--color-green-6)" }} className="!text-center">
                  {tabIndex === 0 ? "Identity Information" : "Address verification"}
                </Typography>

                {tabIndex === 0 &&
                  ((status?.doc_type !== "adhar card" && new Date(status?.expire_date) && new Date(status?.issue_date) > new Date(status?.expire_date)) ||
                  status === "not_found" ||
                  status?.status === "declined" ? (
                    <IdVerify {...{ setStatus }} />
                  ) : status === "pending" || status?.status === "pending" ? (
                    <IdentityVerificationMessage />
                  ) : (
                    status?.status === "approved" && <ApproveVerification />
                  ))}

                {tabIndex === 1 &&
                  ((new Date(addressStatus?.expire_date) && new Date(addressStatus?.issue_date) > new Date(addressStatus?.expire_date)) ||
                  addressStatus === "not_found" ||
                  addressStatus?.status === "declined" ? (
                    <AddVerify {...{ setAddressStatus }} />
                  ) : addressStatus === "pending" || addressStatus?.status === "pending" ? (
                    <AddressVerificationMessage />
                  ) : (
                    addressStatus?.status === "approved" && <ApproveVerification />
                  ))}
              </Box>
            </Grid>
            <Grid item sm={4} md={6} lg={3} sx={{ display: { xs: "none", sm: "block" } }}>
              <Box
                sx={{ boxShadow: theme.palette.mode === "dark" ? "0 -2px 10px rgba(0, 0, 0, 1)" : "0 -2px 10px rgba(0, 0, 0, 0.5)", height: "530px" }}
                className="!px-4 !py-4 rounded"
              >
                <Typography
                  component={"h2"}
                  variant="h5"
                  className="!uppercase text-center"
                  sx={{ borderBottom: "1px solid var(--color-gray-1)", paddingBottom: "10px", fontSize: { sm: "18px" } }}
                >
                  KYC Information
                </Typography>
                <Box className="!mt-6">
                  <Grid container className="!mt-2" wrap="nowrap" spacing={2}>
                    <Grid item xs={6} sm={4} md={4}>
                      Name
                    </Grid>
                    <Grid item xs={6} sm={8} md={8} className="dark:!text-[var(--color-gray-1)] !capitalize">
                      {user?.name}
                    </Grid>
                  </Grid>

                  <Grid container className="!mt-2" wrap="nowrap" spacing={2}>
                    <Grid item xs={6} sm={4} md={4}>
                      Email
                    </Grid>
                    <Grid item xs={6} sm={8} md={8}>
                      <Typography noWrap className="dark:!text-[var(--color-gray-1)] ">
                        {user?.email}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="!mt-2" wrap="nowrap" spacing={2}>
                    <Grid item xs={6} sm={4} md={4}>
                      <Typography whiteSpace="nowrap">Address</Typography>
                    </Grid>
                    <Grid item xs={6} sm={8} md={8}>
                      <Typography className="dark:!text-[var(--color-gray-1)] !capitalize">{user?.address ? user?.address : "..."}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="!mt-2" wrap="nowrap" spacing={2}>
                    <Grid item xs={6} sm={4} md={4}>
                      <Typography whiteSpace="nowrap">Country</Typography>
                    </Grid>
                    <Grid item xs={6} sm={8} md={8} className="dark:!text-[var(--color-gray-1)] !capitalize">
                      {user?.country ? user?.country : "..."}
                    </Grid>
                  </Grid>
                  <Grid container className="!mt-2" wrap="nowrap" spacing={2}>
                    <Grid item xs={6} sm={3} md={4}>
                      <Typography>Phone</Typography>
                    </Grid>
                    <Grid item xs={6} sm={9} md={8}>
                      <Typography noWrap className="dark:!text-[var(--color-gray-1)] ">
                        {user?.phone ? user?.phone : "..."}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="!mt-2" wrap="nowrap" spacing={2}>
                    <Grid item xs={6} sm={3} md={4}>
                      Zip Code
                    </Grid>
                    <Grid item xs={6} sm={9} md={8} whiteSpace="nowrap" className="dark:!text-[var(--color-gray-1)] ">
                      {user?.zipcode ? user?.zipcode : "..."}
                    </Grid>
                  </Grid>

                  <Grid container className="!mt-2" wrap="nowrap" spacing={2}>
                    <Grid item xs={6} sm={4} md={4}>
                      Verified
                    </Grid>
                    <Grid item xs={6} sm={8} md={8}>
                      <Typography noWrap className="dark:!text-[var(--color-gray-1)] ">
                        {user?.kyc_status}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} lg={3} sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
          <Grid container rowSpacing={4} columnSpacing={{ sm: 2 }} className="dashBoradRightSide">
            <Grid item xs={12} sm={4} md={12} order={{ xs: 3, sm: 1 }}>
              <Box
                sx={{ filter: "lg:drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))", boxShadow: "0px 16.692px 38.949px 0px rgba(7, 71, 53, 0.10)" }}
                className="dark:bg-[var(--color-black-9)] md:dark:bg-[var(--color-black-10)] bg-[#0F3529] rounded-[10px] flex flex-col justify-center items-center py-[50px] px-[10px] backdrop-blur-[6px] "
              >
                <Typography className="profileTitle !pb-[10px] !text-[22px]  !text-[var(--color-white-1)]">My Account</Typography>
                {accounts?.length > 0 && (
                  <Button
                    onClick={handleClickMultipleAccount}
                    endIcon={multipleAccountOpen ? <ExpandLess sx={{ color: "var(--color-green-2)" }} /> : <ExpandMore sx={{ color: "var(--color-green-2)" }} />}
                    size="small"
                    className="!mb-[14px]"
                    sx={{
                      background: "var(--color-green-15)",
                      textTransform: "capitalize",
                      boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
                      color: "var(--color-white-1)",
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      borderRadius: "5px",
                      minWidth: "100px !important",
                      minHeight: "40px !important",

                      "&:hover": {
                        background: "var(--color-green-15)",
                      },
                    }}
                  >
                    {selectedAccount}
                  </Button>
                )}

                <Box>
                  <Avatar sx={{ width: "100px", height: "95px" }} variant="rounded" src={user?.photo} alt="Avatar" />
                </Box>
                <Typography
                  sx={{ marginTop: "20px" }}
                  className="profileDescription !font-[Poppins] !text-[12px] dark:!text-[var(--color-gray-1)] !text-[var(--color-white-1)] !text-center"
                >
                  Distributions of sales across platform
                </Typography>
                <Typography component={"button"} className={`profileBtn !capitalize cursor-pointer`} onClick={handleOpenAccountModal}>
                  Add Account
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4} md={12} order={{ xs: 2, sm: 3 }}>
              <Box
                className="dark:bg-[var(--color-black-9)] md:dark:bg-[var(--color-black-10)] bg-[#0F3529] rounded-[10px] text-[var(--color-white-1)] py-[50px] px-[10px] text-center"
                sx={{
                  boxShadow: "0px 16.692px 38.949px 0px rgba(7, 71, 53, 0.10)",
                  display: "flex",
                  flexFlow: "column",
                  alignItems: "center",
                }}
              >
                <Typography className="!text-[24px] uppercase font-[poppins] !font-bold brokerTitle">Join Broker Account</Typography>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="brokerImage">
                  <Typography component={"img"} src={brokerImg} className="w-full h-auto"></Typography>
                </Box>
                <Typography
                  onClick={() => navigate("/broker-portal")}
                  component={"button"}
                  sx={{
                    color: "var(--color-white-1)",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    padding: "10px 30px",
                    marginTop: "20px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  className="profileBtn"
                >
                  Click Here
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MultipleAccountDropdown {...{ anchorElMultipleAccount, multipleAccountOpen, handleCloseMultipleAccount, handleSelectedAccount }} />
    </Layout>
  );
};

export default Kyc;
