import { Box, Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, Switch, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addGroupList, removeEditGroup, setDetailsShowingGroup, setGroupChatList, setGroupList, setGroupNavigate } from "redux/features/chatSlice/chatSlice";
import { edit_group, get_group_chat_list, get_group_list, upload_image } from "server/chat";
import { enqueueSnackbar } from "notistack";
import ReactFileReader from "react-file-reader";
import { ArrowBack } from "@mui/icons-material";

const profilePic = {
    width: "100px",
    aspectRatio: 1,
    clipPath: "circle(50% at 50% 50%)",
};
const EditGroup = ({ handlePopoverCloseChat }) => {
    const group = useSelector((state) => state.chat.editGroup);
    const [image, setImage] = useState(null);
    const [url, setUrl] = useState(group?.icon ? group?.icon : "/assets/avatar.jpg");
    const [name, setName] = useState(group?.name ? group?.name : "");
    const [description, setDescription] = useState(group?.description ? group?.description : "");
    const [isPrivate, setIsPrivate] = useState(group?.private ? group?.private : false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleFiles = (files) => {
        if (files?.fileList[0]?.type !== "image/jpeg" && files?.fileList[0]?.type !== "image/png") {
            enqueueSnackbar("Only jpg, jpeg, png is allowed", {
                variant: "error",
            });
            return;
        }
        setImage(files?.fileList[0]);
        setUrl(files.base64);
    };

    const updateGroupHandler = async () => {
        try {
            setLoading(true);
            if (name === "") {
                enqueueSnackbar("Name is required", {
                    variant: "error",
                });
                return;
            }

            let icon = group?.icon;
            if (image) {
                const formData = new FormData();
                formData.append("file", image);
                formData.append("attachment_for", "other");
                formData.append("for_id", "create_group");

                const upload = await upload_image(formData);
                if (upload.data?.status) {
                    icon = process.env.REACT_APP_CHAT_API_BASEURL + "/file/upload/" + upload.data.data.id;
                }
            }
            const data = {
                id: group?.id,
                name: name,
                description: description,
                icon: icon,
                private: isPrivate,
            }

            const result = await edit_group(data);
            setLoading(false);
            if (result.data?.status) {
                dispatch(setDetailsShowingGroup(result.data.data));
                dispatch(setGroupNavigate("group_details"));
                dispatch(removeEditGroup());
                dispatch(addGroupList(result.data.data));
                enqueueSnackbar("Group Update Successfully", {
                    variant: "success",
                });
                await updateGroupList();
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    };


    // update group
    const updateGroupList = async () => {
        const updateGroupList = await get_group_list();
        if (updateGroupList.data?.status) {
            dispatch(setGroupList(updateGroupList.data.data));
        }

        const updateChatList = await get_group_chat_list();
        if (updateChatList.data?.status) {
            dispatch(setGroupChatList(updateChatList.data.data));
        }

    };

    return (
        <Grid
            container
            sx={{
                width: "360px",
                height: "400px",
                background: "var(--color-green-8)",
                padding: "15px",
                borderRadius: "10px",
                overflow: "hidden",
                position: 'relative'
            }}>
            <IconButton
                aria-label="back"
                sx={{ position: 'absolute', top: 5, left: 5, color: 'var(--color-white-1)' }}
                onClick={() => dispatch(setGroupNavigate("group_details"))}
                title="Back"
            >
                <ArrowBack sx={{ fontSize: 16 }} />
            </IconButton>
            <Grid
                item
                xs={12}
                className="chat-list-scrollbar"
                sx={{ height: "340px", width: "100%", overflowY: "auto" }}>
                <Grid item xs={12} sm={12} md={12} className="flex justify-center"
                    sx={{
                        marginTop: 2
                    }}
                >
                    <FormControl>
                        <Box sx={profilePic}>
                            <Box className="profilePicUpdate">
                                <img src={url} alt="" className="w-full" />
                                <ReactFileReader fileTypes={["jpg", "jpeg", "png"]} base64={true} handleFiles={handleFiles}>
                                    <Typography component="input" className="profilePicUpdateBtn" type="button" value={"Add"} />
                                </ReactFileReader>
                            </Box>
                        </Box>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{ marginTop: 2 }} className='flex items-center'>
                    <InputLabel className="capitalize w-[20%] mb-0">
                        <Typography
                            component={"span"}
                            className="!text-xs"
                            sx={{ color: "var(--color-white-1)" }}>
                            Name:
                        </Typography>
                    </InputLabel>
                    <FormControl sx={{ width: '80%' }}>
                        <TextField
                            inputProps={{ style: { fontSize: 12 } }}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={{
                                backgroundColor: "var(--color-dark-green)",
                                "& .MuiOutlinedInput-root": {
                                    color: "var(--color-white-1)",
                                    "& fieldset": {
                                        border: 0,
                                    },
                                    "&:hover": {
                                        border: 0,
                                    },
                                    "&.Mui-focused fieldset": {
                                        border: 0,
                                    },
                                },
                                "& .MuiOutlinedInput-input": {
                                    paddingY: "8px",
                                    color: "var(--color-white-1)",
                                    outline: 0,
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{ marginTop: 2 }} className='flex items-center'>
                    <InputLabel className="capitalize w-[20%] mb-0">
                        <Typography
                            component={"span"}
                            className="!text-xs"
                            sx={{ color: "var(--color-white-1)" }}>
                            Description:
                        </Typography>
                    </InputLabel>
                    <FormControl sx={{ width: "80%" }}>
                        <TextField
                            inputProps={{ style: { fontSize: 12 } }}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            sx={{
                                backgroundColor: "var(--color-dark-green)",
                                "& .MuiOutlinedInput-root": {
                                    color: "var(--color-white-1)",
                                    "& fieldset": {
                                        border: 0,
                                    },
                                    "&:hover": {
                                        border: 0,
                                    },
                                    "&.Mui-focused fieldset": {
                                        border: 0,
                                    },
                                },
                                "& .MuiOutlinedInput-input": {
                                    paddingY: "8px",
                                    color: "var(--color-white-1)",
                                    outline: 0,
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{ marginTop: 2 }} className="flex justify-between">
                    <Box className="flex items-center">
                        <Typography
                            component={"span"}
                            className="!text-xs"
                            sx={{ color: "var(--color-white-1)" }}>
                            Private:
                        </Typography>
                        <Switch color="primary" onChange={(e) => setIsPrivate(e.target.checked)} />
                    </Box>
                    {/* <Box className="flex items-center">
                        <Typography
                            component={"span"}
                            className="!text-xs"
                            sx={{ color: "var(--color-white-1)" }}>
                            Visible on search:
                        </Typography>
                        <Switch color="primary" onChange={(e) => setIsVisible(e.target.checked)} />
                    </Box> */}
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{
                    marginTop: 2
                }}>
                    <Button
                        onClick={updateGroupHandler}
                        size="small"
                        disabled={loading}
                        sx={{
                            width: '100%',
                            background: "var(--color-green-1)",
                            textTransform: "capitalize",
                            boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
                            "&:hover": {
                                color: "var(--color-white-1)",
                                background: "var(--color-green-2)",
                            },
                        }}
                    >

                        {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white-1)" }} /> : "Update"}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EditGroup;
