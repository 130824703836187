import { Message, People, Search } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNavigateChat } from "redux/features/chatSlice/chatSlice";

const TopBarChat = ({ handlePopoverCloseChat }) => {
  const chatNavigate = useSelector((state) => state.chat.chatNavigate);
  const dispatch = useDispatch();
  const activeChat = useSelector((state) => state.chat.activeChat);
  return (
    <Grid container alignItems={"center"}>
      <Grid item xs={6} className="overflow-hidden whitespace-nowrap overflow-ellipsis !capitalize">
        {activeChat?.user?.name || "Mirror Chat"}
      </Grid>
      <Grid className="text-end" item xs={2} onClick={() => dispatch(setNavigateChat("chat_list"))}>
        <IconButton title="Chat List" sx={{ 
          backgroundColor: `${chatNavigate === 'chat_list' ? 'var(--btn-bg-color)' : ''}`,
         }}>
          <Message />
        </IconButton>
      </Grid>
      <Grid className="text-end" item xs={2} onClick={() => dispatch(setNavigateChat("friends"))}>
        <IconButton title="Friends" sx={{ 
          backgroundColor: `${chatNavigate === 'friends' ? 'var(--btn-bg-color)' : ''}`,
         }}>
          <People />
        </IconButton>
      </Grid>
      <Grid className="text-end" item xs={2}>
        <IconButton onClick={() => dispatch(setNavigateChat("search_list"))} title="Search friend" sx={{ 
          backgroundColor: `${chatNavigate === 'search_list' ? 'var(--btn-bg-color)' : ''}`,
         }}>
          <Search />
        </IconButton>
      </Grid>
      
    </Grid>
  );
};

export default TopBarChat;
