import { Box } from "@mui/material";
import React, { Fragment, memo } from "react";
import point from "../../assets/img/Oval 6.png";
import { useSelector } from "react-redux";

const AccountDetailsBottomPamGrowthCurve = () => {
  const tradingGrowth = useSelector((state) => state.chart.tradingGrowthBottom);
  return (
    <Fragment>
      <Box className="flex justify-center space-x-8 mt-[20px]">
        <Box title="Total Profit" className="!min-w-14 !w-auto flex justify-between items-center py-[1px] px-[9px] rounded-[16px] dark:bg-[var(--color-dark-green)] bg-transparent dark:border-0 border border-solid border-[var(--color-green-3)] shadow-[0px 9.597px 11.197px 0px rgba(0, 208, 148, 0.05)] backdrop-blur">
          <Box className="text-[12px] dark:text-[var(--color-gray-2)] text-[var(--color-green-3)] font-[HelveticaNeue] font-normal mr-1">$ {tradingGrowth?.profit_total}</Box>
          <Box>
            <Box component={"img"} src={point} alt="" />
          </Box>
        </Box>
        <Box title="Total Loss" className="!min-w-14 !w-auto flex justify-between items-center py-[1px] px-[9px] rounded-[16px] dark:bg-[var(--color-dark-green)] bg-transparent dark:border-0 border border-solid border-[var(--color-green-3)] shadow-[0px 9.597px 11.197px 0px rgba(0, 208, 148, 0.05)] backdrop-blur">
          <Box>
            <Box component={"img"} src={point} alt="" />
          </Box>
          <Box className="text-[12px] dark:text-[var(--color-gray-2)] text-[var(--color-green-3)] font-[HelveticaNeue] ml-1">$ {tradingGrowth?.loss_total}</Box>
        </Box>
        <Box title="Total Volume" className="!min-w-14 !w-auto flex justify-between items-center py-[1px] px-[9px] rounded-[16px] dark:bg-[var(--color-dark-green)] bg-transparent dark:border-0 border border-solid border-[var(--color-green-3)] shadow-[0px 9.597px 11.197px 0px rgba(0, 208, 148, 0.05)] backdrop-blur">
          <Box>
            <Box component={"img"} src={point} alt="" />
          </Box>
          <Box className="text-[12px] dark:text-[var(--color-gray-2)] text-[var(--color-green-3)] font-[HelveticaNeue] ml-1">{tradingGrowth?.volume_total}</Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default memo(AccountDetailsBottomPamGrowthCurve);
